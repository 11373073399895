import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as fromBusinessAccountBeneficiary from './business-account-beneficiary.reducer';

export const businessAccountFeatureKey = 'businessAccount';

export interface BusinessAccountState {
  [fromBusinessAccountBeneficiary.businessAccountBeneficiaryPageFeatureKey]: fromBusinessAccountBeneficiary.BusinessAccountBeneficiaryState;
}

export interface State extends fromRoot.RootState {
  [businessAccountFeatureKey]: BusinessAccountState;
}

export const reducers = (state: BusinessAccountState | undefined, action: Action) => {
  return combineReducers({
    [fromBusinessAccountBeneficiary.businessAccountBeneficiaryPageFeatureKey]: fromBusinessAccountBeneficiary.reducer,
  })(state, action);
};

export const selectBusinessAccountState = createFeatureSelector<BusinessAccountState>(businessAccountFeatureKey);

export const selectBusinessAccountBeneficiaryState = createSelector(
  selectBusinessAccountState,
  (state) => state.businessAccountBeneficiaryPage,
);

export const selectBusinessAccountBeneficiaryDetails = createSelector(
  selectBusinessAccountBeneficiaryState,
  fromBusinessAccountBeneficiary.getBusinessAccountBeneficiary,
);

export const selectBusinessAccountBeneficiaryDetailsLoading = createSelector(
  selectBusinessAccountBeneficiaryState,
  fromBusinessAccountBeneficiary.getLoading,
);

export const selectBusinessAccountBeneficiaryDetailsError = createSelector(
  selectBusinessAccountBeneficiaryState,
  fromBusinessAccountBeneficiary.getError,
);
