import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { emptySpacesValidator } from '@shared/validators';
import { TransactionFormActions } from '@shared/store';

@Component({
  selector: 'app-create-transaction-note',
  templateUrl: './create-transaction-note.component.html',
})
export class CreateTransactionNoteComponent {
  moveNotes = new FormControl<string | undefined>('', [emptySpacesValidator]);

  constructor(private store: Store) {}

  onNoteFieldBlur(): void {
    this.moveNotes.setValue(this.moveNotes.value?.trim());
  }

  onChange(value?: string): void {
    this.store.dispatch(TransactionFormActions.setNote({ note: value?.length ? value : null }));
  }
}
