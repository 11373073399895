import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { RktFormFieldConfig, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';
import { FormModel, MultiLegTransactionWireDetailsModel } from '@shared/models';
import { TransactionFormActions, transactionFormFeature } from '@shared/store';

@Component({
  selector: 'app-create-transaction-wire-details',
  templateUrl: './create-transaction-wire-details.component.html',
})
export class CreateTransactionWireDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() type?: 'from' | 'to' = 'from';

  narrativeFrom = new FormGroup({});

  memoForm = new FormGroup({});

  narrativeFromFields: RktFormFieldConfig[] = [
    formTextarea({
      key: 'description',
      label: '',
      props: {
        required: true,
        minLength: 1,
        maxLength: 1024,
        withCounter: true,
      },
    }),
  ];

  memoFormFields: RktFormFieldConfig[] = [
    formTextarea({
      key: 'memo',
      label: '',
      props: {
        maxLength: 1024,
        withCounter: true,
      },
    }),
  ];

  wireDetailsModel: FormModel<MultiLegTransactionWireDetailsModel> = {
    description: undefined,
    memo: undefined,
  };

  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.store
      .select(transactionFormFeature.selectToAccountWireDetails)
      .pipe(
        concatLatestFrom(() => [this.store.select(transactionFormFeature.selectFromAccountWireDetails)]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([toWireDetails, fromWireDetails]) => {
          if (!isEqual(toWireDetails, { ...this.wireDetailsModel }) && !isEqual(fromWireDetails, { ...this.wireDetailsModel })) {
            if (this.type === 'from') {
              this.wireDetailsModel = { ...this.wireDetailsModel, ...fromWireDetails };
            } else if (this.type === 'to') {
              this.wireDetailsModel = { ...this.wireDetailsModel, ...toWireDetails };
            }
          }
        },
      });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onWireDetailsChange() {
    this.wireDetailsModel = { ...this.wireDetailsModel };

    if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFromWireDetails({ wireDetails: { ...this.wireDetailsModel } }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveToWireDetails({ wireDetails: { ...this.wireDetailsModel } }));
    }
  }
}
