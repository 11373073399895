import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash-es';

@Pipe({ name: 'abs' })
export class AbsNumberPipe implements PipeTransform {
  transform(value?: number | string): string | number {
    if (!value) {
      return '';
    }

    const num = isString(value) ? parseFloat(value) : value;

    return Math.abs(num);
  }
}
