<ng-container *ngIf="cardAccount$ | async as financialAccount; else financialAccountLoading">
  <ng-container *ngTemplateOutlet="financialAccountPage; context: { $implicit: financialAccount, loading: false }"></ng-container>
</ng-container>
<ng-template #financialAccountLoading>
  <ng-container *ngTemplateOutlet="financialAccountPage; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #financialAccountPage [appTemplateContext]="cardAccountPageContext" let-financialAccount let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="cardAccountDetails"></app-card-pages-breadcrumbs>

  <div class="financial-account-balances-row">
    <div class="account-balances">
      <app-financial-account-balances
        class="account-balances-container"
        [accountBalanceValues]="financialAccount?.accountBalances"
        [isLoading]="loading"
        [isFullWidth]="true"
        label="Account Balances"
      ></app-financial-account-balances>
    </div>
  </div>

  <app-tabs [tabItems]="tabItems" [noHorizontalOffset]="true"></app-tabs>
</ng-template>

<router-outlet></router-outlet>
