import { formInput, formInputPhone, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { Patterns } from '@shared/components';

export const formFields = () => {
  return [
    formRow([
      formInput({ key: 'firstName', label: 'First name', props: { required: true } }),
      formInput({ key: 'middleName', label: 'Middle name' }),
      formInput({ key: 'lastName', label: 'Last name', props: { required: true } }),
    ]),
    formRow([
      formInput({ key: 'title', label: 'Job Title', className: 'half-row-field' }),
      formSelect({
        key: 'permissionRoles',
        label: 'Role',
        className: 'half-row-field',
        props: {
          required: true,
          options: [],
        },
      }),
    ]),
    formRow([
      {
        type: '#email',
        props: { pattern: Patterns.EMAIL, minLength: 1, maxLength: 100, disabled: false },
        validation: {
          messages: {
            pattern: 'Enter a valid email',
          },
        },
      },
      formInputPhone({
        key: 'mobileNumber',
        label: 'Mobile number',
        className: 'half-row-field',
        props: { required: true, minLength: 10, maxLength: 40, countryRestrictions: [] },
        validators: { validation: [{ name: 'isValidPhoneNumber' }] },
      }),
    ]),
    formRow([
      formInputPhone({
        key: 'workPhoneNumber',
        label: 'Work phone',
        className: 'half-row-field',
        props: { required: true, minLength: 10, maxLength: 40, countryRestrictions: [] },
        validators: { validation: [{ name: 'isValidPhoneNumber' }] },
        expressions: {
          'props.required': '!!model.workPhoneNumberExtension',
        },
      }),
      formInput({
        key: 'workPhoneNumberExtension',
        label: 'Ext',
        className: 'half-row-field phone-ext-field',
        props: {
          maxLength: 6,
        },
        expressions: {
          'props.disabled': '!model.workPhoneNumber',
        },
      }),
    ]),
  ];
};
