<div class="file-upload-selected-container" *ngIf="uploadedFileLabel">
  <div class="uploaded-file-item">
    <rkt-icon class="file-status" name="check"></rkt-icon>
    <div class="name">{{ uploadedFileLabel }}</div>
    <button type="button" (click)="onReplaceBtnClick()" class="delete-file-btn">Replace</button>
  </div>
</div>

<div class="file-upload-selected-container" *ngIf="fileName; else fileNotUploaded">
  <div class="uploaded-file-item">
    <rkt-icon class="file-status" name="check"></rkt-icon>
    <div class="name">{{ fileName }}</div>
    <button type="button" (click)="onDeleteFileBtnClick()" class="delete-file-btn">Delete</button>
  </div>
</div>

<ng-template #fileNotUploaded>
  <div
    [hidden]="uploadedFileLabel"
    class="file-upload-container"
    [class.hovering]="isDragging"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="stopDrag($event)"
    (dragend)="stopDrag($event)"
  >
    <rkt-icon name="document" class="file-upload-icon"></rkt-icon>

    <div class="title">{{ title }}</div>
    <div class="subtitle">Max file size: 5MB</div>
    <div class="subtitle">Format: {{ fileFormats }}</div>
    <button type="button" class="upload-btn rbnk-btn btn-success" (click)="fileSelector.click()">
      <rkt-icon name="monitor" class="upload-btn-icon"></rkt-icon>
      Upload file
    </button>
    <input #fileSelector type="file" [accept]="fileTypes.join(', ')" (change)="onFileSelected($event)" hidden />
  </div>
</ng-template>
