import { Pipe, PipeTransform } from '@angular/core';
import { toPhone } from '@shared/utils';
import { CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(val?: string, region: CountryCode = 'US'): string {
    if (!val) {
      return '';
    }

    return toPhone(val, region);
  }
}
