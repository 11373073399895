import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, Subject, takeUntil } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { formInput, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { Address, FinancialAccountListItem, FormModel } from '@shared/models';
import { FinancialAccountService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-move-account-address-form',
  templateUrl: './move-account-address-form.component.html',
  styleUrls: ['./move-account-address-form.component.scss'],
})
export class MoveAccountAddressFormComponent implements AfterViewChecked, OnDestroy {
  financialAccount!: FinancialAccountListItem;

  submitError = '';

  isLoading = false;

  addressForm = new FormGroup({});

  addressFormModel: FormModel<Address> = {};

  addressFormFields: FormlyFieldConfig[] = [
    formRow([{ type: '#addressLine1', props: { label: 'Address 1', minLength: 1, maxLength: 50, required: true } }]),
    formRow([formInput({ key: 'addressLine2', label: 'Address 2', props: { minLength: 1, maxLength: 50 } })]),
    formRow([
      formInput({ key: 'city', label: 'City', className: 'city-field', props: { minLength: 1, maxLength: 25, required: true } }),
      formInput({
        key: 'state',
        label: 'State',
        className: 'state-field',
        props: { minLength: 2, maxLength: 3, required: true },
        validators: { validation: [{ name: 'isValidStateCode' }] },
      }),
      formInput({
        key: 'postalCode',
        label: 'Zip Code',
        className: 'postal-code-field',
        props: { minLength: 1, maxLength: 10, required: true },
      }),
    ]),
    formRow([
      formInput({
        key: 'country',
        label: 'Country',
        parsers: [(value?: string) => value?.toUpperCase()],
        props: { minLength: 3, maxLength: 3, required: true },
        validators: { validation: [{ name: 'isValidCountryCode' }] },
        validation: {
          messages: {
            minLength: 'Must be 3 characters',
          },
        },
      }),
    ]),
  ];

  private destroy$ = new Subject<void>();

  constructor(public activeModal: ActiveModal, public ref: ChangeDetectorRef, private financialAccountService: FinancialAccountService) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.addressForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData({ financialAccount }: { financialAccount: FinancialAccountListItem }): void {
    this.financialAccount = financialAccount;
  }

  onAddressChange(): void {
    this.submitError = '';
    this.addressForm.get('addressLine1')?.markAsTouched();
    this.ref.markForCheck();
  }

  onSave(): void {
    const { addressLine1, addressLine2, city, state, country, postalCode } = this.addressFormModel;

    if (!addressLine1 || !postalCode || !country || !city || !state) {
      return;
    }

    this.submitError = '';
    this.isLoading = true;

    this.financialAccountService
      .updateFinancialAccountBillingAddress({
        financialAccountId: this.financialAccount.id,
        address: {
          addressLine1,
          addressLine2,
          city,
          state,
          country,
          postalCode,
        },
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe({
        next: () => {
          this.activeModal.close({
            ...this.financialAccount,
            bankAccount: {
              ...this.financialAccount.bankAccount,
              billingAddress: { addressLine1, addressLine2, city, state, country, postalCode },
            },
          });
        },

        error: (error) => {
          this.submitError = MESSAGE.UNKNOWN_ERROR;
          ErrorUtils.catchError('financialAccountService.updateFinancialAccountBillingAddress error', error);
        },
      });
  }
}
