<div class="page-header">
  <div class="page-header-title">Create Transaction</div>
</div>

<div class="create-transaction-form top-segment">
  <div class="create-transaction-form-header">
    <app-create-transaction-amount class="amount-row"></app-create-transaction-amount>

    <app-create-transaction-amount-options class="amount-options-row"></app-create-transaction-amount-options>
  </div>

  <div class="create-transaction-form-body">
    <div class="financial-account-selection-wrapper">
      <app-create-transaction-financial-account class="financial-account-selection"></app-create-transaction-financial-account>

      <div class="financial-account-selection-icon"></div>

      <app-create-transaction-financial-account class="financial-account-selection" type="to"></app-create-transaction-financial-account>
    </div>
  </div>
</div>

<div class="create-transaction-form mid-segment" *ngIf="(deliverySpeedOptions$ | async)?.length">
  <div class="create-transaction-form-body">
    <app-create-transaction-delivery-speed></app-create-transaction-delivery-speed>
  </div>
</div>

<div class="create-transaction-form bottom-segment">
  <div class="create-transaction-form-body">
    <app-create-transaction-scheduler></app-create-transaction-scheduler>

    <div class="create-transxaction-attachments">
      <app-create-entity-attachment (filesToUpload)="onFilesUpload($event)"></app-create-entity-attachment>
    </div>

    <div class="create-transaction-form-footer">
      <app-create-transaction-note class="create-transaction-note"></app-create-transaction-note>

      <div class="buttons">
        <button
          rktButton
          btnType="tertiary"
          class="create-transaction-btn create-transaction-btn-clear"
          title="Clear"
          (click)="onClearBtnClick()"
        >
          Clear
        </button>

        <button
          rktButton
          title="Continue"
          class="create-transaction-btn create-transaction-btn-continue"
          (click)="onContinueBtnClick()"
          [disabled]="(isCreateTransactionDisabled$ | async) === true"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
