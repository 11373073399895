import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap, switchMap, defaultIfEmpty, concatMap, exhaustMap, take } from 'rxjs/operators';
import { compact, sortBy } from 'lodash-es';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from '@env';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { constants } from '@shared/constants';
import {
  PaymentReason,
  BusinessAccount,
  TransactionReverseRequestData,
  TransactionEditRequestData,
  TeamMember,
  TransactionHistoryList,
  TransactionHistoryListResponse,
  TransactionHistoryRequest,
  TransactionDetailsRaw,
  TransactionDetails,
  MCSendTransactionDetailsRaw,
  MoveMoneyRequest,
  TransactionListRaw,
  TransactionList,
  TransactionReversalRaw,
  EnabledSolutionPermissions,
  EnabledSolutionsRaw,
  TransactionListRequest,
  FilterValues,
  EnabledSolutionConfig,
  TransactionDirection,
  RecipientRaw,
  CustomerDetailRaw,
  SingleLegTransactionFinancialAccount,
  MultiLegPaymentReason,
  MultiLegPaymentReasonRaw,
} from '@shared/models';
import { AuditActions, fromAuth } from '@shared/store';
import { systemTimeZone } from '@shared/utils';
import {
  StorageService,
  TeamMemberService,
  BusinessAccountService,
  FinancialAccountService,
  CustomerService,
  MultiLegTransactionsService,
} from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import {
  mapEnabledSolutionsData,
  mapFinancialAccountHolderInfo,
  mapFinancialAccountInfo,
  mapMCSendTransactionDetails,
  mapTransactionDetails,
  mapTransactionHistoryData,
  mapTransactionListData,
  transactionsRequestParams,
} from './transaction-mapping-utils';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { SKIP_ERROR_NOTIFICATION } from '@shared/interceptors';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  transactionsBasePath = `${environment.transactionFlowEndpoint}/business-accounts`;

  activeTransactionFilters = new BehaviorSubject<FilterValues>({});

  batchTransactionActivityFilters = new BehaviorSubject<FilterValues>({});

  teamMembersName: Record<string, TeamMember['name'] | undefined> = {};

  multiLegTransactionsService = inject(MultiLegTransactionsService);

  constructor(
    private businessAccountService: BusinessAccountService,
    private teamMemberService: TeamMemberService,
    private customerService: CustomerService,
    private backendService: BackendService,
    private storageService: StorageService,
    private store: Store,
    private financialAccountService: FinancialAccountService,
  ) {}

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  /**
   * Retrieves a list of transactions from the backend API for the selected business account based on the provided request parameters.
   * API {{base-url}}/transactionflow/api/portal/v1/business-accounts/{{ba-id}}/transactions
   *
   * @param {TransactionListRequest} requestParams - The request parameters object containing pagination, sorting, filtering and batchId.
   * @returns {Observable<TransactionList>} - An Observable that emits a TransactionList object.
   */
  public getTransactions(requestParams: TransactionListRequest): Observable<TransactionList> {
    const params = transactionsRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService.get<TransactionListRaw>(`${this.transactionsBasePath}/${businessAccountId}/transactions`, { params }).pipe(
          map((transactionsData) => mapTransactionListData(transactionsData)),
          catchError((errorRes) => throwError(() => errorRes)),
        ),
      ),
    );
  }

  /**
   * Retrieves a list of transactions from the backend API for the business account based on the provided request parameters.
   * API {{base-url}}/transactionflow/api/portal/v1/business-accounts/{{ba-id}}/transactions
   *
   * @param {string} businessAccountId - Business Account ID
   * @param {TransactionListRequest} requestParams - The request parameters object containing pagination, sorting, filtering and batchId.
   * @returns {Observable<TransactionList>} - An Observable that emits a TransactionList object.
   */
  public getTransactionList(businessAccountId: BusinessAccount['id'], requestParams: TransactionListRequest): Observable<TransactionList> {
    const params = transactionsRequestParams(requestParams);
    return this.backendService.get<TransactionListRaw>(`${this.transactionsBasePath}/${businessAccountId}/transactions`, { params }).pipe(
      map((transactionsData) => mapTransactionListData(transactionsData)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getPaymentReasons(type?: TransactionDirection): Observable<PaymentReason[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (type) {
      params = params.append('type', type.toUpperCase());
    }

    return this.backendService.get<PaymentReason[]>(`${environment.transactionFlowEndpoint}/payment-reasons`, { params }).pipe(
      map((response) => {
        const reasonsList = response || [];
        const sortedPaymentReasons = sortBy(reasonsList, 'reason');
        return reasonsList.length ? [...sortedPaymentReasons] : [];
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public createMoveMoneyTransaction(moveMoneyData: MoveMoneyRequest, idempotencyKey?: string): Observable<TransactionDetails> {
    let headers = new HttpHeaders();
    if (idempotencyKey) {
      headers = headers.set('Idempotency-Key', idempotencyKey);
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<TransactionDetailsRaw>(`${this.transactionsBasePath}/${businessAccountId}/transactions`, moveMoneyData, { headers })
          .pipe(
            map((transaction) => mapTransactionDetails(transaction)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getTransactionById(businessAccountId: string, transactionId: TransactionDetails['id']): Observable<TransactionDetails> {
    return this.backendService
      .get<TransactionDetailsRaw>(`${this.transactionsBasePath}/${businessAccountId}/transactions/${transactionId}`)
      .pipe(
        exhaustMap((transactionResponse) => {
          return this.getAllPaymentReasons().pipe(
            map((paymentReasons) => ({
              ...transactionResponse,
              paymentReasons,
            })),
          );
        }),
        switchMap((transactionData) => {
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([transactionData.createdBy, transactionData.updatedBy]) }));

          const zonedDate = utcToZonedTime(transactionData.createdAt, systemTimeZone);
          const formattedZonedDate = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

          const estimatedDeliveryDate$ = this.multiLegTransactionsService.getDeliverySpeedOptions({
            debitFinancialAccountId: transactionData.debitFinancialAccountId,
            creditFinancialAccountId: transactionData.creditFinancialAccountId,
            dateTime: formattedZonedDate,
          });

          const debitFinancialAccount$ = this.getFinancialAccountAccountHolder(transactionData.debitFinancialAccount).pipe(
            switchMap((financialAccount) => {
              return of(mapFinancialAccountInfo(financialAccount));
            }),
          );
          const debitFinancialAccountDetails$ = this.financialAccountService.getFinancialAccountDetailsWithBalances(
            transactionData.debitFinancialAccountId,
          );

          const creditFinancialAccount$ = this.getFinancialAccountAccountHolder(transactionData.creditFinancialAccount).pipe(
            switchMap((financialAccount) => {
              return of(mapFinancialAccountInfo(financialAccount));
            }),
          );

          const creditFinancialAccountDetails$ = this.financialAccountService.getFinancialAccountDetailsWithBalances(
            transactionData.creditFinancialAccountId,
          );

          const transactionInitiator$ = transactionData?.initiatorAccountHolderId
            ? this.getSelectedBusinessAccountId().pipe(
                switchMap((accountId) => {
                  if (accountId === transactionData.initiatorAccountHolderId) {
                    return this.store.select(fromAuth.selectBusinessAccountName).pipe(take(1));
                  } else {
                    return this.customerService.getCustomerById(transactionData.initiatorAccountHolderId!).pipe(
                      map((customer) => {
                        return `${customer.firstName} ${customer.lastName}`;
                      }),
                    );
                  }
                }),
              )
            : of(undefined);

          return forkJoin([
            debitFinancialAccount$,
            debitFinancialAccountDetails$,
            creditFinancialAccount$,
            creditFinancialAccountDetails$,
            transactionInitiator$,
            estimatedDeliveryDate$,
          ]).pipe(
            map(
              ([
                debitFinancialAccount,
                debitFinancialAccountDetails,
                creditFinancialAccount,
                creditFinancialAccountDetails,
                transactionInitiatorDetails,
                deliverySpeedOptions,
              ]) => {
                return {
                  ...transactionData,
                  debitFinancialAccount: {
                    ...debitFinancialAccount,
                    availableBalance: debitFinancialAccountDetails.accountBalances?.availableBalance,
                  },
                  creditFinancialAccount: {
                    ...creditFinancialAccount,
                    availableBalance: creditFinancialAccountDetails.accountBalances?.availableBalance,
                  },
                  transactionInitiatorDetails,
                  deliverySpeedOptions,
                };
              },
            ),
          );
        }),
        map((transaction: TransactionDetailsRaw) => mapTransactionDetails(transaction)),
        catchError((errorRes) => throwError(() => errorRes)),
      )
      .pipe(
        concatMap((transactionDetails: TransactionDetails) => {
          if (transactionDetails.solution !== 'push-to-card') {
            return of(transactionDetails);
          }
          return this.backendService
            .get<MCSendTransactionDetailsRaw>(
              `${environment.compositionEndpoint}/business-accounts/${businessAccountId}/transactions/${transactionId}/details`,
            )
            .pipe(
              map((transactionMCSendDetails) => mapMCSendTransactionDetails(transactionDetails, transactionMCSendDetails)),
              catchError(() => of(transactionDetails)),
            );
        }),
      );
  }

  public getSolutionConfigs(solutionNames: string[]): Observable<EnabledSolutionConfig[]> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('solutionNames', solutionNames.join(','));
    return this.backendService.get<EnabledSolutionConfig[]>(`${environment.transactionFlowEndpoint}/solutions/quick-info`, { params }).pipe(
      map((configs) => configs),
      catchError((errorRes) => {
        return throwError(() => errorRes);
      }),
    );
  }

  public reverseTransaction(
    transactionId: TransactionDetails['id'],
    reverseData: TransactionReverseRequestData,
  ): Observable<TransactionDetails> {
    let headers = new HttpHeaders();

    const idempotencyKey = `idemp-${uuidv4()}`;
    headers = headers.set('Idempotency-Key', idempotencyKey);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<TransactionReversalRaw>(
            `${this.transactionsBasePath}/${businessAccountId}/transactions/${transactionId}/reverse`,
            reverseData,
            { headers },
          )
          .pipe(
            map((transaction: TransactionReversalRaw) => {
              const { correctionTransaction, reversalTransaction } = transaction;
              return mapTransactionDetails(correctionTransaction ?? reversalTransaction);
            }),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public editTransaction(transactionId: TransactionDetails['id'], editData: TransactionEditRequestData): Observable<TransactionDetails> {
    let headers = new HttpHeaders();
    const transactionDataString = transactionId + JSON.stringify(editData);
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    headers = headers.set('Idempotency-Key', idempotencyKey);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<TransactionDetailsRaw>(`${this.transactionsBasePath}/${businessAccountId}/transactions/${transactionId}`, editData, {
            headers,
          })
          .pipe(
            map((transaction: TransactionDetailsRaw) => mapTransactionDetails(transaction)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public cancelTransaction(transactionId: TransactionDetails['id']): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService.delete<void>(`${this.transactionsBasePath}/${businessAccountId}/transactions/${transactionId}`).pipe(
          map(() => true),
          catchError((errorRes) => throwError(() => errorRes)),
        ),
      ),
    );
  }

  /**
   * Returns Transaction History List
   * API {{base-url}}/transactionflow/api/portal/v1/business-accounts/{{ba-id}}/transaction-histories
   *
   * @param page - page number
   * @param size - page size
   * @param transactionId - transaction id
   * @returns {Observable<TransactionHistoryList>}
   */

  // TODO missed documentation at the developer center?
  public getTransactionHistory({ transactionId, page, size }: TransactionHistoryRequest): Observable<TransactionHistoryList> {
    const pageSize = size ?? constants.TABLE_ROWS;

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('pageSize', `${pageSize}`)
      .set('transactionId', transactionId);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<TransactionHistoryListResponse>(`${this.transactionsBasePath}/${businessAccountId}/transaction-histories`, { params })
          .pipe(
            tap({
              next: (response) => {
                const { content = [] } = response || {};
                content
                  .filter((item) => !!item.createdBy && item.createdBy.id !== businessAccountId)
                  .forEach((item) => {
                    if (!this.teamMembersName[item.createdBy?.id!]) {
                      this.teamMembersName[item.createdBy?.id!] = undefined;
                    }
                  });
              },
            }),
            switchMap((transactionHistoryResponse) =>
              forkJoin(
                Object.keys(this.teamMembersName).map((teamMemberId) => {
                  if (Object.keys(this.teamMembersName).includes(teamMemberId) && this.teamMembersName[teamMemberId]) {
                    return of({});
                  }
                  const teamMemberRaw = transactionHistoryResponse.content?.find((item) => item.createdBy?.id === teamMemberId);
                  if (teamMemberRaw?.createdBy?.type === 'internal') {
                    this.teamMembersName[teamMemberId] = undefined;
                    return of({});
                  }
                  return this.teamMemberService.getSubjectsQuickInfo([teamMemberId]).pipe(
                    map(([teamMember]) => {
                      this.teamMembersName[teamMemberId] = teamMember?.name ?? undefined;
                    }),
                    catchError(() => of({})),
                  );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => mapTransactionHistoryData(transactionHistoryResponse, this.teamMembersName)),
              ),
            ),
          ),
      ),
    );
  }

  /**
   * Returns Enabled Solutions List
   * API {{base-url}}/accountpermissions/api/portal/permissions/enabled-solutions
   *
   * @returns {Observable<EnabledSolutionPermissions>}
   */
  public getEnabledSolutions(): Observable<EnabledSolutionPermissions> {
    return this.backendService.get<EnabledSolutionsRaw>(`${environment.accountPermissionEndpoint}/permissions/enabled-solutions`).pipe(
      map((response) => mapEnabledSolutionsData(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  // TODO: use state management for filters
  public setFilterParams(filters: FilterValues, isBatch?: boolean): void {
    const key = isBatch ? constants.KOR_TRANSACTION_BATCH_ACTIVITY_FILTER : constants.KOR_TRANSACTION_FILTER;
    this.storageService.setItem(key, filters);
    this.updateFilterParams(filters, isBatch);
  }

  public getFilterParams(isBatch?: boolean): void {
    const key = isBatch ? constants.KOR_TRANSACTION_BATCH_ACTIVITY_FILTER : constants.KOR_TRANSACTION_FILTER;
    const filters = this.storageService.getItem<FilterValues>(key);

    if (filters && Object.keys(filters).length) {
      this.updateFilterParams(filters, isBatch);
    }
  }

  private updateFilterParams(filters: FilterValues, isBatch?: boolean): void {
    if (isBatch) {
      this.batchTransactionActivityFilters.next(filters);
    } else {
      this.activeTransactionFilters.next(filters);
    }
  }

  getFinancialAccountAccountHolder(
    financialAccountResponse: SingleLegTransactionFinancialAccount,
  ): Observable<SingleLegTransactionFinancialAccount> {
    const { accountHolderId, accountHolderType, businessAccountId } = financialAccountResponse;
    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      return this.backendService
        .get<RecipientRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${accountHolderId}`)
        .pipe(
          map((recipient) => mapFinancialAccountHolderInfo({ ...financialAccountResponse, recipient })),
          catchError(() => of(financialAccountResponse)),
        );
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      return this.backendService
        .get<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers/${accountHolderId}`, {
          context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true),
        })
        .pipe(
          map((customer) => mapFinancialAccountHolderInfo({ ...financialAccountResponse, customer })),
          catchError(() => of(financialAccountResponse)),
        );
    } else if (!accountHolderId) {
      return of(true).pipe(
        concatLatestFrom(() => this.store.select(fromAuth.selectActiveBusinessAccountDetails)),
        map(([, activeBusinessAccount]) => {
          if (businessAccountId === activeBusinessAccount.id) {
            return mapFinancialAccountHolderInfo({ ...financialAccountResponse, businessAccount: activeBusinessAccount });
          }

          return financialAccountResponse;
        }),
      );
    } else {
      return of(financialAccountResponse);
    }
  }

  getAllPaymentReasons(): Observable<MultiLegPaymentReason[]> {
    return this.backendService.get<MultiLegPaymentReasonRaw[]>(`${environment.transactionFlowEndpoint}/payment-reasons`).pipe(
      map((response = []) => {
        return sortBy(response, 'reason')
          .filter(({ state, types }) => state === 'ACTIVE' && types?.length)
          .map((reasons) => {
            const { id, reason, allowedSolutions, types } = reasons;
            return { label: reason, value: id, allowedSolutions, types: types! };
          });
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }
}
