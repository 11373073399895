<div class="details-page-header-wrapper" *ngIf="financialAccount; else loadingPlaceholder">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>
      <h1 class="details-page-title">
        {{ titleName }}
      </h1>

      <div class="status-tag" [class.no-value]="!financialAccount.state" [ngClass]="'status-tag-' + financialAccount.state.toLowerCase()">
        {{ financialAccount.state | titlecase }}
      </div>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
  </div>

  <div class="page-header-controls" [class.no-actions]="!financialAccount || !isExternalFinancialAccount" *ngIf="!isEscrowAccount">
    <app-financial-account-actions
      [financialAccount]="financialAccount"
      [financialAccountHolderType]="entityType"
    ></app-financial-account-actions>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL"></app-details-page-header-placeholder>
</ng-template>

<div class="financial-account-balances-limits-row">
  <div class="account-balances">
    <app-financial-account-balances
      class="account-balances-container"
      label="Account Balances"
      [accountBalanceValues]="accountBalanceValues"
      [selectedCurrency]="selectedCurrency"
      [currencyList]="financialAccountCurrencyList"
      [isLoading]="isFinancialAccountLoading"
      (setSelectedCurrency)="setAccountCurrency($event)"
      *ngIf="financialAccount && !isExternalFinancialAccount && !isEscrowAccount"
    ></app-financial-account-balances>
  </div>

  <div class="account-limits">
    <app-transaction-limits
      *ngIf="financialAccount && !isExternalFinancialAccount && !isIntegratedCardFinancialAccount && !isEscrowAccount"
      [isEditAllowed]="financialAccount.accountHolderType !== 'RECIPIENT' && financialAccount.state !== 'DELETED'"
      [scopeId]="financialAccount.id"
      scopeType="FA_KFA"
    ></app-transaction-limits>
  </div>
</div>

<app-tabs [tabItems]="tabItems"></app-tabs>

<router-outlet></router-outlet>
