import { endOfMonth, isPast, parse } from 'date-fns';
import { Patterns } from '@shared/components';

export const cardExpiryCheck = (expiry?: string): string | undefined => {
  if (!expiry) {
    return;
  }

  const month = expiry.substring(0, 2);
  const year = expiry.substring(expiry.length - 4, expiry.length);
  const providedDate = endOfMonth(parse(`${month}/${year}` || '', 'MM/yyyy', new Date()));

  if (month && !month.match(Patterns.MONTH_NUMBER)) {
    return 'Invalid month entered (number between 01 and 12).';
  } else if (year && !year.match(Patterns.YEAR_NUMBER)) {
    return 'Invalid year.';
  } else if (isPast(providedDate)) {
    return 'The card has expired. Please add a new one.';
  } else {
    return undefined;
  }
};
