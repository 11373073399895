import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { isUndefined } from 'lodash-es';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DatatableColumn, DatatableSorting, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { RequestPageParams } from '@shared/models';

interface CardTransactionListItem {
  createdAt: Date;
  merchantName: string;
  id: string;
  transactionType: string;
  paymentReason: string;
  amount: string;
  currency: string;
  status: string;
}

@Component({
  selector: 'app-card-transaction-list',
  templateUrl: './card-transaction-list.component.html',
})
export class CardTransactionListComponent implements AfterViewChecked {
  transactions$: Observable<CardTransactionListItem[]> = of([]);

  totalElements$ = of(0);

  loading$ = of(false);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Merchant Name', prop: 'merchantName', isSortable: true, flexSize: 200 },
    { name: 'Transaction ID', prop: 'id', colType: 'short-id', flexSize: 150 },
    { name: 'Transaction Type', prop: 'transactionType', flexSize: 150 },
    { name: 'Payment Reason', prop: 'paymentReason', flexSize: 150 },
    { name: 'Amount', prop: 'amount', fixedSize: 100 },
    { name: 'Currency', prop: 'currency', fixedSize: 100, colAlign: 'right' },
    { name: 'Status', prop: 'status', fixedSize: 160, colType: 'status-tag', colAlign: 'right' },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  constructor(public ref: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getCardTransactions({ page, sortParams }: FetchData): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }
  }

  onRowClick({ id }: CardTransactionListItem): void {
    this.router.navigateByUrl(`/app/cards/transaction-activity/${id}`);
  }
}
