import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'lodash-es';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { DatatableColumn, DatatableSorting } from '@rocketfinancialcorp/rocket-ui/table';
import { FilterField, FilterValues, RequestPageParams, ScheduledTransactionListItem } from '@shared/models';
import { ScheduledTransactionActions, selectScheduledTransactionsActiveFiltersCount, transactionFeature } from '@shared/store';

@Component({
  selector: 'app-scheduled-transaction-list',
  templateUrl: './scheduled-transaction-list.component.html',
})
export class ScheduledTransactionListComponent implements AfterViewChecked {
  activeFilters$ = this.store.select(transactionFeature.selectScheduledTransactionsActiveFilters);

  activeFiltersCount$ = this.store.select(selectScheduledTransactionsActiveFiltersCount);

  transactions$ = this.store.select(transactionFeature.selectScheduledTransactions);

  totalElements$ = this.store.select(transactionFeature.selectScheduledTransactionsTotalElements);

  isLoading$ = this.store.select(transactionFeature.selectIsLoading);

  searchString?: string;

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'All Time', value: undefined },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      props: { maxMonths: '15' },
    },
    {
      name: 'maskedDebitAccountNumber',
      displayName: 'From Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'maskedCreditAccountNumber',
      displayName: 'To Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    { name: 'amount', displayName: 'Amount', type: 'AMOUNT' },
  ];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Name', prop: 'name', isMultiline: true, flexSize: 315 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 315 },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 315 },
    { name: 'Schedule Type', prop: 'scheduleType', flexSize: 190 },
    { name: 'Type', prop: 'transactionType', fixedSize: 116 },
    { name: 'Amount', prop: 'amount', flexSize: 200, isSortable: true, colType: 'amount', colAlign: 'right' },
    {
      name: 'Status',
      prop: 'status',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  protected destroy$: Subject<void> = new Subject<void>();

  constructor(public ref: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private router: Router, private store: Store) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  onSearch(searchString?: string): void {
    if (!searchString) {
      this.onSearchReset();
      return;
    }
    this.searchString = searchString;
    this.getScheduledTransactions({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.getScheduledTransactions({ page: 0 });
  }

  getScheduledTransactions({ page, sortParams }: RequestPageParams): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      ScheduledTransactionActions.load({
        params: {
          page: page ?? 0,
          searchString: this.searchString,
          sortParams: sortParams ?? this.sortParams,
        },
      }),
    );
  }

  onRowClick({ id }: ScheduledTransactionListItem): void {
    this.router.navigateByUrl(`/app/transactions/scheduled-transactions/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.store.dispatch(ScheduledTransactionActions.setFilters({ filters: { ...filterValues } }));
    this.getScheduledTransactions({ page: 0 });
  }
}
