<div class="entity-actions">
  <button
    *ngIf="customer?.status !== 'SUSPENDED'"
    class="action-button action-button-suspend"
    [disabled]="isControlsDisabled"
    (click)="btnClicked.emit('SUSPEND')"
    title="Suspend Customer"
  >
    <rkt-icon name="suspend"></rkt-icon>
  </button>
  <button
    *ngIf="customer?.status === 'SUSPENDED'"
    class="action-button action-button-unsuspend"
    [disabled]="isControlsDisabled"
    (click)="btnClicked.emit('UNSUSPEND')"
    title="Unsuspend Customer"
  >
    <rkt-icon name="resume"></rkt-icon>
  </button>
</div>
