<h1 class="title">Send Email Statement</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="modal-message" *ngIf="statement">
  The account statement for
  <span class="accent">{{ statement.month + '/1/' + statement.year | dateFormat: 'MMMM yyyy' }}</span> will be emailed to
  <span class="accent email">{{ statement.customerEmail }}</span
  >.
  <br />
  <br />
  Please confirm the cardholder email address before submitting the request.
</div>

<div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="button" (click)="onSubmit()" [disabled]="loading">Confirm</button>
</div>
