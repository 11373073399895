<div class="create-transaction-note-wrapper">
  <div class="create-transaction-note-label">Note</div>

  <textarea
    name="create-transaction-note"
    id="create-transaction-note"
    (ngModelChange)="onChange($event)"
    (blur)="onNoteFieldBlur()"
    [formControl]="moveNotes"
    class="create-transaction-note-textarea"
    maxlength="1000"
  ></textarea>
</div>
