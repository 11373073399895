import { createReducer, on } from '@ngrx/store';
import {
  CardAlert,
  CardDispute,
  CardRestriction,
  CardMerchant,
  CardStatement,
  FinancialAccountDetails,
  CardProgram,
  MccItem,
  SupportedLanguage,
} from '@shared/models';
import { CardAccountActions, MccActions } from './card-account.actions';

export const cardAccountFeatureKey = 'cardAccount';

export interface CardAccountState {
  cardAccountDetails: FinancialAccountDetails | null;
  cardAccountDetailsLoading: boolean;
  restrictionList: CardRestriction[] | null;
  restrictionDetails: CardRestriction | null;
  alertList: CardAlert[] | null;
  alertDetails: CardAlert | null;
  disputeList: CardDispute[] | null;
  disputeDetails: CardDispute | null;
  merchantDetails: CardMerchant | null;
  statementList: CardStatement[] | null;
  cardProgramList: CardProgram[] | null;
  mccItems: MccItem[] | null;
  cardIssueSupportedLanguages: SupportedLanguage[] | null;
}

export const initialState: CardAccountState = {
  cardAccountDetails: null,
  cardAccountDetailsLoading: false,
  restrictionList: null,
  restrictionDetails: null,
  alertList: null,
  alertDetails: null,
  disputeList: null,
  disputeDetails: null,
  merchantDetails: null,
  statementList: null,
  cardProgramList: null,
  mccItems: null,
  cardIssueSupportedLanguages: null,
};

export const reducer = createReducer(
  initialState,
  on(CardAccountActions.loadCardAccountDetails, (state): CardAccountState => {
    return {
      ...state,
      cardAccountDetailsLoading: true,
    };
  }),
  on(CardAccountActions.loadCardAccountDetailsSuccess, (state, action): CardAccountState => {
    return {
      ...state,
      cardAccountDetails: action.financialAccount,
      cardAccountDetailsLoading: false,
    };
  }),
  on(CardAccountActions.loadCardAccountDetailsError, (state): CardAccountState => {
    return {
      ...state,
      cardAccountDetailsLoading: false,
    };
  }),
  on(CardAccountActions.loadRestrictions, (state): CardAccountState => {
    return {
      ...state,
      restrictionList: null,
    };
  }),
  on(CardAccountActions.loadRestrictionsSuccess, (state, action): CardAccountState => {
    return {
      ...state,
      restrictionList: action.restrictionList,
    };
  }),
  on(CardAccountActions.loadRestrictionsError, (state): CardAccountState => {
    return {
      ...state,
      restrictionList: [],
    };
  }),
  on(CardAccountActions.loadAlerts, (state): CardAccountState => {
    return {
      ...state,
      alertList: null,
    };
  }),
  on(CardAccountActions.loadAlertsSuccess, (state, action): CardAccountState => {
    return {
      ...state,
      alertList: action.alertList,
    };
  }),
  on(CardAccountActions.loadAlertsError, (state): CardAccountState => {
    return {
      ...state,
      alertList: [],
    };
  }),
  on(CardAccountActions.loadDisputes, (state): CardAccountState => {
    return {
      ...state,
      disputeList: null,
    };
  }),
  on(CardAccountActions.loadDisputesSuccess, (state, action): CardAccountState => {
    return {
      ...state,
      disputeList: action.disputeList,
    };
  }),
  on(CardAccountActions.loadDisputesError, (state): CardAccountState => {
    return {
      ...state,
      disputeList: [],
    };
  }),
  on(CardAccountActions.loadRestrictionDetails, (state): CardAccountState => {
    return { ...state, restrictionDetails: null };
  }),
  on(CardAccountActions.loadRestrictionDetailsSuccess, (state, action): CardAccountState => {
    return { ...state, restrictionDetails: action.restrictionDetails };
  }),
  on(CardAccountActions.loadRestrictionDetailsError, (state): CardAccountState => {
    return { ...state, restrictionDetails: null };
  }),
  on(CardAccountActions.loadStatements, (state): CardAccountState => {
    return { ...state, statementList: null };
  }),
  on(CardAccountActions.loadStatementsSuccess, (state, action): CardAccountState => {
    return { ...state, statementList: action.statementList };
  }),
  on(CardAccountActions.loadStatementsError, (state): CardAccountState => {
    return { ...state, statementList: [] };
  }),
  on(CardAccountActions.loadAlertDetails, (state): CardAccountState => {
    return { ...state, alertDetails: null };
  }),
  on(CardAccountActions.loadAlertDetailsSuccess, (state, action): CardAccountState => {
    return { ...state, alertDetails: action.alertDetails };
  }),
  on(CardAccountActions.loadAlertDetailsError, (state): CardAccountState => {
    return { ...state, alertDetails: null };
  }),
  on(CardAccountActions.loadDisputeDetails, (state): CardAccountState => {
    return { ...state, disputeDetails: null };
  }),
  on(CardAccountActions.loadDisputeDetailsSuccess, (state, action): CardAccountState => {
    return { ...state, disputeDetails: action.disputeDetails };
  }),
  on(CardAccountActions.loadDisputeDetailsError, (state): CardAccountState => {
    return { ...state, disputeDetails: null };
  }),
  on(CardAccountActions.loadMerchants, (state): CardAccountState => {
    return { ...state, merchantDetails: null };
  }),
  on(CardAccountActions.loadCardPrograms, (state): CardAccountState => {
    return { ...state, cardProgramList: null };
  }),
  on(CardAccountActions.loadCardProgramsSuccess, (state, action): CardAccountState => {
    return { ...state, cardProgramList: action.cardProgramList };
  }),
  on(CardAccountActions.loadCardProgramsError, (state): CardAccountState => {
    return { ...state, cardProgramList: [] };
  }),
  on(MccActions.load, (state): CardAccountState => ({ ...state, mccItems: null })),
  on(MccActions.loadSuccess, (state, { mccItems }): CardAccountState => ({ ...state, mccItems })),
  on(MccActions.loadFailure, (state): CardAccountState => ({ ...state, mccItems: [] })),
  on(CardAccountActions.loadCardIssueSupportedLanguagesSuccess, (state, { cardIssueSupportedLanguages }): CardAccountState => {
    return { ...state, cardIssueSupportedLanguages };
  }),
);

export const getCardAccountDetails = (state: CardAccountState) => state.cardAccountDetails;
export const getCardAccountDetailsLoading = (state: CardAccountState) => state.cardAccountDetailsLoading;
export const getRestrictionList = (state: CardAccountState) => state.restrictionList;
export const getRestrictionDetails = (state: CardAccountState) => state.restrictionDetails;
export const getAlertDetails = (state: CardAccountState) => state.alertDetails;
export const getMerchantDetails = (state: CardAccountState) => state.merchantDetails;
export const getStatementList = (state: CardAccountState) => state.statementList;
export const getCardProgramList = (state: CardAccountState) => state.cardProgramList;
export const getMccItems = (state: CardAccountState) => state.mccItems;
