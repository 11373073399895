import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { emptySpacesValidator } from '@shared/validators';

@Component({
  selector: 'app-move-note-control',
  templateUrl: 'move-note-control.component.html',
  styleUrls: ['move-note-control.component.scss'],
})
export class MoveNoteControlComponent {
  @Input() isMoveFieldsDisabled = false;

  @Output() changed = new EventEmitter<string>();

  moveNotes = new UntypedFormControl('', [emptySpacesValidator]);

  onNoteFieldBlur(): void {
    this.moveNotes.setValue(this.moveNotes.value?.trim());
    this.changed.emit(this.moveNotes.value);
  }

  onChange(value: string): void {
    this.changed.emit(value);
  }

  resetValue(): void {
    this.moveNotes.reset();
  }
}
