import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, finalize, takeUntil } from 'rxjs';
import { formCheckboxGroup, formInput, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardReissue, FormModel } from '@shared/models';
import { CardAccountService, NotificationService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';
import { I2C_CARD_REISSUE_REASONS, I2C_CARD_SHIPPING_METHODS } from '@shared/enums';

interface ReissueCardModel {
  reissueReason: string;
  newProgramId: string;
  nameOnCard: string;
  shippingMethod: string;
  flags: {
    changeCardNumber: boolean;
    changeExpiryDate: boolean;
  };
}

const CARD_REISSUE_FLAGS = [
  { value: 'changeCardNumber', label: 'Change Card Number' },
  { value: 'changeExpiryDate', label: 'Change Expiry Date' },
];

@Component({
  selector: 'app-card-reissue-modal',
  templateUrl: './card-reissue-modal.component.html',
})
export class CardReissueModalComponent implements AfterViewChecked, OnDestroy {
  financialAccountId!: string;

  formError = '';

  loading = false;

  reissueCardForm = new FormGroup({});

  formModel: FormModel<ReissueCardModel> = {
    shippingMethod: 'NORMAL',
    flags: {
      changeCardNumber: false,
      changeExpiryDate: false,
    },
  };

  cardReissueFlags$ = new BehaviorSubject(CARD_REISSUE_FLAGS);

  reissueCardFields = [
    formRow([
      formSelect({
        key: 'reissueReason',
        label: 'Reissue Reason',
        props: {
          options: Object.keys(I2C_CARD_REISSUE_REASONS).map((key) => ({ label: I2C_CARD_REISSUE_REASONS[key], value: key })),
          required: true,
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'shippingMethod',
        label: 'Shipping Method',
        props: {
          options: Object.keys(I2C_CARD_SHIPPING_METHODS).map((key) => ({ label: I2C_CARD_SHIPPING_METHODS[key], value: key })),
          required: true,
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'newProgramId',
        label: 'New Card Program',
        props: { options: [{ value: 'rocketBnkSandbox', label: 'rocketBnkSandbox' }] },
      }),
    ]),
    formRow([formInput({ key: 'nameOnCard', label: 'Nam\u0435 on Car\u0064' })]),
    formRow([formCheckboxGroup({ key: 'flags', label: '', props: { options: this.cardReissueFlags$.asObservable(), required: false } })]),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
  ) {}

  modalInitData({ financialAccountId }: { financialAccountId: string }): void {
    this.financialAccountId = financialAccountId;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.reissueCardForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    const { reissueReason, newProgramId, nameOnCard, shippingMethod, flags: { changeCardNumber, changeExpiryDate } = {} } = this.formModel;

    const cardReissueData: CardReissue = {
      reissueReason: reissueReason!,
      newProgramId,
      nameOnCard,
      shippingMethod: shippingMethod!,
      changeCardNumber,
      changeExpiryDate,
    };

    this.cardAccountService
      .reissueCardAccount(this.financialAccountId, cardReissueData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.activeModal.close(true);
          this.notificationService.displaySuccess('Card successfully reissued');
        },
        error: (error) => {
          this.formError = error === 403 ? MESSAGE.PERMISSION_DENIED : error ?? MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('cardAccountService.reissueCardAccount error', error);
        },
      });
  }
}
