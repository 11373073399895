import { createReducer, on } from '@ngrx/store';
import { DocumentDetails, ErrorMessage } from '@shared/models';
import { DocumentActions } from './document.actions';

export const documentPageFeatureKey = 'detailsPage';

export interface DocumentsState {
  document: DocumentDetails | null;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialDocumentsState: DocumentsState = {
  document: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<DocumentsState>(
  initialDocumentsState,
  on(DocumentActions.initialize, (state): DocumentsState => {
    return {
      ...state,
      isLoading: true,
      document: null,
      error: null,
    };
  }),
  on(DocumentActions.loadDocument, (state): DocumentsState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(DocumentActions.loadDocumentSuccess, (state, action): DocumentsState => {
    return {
      ...state,
      document: action.document,
      isLoading: false,
    };
  }),
  on(DocumentActions.loadDocumentError, (state, action): DocumentsState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getDocument = (state: DocumentsState) => state.document;
export const getLoading = (state: DocumentsState) => state.isLoading;
export const getError = (state: DocumentsState) => state.error;
