import { inject, Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { initializeApp } from 'firebase/app';
import { Auth, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { environment } from '@env';
import { getRealmName } from '@shared/utils';
import { AuthTokenResponse, LoginTenantResponse } from '@shared/models';
import { BackendService } from '@shared/services/backend.service';
import { MESSAGE } from '@shared/constants';
import { APP_ENV_CONFIG } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  envConfig = inject(APP_ENV_CONFIG);

  afAuth?: Auth;

  get realmName(): string {
    return getRealmName();
  }

  constructor(private backendService: BackendService) {}

  // Login - Step 0
  public getAuthTenantInfo(tenant = this.realmName): Observable<LoginTenantResponse> {
    return this.backendService.get<LoginTenantResponse>(`${environment.uaaService}/${tenant}/tenant-info`);
  }

  // Login - Step 1 - KEYCLOAK
  public login(email: string, password: string, tenant = this.realmName): Observable<string> {
    return this.backendService.post(`${environment.uaaService}/${tenant}/login`, { userName: email, password });
  }

  //  Login - Step 1 - FIREBASE | GCP_IDENTITY_PLATFORM
  public googleCloudLogin(email: string, password: string, tenantId?: string): Observable<string> {
    const app = initializeApp(this.envConfig.firebase);
    this.afAuth = getAuth(app);
    this.afAuth.tenantId = tenantId || null;

    return from(signInWithEmailAndPassword(this.afAuth, email, password)).pipe(
      catchError(() => throwError(() => new Error(MESSAGE.LOGIN_ERROR))),
      switchMap(() => {
        if (this.afAuth?.currentUser) {
          return from(this.afAuth.currentUser.getIdToken());
        } else {
          return throwError(() => new Error(MESSAGE.UNKNOWN_ERROR));
        }
      }),
    );
  }

  /**
   * Login - Step 2 - KEYCLOAK | FIREBASE | GCP_IDENTITY_PLATFORM
   * API {{base-url}}/uaa/api/auth/{{tenant}}/exchange-token
   *
   * @param accessToken id token from auth provider KEYCLOAK | FIREBASE | GCP_IDENTITY_PLATFORM
   * @param [tenant] realm name from user input
   * @returns {Observable<AuthTokenResponse>}
   */
  public exchangeToken(accessToken: string, tenant = this.realmName): Observable<AuthTokenResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    return this.backendService.post<AuthTokenResponse>(
      `${environment.uaaService}/${tenant}/exchange-token`,
      { externalToken: accessToken },
      { headers },
    );
  }

  // Refresh token
  public refreshToken(businessAccountId: string, tenant = this.realmName): Observable<AuthTokenResponse> {
    return this.backendService.post(`${environment.uaaService}/${tenant}/business-accounts/${businessAccountId}/token`);
  }

  // Forgot Password
  public forgotPassword(email: string, tenant = this.realmName): Observable<boolean> {
    return this.backendService
      .post(`${environment.accountFlowEndpoint}/api/auth/realms/${tenant}/forgot-password`, { email })
      .pipe(map(() => true));
  }

  // Logout
  public signOut(tenant = this.realmName): Observable<boolean> {
    return this.backendService.post(`${environment.uaaService}/${tenant}/logout`).pipe(
      tap({ next: () => this.afAuth?.signOut() }),
      map(() => true),
      catchError(() => of(true)),
    );
  }
}
