import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as fromAudit from './audit.reducer';

export const auditFeatureKey = 'audit';

export interface AuditState {
  [fromAudit.auditInfoFeatureKey]: fromAudit.AuditInfoState;
}

export interface State extends fromRoot.RootState {
  [auditFeatureKey]: AuditState;
}

export const reducers = (state: AuditState | undefined, action: Action) => {
  return combineReducers({
    [fromAudit.auditInfoFeatureKey]: fromAudit.reducer,
  })(state, action);
};

export const selectAuditInfoState = createFeatureSelector<AuditState>(auditFeatureKey);

export const selectTeamMembersState = createSelector(selectAuditInfoState, (state) => state.auditInfo);

export const selectTeamMembersInfo = createSelector(selectTeamMembersState, fromAudit.getTeamMembersAuditInfo);

export const selectTeamMemberName = (id: string) => createSelector(selectTeamMembersInfo, (teamMembers) => teamMembers[id]);
