import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { formInput, formRow, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { TeamMember } from '@shared/models';
import { NotificationService, TeamMemberService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-team-member-activation-modal',
  templateUrl: './team-member-activation-modal.component.html',
})
export class TeamMemberActivationModalComponent implements AfterViewChecked {
  teamMember!: TeamMember;

  activationError = '';

  formLoading = false;

  activationForm = new FormGroup({});

  activationModel: { [key: string]: string | undefined } = {
    id: '',
    email: '',
  };

  activationFields: RktFormFieldConfig[] = [
    formRow([formInput({ key: 'email', label: 'Email to send account activation link', props: { disabled: true } })]),
  ];

  constructor(
    public activeModal: ActiveModal,
    public ref: ChangeDetectorRef,
    private teamMemberService: TeamMemberService,
    private notificationService: NotificationService,
  ) {}

  modalInitData({ teamMember }: { teamMember: TeamMember }): void {
    this.activationModel = {
      id: teamMember.id,
      email: teamMember.primaryEmail,
    };
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  onSubmit(): void {
    this.activationForm.markAllAsTouched();

    if (this.activationForm.invalid) {
      return;
    }

    const teamMemberData = { ...this.activationModel };

    this.formLoading = true;
    this.activationError = '';

    this.teamMemberService
      .resendEmployeeWelcomeEmail(teamMemberData.id!)
      .pipe(
        finalize(() => {
          this.formLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.notificationService.displaySuccess('The activation email has been sent.');
        },
        error: (error) => {
          this.activationError =
            'We are unable to resend the activation email for this team member, please contact administrator for assistance.';
          ErrorUtils.catchError('teamMemberService.resendWelcomeEmail error', error);
        },
      });
  }
}
