<div class="modal-header-block title">
  <h2>Feature Management</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="flags-wrapper" *ngIf="featureFlagsBoolean$ | async as featureFlags">
  <div class="flags-section">
    <div *ngFor="let featureFlag of featureFlags">
      <div class="switcher-container">
        <div class="switcher">
          <button
            type="button"
            (click)="onSwitch(featureFlag.key)"
            class="input-switch"
            [class.input-switch-checked]="featureFlagsModel[featureFlag.key] === true"
          >
            <div class="input-switch-slider"></div>
          </button>
        </div>

        <div class="switcher-label">{{ featureFlag.name }}</div>
      </div>
    </div>
  </div>
</div>

<app-details-item
  class="app-details-item"
  label="App run on environment"
  [showPlaceholder]="false"
  [value]="currentEnvironment | uppercase"
></app-details-item>

<app-details-item class="app-details-item" label="Environments Presets" [showPlaceholder]="false" [contentValue]="true">
  <div class="env-presets">
    <button *ngFor="let envItem of environmentList" rktButton type="button" (click)="onEnvPresetClick(envItem)">
      {{ envItem | uppercase }}
    </button>
  </div>
</app-details-item>

<div class="buttons-container">
  <button rktButton type="button" btnType="secondary" title="Reset To Env Defaults" (click)="onReset()">Reset To Env Defaults</button>
  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Discard</button>
    <button rktButton type="submit" (click)="onSave()" title="Save">Save</button>
  </div>
</div>
