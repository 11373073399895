import { Injectable } from '@angular/core';
import { compact } from 'lodash-es';
import {
  FinancialAccountListItem,
  MoveHowAchDetails,
  MoveHowWireDetails,
  MoveMoneyScheduleDetails,
  MoveWireDetails,
  PaymentReason,
  Recipient,
  TransactionDetails,
  TransactionDirection,
} from '@shared/models';
import { getSolutionDisplayName, getSolutionIconName, toPhone, toTitleCase } from '@shared/utils';
import { MoveHowSelection } from '@shared/components';

export type MoveRowType = 'move-how' | 'move-how-wire-details' | 'move-how-ach-details' | 'move-reason-amount' | 'move-from' | 'move-to';

export abstract class AbstractMoveMoneyBase {
  abstract moveHow: MoveHowSelection;
  abstract moveHowAchDetails: MoveHowAchDetails;
  abstract moveHowWireDetails: MoveHowWireDetails;
  abstract wireDetails: MoveWireDetails;
  abstract expandedRow?: MoveRowType;
  abstract moveAmount?: string;
  abstract moveAmountError?: boolean;
  abstract note?: string;
  abstract debitFinancialAccountError?: boolean;
  abstract paymentReason?: PaymentReason;
  abstract moveHowDirectionDisplayValue: string;
  abstract moveHowSolutionDisplayValue: string;
  abstract moveHowSolutionIcon: string;
  abstract selectedDebitFinancialAccount?: FinancialAccountListItem;
  abstract selectedCreditFinancialAccount?: FinancialAccountListItem;
  abstract isMoveDirectionPull: boolean;
  abstract isMoveDirectionPush: boolean;
  abstract scheduleDetails?: MoveMoneyScheduleDetails;
  abstract scheduleError?: boolean;
  abstract isRowExpanded(type: MoveRowType): boolean;
  abstract isRowHasValue(type: MoveRowType): boolean;
  abstract onAmountChange(amount: string): void;
  abstract onAmountError(isAmountHasError?: boolean): void;
  abstract onEditClick(_event: Event, type: MoveRowType): void;
  abstract onNoteChange(note?: string): void;
  abstract handleRowExpand(type?: MoveRowType): void;
  abstract mapFinancialAccountData(financialAccount: FinancialAccountListItem, recipient?: Recipient): FinancialAccountListItem;
  abstract onPaymentReasonSelect(reason: PaymentReason): void;
  abstract onCreditFinancialAccountSelect(financialAccount: FinancialAccountListItem): void;
  abstract onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void;
  abstract setTransactionData(transaction: TransactionDetails): void;
  abstract onScheduleDetailsChange(scheduleDetails: MoveMoneyScheduleDetails): void;
  abstract onScheduleDetailsError(isScheduleHasError?: boolean): void;
}

@Injectable()
export class MoveMoneyWrapperService implements AbstractMoveMoneyBase {
  public expandedRow?: MoveRowType;

  moveHow: MoveHowSelection = {};

  moveHowAchDetails: MoveHowAchDetails = {};

  moveHowWireDetails: MoveHowWireDetails = {};

  wireDetails: MoveWireDetails = {};

  scheduleDetails?: MoveMoneyScheduleDetails;

  scheduleError?: boolean;

  moveAmount?: string;

  moveAmountError?: boolean;

  selectedDebitFinancialAccount?: FinancialAccountListItem;

  selectedCreditFinancialAccount?: FinancialAccountListItem;

  paymentReason?: PaymentReason;

  note?: string;

  get isMoveDirectionPull(): boolean {
    return this.moveHow.moveDirection === 'pull';
  }

  get isMoveDirectionPush(): boolean {
    return this.moveHow.moveDirection === 'push';
  }

  get isMoveHowRowHasValue(): boolean {
    const { moveDirection, moveHowType } = this.moveHow;
    return !!moveDirection && !!moveHowType;
  }

  get isMoveHowAchDetailsRowHasValue(): boolean {
    const {
      moveAchEntryType,
      moveAchType,
      rkorACHCheckSerialNumber = '',
      rkorACHIndividualId = '',
      rkorACHTerminalCity = '',
      rkorACHTerminalState = '',
    } = this.moveHowAchDetails;
    let isExtraFieldsFilled = true;

    if (moveAchEntryType && ['CIE', 'PPD', 'TEL'].includes(moveAchEntryType)) {
      isExtraFieldsFilled = rkorACHIndividualId.length > 0;
    } else if (moveAchEntryType && ['ARC', 'BOC', 'RCK'].includes(moveAchEntryType)) {
      isExtraFieldsFilled = rkorACHCheckSerialNumber.length > 0;
    } else if (moveAchEntryType === 'POP') {
      isExtraFieldsFilled = rkorACHTerminalCity.length > 0 && rkorACHTerminalState.length > 0 && rkorACHCheckSerialNumber.length > 0;
    }

    return !!moveAchEntryType && !!moveAchType && isExtraFieldsFilled;
  }

  get isMoveHowWireDetailsRowHasValue(): boolean {
    const { moveWireType } = this.moveHowWireDetails;

    return !!moveWireType;
  }

  get isMoveReasonAmountRowHasValue(): boolean {
    const paymentReasonAndAmountCheck = !!this.paymentReason && !!this.moveAmount;
    return this.moveHow.moveHowType === 'wire' ? !!this.wireDetails.narrative && paymentReasonAndAmountCheck : paymentReasonAndAmountCheck;
  }

  get isMoveFromRowHasValue(): boolean {
    return !!this.selectedDebitFinancialAccount;
  }

  get isMoveToRowHasValue(): boolean {
    return !!this.selectedCreditFinancialAccount;
  }

  get moveHowDirectionDisplayValue(): string {
    const { moveDirection } = this.moveHow;

    if (!moveDirection) {
      return '';
    }

    const directionNames = {
      push: 'Send Money',
      pull: 'Request Money',
    };

    return directionNames[moveDirection] || '';
  }

  get moveHowSolutionDisplayValue(): string {
    const { moveHowType } = this.moveHow;

    return getSolutionDisplayName(moveHowType);
  }

  get moveHowSolutionIcon(): string {
    const { moveHowType } = this.moveHow;

    return getSolutionIconName(moveHowType) ?? '';
  }

  public isRowExpanded(type: MoveRowType): boolean {
    return this.expandedRow === type;
  }

  handleRowExpand(type?: MoveRowType): void {
    this.expandedRow = type;

    // TODO: recalculate table sizes in case of window resizing while section was hidden (offsetWidth === 0)
    if (type === 'move-from' || type === 'move-to') {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    }
  }

  mapFinancialAccountData(financialAccount: FinancialAccountListItem, recipient?: Recipient): FinancialAccountListItem {
    let displayName = financialAccount.displayName;

    if (recipient) {
      const mobileNumber = recipient.phoneNumber ? ` ${toPhone(recipient.phoneNumber)};` : '';

      displayName = `${compact([recipient.firstName, recipient.middleName, recipient.lastName]).join(' ')};${
        recipient.email ? ' ' + recipient.email.toLowerCase() + ';' : ''
      }${mobileNumber}`;
    }

    if (financialAccount.bankAccount || financialAccount.card) {
      displayName = financialAccount.bankAccount
        ? displayName + ` **** ${financialAccount.bankAccount.accountNumberTail} | ${toTitleCase(financialAccount.subtype)} Acc`
        : displayName + ` **** ${financialAccount.card!.cardNumberTail} | ${toTitleCase(financialAccount.subtype)} Card`;
    }
    return { ...financialAccount, recipient, displayName, availableBalance: financialAccount.accountBalances?.availableBalance ?? 0 };
  }

  isRowHasValue(type: string): boolean {
    switch (type) {
      case 'move-how':
        return this.isMoveHowRowHasValue;
      case 'move-how-wire-details':
        return this.isMoveHowWireDetailsRowHasValue;
      case 'move-how-ach-details':
        return this.isMoveHowAchDetailsRowHasValue;
      case 'move-reason-amount':
        return this.isMoveReasonAmountRowHasValue;
      case 'move-from':
        return this.isMoveFromRowHasValue;
      case 'move-to':
        return this.isMoveToRowHasValue;

      default:
        return false;
    }
  }

  onAmountChange(amount: string): void {
    this.moveAmount = amount;
  }

  onAmountError(isAmountHasError?: boolean): void {
    this.moveAmountError = isAmountHasError;
  }

  onScheduleDetailsChange(scheduleDetails: MoveMoneyScheduleDetails): void {
    this.scheduleDetails = { ...scheduleDetails };
  }

  onScheduleDetailsError(isScheduleHasError?: boolean): void {
    this.scheduleError = isScheduleHasError;
  }

  onNoteChange(note: string): void {
    this.note = note;
  }

  onPaymentReasonSelect(paymentReason: PaymentReason): void {
    this.paymentReason = paymentReason;
  }

  onCreditFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.selectedCreditFinancialAccount = financialAccount;
  }

  onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.selectedDebitFinancialAccount = financialAccount;
  }

  onEditClick(_event: Event, type: MoveRowType): void {
    if (this.isRowExpanded(type) || this.isRowHasValue(type)) {
      return;
    }
    this.handleRowExpand(type);
  }

  setTransactionData(transaction: TransactionDetails): void {
    const { settlementPriority, solution, paymentReasonId, paymentReason, transactionType, metadata, amount, description, memo } =
      transaction;

    this.moveHow = {
      moveDirection: this.getMoveDirection(transactionType),
      moveHowType: solution,
    };

    this.paymentReason = {
      id: paymentReasonId,
      reason: paymentReason ?? '',
    };

    this.moveHowAchDetails = {
      moveAchType: settlementPriority,
      moveAchEntryType: metadata?.rkorACHEntryType,
      rkorACHIndividualId: metadata?.rkorACHIndividualId,
      rkorACHCheckSerialNumber: metadata?.rkorACHCheckSerialNumber,
      rkorACHTerminalCity: metadata?.rkorACHTerminalCity,
      rkorACHTerminalState: metadata?.rkorACHTerminalState,
    };

    this.moveAmount = amount;

    this.moveHowWireDetails = {
      moveWireType: settlementPriority,
    };

    this.wireDetails = {
      narrative: description,
      memo,
    };
  }

  private getMoveDirection(transactionType: TransactionDetails['transactionType']): TransactionDirection {
    return transactionType === 'REQUEST' ? 'pull' : 'push';
  }
}
