<ng-container *ngIf="alertDetails$ | async as alert; else alertDetailsLoading">
  <ng-container *ngTemplateOutlet="alertDetails; context: { $implicit: alert, loading: false }"></ng-container>
</ng-container>
<ng-template #alertDetailsLoading>
  <ng-container *ngTemplateOutlet="alertDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #alertDetails [appTemplateContext]="alertDetailsContext" let-alert let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="alertDetails"></app-card-pages-breadcrumbs>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Alert Details</app-text-with-placeholder>
      </div>

      <div class="controls">
        <button rktButton btnType="link" class="control-btn" (click)="onEditBtnClick(alert)" [disabled]="loading">Edit</button>
      </div>
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Alert ID"
        [value]="alert?.id || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'financial-account-id'"
        [showPlaceholder]="loading"
        label="Financial Account ID"
        [value]="alert?.financialAccountId || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'provider-alert-id'"
        [showPlaceholder]="loading"
        label="Provider Alert ID"
        [value]="alert?.providerAlertId || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'use-email'"
        [showPlaceholder]="loading"
        label="Use Email"
        [value]="alert?.useEmail ? 'Yes' : 'No'"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'email'"
        [showPlaceholder]="loading"
        label="Email Address"
        [noBreak]="true"
        [value]="alert?.email"
      ></app-details-item>
    </div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'use-contact-number'"
        [showPlaceholder]="loading"
        label="Use Contact Number"
        [value]="alert?.useContactNumber ? 'Yes' : 'No'"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'contact-number'"
        [showPlaceholder]="loading"
        label="Contact Number"
        [value]="alert?.mobileContactNumber | phone"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'alert-type'"
        [showPlaceholder]="loading"
        label="Alert Type"
        [value]="alert?.typeLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'status'"
        [showPlaceholder]="loading"
        label="Status"
        [value]="alert?.active ? 'Active' : 'Not Active'"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'threshold-type'"
        [showPlaceholder]="loading"
        label="Threshold Type"
        [noBreak]="true"
        [value]="alert?.thresholdTypeLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'threshold-amount'"
        [showPlaceholder]="loading"
        label="Threshold Amount"
        [value]="alert?.thresholdDto?.amount"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'merchant-category-code'"
        [showPlaceholder]="loading"
        label="Merchant Category Code"
        [value]="alert?.merchantCategoryCodes?.join(', ')"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="create-update-section">
      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'created-at'"
          [showPlaceholder]="loading"
          label="Created At"
          [value]="alert?.createdAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'created-by'"
          [showPlaceholder]="loading"
          label="Created By"
          [value]="alert?.createdBy"
        ></app-details-item>
      </div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'updated-at'"
          [showPlaceholder]="loading"
          label="Updated At"
          [value]="alert?.updatedAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'updated-by'"
          [showPlaceholder]="loading"
          label="Updated By"
          [value]="alert?.updatedBy"
        ></app-details-item>
      </div>
    </div>
  </div>
</ng-template>
