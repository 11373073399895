<h1 class="title">Enter Address For Selected Account</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="description">Wire transfer requires an address to proceed. Please enter the address below.</div>

<div class="account-preview">
  <div class="preview-content" *ngIf="financialAccount">
    <div class="preview-content-item" *ngIf="!financialAccount.accountHolderType">
      {{ financialAccount.accountHolderTypeDisplayName }}
    </div>

    <div class="preview-content-item" *ngIf="financialAccount.customer">
      <span class="preview-label"
        >{{ financialAccount.customer.type | titlecase }} {{ financialAccount.accountHolderTypeDisplayName }}:&nbsp;</span
      >
      {{ financialAccount.customer.displayName }}
    </div>

    <div class="preview-content-item" *ngIf="financialAccount.recipient">
      <span class="preview-label"
        >{{ financialAccount.recipient.recipientType | titlecase }} {{ financialAccount.accountHolderTypeDisplayName }}:&nbsp;</span
      >
      {{ financialAccount.recipient.displayName }}
    </div>

    <div class="preview-content-item">
      <rkt-icon [name]="financialAccount.type === 'BANK' ? 'bank' : 'card'"></rkt-icon>
      {{ financialAccount.name }}
      *
      {{
        financialAccount.bankAccount?.accountNumberTail ||
          financialAccount.card?.cardNumberTail ||
          financialAccount.lastFourNumbers?.replace('*', '')
      }}
      {{ financialAccount.subtype | titlecase }}
      {{ financialAccount.type === 'BANK' ? 'Acc' : 'Card' }}
    </div>

    <div class="preview-content-item" *ngIf="financialAccount.category === 'INTERNAL'">
      <span class="preview-label">Available Balance:&nbsp;</span>
      {{ (financialAccount.availableBalance | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$ ' }}
    </div>

    <div class="preview-content preview-content-account-info" *ngIf="financialAccount.customer">
      <div class="preview-content-item">{{ financialAccount.customer.primaryEmail?.value }}</div>

      <div class="preview-content-item">{{ financialAccount.customer.primaryPhoneNumber?.number }}</div>
    </div>

    <div class="preview-content preview-content-account-info" *ngIf="financialAccount.recipient">
      <div class="preview-content-item">{{ financialAccount.recipient.email }}</div>

      <div class="preview-content-item">{{ financialAccount.recipient.phoneNumber }}</div>
    </div>
  </div>
</div>

<form [formGroup]="addressForm">
  <rkt-form [form]="addressForm" [fields]="addressFormFields" [model]="addressFormModel" (modelChange)="onAddressChange()"></rkt-form>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSave()" [disabled]="isLoading || addressForm.invalid">Save</button>
  </div>
</form>
