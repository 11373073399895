import { FormlyFieldConfig } from '@ngx-formly/core';
import { formRadioGroup } from '@rocketfinancialcorp/rocket-ui/form';

interface MoveHowFieldsChangeHookParams {
  moveHowFieldsChangeHook: (field: FormlyFieldConfig) => void;
  options?: { label: string; value: string; disabled: boolean }[];
}

export const moveHowDirectionFields = ({ moveHowFieldsChangeHook, options }: MoveHowFieldsChangeHookParams) => {
  return [
    formRadioGroup({
      key: 'moveDirection',
      label: 'Transaction Type',
      className: 'linx-request-transaction-type',
      props: {
        required: true,
        options: options,
        change: (field) => moveHowFieldsChangeHook(field),
      },
    }),
  ];
};

export const moveHowTypeFields = ({ moveHowFieldsChangeHook, options }: MoveHowFieldsChangeHookParams) => {
  return [
    formRadioGroup({
      key: 'moveHowType',
      label: 'Solution',
      className: 'linx-request-solution',
      props: {
        required: true,
        options: options,
        change: (field) => moveHowFieldsChangeHook(field),
      },
    }),
  ];
};
