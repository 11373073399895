import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { moveHowTypeFields, moveHowDirectionFields } from './linx-request-move-money-how-selection-utils';
import { UntypedFormGroup } from '@angular/forms';
import { FinancialAccountListItem, FormModel, TransactionDirection, TransactionSolution } from '@shared/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MoveHowSelection, MoveMoneyConfirmChangesModalComponent } from '@shared/components';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';

interface MoveHowChange {
  moveItem?: TransactionDirection;
  moveHowType?: TransactionSolution;
}

@Component({
  selector: 'app-linx-request-move-money-how-selection',
  templateUrl: './linx-request-move-money-how-selection.component.html',
})
export class LinxRequestMoveMoneyHowSelectionComponent implements OnChanges {
  @Input() selectedMoveHowSelection?: MoveHowSelection;

  @Input() customerFinancialAccountType!: FinancialAccountListItem['type'] | undefined;

  @Output() selected = new EventEmitter<MoveHowSelection>();

  @Output() cleared = new EventEmitter<void>();

  selectedMoveItem?: TransactionDirection;

  selectedMoveHowType?: TransactionSolution;

  moveHowDirectionForm = new UntypedFormGroup({});

  moveHowTypeForm = new UntypedFormGroup({});

  formModel: FormModel<MoveHowSelection> = {};

  moveHowDirections = [
    { label: 'Send', value: 'push', disabled: false },
    { label: 'Request', value: 'pull', disabled: false },
  ];

  moveHowTypes = [
    { label: 'ACH', value: 'ach', disabled: false },
    { label: 'Push To Card', value: 'push-to-card', disabled: false },
    { label: 'Wire', value: 'wire', disabled: false },
  ];

  moveHowDirectionFields = moveHowDirectionFields({
    moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowDirectionFieldsChangeHook(field),
    options: this.moveHowDirections,
  });

  moveHowTypeFields = moveHowTypeFields({
    moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowSolutionFieldsChangeHook(field),
    options: this.moveHowTypes,
  });

  directionTypes: TransactionDirection[] = ['push', 'pull'];

  solutionTypes: TransactionSolution[] = ['ach', 'push-to-card', 'wire'];

  get directionDisabledTypes(): TransactionDirection[] {
    if (!this.customerFinancialAccountType) {
      return this.directionTypes;
    }

    const disabledTypes: TransactionDirection[] = [];

    if (this.customerFinancialAccountType === 'CARD' || this.selectedMoveHowType === 'wire') {
      disabledTypes.push('pull');
    }

    return disabledTypes;
  }

  get solutionDisabledTypes(): TransactionSolution[] {
    if (!this.customerFinancialAccountType) {
      return this.solutionTypes;
    }

    const disabledTypes = this.solutionTypes.filter((type) => {
      const isCardAccount = this.customerFinancialAccountType === 'CARD';

      if (isCardAccount && type === 'push-to-card') {
        return false;
      }

      const isPushOnlyType = ['ach', 'wire'].includes(type);
      const isPush = this.selectedMoveItem === 'push' && isPushOnlyType;
      const isPull = this.selectedMoveItem === 'pull' && type === 'ach';

      if (!isCardAccount && (isPush || isPull || (!this.selectedMoveItem && isPushOnlyType))) {
        return false;
      }

      return true;
    });

    return disabledTypes;
  }

  constructor(private ref: ChangeDetectorRef, private modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customerFinancialAccountType) {
      this.setDirectionFields();
      this.setSolutionFields();
    }
    if (changes.selectedMoveHowSelection.firstChange && this.selectedMoveHowSelection) {
      this.formModel = {
        moveDirection: this.selectedMoveHowSelection.moveDirection,
        moveHowType: this.selectedMoveHowSelection.moveHowType,
      };
    }
  }

  moveHowDirectionFieldsChangeHook(field: FormlyFieldConfig): void {
    const moveItem = field.formControl?.value;
    this.selectMove(moveItem, this.selectedMoveHowType, { moveItem });
    this.setSolutionFields();
  }

  moveHowSolutionFieldsChangeHook(field: FormlyFieldConfig): void {
    const moveHowType = field.formControl?.value;
    this.selectMove(this.selectedMoveItem, moveHowType, { moveHowType });
    this.setDirectionFields();
  }

  selectMove(moveItem: TransactionDirection | undefined, moveHowType: TransactionSolution | undefined, params: MoveHowChange): void {
    if (!this.selectedMoveItem || !this.selectedMoveHowType) {
      this.selectedMoveItem = params.moveItem ?? moveItem;
      this.selectedMoveHowType = params.moveHowType ?? moveHowType;

      this.onMoveHowSelect();
      return;
    }

    const confirmModalRef = this.modalService.open(MoveMoneyConfirmChangesModalComponent, { className: 'confirm-modal' });

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.onMoveHowChange(params);
        }
      },
      () => false,
    );
  }

  onMoveHowChange({ moveItem, moveHowType }: MoveHowChange) {
    if (!moveItem && !moveHowType) {
      return;
    }

    this.cleared.emit();
    this.selectedMoveItem = moveItem;
    this.selectedMoveHowType = moveHowType;

    if (!moveItem) {
      this.moveHowDirectionForm.reset();
      this.setDirectionFields();
    }

    if (!moveHowType) {
      this.moveHowTypeForm.reset();
      this.setSolutionFields();
    }
    this.onMoveHowSelect();
  }

  onMoveHowSelect() {
    this.selected.emit({
      moveDirection: this.selectedMoveItem,
      moveHowType: this.selectedMoveHowType,
    });
  }

  resetValue(): void {
    this.selectedMoveItem = undefined;
    this.selectedMoveHowType = undefined;

    this.moveHowDirectionForm.reset();
    this.moveHowDirections.forEach((direction) => {
      direction.disabled = false;
    });
    this.updateMoveHowDirectionFields();

    this.moveHowTypeForm.reset();
    this.moveHowTypes.forEach((solution) => {
      solution.disabled = false;
    });
    this.updateMoveHowSolutionFields();
  }

  setDirectionFields(): void {
    this.moveHowDirections.forEach((direction) => {
      direction.disabled = !!this.directionDisabledTypes.includes(direction.value as TransactionDirection);
    });
    this.updateMoveHowDirectionFields();
  }

  setSolutionFields(): void {
    this.moveHowTypes.forEach((solution) => {
      solution.disabled = !!this.solutionDisabledTypes.includes(solution.value as TransactionSolution);
    });
    this.updateMoveHowSolutionFields();
  }

  updateMoveHowDirectionFields(): void {
    this.moveHowDirectionFields = moveHowDirectionFields({
      moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowDirectionFieldsChangeHook(field),
      options: this.moveHowDirections,
    });
    this.ref.detectChanges();
  }

  updateMoveHowSolutionFields(): void {
    this.moveHowTypeFields = moveHowTypeFields({
      moveHowFieldsChangeHook: (field: FormlyFieldConfig) => this.moveHowSolutionFieldsChangeHook(field),
      options: this.moveHowTypes,
    });
    this.ref.detectChanges();
  }
}
