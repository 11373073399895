<h1 class="modal-title">Select {{ type | titlecase }} Account</h1>

<div class="tab-nav-items no-horizontal-offset">
  <div class="tab-nav-item" *ngFor="let category of categories">
    <button (click)="onCategoryClick(category.value)" class="tab-nav-link" [class.active]="selectedCategory === category.value">
      {{ category.label }}
    </button>
  </div>
</div>

<div class="list-page-controls">
  <rkt-table-search #tableSearchRef (changed)="onSearch($event)" placeholder="Search by Financial Account Name"></rkt-table-search>

  <div class="list-filters">
    <button type="button" class="table-header-filter-btn" (click)="filterMenuRef.toggle($event)" #filterBtn>
      <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
      Filter
      <span class="table-header-filter-counter" *ngIf="activeFiltersCount$ | async as activeFiltersCount">{{ activeFiltersCount }}</span>
    </button>

    <app-filter-menu
      #filterMenuRef
      [initialValues]="(activeFilters$ | async) || {}"
      [filters]="filters"
      (opened)="filterBtn.classList.add('opened')"
      (closed)="filterBtn.classList.remove('opened')"
      (saved)="applyFilters($event)"
    ></app-filter-menu>
  </div>
</div>

<div class="table-container" *ngIf="type" [class.empty]>
  <rkt-table
    #financialAccountsTableRef
    [rows]="(financialAccounts$ | async) ?? []"
    [columns]="selectedCategory === 'EXTERNAL' ? externalTypeColumns : columns"
    [class.empty-list-loading]="(totalElements$ | async) === 0 && (isLoading$ | async) === true"
    [class.no-pagination]="((totalElements$ | async) ?? 0) < 5"
    [limit]="5"
    (fetchData)="fetchFinancialAccounts($event)"
    [count]="(totalElements$ | async) ?? 0"
    [loadingIndicator]="(isLoading$ | async) ?? false"
    [stickyHeader]="false"
    [rowHeight]="64"
    [placeholderItemsCount]="5"
    [isItemsAlignCenter]="true"
    [offset]="activePage"
    [messages]="{ emptyMessage: 'No Financial Accounts found.' }"
    emptyValuePlaceholder="--"
    [selected]="selectedFinancialAccounts"
    [selectionType]="selectionType"
    (rowSelected)="onFinancialAccountSelect($event)"
    [footerHeight]="56"
  ></rkt-table>
</div>

<div class="modal-buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button
    rktButton
    type="submit"
    (click)="onSelectBtnClick()"
    [disabled]="!selectedFinancialAccounts.length"
    [title]="'Select ' + (type | titlecase) + ' Account'"
  >
    Select
  </button>
</div>
