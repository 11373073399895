export const ALERT_TYPES: Record<string, string> = {
  BALANCE_THRESHOLD: 'Balance',
  DEBIT_TRANSACTION_POSTED: 'Debit Transaction',
  CREDIT_TRANSACTION_POSTED: 'Credit Transaction',
  BILL_PAYMENT: 'Bill Payment',
  MCC: 'Merchant Category Code',
  WRONG_PIN_ATTEMPT: 'Wrong PIN',
  INSUFFICIENT_FUNDS: 'Insufficient Funds',
  PROMO: 'Promotion',
};

export const THRESHOLD_TYPES: Record<string, string> = {
  GREATER_THAN_OR_EQUALS_TO: 'Greater Than OR Equals',
  LOWER_THAN_OR_EQUALS_TO: 'Lesser Than OR Equals',
};

export const RESTRICTION_TYPES: Record<string, string> = {
  ACQUIRER_ID: 'Acquirer ID',
  ACQUIRER_ID_MERCHANT_ID: 'Acquirer ID/Merchant ID',
  ACQUIRER_ID_DEVICE_ID: 'Acquirer Device ID',
  MERCHANT_ID: 'Merchant ID',
  MERCHANT_NAME: 'Merchant Name',
  BRAND_MERCHANT_ID: 'Brand Merchant ID',
  MERCHANT_GROUPS: 'Merchant Group',
  MCC_GROUPS: 'Merchant Category',
  DEVICE_OR_TERMINAL_ID: 'Device ID OR Terminal ID',
  BLOCK_ONLINE_TRANSACTIONS: 'Block Online Transactions',
  BLOCK_POS_PURCHASES: 'Block POS Purchases',
  BLOCK_ATM_WITHDRAWALS: 'Block ATM Withdrawals',
  BLOCK_PHONE_AND_MAIL_TRANSACTIONS: 'Block Phone AND Email Transactions',
  BLOCK_INTERNATIONAL_TRANSACTIONS_CH: 'Block International Transactions',
  PURCHASE_TRANSACTION_AMOUNT_LIMIT: 'Purchase Amount Limit',
  DAILY_PURCHASE_LIMIT: 'Daily Purchase Limit',
  CASH_WITHDRAWAL_TRANSACTION_AMOUNT_LIMIT: 'Cash Withdrawal Limit',
  DAILY_CASH_WITHDRAWAL_LIMIT: 'Daily Cash Withdrawal Limit',
  CITY: 'City',
  STATE: 'State',
  COUNTRY: 'Country',
  COUNTRY_ZIPCODE: 'Country ZIP Code',
  CARDHOLDER_CITY: 'Cardholder City',
  CARDHOLDER_STATE: 'Cardholder State',
  CARDHOLDER_COUNTRY: 'Cardholder Country',
  BLOCK_INTERNATIONAL_TRANSACTIONS_LOC: 'Block International Transactions',
  BLOCK_DOMESTIC_TRANSACTIONS_LOC: 'Block Domestic Transactions',
  MAXIMUM_CARD_BALANCE_LIMIT: 'Card Balance Limit',
  DAILY_LOAD_AMOUNT: 'Daily Load Amount Limit',
  DAILY_LOAD_FREQUENCY: 'Daily Load Frequency Limit',
  MONTHLY_LOAD_AMOUNT: 'Monthly Load Amount Limit',
  MONTHLY_LOAD_FREQUENCY: 'Monthly Load Frequency Limit',
};

export const RESTRICTION_MODES: Record<string, string> = {
  ALLOW: 'Allow',
  BLOCK: 'Block',
};

export const RESTRICTION_OPERATORS: Record<string, string> = {
  EQ: 'Equals to',
  NE: 'Not Equals to',
  GT: 'Greater than',
  GE: 'Greater than or equals to',
  LT: 'Less than',
  LE: 'Less than or equals to',
  BW: 'Between',
  NB: 'Not Between',
  IN: 'In',
  NI: 'Not In',
  CT: 'Contains',
  NC: 'Not Contains',
  LK: 'Like',
  NL: 'Not Like',
};

export const DISPUTE_TYPES: Record<string, string> = {
  UNAUTHORIZED: 'Unauthorized',
  MERCHANT_DISPUTE: 'Merchant Dispute',
};

export const DISPUTE_REASONS: Record<string, string> = {
  ATM_REQ_NOT_FULFILLED: 'ATM Issue',
  DOLLAR_AMT_SALE_INCR: 'Amount Incorrect',
  DISPUTE_ON_ENTIRE_CHARGE: 'Overall Dispute',
  MERCH_NOT_RECEIVED: 'Merchant Did Not Receive Funds',
  MERCH_RETURNED: 'Returned by Merchant',
  ENCLOSING_EXPLANATION: 'Explanation Enclosed',
  DUP_TRANSACTION: 'Duplicate Transaction',
  CREDIT_SLIP_CHARGE_APPLIED: 'Credit Charge Applied',
  CREDIT_SLIP_CHARGE_NOT_APPLIED: 'Credit Charge NOT Applied',
  HOTEL_RESERVE_CANCEL: 'Hotel Reservation Cancelled',
  RECEIVED_CANCEL_NUMBER: 'Received Cancel Number',
  OTHERS: 'Other',
  CHARGE_AFTER_SUB_CANCELLED: 'Charge After Subscription Cancelled',
  PAID_BY_OTR_MEANS: 'Paid My Other Means',
  UN_AUTH_POS_TXN: 'Unauthorized POS Transaction',
  UN_AUTH_ATM_WITHDRAWAL: 'Unauthorized ATM Withdrawal',
  UN_AUTH_ONLINE_PURCHASE: 'Unauthorized Online Purchase ',
  UN_CHARGE_AFTER_SUB_CANCELLED: 'Unauthorized Charge (Subscription Cancelled)',
  CHARGE_WITHOUT_CONSENT: 'Charge Without Consent',
};

export const DISPUTE_STATUSES: Record<string, string> = {
  LOGGED: 'Logged',
  IN_REVIEW: 'In Review',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  CARDHOLDER_WIN: 'Cardholder Win',
};

export const I2C_CARD_STATUSES: Record<string, string> = {
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  DELETED: 'Deleted',
  LOST: 'Lost',
  STOLEN: 'Stolen',
  NO_WITHDRAWALS: 'Withdrawals Not Allowed',
  LOST_NOT_CAPTURED: 'Lost',
  STOLEN_NOT_CAPTURED: 'Stolen',
  INACTIVE: 'Inactive',
  REISSUED: 'Reissued',
  FRAUD_BLOCK: 'Fraud Blocked',
};

export const I2C_CARD_SHIPPING_METHODS: Record<string, string> = {
  NORMAL: 'Normal',
  URGENT: 'Urgent',
  VIP: 'VIP',
  BULK: 'Bulk',
  BULK_URGENT: 'Bulk Urgent',
  BULK_VIP: 'Bulk VIP',
};

export const I2C_CARD_REISSUE_REASONS: Record<string, string> = {
  DAMAGED: 'Card has been damaged',
  EXPIRED: 'Card has expired',
  LOST_STOLEN: 'Card has been lost or stolen',
  FRAUD_COMPROMISED: 'Card has been used for fraudulent charges',
  RETURN: 'Card has been returned',
  NEW_ENROLLMENT: 'New enrollment',
};

export const I2C_CARD_REISSUE_TYPES: Record<string, string> = {
  REISSUED_FOR_SAME_CARD_NUMBER: 'Reissued for same card number',
  REISSUED_FOR_NEW_EXPIRY: 'Reissued for new expiry',
  REISSUED_FOR_NEW_CARD_NUMBER: 'Reissued for new card number',
};
