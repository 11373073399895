import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { FinancialAccountDocumentService } from '@shared/services';
import { IntegratedFinancialAccountAcceptance } from '@shared/models';

@Component({
  selector: 'app-add-integrated-financial-account-agreements',
  templateUrl: './add-integrated-financial-account-agreements.component.html',
  styleUrls: ['./add-integrated-financial-account-agreements.component.scss'],
})
export class AddIntegratedFinancialAccountAgreementsComponent implements OnInit, AfterViewChecked, OnDestroy {
  documents: Record<string, string> = {};

  documentsPagesCount: Record<string, number | undefined> = {};

  agreementTabs: Record<string, string>[] = [];

  selectedTab?: string;

  isScrolledToTheEnd: Record<string, boolean> = {};

  documentSigned: Record<string, string> = {};

  documentVersion: Record<string, string> = {};

  document?: ArrayBuffer;

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private financialAccountDocumentService: FinancialAccountDocumentService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.financialAccountDocumentService
      .getFinancialAccountAcceptanceDocuments({ accountHolderType: 'BUSINESS_ACCOUNT' })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (acceptanceDocuments) => {
          acceptanceDocuments.forEach(({ documentId, downloadUrl }) => {
            this.agreementTabs.push({
              label: documentId.replace(/(?:ebt|rkor)-/iu, '').replace(/-/g, ' '),
              value: documentId,
            });

            this.documents[documentId] = downloadUrl;
            this.documentsPagesCount[documentId] = undefined;
            this.isScrolledToTheEnd[documentId] = false;
            this.documentSigned[documentId] = '';
            this.documentVersion[documentId] = '';
          });

          this.setSelectedTab(this.agreementTabs[0].value);
        },
      });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  setSelectedTab(tab: string): void {
    if (tab === this.selectedTab) {
      return;
    }

    this.selectedTab = tab;
    this.documentSigned[tab] = '';
    this.isScrolledToTheEnd[tab] = false;

    this.financialAccountDocumentService
      .getAcceptanceDocument(this.documents[this.selectedTab])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.document = response.body;
          this.documentVersion[this.selectedTab!] = response.headers.get('Kor-Document-Version')!;
        },
      });
  }

  prevTab(): void {
    const selectedTabIndex = this.agreementTabs.findIndex((tab) => tab.value === this.selectedTab);
    const selectedTab = this.agreementTabs[selectedTabIndex].value;

    this.documentSigned[selectedTab] = '';
    this.isScrolledToTheEnd[selectedTab] = false;

    if (selectedTabIndex === 0) {
      this.activeModal.close({ action: 'EDIT' });
    } else {
      this.setSelectedTab(this.agreementTabs[selectedTabIndex - 1].value);
    }
  }

  nextTab(): void {
    const selectedTabIndex = this.agreementTabs.findIndex((tab) => tab.value === this.selectedTab);
    const selectedTab = this.agreementTabs[selectedTabIndex].value;

    this.documentSigned[selectedTab] = new Date().toISOString();

    if (selectedTabIndex === this.agreementTabs.length - 1) {
      const acceptances: IntegratedFinancialAccountAcceptance[] = Object.keys(this.documentSigned).map((documentId) => ({
        documentId: documentId,
        documentVersion: this.documentVersion[documentId],
        accepted: !!this.documentSigned[documentId],
        respondedAt: this.documentSigned[documentId],
      }));
      this.activeModal.close({ action: 'REVIEW', acceptances });
    } else {
      this.setSelectedTab(this.agreementTabs[selectedTabIndex + 1].value);
    }
  }

  onPageChange(page: number): void {
    if (!this.selectedTab) {
      return;
    }
    if (page === this.documentsPagesCount[this.selectedTab]) {
      this.isScrolledToTheEnd[this.selectedTab] = true;
    }
  }

  onPagesLoaded({ pagesCount }: { pagesCount: number }): void {
    if (!this.selectedTab) {
      return;
    }
    this.documentsPagesCount[this.selectedTab] = pagesCount;
  }
}
