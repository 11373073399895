<div class="attachments-container">
  <div class="attachments-header">
    <div class="attachments-title">
      Attachments ({{ attachments.length }})
      <button *ngIf="isEdit" rktButton btnType="link" class="attachments-btn" (click)="onAddAttachment()">
        <rkt-icon name="plus"></rkt-icon>
        Add
      </button>
    </div>
    <div class="attachment-toggle-buttons">
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'tiles'"
        (click)="onToggleAttachmentsView('tiles')"
      >
        <rkt-icon name="tiles"></rkt-icon>
      </button>
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'list'"
        (click)="onToggleAttachmentsView('list')"
      >
        <rkt-icon name="list"></rkt-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="!loading && attachments.length">
    <ng-container *ngIf="attachmentsView === 'tiles'; else listView">
      <rkt-carousel [items]="attachments" [itemsVisible]="2" [itemsScroll]="2">
        <ng-template let-item rktCarouselItem="item">
          <div class="file-item" (click)="onViewAttachmentCLick(item)">
            <div class="file-name">
              <rkt-icon [name]="'attachment'"></rkt-icon>
              <span class="file-name-text">{{ item.name }}</span>
            </div>
            <div class="file-image">
              <img [src]="item.thumbnailUrl?.value" *ngIf="item.thumbnailUrl?.value" [alt]="item.name" />
              <div class="banner-text">{{ getAttachmentScanLabel(item) }}</div>
              <div class="action-buttons" *ngIf="isEdit">
                <rkt-icon class="trash-button" [name]="'trash'" (click)="onActionButtonClick('DELETE', item.id, $event)"></rkt-icon>
                <rkt-icon class="edit-button" [name]="'edit'" (click)="onActionButtonClick('EDIT', item.id, $event)"></rkt-icon>
                <rkt-icon class="download-button" [name]="'download'" (click)="onActionButtonClick('DOWNLOAD', item.id, $event)"></rkt-icon>
              </div>
            </div>
          </div>
        </ng-template>
      </rkt-carousel>
    </ng-container>
  </ng-container>
</div>

<ng-template #listView>
  <div class="attachment-upload-selected-container">
    <div class="uploaded-attachment-item" *ngFor="let attachment of attachments" (click)="onViewAttachmentCLick(attachment)">
      <rkt-icon class="attachment-status" name="check"></rkt-icon>
      <div class="name">{{ attachment.name }}</div>
      <button
        *ngIf="isEdit"
        (click)="onActionButtonClick('EDIT', attachment.id, $event)"
        class="attachment-action-button attachment-action-button-edit"
      >
        <rkt-icon name="edit"></rkt-icon>
      </button>
      <button
        *ngIf="isEdit"
        (click)="onActionButtonClick('DELETE', attachment.id, $event)"
        class="attachment-action-button attachment-action-button-delete"
      >
        <rkt-icon name="trash"></rkt-icon>
      </button>
    </div>
  </div>
</ng-template>
