import { Component, Input } from '@angular/core';
import { toTitleCase } from '@shared/utils';

@Component({
  selector: 'app-summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.scss'],
})
export class SummaryInfoComponent {
  @Input() user!: Partial<{
    name: string;
    businessContactName: string;
    phoneNumber: string;
    email: string;
    address: string;
    sex: string;
  }> | null;

  @Input() isRecipient = false;

  @Input() isLoading = false;

  @Input() customerType?: string;

  get userGender() {
    return this.user?.sex === 'NONBINARY' ? 'Non-Binary' : toTitleCase(this.user?.sex);
  }

  get isBusinessCustomer() {
    return this.customerType === 'business';
  }
}
