<div class="modal-notes">
  <div class="modal-notes-container">
    <div class="modal-notes-title">
      <div class="modal-notes-title-text">Notes</div>
      <button class="modal-close" (click)="activeModal.close()" title="Cancel">
        <rkt-icon name="times"></rkt-icon>
      </button>
    </div>

    <div class="note-list" *ngIf="notes$ | async as notes; else notesLoading">
      <ng-scrollbar track="vertical" class="notes-scroll-wrapper" #scrollRef>
        <div class="d-flex flex-column">
          <div class="notes-empty-message" *ngIf="!notes.length && !newNoteLoading">There are no notes.</div>

          <ng-container *ngIf="newNoteLoading">
            <ng-container *ngTemplateOutlet="noteItemPlaceholder"></ng-container>
          </ng-container>

          <app-note-item class="note-list-item" *ngFor="let note of notes" [note]="note" (changed)="refetchNotes()"></app-note-item>
        </div>
      </ng-scrollbar>
    </div>

    <ng-template #notesLoading>
      <div class="notes-empty-placeholder">
        <div class="mb-5" *ngFor="let i of [].constructor(3)">
          <ng-container *ngTemplateOutlet="noteItemPlaceholder"></ng-container>
        </div>
      </div>
    </ng-template>

    <app-add-note
      (addBtnClick)="onNoteAdd($event)"
      [notesLoading]="newNoteLoading"
      [isDisabled]="!(notes$ | async)?.length && loading && !newNoteLoading"
      [error]="noteAddError"
    ></app-add-note>
  </div>
</div>

<ng-template #noteItemPlaceholder>
  <app-text-with-placeholder class="notes-placeholder-item" [class.loading]="true" [showPlaceholder]="true"></app-text-with-placeholder>
</ng-template>
