<div class="reason-section-title">Reason</div>

<ng-container *ngIf="paymentReasons.length; else loading">
  <div class="payment-reasons">
    <div class="payment-reason-item" [class.disabled]="isMoveFieldsDisabled" *ngFor="let paymentReason of paymentReasons">
      <label [for]="paymentReason.id" class="payment-reason-radio-input-label">
        <span class="payment-reason-radio-input" [class.checked]="paymentReasonField.value === paymentReason.id"></span>

        <input
          hidden
          [id]="paymentReason.id"
          [value]="paymentReason.id"
          [formControl]="paymentReasonField"
          [appFieldDisable]="isMoveFieldsDisabled"
          (change)="onPaymentReasonSelect(paymentReason)"
          name="payment-reason"
          type="radio"
          class="form-check-input"
        />
        <span class="payment-reason-item-value" [title]="paymentReason.reason">
          {{ paymentReason.reason }}
        </span>
      </label>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="payment-reasons"></div>
</ng-template>
