import { formInput, formInputCard, formRow } from '@rocketfinancialcorp/rocket-ui/form';

const expressions = {
  'props.disabled': 'model.cardOrBankAccount === "bankAccount" || model.category === "INTERNAL"',
  'props.required': 'model.cardOrBankAccount === "card"',
};

const editExpressions = {
  'props.disabled': '!!model.isEdit',
};

export const cardFields = [
  formRow([
    formInput({ key: 'card.firstName', name: 'field-name-1', label: 'First Nam\u0435 on Car\u0064', expressions }),
    formInput({ key: 'card.middleName', name: 'field-name-2', label: 'Middle Nam\u0435 on Car\u0064' }),
    formInput({ key: 'card.lastName', name: 'field-name-3', label: 'Last Nam\u0435 on Car\u0064', expressions }),
  ]),
  formRow([
    formInputCard({
      key: 'card.cardNumber',
      label: 'Car\u0064 Numb\u0435r',
      className: 'card-field',
      name: 'field-name-4',
      props: {
        minLength: 11,
        maxLength: 23,
        maskString: 'XXXX XXXX XXXXX||XXXX XXXXXX XXXX||XXXX XXXXXX XXXXX||XXXX XXXX XXXX XXXX||XXXX XXXX XXXX XXXX XXX',
      },
      expressions: { ...expressions, ...editExpressions },
    }),
    formInput({
      key: 'card.expiry',
      label: 'Car\u0064 Exp. Dat\u0435',
      className: 'card-exp-field',
      name: 'field-name-5',
      props: {
        maskString: 'XX/XXXX',
        placeholder: 'MM/YYYY',
      },
      expressions: { ...expressions, ...editExpressions },
    }),
  ]),
];
