import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-page-leave-confirmation-modal',
  templateUrl: './page-leave-confirmation-modal.component.html',
})
export class PageLeaveConfirmationModalComponent {
  constructor(public activeModal: ActiveModal) {}
}
