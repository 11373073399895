import { Directive, ElementRef, HostListener } from '@angular/core';
import { Patterns } from '@shared/components';

@Directive({
  selector: '[appPercentageField]',
})
export class PercentageFieldDirective {
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(e.key) !== -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(e.key);

    if (!next) {
      return;
    }

    const matchedString = RegExp(Patterns.NUMBER_ONLY).exec(String(next));
    if (!matchedString || (matchedString && Number(next) > 100)) {
      e.preventDefault();
    }
  }
}
