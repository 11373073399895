import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-form-item',
  templateUrl: 'note-form-item.component.html',
  styleUrls: ['note-form-item.component.scss'],
})
export class NoteFormItemComponent {
  @Input() errors?: { [key: string]: { [key: string]: string | boolean } } | null;

  @Input() value?: string | null;

  @Input() title?: string;

  @Input() isShowTitle = true;

  @Input() disabled?: boolean;
}
