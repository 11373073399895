<ng-container *ngIf="restrictionDetails$ | async as restriction; else restrictionDetailsLoading">
  <ng-container *ngTemplateOutlet="restrictionDetails; context: { $implicit: restriction, loading: false }"></ng-container>
</ng-container>
<ng-template #restrictionDetailsLoading>
  <ng-container *ngTemplateOutlet="restrictionDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #restrictionDetails [appTemplateContext]="restrictionDetailsContext" let-restriction let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="restrictionDetails"></app-card-pages-breadcrumbs>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Restriction Details</app-text-with-placeholder>
      </div>

      <div class="controls">
        <button rktButton btnType="link" class="control-btn" (click)="onEditBtnClick(restriction)" [disabled]="loading">Edit</button>
      </div>
    </div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Restriction ID"
        [value]="restriction?.id || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'financial-account-id'"
        [showPlaceholder]="loading"
        label="Financial Account ID"
        [value]="restriction?.financialAccountId || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'type'"
        [showPlaceholder]="loading"
        label="Type"
        [value]="restriction?.typeLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'value'"
        [showPlaceholder]="loading"
        label="Value"
        [value]="restriction?.value"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'mode'"
        [showPlaceholder]="loading"
        label="Mode"
        [value]="restriction?.modeLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'enabled'"
        [showPlaceholder]="loading"
        label="Enabled"
        [value]="restriction?.active ? 'Yes' : 'No'"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'start-date'"
        [showPlaceholder]="loading"
        label="Start Date and Time"
        [noBreak]="true"
        [value]="restriction?.startDateTime | dateFormat"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'end-date'"
        [showPlaceholder]="loading"
        label="End Date and Time"
        [value]="restriction?.endDateTime | dateFormat"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'operator'"
        [showPlaceholder]="loading"
        label="Operator"
        [value]="restriction?.operatorLabel"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="create-update-section">
      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'created-at'"
          [showPlaceholder]="loading"
          label="Created At"
          [value]="restriction?.createdAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'created-by'"
          [showPlaceholder]="loading"
          label="Created By"
          [value]=""
        ></app-details-item>
      </div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'updated-at'"
          [showPlaceholder]="loading"
          label="Updated At"
          [value]="restriction?.updatedAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'updated-by'"
          [showPlaceholder]="loading"
          label="Updated By"
          [value]=""
        ></app-details-item>
      </div>
    </div>
  </div>
</ng-template>
