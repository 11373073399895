import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { catchError, defaultIfEmpty, delay, finalize, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { formInput, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import {
  AddRecipientParams,
  Customer,
  EntityFinancialAccountDetails,
  FinancialAccountCreateData,
  FinancialAccountListItem,
  FormModel,
  Recipient,
  AccountHolderType,
  FinancialAccountCategory,
  AddCustomerParams,
} from '@shared/models';
import { cardExpiryCheck, emptySpacesValidator } from '@shared/validators';
import {
  addressFields,
  customerCreateFields,
  financialAccountTypeFields,
  cardFields,
  bankAccountFields,
  accountHolderTypeFields,
  recipientCreateFields,
  accountCategoryFields,
  internalAccountFields,
} from '@shared/components';
import { CustomerService, FinancialAccountService, NoteService, NotificationService, RecipientService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';
import { bankAccountCreateData, cardCreateData, phoneNumberFormat } from './helpers';
import {
  AddEntityWithFinancialAccountForm,
  AddEntityWithFinancialAccountModalParams,
  EntityFinancialAccountCreateData,
  NoteCreateData,
  ResultNotification,
} from './models';
import { Patterns } from '../patterns';

@Component({
  templateUrl: './add-entity-with-financial-account-modal.component.html',
  styleUrls: ['./add-entity-with-financial-account-modal.component.scss'],
})
export class AddEntityWithFinancialAccountModalComponent implements AfterViewChecked, OnDestroy {
  accountHolderTypes: AccountHolderType[] = [];

  accountTypes: FinancialAccountCategory[] = ['EXTERNAL'];

  entityTypeForm = new FormGroup({});

  entityRecipientForm = new FormGroup({});

  entityCustomerForm = new FormGroup({});

  cardForm = new FormGroup({});

  cardInfoForm = new FormGroup({});

  bankForm = new FormGroup({});

  cardAddressForm = new FormGroup({});

  bankAddressForm = new FormGroup({});

  formModel: FormModel<AddEntityWithFinancialAccountForm> = {
    defaultFlag: false,
    accountHolderType: undefined,
    category: 'EXTERNAL',
    name: undefined,
  };

  formError = '';

  formLoading = false;

  accountCategoryFields = accountCategoryFields({});

  accountHolderTypeFields: FormlyFieldConfig[] = accountHolderTypeFields;

  financialAccountTypeFields: FormlyFieldConfig[] = [];

  recipientCreateFields: FormlyFieldConfig[] = recipientCreateFields;

  customerCreateFields: FormlyFieldConfig[] = customerCreateFields;

  cardFields: FormlyFieldConfig[] = cardFields;

  bankFields: FormlyFieldConfig[] = bankAccountFields;

  addressFields: FormlyFieldConfig[] = addressFields;

  internalAccountFields = internalAccountFields;

  financialAccountFields = [
    formRow([
      formInput({
        key: 'name',
        label: 'Financial Account Nam\u0435',
        props: {
          pattern: Patterns.GENERIC_NAME,
          required: true,
        },
        validation: { messages: { pattern: 'Enter a valid Financial Account Nam\u0435' } },
      }),
    ]),
  ];

  note = new FormControl('', [emptySpacesValidator]);

  parentFinancialAccount?: string;

  parentFinancialAccountListPageNumber$ = new Subject<number>();

  parentFinancialAccountListLoading = false;

  parentFinancialAccountListItems: { label: string; value: string }[] = [];

  parentFinancialAccountListHasNext = true;

  parentFinancialAccountListPageNumber = 0;

  parentEntityId?: string;
  parentEntityType?: string;

  private bankAccountOnly = false;

  private cardOnly = false;

  private destroy$ = new Subject<void>();

  get isCustomerAvailable(): boolean {
    return this.accountHolderTypes.includes('CUSTOMER');
  }

  get isInternalAvailable(): boolean {
    return this.accountTypes.includes('INTERNAL');
  }

  get accountHolderTypeName(): string | undefined {
    if (!this.formModel.accountHolderType) {
      return;
    }

    const accountHolderTypes: { [key: string]: string } = {
      CUSTOMER: 'Customer',
      RECIPIENT: 'Recipient',
    };
    return accountHolderTypes[this.formModel.accountHolderType] || this.formModel.accountHolderType;
  }

  get isCard(): boolean {
    return this.formModel.cardOrBankAccount === 'card';
  }

  get isBank(): boolean {
    return this.formModel.cardOrBankAccount === 'bankAccount';
  }

  get isInternal(): boolean {
    return this.formModel.category === 'INTERNAL';
  }

  get isCustomer(): boolean {
    return this.formModel.accountHolderType === 'CUSTOMER';
  }

  get isRecipient(): boolean {
    return this.formModel.accountHolderType === 'RECIPIENT';
  }

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private noteService: NoteService,
    private customerService: CustomerService,
    private recipientService: RecipientService,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
  ) {
    this.parentFinancialAccountListPageNumber$
      .pipe(
        switchMap((pageNumber) => {
          this.parentFinancialAccountListLoading = true;

          return this.financialAccountService.getFinancialAccounts({
            page: pageNumber,
            size: 10,
            state: ['ACTIVE'],
            accountTypes: ['INTERNAL'],
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (financialAccounts) => {
          this.parentFinancialAccountListItems = this.mapParentFinancialAccountListItems(
            financialAccounts.items,
            financialAccounts.hasNext!,
          );
          this.parentFinancialAccountListLoading = false;
        },
        error: () => {
          this.parentFinancialAccountListLoading = false;
        },
      });
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.markFieldsAsUnTouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  markFieldsAsTouched(): void {
    this.entityTypeForm.markAllAsTouched();
    this.entityRecipientForm.markAllAsTouched();
    this.entityCustomerForm.markAllAsTouched();
    this.bankAddressForm.markAllAsTouched();
    this.cardAddressForm.markAllAsTouched();
    this.cardForm.markAllAsTouched();
    this.cardInfoForm.markAllAsTouched();
    this.bankForm.markAllAsTouched();
  }

  markFieldsAsUnTouched(): void {
    this.entityTypeForm.markAsUntouched();
    this.entityRecipientForm.markAsUntouched();
    this.entityCustomerForm.markAsUntouched();
    this.bankAddressForm.markAsUntouched();
    this.cardAddressForm.markAsUntouched();
    this.cardForm.markAsUntouched();
    this.cardInfoForm.markAsUntouched();
    this.bankForm.markAsUntouched();
  }

  public modalInitData(data: AddEntityWithFinancialAccountModalParams): void {
    const { accountHolderTypes = [], accountTypes, bankAccountOnly = false, cardOnly = false, parentEntityId, parentEntityType } = data;
    this.accountHolderTypes = accountHolderTypes;
    this.bankAccountOnly = bankAccountOnly;
    this.cardOnly = cardOnly;
    this.parentEntityId = parentEntityId;
    this.parentEntityType = parentEntityType;

    if (accountTypes?.length) {
      this.accountTypes = accountTypes;
    }

    if (this.accountHolderTypes.length > 0) {
      this.updatedFormData({
        accountHolderType: this.accountHolderTypes[0],
      });
      this.onAccountHolderChange({ accountHolderType: this.accountHolderTypes[0] });
    }
  }

  public onNoteFieldBlur(): void {
    this.note.setValue(this.note.value?.trim() || '');
  }

  public onAccountCategoryChange(data: unknown): void {
    const { category } = data as { category: FinancialAccountCategory };

    if (!category) {
      return;
    }

    if (category === 'INTERNAL') {
      this.parentFinancialAccountListPageNumber$.next(0);
      this.updatedFormData({
        cardOrBankAccount: 'bankAccount',
      });

      this.financialAccountTypeFields = financialAccountTypeFields({
        isBankAccountOnly: true,
      });
    } else {
      this.updatedFormData({
        cardOrBankAccount: this.bankAccountOnly ? 'bankAccount' : 'card',
      });

      this.financialAccountTypeFields = financialAccountTypeFields({
        isBankAccountOnly: this.bankAccountOnly,
        isCardOnly: this.cardOnly,
      });
    }

    this.parentFinancialAccountListItems = [];
  }

  public onAccountHolderChange(data: unknown): void {
    const { accountHolderType } = data as { accountHolderType: AccountHolderType };

    if (!accountHolderType) {
      return;
    }

    switch (accountHolderType) {
      case 'RECIPIENT':
        this.updatedFormData({
          cardOrBankAccount: this.bankAccountOnly ? 'bankAccount' : 'card',
          recipientType: 'INDIVIDUAL',
        });

        this.financialAccountTypeFields = financialAccountTypeFields({
          isBankAccountOnly: this.bankAccountOnly,
          isCardOnly: this.cardOnly,
        });
        break;

      case 'CUSTOMER':
        this.updatedFormData({
          cardOrBankAccount: this.bankAccountOnly ? 'bankAccount' : 'card',
          customerType: 'INDIVIDUAL',
        });

        this.financialAccountTypeFields = financialAccountTypeFields({
          isBankAccountOnly: this.bankAccountOnly,
          isCardOnly: this.cardOnly,
        });
        break;

      default:
        break;
    }
  }

  public onCardChange(): void {
    const validationMessage = cardExpiryCheck(this.formModel.card?.expiry);
    const formEl = this.cardInfoForm.get('card.expiry')!;
    if (validationMessage) {
      formEl.setErrors({
        isValidExpiryDate: {
          message: validationMessage,
        },
      });
    }
  }

  public onAccountNumberChange(): void {
    const { accountNumber, accountNumberConfirm } = this.formModel.bankAccount || {};
    const formEl = this.bankForm.get('bankAccount.accountNumberConfirm')!;
    formEl.setErrors(null);
    if (accountNumber !== accountNumberConfirm) {
      formEl.setErrors({ matchError: { message: 'Oops, it looks like the account numbers do not match. Please re-enter.' } });
    }
  }

  public onFinancialAccountTypeChanged(): void {
    const { cardOrBankAccount } = this.formModel;
    if (cardOrBankAccount === 'card') {
      this.updatedFormData({
        bankAccount: undefined,
        name: undefined,
      });
    } else {
      this.updatedFormData({
        card: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        postalCode: undefined,
        country: undefined,
        state: undefined,
        city: undefined,
        name: undefined,
      });
    }
  }

  public addressFieldsInitHook(field: FormlyFieldConfig): void {
    field.formControl!.valueChanges.pipe(delay(200), takeUntil(this.destroy$)).subscribe();
  }

  public onSubmit(): void {
    this.markFieldsAsTouched();

    if ((this.isRecipient && this.entityRecipientForm.invalid) || (this.isCustomer && this.entityCustomerForm.invalid)) {
      return;
    }

    this.formLoading = true;
    this.formError = '';

    this.createEntityAccount()
      .pipe(
        mergeMap(({ recipient, customer }) => {
          const entityType = this.formModel.accountHolderType!;
          const entityId = (recipient?.id || customer?.id)!;

          const financialAccountResponse = this.addEntityFinancialAccount({
            entityId,
            entityType,
            financialAccountData: this.financialAccountCreateData(),
          });
          const noteResponse = this.addNoteToEntity({
            entityId,
            entityType,
            note: this.note.value || undefined,
          });
          return forkJoin({ financialAccountResponse, noteResponse }).pipe(
            map((responses) => ({
              ...responses,
              recipient,
              customer,
            })),
          );
        }),
        take(1),
        finalize(() => {
          this.formLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ({ financialAccountResponse, recipient, customer, noteResponse }) => {
          this.displayResultNotification({
            financialAccountError: !financialAccountResponse,
            noteError: !noteResponse,
          });

          this.activeModal.close({ financialAccount: financialAccountResponse, recipient, customer });
        },
        error: (error) => {
          const duplicateEmailError = this.getEmailDuplicationError(error);
          if (duplicateEmailError) {
            this.formError = duplicateEmailError;
          } else {
            this.formError = error?.status === 403 || error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
          }
          ErrorUtils.catchError('createEntityAccount', this.formError);
        },
      });
  }

  private getEmailDuplicationError(error: string): string | null {
    if (typeof error !== 'string') return null;
    const matches = error.match(/^(?<text>.+ already exists with).*(?<email>email:\s.+)$/i);
    const { text, email } = matches?.groups ?? {};
    if (text && email) return `${text} ${email}`;
    return null;
  }

  private updatedFormData(data: Partial<AddEntityWithFinancialAccountForm>): void {
    this.formModel = {
      ...this.formModel,
      ...data,
    };
  }

  private recipientData(): AddRecipientParams {
    const { firstName, middleName, lastName, email, phoneNumber, recipientType, businessName } = this.formModel;

    return {
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
      email: email ? email : undefined,
      recipientType: recipientType!,
      middleName: middleName ? middleName : undefined,
      phoneNumber: phoneNumberFormat(phoneNumber),
      businessName: recipientType === 'BUSINESS' ? businessName : undefined,
      accountHolderType: this.parentEntityType as Customer['type'],
      accountHolderId: this.parentEntityId,
    };
  }

  private customerData(): AddCustomerParams {
    const {
      customerType,
      firstName,
      middleName,
      lastName,
      customerEmailAddress,
      customerPhoneNumber,
      doingBusinessAsName,
      contactFirstName,
      contactMiddleName,
      contactLastName,
      contactPrimaryEmail,
      contactPrimaryPhoneNumber,
    } = this.formModel;

    return customerType === 'INDIVIDUAL'
      ? {
          type: customerType!,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          primaryEmail: {
            value: customerEmailAddress!,
          },
          primaryPhoneNumber: {
            number: phoneNumberFormat(customerPhoneNumber),
          },
        }
      : {
          type: customerType!,
          contacts: [
            {
              firstName: contactFirstName!,
              middleName: contactMiddleName!,
              lastName: contactLastName!,
              primaryEmail: {
                value: contactPrimaryEmail!,
              },
              primaryPhoneNumber: {
                number: phoneNumberFormat(contactPrimaryPhoneNumber)!,
              },
              primary: true,
            },
          ],
          doingBusinessAsName: doingBusinessAsName,
        };
  }

  private financialAccountCreateData(): FinancialAccountCreateData {
    const {
      defaultFlag,
      cardOrBankAccount,
      bankAccount,
      card,
      addressLine1,
      addressLine2,
      postalCode,
      country,
      state,
      city,
      category,
      name,
      subtype,
    } = this.formModel;

    return {
      defaultFlag,
      cardOrBankAccount,
      category,
      name,
      subtype: cardOrBankAccount === 'bankAccount' ? subtype : 'DEBIT',
      parentId: category === 'INTERNAL' ? this.parentFinancialAccount : undefined,
      bankAccount:
        cardOrBankAccount === 'bankAccount'
          ? bankAccountCreateData({
              bankAccount: bankAccount!,
              address: {
                addressLine1: addressLine1!,
                addressLine2,
                postalCode: postalCode!,
                country: country!,
                state: state!,
                city: city!,
              },
            })
          : undefined,
      card:
        cardOrBankAccount === 'card'
          ? cardCreateData({
              card: card!,
              address: {
                addressLine1: addressLine1!,
                addressLine2,
                postalCode: postalCode!,
                country: country!,
                state: state!,
                city: city!,
              },
            })
          : undefined,
    };
  }

  private createEntityAccount(): Observable<{ recipient?: Recipient; customer?: Customer }> {
    if (this.formModel.accountHolderType === 'RECIPIENT') {
      return this.recipientService.addRecipient(this.recipientData()).pipe(map((recipient) => ({ recipient })));
    } else if (this.formModel.accountHolderType === 'CUSTOMER') {
      return this.customerService.addCustomer(this.customerData()).pipe(map((customer) => ({ customer })));
    } else {
      return of();
    }
  }

  private addEntityFinancialAccount(data: EntityFinancialAccountCreateData): Observable<EntityFinancialAccountDetails | undefined> {
    const { entityId, entityType, financialAccountData } = data;

    return this.financialAccountService.addEntityFinancialAccount(entityId, entityType, financialAccountData).pipe(
      catchError((error) => {
        ErrorUtils.catchError('financialAccountService.addEntityFinancialAccount', error);
        return of(undefined);
      }),
      defaultIfEmpty(undefined),
      map((financialAccountResponse) => financialAccountResponse),
    );
  }

  private addNoteToEntity(data: NoteCreateData): Observable<boolean> {
    const { entityId, entityType, note } = data;

    if (!note) {
      return of(true);
    }

    return this.noteService
      .addNote({
        entityId,
        entityType,
        contentText: note,
      })
      .pipe(
        catchError((error) => {
          ErrorUtils.catchError('noteService.addNote', error);
          return of(false);
        }),
        map((noteResponse) => !!noteResponse),
      );
  }

  private displayResultNotification({ financialAccountError, noteError }: ResultNotification) {
    if (financialAccountError && noteError) {
      this.notificationService.displayError(
        `${this.accountHolderTypeName} created successfully. Unable to create Financial Account and Note for ${this.accountHolderTypeName}.`,
        'Error',
      );
    } else if (financialAccountError) {
      this.notificationService.displayError(
        `${this.accountHolderTypeName} created successfully. Unable to create Financial Account for ${this.accountHolderTypeName}.`,
        'Error',
      );
    } else if (noteError) {
      this.notificationService.displayError(
        `${this.accountHolderTypeName} created successfully. Unable to create Note for ${this.accountHolderTypeName}.`,
        'Error',
      );
    } else {
      this.notificationService.displaySuccess(`${this.accountHolderTypeName} created successfully.`, 'Success');
    }
  }

  onParentFinancialAccountSelectScrollToEnd(): void {
    if (this.parentFinancialAccountListLoading || !this.parentFinancialAccountListHasNext) {
      return;
    }
    const pageNumber = this.parentFinancialAccountListPageNumber + 1;
    this.parentFinancialAccountListPageNumber = pageNumber;
    this.parentFinancialAccountListPageNumber$.next(pageNumber);
  }

  onParentFinancialAccountSelect(id: unknown) {
    this.parentFinancialAccount = id as string;
  }

  mapParentFinancialAccountListItems(financialAccounts: FinancialAccountListItem[], hasNext: boolean): { label: string; value: string }[] {
    const brandItems = financialAccounts.map((financialAccount) => ({
      label: `${financialAccount.name} ${financialAccount.displayName}` || '',
      value: financialAccount.id,
    }));
    this.parentFinancialAccountListHasNext = hasNext;

    return [...this.parentFinancialAccountListItems, ...brandItems];
  }
}
