<app-attention-modal
  type="success"
  title="Successful!"
  [messageTemplate]="message"
  [title]="customTitle"
  [buttonsTemplate]="buttons"
></app-attention-modal>

<ng-template #message>
  <ng-container *ngIf="!customTitle">You have successfully {{ actionName }} the {{ typeText }}.</ng-container>
</ng-template>

<ng-template #buttons>
  <button rktButton title="Done" (click)="activeModal.close(true)">Done</button>
</ng-template>
