import { Injectable } from '@angular/core';
import { BackendService } from '@shared/services/backend.service';
import { Observable, of, throwError } from 'rxjs';
import { constants } from '@shared/constants';
import { HttpContext, HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env';
import { AuthService } from '@shared/services';
import { mapPushNotification } from '@shared/utils';
import {
  RequestPageParams,
  AddPushNotificationParams,
  PushNotification,
  PushNotificationEventType,
  PushNotificationEventTypeRaw,
  BusinessAccountPushNotificationConfig,
} from '@shared/models';
import { SKIP_ERROR_NOTIFICATION } from '@shared/interceptors';
import { AuditActions } from '@shared/store';
import { compact } from 'lodash-es';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private backendService: BackendService, private authService: AuthService, private store: Store) {}

  public getPushNotifications({ page, size }: RequestPageParams): Observable<PushNotification[]> {
    const pageSize = size ?? constants.TABLE_ROWS;

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    return this.backendService.get<PushNotification[]>(`${environment.webhookService}/subscriptions`, { params }).pipe(
      map((response) => response.map((item) => mapPushNotification(item))),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public addNewSubscription(subscriptionData: AddPushNotificationParams): Observable<PushNotification> {
    return this.backendService.post<PushNotification>(`${environment.webhookService}/subscriptions`, { ...subscriptionData }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getNotificationById(subscriptionId: string): Observable<PushNotification> {
    return this.backendService.get<PushNotification>(`${environment.webhookService}/subscriptions/${subscriptionId}`).pipe(
      tap({
        next: (response) => {
          const { createdBy, updatedBy } = response;
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
        },
      }),
      map((response) => mapPushNotification(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getBusinessAccountSubscriptionStatus(): Observable<BusinessAccountPushNotificationConfig> {
    return this.backendService
      .get<BusinessAccountPushNotificationConfig>(`${environment.webhookService}/business-accounts`, {
        context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true),
      })
      .pipe(
        map((response) => response),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public enableBusinessAccountSubscription(): Observable<BusinessAccountPushNotificationConfig> {
    return this.getSelectedTenantSlug().pipe(
      switchMap((tenantSlug) =>
        this.backendService
          .post<BusinessAccountPushNotificationConfig>(`${environment.webhookService}/business-accounts`, {
            name: tenantSlug,
            provider: 'SVIX',
          })
          .pipe(
            map((response) => response),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public disableBusinessAccountSubscription() {
    return this.backendService
      .delete<void>(`${environment.webhookService}/business-accounts`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getNotificationEventTypes(): Observable<PushNotificationEventType[]> {
    return this.backendService.get<PushNotificationEventTypeRaw>(`${environment.webhookService}/event-types`).pipe(
      map((response) => PushNotificationService.mapPushNotificationEventTypes(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public deletePushNotification(subscriptionId: string): Observable<boolean> {
    return this.backendService.delete(`${environment.webhookService}/subscriptions/${subscriptionId}`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public updatePushNotification(subscriptionId: string, pushNotificationData: Partial<PushNotification>): Observable<PushNotification> {
    return this.backendService
      .update<PushNotification>(`${environment.webhookService}/subscriptions/${subscriptionId}`, pushNotificationData)
      .pipe(
        map((response) => mapPushNotification(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updatePushNotificationState(subscriptionId: string, state: string): Observable<PushNotification> {
    return this.backendService.update<PushNotification>(`${environment.webhookService}/subscriptions/${subscriptionId}/${state}`).pipe(
      map((response) => mapPushNotification(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  private static mapPushNotificationEventTypes(pushNotificationEventRaw: PushNotificationEventTypeRaw): PushNotificationEventType[] {
    const { eventTypes = [] } = pushNotificationEventRaw || [];

    return eventTypes.map((eventType) => ({
      label: eventType,
      value: eventType,
    }));
  }

  private getSelectedTenantSlug(): Observable<string> {
    return of(this.authService.realmName);
  }
}
