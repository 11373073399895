export const environment = {
  uaaService: `/uaa/api/auth`,
  documentService: `/document/api/portal/v1`,
  documentAcceptanceService: `/documentacceptance/api/portal/v1`,
  accountFlowService: `/accountflow/api/portal`,
  accountFlowEndpoint: `/accountflow`,
  accountPermissionEndpoint: `/accountpermission/api/portal`,
  financialAccountFlow: `/financialaccountflow/api/portal`,
  transactionFlowEndpoint: `/transactionflow/api/portal/v1`,
  transactionV2Endpoint: `/transactionflow/api/portal/v2`,
  compositionEndpoint: `/composition/api/portal/v1`,
  webhookService: `/webhookmanager/api/portal/v1`,
  noteFlowEndpoint: `/note/api/portal/v1`,
  limitsDomainEndpoint: `/limitsdomain/api/portal/v1`,
  linxV1Endpoint: `/rocketcapture/api/portal/v1/form-instances`,
  linxEndpoint: `/rocketcapture/api/portal/v2/workflows`,
  linxTemplatesEndpoint: `/rocketcapture/api/portal/v2/templates`,
  cardsEndpoint: `/card/api/portal/v1`,
  identityService: `/identityverification/api/portal/v1`,
  earnedWageEndpoint: `/earned-wage/api/portal/v1`,
  insightsEndpoint: `/biinsights/api/portal/v1`,
};
