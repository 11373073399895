<h2 class="title">Choose a document type</h2>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="type-list">
  <button
    *ngFor="let type of documentTypes"
    class="type-button"
    [class.selected]="type.value === documentType"
    (click)="selectDocumentType(type.value)"
  >
    <rkt-icon [name]="type.icon"></rkt-icon>
    {{ type.label }}
  </button>
</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="submit" [disabled]="!documentType" (click)="onSubmit()" title="Continue">Continue</button>
</div>
