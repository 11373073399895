<div class="footer-section">
  <div class="footer-links">
    <div class="footer-link-wrapper">
      <button class="footer-link" (click)="onPrivacyPolicyClick()" title="Privacy Policy">Privacy Policy</button>
    </div>

    <div class="footer-link-wrapper">
      <button class="footer-link" (click)="onTermsConditionsClick()" title="Terms & Conditions">Terms</button>
    </div>

    <div class="footer-link-wrapper">
      <a class="footer-link" href="https://insights.rocketkor.net" rel="noopener" target="_blank" title="Insights Dashboard"
        >Insights Dashboard</a
      >
    </div>

    <div class="footer-link-wrapper">
      <a class="footer-link" href="https://developer.rocketfncl.com" rel="noopener" target="_blank" title="API Documentation"
        >API Documentation</a
      >
    </div>
  </div>

  &copy; {{ currentYear }} Rocket Financial, Inc. All rights reserved.
</div>
