import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  confirmType: 'warning' | 'error' | 'success' | 'suspend' | 'resume' = 'error';

  title: string = 'Attention';

  actionName?: string;

  confirmBtnText?: string;

  displayName?: string;

  customText?: string;

  type!: 'Recipient' | 'TeamMember' | 'FinancialAccount' | 'Transaction' | 'Customer';

  get typeText() {
    switch (this.type) {
      case 'TeamMember':
        return 'Team Member';
      case 'Recipient':
        return 'Recipient Account';
      case 'FinancialAccount':
        return 'Financial Account';
      case 'Customer':
        return 'Customer Account';
      default:
        return this.type;
    }
  }

  constructor(public activeModal: ActiveModal) {}
}
