<div class="wire-type-wrapper">
  <div class="wire-type-item" [class.disabled]="isSameDayWindowDisabled">
    <label for="wire-same-day" class="wire-type-radio-input-label" [class.disabled]="isSameDayWindowDisabled">
      <span class="wire-type-radio-input" [class.checked]="wireType.value === 'SAME_DAY'"></span>

      <input
        hidden
        id="wire-same-day"
        value="SAME_DAY"
        [formControl]="wireType"
        (change)="onWireTypeSelect()"
        [attr.disabled]="isSameDayWindowDisabled ? true : null"
        name="wire-type"
        type="radio"
        class="form-check-input"
      />
      Same Day
    </label>

    <div class="wire-type-description">Funds will be available to the recipient today</div>
  </div>

  <div class="wire-type-item" [class.disabled]="isNextDayWireWindowDisabled">
    <label for="wire-next-day" class="wire-type-radio-input-label" [class.disabled]="isNextDayWireWindowDisabled">
      <span class="wire-type-radio-input" [class.checked]="wireType.value === 'NEXT_DAY'"></span>

      <input
        hidden
        id="wire-next-day"
        value="NEXT_DAY"
        [formControl]="wireType"
        (change)="onWireTypeSelect()"
        [attr.disabled]="isNextDayWireWindowDisabled ? true : null"
        name="wire-type"
        type="radio"
        class="form-check-input"
      />
      Next Day
    </label>

    <div class="wire-type-description">Funds will be available to the recipient on the next banking day</div>
  </div>
</div>
