import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromAuth, AuthActions, LoginPageActions } from '@shared/store';

@Injectable()
export class NoAuthGuard implements CanActivate {
  isAuthenticated$ = this.store.select(fromAuth.selectLoggedIn);

  constructor(private store: Store) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          return true;
        }

        if (state.url.includes('activate')) {
          this.store.dispatch(AuthActions.logout({ params: { skipNavigation: true } }));
          return true;
        }

        this.store.dispatch(LoginPageActions.loggedInRedirect());
        return false;
      }),
    );
  }
}
