<h1 class="title">Ask me anything about your account</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="rkt-table-search-container">
  <div class="rkt-table-search-label">
    <rkt-icon name="search" class="rkt-table-search-icon rkt-icon"></rkt-icon>
    <input
      type="text"
      class="rkt-input rkt-table-search-input"
      placeholder="Enter your search term..."
      [(ngModel)]="searchString"
      (keydown.enter)="getAISearchResults()"
    />
    <button (click)="getAISearchResults()" class="submit-icon" [disabled]="!searchString?.length">
      <rkt-icon name="send" class="rkt-icon"></rkt-icon>
    </button>
  </div>
  <div class="sample-query-div">
    <div class="example-pill" *ngFor="let queryText of sampleQueryTexts" (click)="updateSearchString(queryText)">{{ queryText }}</div>
  </div>
</div>

<div class="natural-language-search-results-container">
  <div class="loading-placeholder" *ngIf="loading"></div>

  <ng-container *ngIf="searchResults.length && !loading; else emptyResult">
    <div class="natural-language-sql-query">
      <div class="query-text">
        <code>{{ displaySearchString }}</code>
      </div>
    </div>

    <div class="natural-language-search-results-table">
      <rkt-table
        [rows]="searchResults"
        [columns]="columns"
        [count]="totalElements"
        [limit]="10"
        [loadingIndicator]="loading"
        [messages]="{ emptyMessage: 'No records found.' }"
        [scrollbarH]="true"
        emptyValuePlaceholder="--"
      ></rkt-table>
    </div>

    <div *ngIf="insightSummary">{{ insightSummary }}</div>

    <div class="download-csv-btn" *ngIf="downloadResultEnabled">
      <button rktButton btnType="primary" (click)="downloadCSVResult()">Download CSV</button>
    </div>
  </ng-container>

  <ng-template #emptyResult>
    <div class="empty-search-results">
      {{ searchErrorText }}
    </div>
  </ng-template>
</div>
