import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { AttachmentDetails } from '@shared/models';
import { AttachmentService } from '@shared/services';

@Component({
  selector: 'app-view-attachment-modal',
  templateUrl: './view-attachment-modal.component.html',
  styleUrls: ['./view-attachment-modal.component.scss'],
})
export class ViewAttachmentModalComponent {
  type = '';

  url = '';

  name = '';

  description = '';

  fileFormat = '';

  isDescriptionExpanded = false;

  attachmentId = '';

  get isImageFormat(): boolean {
    return ['PNG', 'JPG', 'JPEG', 'SVG'].includes(this.fileFormat);
  }

  constructor(public activeModal: ActiveModal, private attachmentService: AttachmentService) {}

  modalInitData({ url, name, extension, id, description }: AttachmentDetails) {
    this.url = url ? url.value : '';

    this.name = name;

    this.description = description;

    this.fileFormat = extension;

    this.attachmentId = id;
  }

  onExpandCollapse(): void {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }

  onDownloadClick(): void {
    this.attachmentService.downloadAttachment(this.attachmentId);
  }
}
