import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormModel } from '@shared/models';
import { linxRequestProfileFields } from './linx-request-enhance-profile-utils';

@Component({
  selector: 'app-linx-request-enhance-profile',
  templateUrl: './linx-request-enhance-profile.component.html',
})
export class LinxRequestEnhanceProfileComponent implements OnInit, AfterViewChecked {
  @Input() selectedEnhanceProfileDetails?: { [key: string]: string[] };

  @Output() continue = new EventEmitter<{ [key: string]: string[] }>();

  profileForm = new UntypedFormGroup({});

  formModel: FormModel<Record<string, Record<string, boolean> | string>> = {};

  profileFields: FormlyFieldConfig[] = linxRequestProfileFields;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setEnhanceProfile();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  private setEnhanceProfile(): void {
    if (!this.selectedEnhanceProfileDetails) return;
    this.formModel = {};
    if (this.selectedEnhanceProfileDetails.phoneNumber) {
      this.formModel.phoneNumber = this.selectedEnhanceProfileDetails.phoneNumber[0];
    }

    if (this.selectedEnhanceProfileDetails.personalInfo) {
      const personalInfo = {} as { [key: string]: boolean };
      this.selectedEnhanceProfileDetails.personalInfo.forEach((info: string) => {
        personalInfo[info] = true;
      });
      this.formModel.personalInfo = personalInfo;
    }

    if (this.selectedEnhanceProfileDetails.addresses) {
      const addresses = {} as { [key: string]: boolean };
      this.selectedEnhanceProfileDetails.addresses.forEach((address: string) => {
        addresses[address] = true;
      });
      this.formModel.addresses = addresses;
    }
  }

  onClickContinue(): void {
    const profileDetails: { [key: string]: string[] } = {};

    Object.keys(this.formModel).forEach((key) => {
      if (key === 'phoneNumber') {
        profileDetails[key] = [this.formModel[key]! as string];
      } else {
        const nestedObj = this.formModel[key]! as Record<string, boolean>;
        const trueKeys = Object.keys(nestedObj).filter((nestedKey) => nestedObj[nestedKey]);
        profileDetails[key] = trueKeys;
      }
    });

    this.continue.emit(profileDetails);
  }

  onClearBtnClick(): void {
    this.profileForm.reset();
  }
}
