<app-attention-modal
  type="error"
  [title]="modalTitle"
  [subtitle]="modalSubTitle"
  [messageTemplate]="message"
  [buttonsTemplate]="buttons"
></app-attention-modal>

<ng-template #message>{{ messageText }}</ng-template>

<ng-template #buttons>
  <button rktButton type="button" (click)="activeModal.close(true)" title="Continue">Continue</button>
</ng-template>
