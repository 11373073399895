import { Component, HostBinding } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { getYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { PrivacyPolicyComponent, TermsConditionsComponent } from '@shared/components';
import { systemTimeZone } from '@shared/utils';

@Component({
  selector: 'app-auth-layout-footer',
  templateUrl: './auth-layout-footer.component.html',
  styleUrls: ['./auth-layout-footer.component.scss'],
})
export class AuthLayoutFooterComponent {
  @HostBinding('class.auth-footer') commonClass = true;

  get currentYear(): string {
    return `${getYear(utcToZonedTime(new Date(), systemTimeZone))}`;
  }

  constructor(private modalService: ModalService) {}

  onPrivacyPolicyClick() {
    this.modalService.open(PrivacyPolicyComponent, { className: 'auth-document-modal', size: 'full-size' });
  }

  onTermsConditionsClick() {
    this.modalService.open(TermsConditionsComponent, { className: 'auth-document-modal', size: 'full-size' });
  }
}
