import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DatatableColumn, DatatableSorting } from '@rocketfinancialcorp/rocket-ui/table';
import { ErrorUtils, systemTimeZone } from '@shared/utils';
import { FinancialAccountStatementItem, FinancialAccountStatements, RequestPageParams } from '@shared/models';
import { FinancialAccountService, NotificationService } from '@shared/services';

@Component({
  selector: 'app-financial-account-statements',
  templateUrl: './financial-account-statements.component.html',
})
export class FinancialAccountStatementsComponent implements AfterViewChecked, OnDestroy {
  accountStatements: FinancialAccountStatementItem[] = [];

  loading = false;

  public columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'statementCreatedDate', colType: 'date', isSortable: true, fixedSize: 150 },
    { name: 'Statement Period', prop: 'statementPeriod', flexSize: 450 },
    { name: 'Opening Balance', prop: 'openingBalance', flexSize: 220, colType: 'amount', colAlign: 'right' },
    { name: 'Closing Balance', prop: 'closingBalance', flexSize: 220, colType: 'amount', colAlign: 'right' },
  ];

  totalElements = 0;

  page = 0;

  sortParams: DatatableSorting = { key: 'statementCreatedDate', sortProp: 'statementCreatedDate', sortDir: 'desc' };

  emptyListMessage = 'No Financial Account Statements found.';

  get activePage(): number {
    return this.page;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRowClick(data: FinancialAccountStatementItem): void {
    const statementPeriod = format(utcToZonedTime(new Date(data.statementStartDate), systemTimeZone), 'yyyy-MM');
    this.router.navigate([statementPeriod], { relativeTo: this.activatedRoute });
  }

  getFinancialAccountStatements({ page, size }: RequestPageParams): void {
    this.loading = true;

    if (page && this.activePage !== page) {
      this.page = page;
    }

    const { parent } = this.activatedRoute;

    this.financialAccountService
      .getFinancialAccountStatements({
        page,
        size,
        financialAccountId: parent!.snapshot.paramMap.get('id')!,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Account Statements found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Account Statements.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountStatements error', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountStatements): void {
    const { items = [], totalElements = 0 } = response ?? {};
    this.accountStatements = items;
    this.totalElements = totalElements;
    this.loading = false;
  }
}
