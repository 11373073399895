import { Address, AddressType, BeneficiaryAddress, BusinessAccountAddress, CustomerAddress } from '@shared/models';
import { compact, isEmpty, orderBy, some } from 'lodash-es';

export const formAddressSerializer = (address: Address): CustomerAddress | undefined => {
  if (isEmpty(address) || !address?.addressLine1 || !address?.country || !address?.city || !address?.state) return undefined;

  const { id, firstName, middleName, lastName, addressType, addressLine1, addressLine2, postalCode, country, city, state, primary } =
    address;

  return {
    ...(some([firstName, middleName, lastName])
      ? {
          name: {
            firstName,
            middleName,
            lastName,
          },
        }
      : {}),
    id: id,
    streetLine1: addressLine1,
    streetLine2: addressLine2,
    zipCode: postalCode,
    cityName: city,
    stateCode: state,
    countryCode: country,
    primary: !!primary,
    type: addressType!,
  };
};

export const formAddressDeserializer = (address: CustomerAddress | BeneficiaryAddress | BusinessAccountAddress): Address | undefined => {
  if (isEmpty(address) || !address?.streetLine1 || !address?.countryCode || !address?.cityName || !address?.stateCode) return undefined;

  const { id, name, streetLine1, streetLine2, zipCode, cityName, stateCode, countryCode, primary, type } = address;

  const { firstName, middleName, lastName } = name ?? {};

  return {
    id,
    firstName,
    middleName,
    lastName,
    addressLine1: streetLine1,
    addressLine2: streetLine2 ?? undefined,
    postalCode: zipCode ?? '',
    city: cityName ?? '',
    state: stateCode ?? '',
    country: countryCode ?? '',
    addressType: type,
    primary,
  };
};

export const getAddressesOfType = (
  addresses: (CustomerAddress | BeneficiaryAddress | BusinessAccountAddress)[],
  addressType: AddressType,
): Address[] => {
  return orderBy(
    compact(addresses.filter(({ type }) => type === addressType).map((address) => formAddressDeserializer(address))),
    ['primary'],
    ['desc'],
  );
};
