<div class="collapsible-row" [class.no-border]="isLastRow">
  <div class="collapsible-row-header" [class.has-value]="isRowHasValue">
    <div class="collapsible-row-icon">
      <div class="icon-success" *ngIf="isRowHasValue && !isRowExpanded"><rkt-icon name="check"></rkt-icon></div>
      <div class="default" *ngIf="number && (!isRowHasValue || isRowExpanded)" [class.is-expanded]="isRowExpanded">{{ number }}</div>
      <div class="default-icon" *ngIf="iconName && (!isRowHasValue || isRowExpanded)" [class.is-expanded]="isRowExpanded">
        <rkt-icon [name]="iconName"></rkt-icon>
      </div>
    </div>
    <div class="collapsible-row-label">
      {{ label }}
    </div>
  </div>

  <div class="collapsible-row-content">
    <ng-content></ng-content>
  </div>
</div>
