import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { PageLeaveConfirmationModalComponent } from '@shared/components';
import { fromAuth } from '@shared/store';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  isAuthenticated$ = this.store.select(fromAuth.selectLoggedIn);

  constructor(private modalService: ModalService, private store: Store) {}

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return of(true);
        } else {
          const deactivateOrShowDialog = component.canDeactivate() ? of(true) : from(this.openConfirmDialog());
          return component.canDeactivate !== undefined ? deactivateOrShowDialog : of(true);
        }
      }),
    );
  }

  openConfirmDialog(): Promise<boolean> {
    const modalRef = this.modalService.open(PageLeaveConfirmationModalComponent, {
      className: 'confirm-modal',
    });

    return modalRef.result.then(
      (result) => !!result,
      () => false,
    );
  }
}
