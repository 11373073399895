<ng-container *ngIf="cancelReviewData$ | async as cancelReviewData">
  <div class="modal-title">Please review the transaction</div>

  <div class="separator-line"></div>

  <div class="cancel-transaction-details">
    <div class="amount">
      <div class="section-label">Amount</div>
      <div class="amount-value">{{ cancelReviewData.amount! | amount: cancelReviewData.currency! }}</div>
    </div>

    <div class="cancel-transaction-amount-to-icon"></div>

    <div class="to-account">
      <div class="section-label">To Account</div>
      <div class="selected-financial-account">
        <div class="rows">
          <div class="row">
            <div class="label">Account Holder:</div>
            <div class="value">
              <div [class.empty-value]="!(cancelReviewData.toAccount?.accountHolderInfo || '' | rktSplit: '::':0)">
                {{ cancelReviewData.toAccount?.accountHolderInfo || '' | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(cancelReviewData.toAccount?.accountHolderInfo || '' | rktSplit: '::':1)">
                {{ cancelReviewData.toAccount?.accountHolderInfo || '' | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Info:</div>
            <div class="value">
              <div [class.empty-value]="!(cancelReviewData.toAccount?.accountInfo || '' || '' | rktSplit: '::':0)">
                {{ cancelReviewData.toAccount?.accountInfo || '' | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(cancelReviewData.toAccount?.accountInfo || '' | rktSplit: '::':1)">
                {{ cancelReviewData.toAccount?.accountInfo || '' | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Category:</div>
            <div class="value">{{ cancelReviewData.toAccount?.accountCategory }}</div>
          </div>

          <div class="row">
            <div class="label">Available Balance:</div>
            <div class="value">
              <div *ngIf="cancelReviewData.toAccount?.availableBalance !== undefined; else noValue">
                {{ cancelReviewData.toAccount?.availableBalance | amount: cancelReviewData.toAccount?.currency ?? 'USD' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="separator-line"></div>

  <div class="notes" *ngIf="cancelReviewData.note">
    <div class="notes-label">Notes</div>
    <div class="notes-note">{{ cancelReviewData.note }}</div>
  </div>

  <div class="buttons">
    <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close({ action: 'CANCEL_EDIT' })">Back to Edit</button>

    <button
      rktButton
      title="Confirm"
      (click)="activeModal.close({ action: 'CANCEL_CONFIRM', financialAccountId: cancelReviewData.toAccount?.id })"
    >
      Confirm
    </button>
  </div>
</ng-container>

<ng-template #noValue>
  <div class="empty-value">--</div>
</ng-template>
