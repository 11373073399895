import { Pipe, PipeTransform } from '@angular/core';
import { TransactionSolution } from '@shared/models';
import { getSolutionIconName } from '@shared/utils';

@Pipe({
  name: 'solutionIcon',
})
export class SolutionIconPipe implements PipeTransform {
  transform(value?: TransactionSolution): string | undefined {
    return getSolutionIconName(value);
  }
}
