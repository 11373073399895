<div class="upload-link-page">
  <div class="title">Document upload link for {{ applicationDetails?.displayName }}</div>

  <div class="sub-title">Share the link below with the applicant to complete their pending application.</div>

  <div class="hosted-sdk-link">
    <div class="label">Hosted SDK Link</div>
    <div class="link-container">
      <div class="upload-link">{{ docUploadLink }}</div>
      <div class="action-btn">
        <button rktButton btnType="link" (click)="openExternalLink()" title="Open external link">
          <rkt-icon name="external-link"></rkt-icon>
        </button>

        <button
          rktButton
          btnType="link"
          [cdkCopyToClipboard]="docUploadLink"
          (cdkCopyToClipboardCopied)="onCopyLinkToClipboard()"
          title="Copy link"
        >
          <rkt-icon name="copy"></rkt-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button rktButton type="submit" (click)="activeModal.close()" title="Done">Done</button>
  </div>
</div>
