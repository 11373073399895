<app-attention-modal
  type="error"
  [title]="modalTitle"
  [subtitle]="modalSubTitle"
  [messageTemplate]="errorBlocks && errorBlocks.length ? messageList : message"
  [buttonsTemplate]="buttons"
></app-attention-modal>

<ng-template #message>{{ messageText }}</ng-template>

<ng-template #messageList>
  <div class="message-list">
    <div class="message-block" *ngFor="let message of errorBlocks">
      <div class="title">{{ message.title }}</div>
      <div class="field" *ngFor="let field of message.fields">Missing {{ field }}</div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <button rktButton type="button" class="error-btn" (click)="activeModal.close(true)" title="Okay">Okay</button>
</ng-template>
