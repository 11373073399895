<div class="note-item-wrapper" *ngIf="!showPlaceholder; else loadingPlaceholder">
  <div
    class="note-item"
    [class.note-item-edit]="isEdit"
    [class.note-item-edit-error]="editNoteValue.errors || (error && displayError)"
    [class.single-note]="singleNote"
    *ngIf="note"
  >
    <ng-container *ngIf="{ activeUserId: activeUserId$ | async } as noteData">
      <div class="note-content-wrapper">
        <div class="note-header-wrapper">
          <div class="note-header">
            <div class="note-title" [title]="note.createdBy.id | teamMemberName | async">
              {{ note.createdBy.id | teamMemberName | async }}
            </div>

            <div class="note-controls" *ngIf="note.createdBy.id === noteData.activeUserId && !isEdit">
              <button class="note-action-button note-action-button-edit" (click)="onNoteEdit()" title="Edit">
                <rkt-icon name="edit"></rkt-icon>
              </button>
              <button class="note-action-button note-action-button-delete" (click)="onNoteDelete()" title="Delete">
                <rkt-icon name="trash"></rkt-icon>
              </button>
            </div>

            <div class="note-controls" *ngIf="note.createdBy.id === noteData.activeUserId && isEdit">
              <button class="note-action-button note-item-edit-save" (click)="onNoteEditSave()" title="Save">
                <rkt-icon name="save"></rkt-icon>
              </button>
              <button class="note-action-button note-item-edit-cancel" (click)="onNoteEditCancel()" title="Cancel">
                <rkt-icon name="times"></rkt-icon>
              </button>
            </div>
          </div>
          <div class="note-date" [class.list-note]="!singleNote" [title]="note.createdAt | dateFormat" *ngIf="!singleNote">
            <div class="note-item-date-text">{{ note.createdAt | dateFormat }}</div>
          </div>
        </div>

        <div class="note-content">
          <div class="note-body" *ngIf="!isEdit">
            {{ note.contentText
            }}<span class="note-body-edited" *ngIf="!isEdit && note.isUpdated" [title]="note.updatedAt | dateFormat">(Edited)</span>
          </div>
          <div class="add-note-field-wrapper" *ngIf="isEdit">
            <textarea
              #noteField
              class="add-notes-field"
              (blur)="onNoteFieldBlur()"
              name="edit-note"
              id="edit-note"
              [formControl]="editNoteValue"
              maxlength="1000"
            ></textarea>

            <div class="add-notes-char-count" [class.count-warning]="editNoteValue.value?.length > 800">
              {{ editNoteValue.value?.length || 0 }}/1000 Characters remaining
            </div>
          </div>

          <div class="attachment-upload-selected-container" *ngIf="attachmentsData.length">
            <div class="uploaded-attachment-item" *ngFor="let attachment of attachmentsData; index as i">
              <rkt-icon class="attachment-status" name="check"></rkt-icon>
              <div class="name">{{ attachment.name ?? attachment.file?.name }}</div>
              <button (click)="onEditFileBtnClick(i)" class="attachment-action-button attachment-action-button-edit">
                <rkt-icon name="edit"></rkt-icon>
              </button>
              <button (click)="onDeleteFileBtnClick(i)" class="attachment-action-button attachment-action-button-delete">
                <rkt-icon name="trash"></rkt-icon>
              </button>
            </div>
          </div>

          <div class="note-date" [title]="note.createdAt | dateFormat" *ngIf="singleNote">
            <div class="note-item-date-text">{{ note.createdAt | dateFormat }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="note-add-error" *ngIf="error && displayError">{{ error }}</div>

    <div class="note-validation-error" *ngIf="editNoteValue.errors && editNoteValue.errors.isEmptySpaces">
      {{ editNoteValue.errors && editNoteValue.errors.isEmptySpaces.message }}
    </div>
  </div>
  <div class="note-attention" *ngIf="isEdit">Do not enter the card/account details in notes</div>

  <app-note-item-attachments
    *ngIf="isEdit || note.attachments.length"
    #attachmentsList
    [isEdit]="isEdit"
    [attachments]="note.attachments"
    [entityId]="note.id"
    (addAttachment)="onAddAttachment($event)"
  >
  </app-note-item-attachments>
</div>

<ng-template #loadingPlaceholder>
  <div class="note-item-wrapper" *ngIf="showPlaceholder">
    <div class="note-item note-item-placeholder" [class.single-note]="singleNote">
      <div class="note-content-wrapper">
        <div class="note-header-wrapper">
          <div class="note-header">
            <div class="note-title"><div class="loading-placeholder"></div></div>
          </div>
        </div>

        <div class="note-content">
          <div class="note-body">
            <div class="loading-placeholder"></div>
            <div class="loading-placeholder"></div>
            <div class="loading-placeholder"></div>
          </div>

          <div class="note-date">
            <div class="note-item-date-text"><div class="loading-placeholder"></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
