import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { FeatureManagementActions, selectFeatureFlagEnabledByKey, selectGlobalBooleanFeatureFlags, selectRouteData } from '@shared/store';
import { BuildEnvironment, FeatureFlag } from '@shared/models';
import { APP_ENV_CONFIG } from '@shared/services';

@Component({
  selector: 'app-feature-management-modal',
  templateUrl: './feature-management-modal.component.html',
  styleUrls: ['./feature-management-modal.component.scss'],
})
export class FeatureManagementModalComponent {
  envConfig = inject(APP_ENV_CONFIG);

  get currentEnvironment() {
    return this.envConfig.name;
  }

  environmentList: BuildEnvironment[] = ['develop', 'qa', 'staging', 'sandbox', 'production'];

  allFlags: FeatureFlag[] = [];

  featureFlagsBoolean$ = this.store.select(selectGlobalBooleanFeatureFlags).pipe(
    tap({
      next: (flags) => {
        this.allFlags = flags;
        flags.forEach((flag) => {
          this.featureFlagsModel = { ...this.featureFlagsModel, [flag.key]: flag.value };
        });
      },
    }),
  );

  featureFlagsModel: Record<string, boolean | string> = {};

  constructor(public activeModal: ActiveModal, private store: Store, private router: Router) {}

  onSave(): void {
    this.store.dispatch(FeatureManagementActions.setFeatureFlags({ featureFlagsValues: this.featureFlagsModel }));
    this.store
      .select(selectRouteData)
      .pipe(switchMap((data) => this.store.select(selectFeatureFlagEnabledByKey(data?.featureKey))))
      .subscribe({
        next: (result) => {
          this.activeModal.close();
          if (!result) {
            this.router.navigateByUrl('/app/dashboard');
          }
        },
      });
  }

  onSwitch(key: string): void {
    this.featureFlagsModel[key] = !this.featureFlagsModel[key];
  }

  onReset(): void {
    this.store.dispatch(FeatureManagementActions.resetFeatureFlags());
    this.activeModal.close();
  }

  onEnvPresetClick(env: BuildEnvironment): void {
    this.allFlags.forEach((flag) => {
      this.featureFlagsModel = { ...this.featureFlagsModel, [flag.key]: flag.envsEnabledByDefault.includes(env) };
    });
  }
}
