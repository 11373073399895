<ng-container *ngIf="loading || customerIDVApplications.length || isFilterActive; else emptyState">
  <div class="list-page-header">
    <div class="toggle-buttons" *ngIf="isIndividualEntity">
      <div class="toggle-button active">IDV Applications</div>
      <div class="toggle-button" (click)="toggleWatchlistEvents.emit()">Watchlist Events</div>
    </div>
    <div class="list-page-controls">
      <div class="list-filters">
        <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
          <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
          Filter
          <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
        </button>

        <app-filter-menu
          #filterMenu
          [initialValues]="activeFilters"
          [filters]="filters"
          (opened)="filterBtn.classList.add('opened')"
          (closed)="filterBtn.classList.remove('opened')"
          (saved)="applyFilters($event)"
        ></app-filter-menu>
      </div>

      <ng-container *ngTemplateOutlet="submitIdentityVerificationTemplate"></ng-container>
    </div>
  </div>

  <rkt-table
    [rows]="customerIDVApplications"
    [columns]="columns"
    [count]="totalElements"
    [limit]="10"
    [loadingIndicator]="loading"
    [offset]="activePage"
    [sortParam]="sortParams"
    (fetchData)="getCustomerIDVApplications($event)"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    dateTimeFormat="MM/dd/yy HH:mm"
    (rowActivated)="onRowClick($event)"
  ></rkt-table>
</ng-container>

<ng-template #emptyState>
  <div class="empty-state">
    <div class="image"></div>

    <div class="message">You have not submitted any identity verification for this customer</div>

    <ng-container *ngTemplateOutlet="submitIdentityVerificationTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #submitIdentityVerificationTemplate>
  <button
    rktButton
    title="Submit Identity Verification"
    class="add-entity-btn"
    (click)="onSubmitIdentityVerification()"
    [disabled]="loading"
    [hidden]="!isIndividualEntity"
  >
    Submit Identity Verification
  </button>
</ng-template>
