import { createAction, props } from '@ngrx/store';
import { AuthResetParams, AuthTokenResponse, BusinessAccount, LinkedBusinessAccount, User } from '@shared/models';

export namespace AuthActions {
  export const loginSuccess = createAction(
    '[Auth/API] Login Success',
    props<{ user: User; businessAccount: LinkedBusinessAccount | null; tenant: string | null }>(),
  );

  export const showSplashScreen = createAction('[Auth] Show Splash Screen');

  export const loginFailure = createAction('[Auth/API] Login Failure', props<{ error: string }>());

  export const businessAccountDetails = createAction(
    '[Auth/API] Business Account Details',
    props<{ businessAccountDetails: BusinessAccount | null }>(),
  );

  export const logout = createAction('[Auth] Logout', props<{ params?: AuthResetParams }>());

  export const logoutCompleted = createAction('[Auth] Logout Completed', props<{ params?: AuthResetParams }>());

  export const refreshToken = createAction('[Auth] Refresh Token');

  export const refreshTokenSuccess = createAction('[Auth] Refresh Token Success', props<{ tokenInfo: AuthTokenResponse }>());

  export const setSpecialAuthTenant = createAction('[Special Auth Tenant] Set', props<{ isSpecialTenant: boolean }>());

  export const noActiveSession = createAction('[Auth] No Active Session');

  export const rehydrateState = createAction('[Auth] Rehydrate State');
}
