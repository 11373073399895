<h1 class="title">{{ (isEdit ? 'Update' : 'Add') + ' Financial Account' }}</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form">
  <div class="form-title">Financial Account Details</div>

  <form [formGroup]="cardForm">
    <rkt-form
      [hidden]="!(isInternalAvailable || isIntegratedAvailable)"
      [form]="cardForm"
      (modelChange)="onAccountCategoryChange($event)"
      [fields]="accountCategoryFields"
      [model]="formModel"
    ></rkt-form>

    <rkt-form
      [form]="cardForm"
      [fields]="financialAccountTypeFields"
      (modelChange)="onFinancialAccountTypeChanged()"
      [model]="formModel"
    ></rkt-form>
  </form>

  <form [formGroup]="financialAccountForm">
    <rkt-form class="name-field" [form]="financialAccountForm" [fields]="financialAccountFields" [model]="formModel"></rkt-form>
  </form>

  <div [hidden]="!isIntegratedCard">
    <form [formGroup]="integratedCardForm">
      <rkt-form [form]="integratedCardForm" [fields]="integratedCardFields" [model]="formModel"></rkt-form>
    </form>
  </div>

  <form [formGroup]="cardInfoForm">
    <div [hidden]="isInternal || isIntegratedCard || formModel.cardOrBankAccount === 'bankAccount'">
      <div class="form-title">Card Info</div>

      <rkt-form (modelChange)="onCardChange()" [form]="cardInfoForm" [fields]="cardFields" [model]="formModel"></rkt-form>

      <div class="form-title">Billing Info</div>

      <rkt-form [form]="cardInfoForm" [fields]="addressFields" [model]="formModel"></rkt-form>
    </div>
  </form>

  <form [formGroup]="bankForm">
    <div class="form-title" *ngIf="formModel.cardOrBankAccount === 'bankAccount'">Banking Info</div>

    <div *ngIf="isInternal">
      <app-form-select-field
        label="Parent Financial Account"
        placeholder="Select a Parent Account"
        [required]="true"
        [items]="parentFinancialAccountListItems"
        [loading]="parentFinancialAccountListLoading"
        (changed)="onParentFinancialAccountSelect($event)"
        (scrolledToEnd)="onParentFinancialAccountSelectScrollToEnd()"
        [disabled]="isEdit"
      ></app-form-select-field>
    </div>

    <div [hidden]="isInternal || formModel.cardOrBankAccount === 'card'">
      <rkt-form (modelChange)="onAccountNumberChange()" [form]="bankForm" [fields]="bankFields" [model]="formModel"></rkt-form>
    </div>
  </form>

  <form [formGroup]="addressForm" [hidden]="isInternal || formModel.cardOrBankAccount === 'card'">
    <div class="form-title">Billing Info</div>

    <div class="bank-account-address-description">
      *Plan to use a <span class="solution-name">wire transfer</span> on this account? Enter the address below, as a wire transfer requires
      an address to proceed.
    </div>

    <rkt-form [form]="addressForm" [fields]="addressFields" [model]="formModel"></rkt-form>
  </form>

  <div class="note-container" [hidden]="isIntegratedCard">
    <app-note-form-item [errors]="note.errors" [value]="note.value">
      <textarea
        class="note-field"
        #noteField
        (blur)="onNoteFieldBlur()"
        name="new-note"
        id="new-note"
        [formControl]="note"
        maxlength="1000"
      ></textarea>
    </app-note-form-item>
  </div>

  <div *ngIf="formError" class="details-page-edit-form-error">{{ formError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="isDisabled"
      [title]="(isEdit ? 'Update' : 'Add') + ' Financial Account'"
    >
      {{ isEdit ? 'Update' : 'Add' }}
    </button>
  </div>
</div>
