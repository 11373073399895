import { formInput, formRow } from '@rocketfinancialcorp/rocket-ui/form';

const expressions = {
  'props.required':
    '!!model.addressLine1 || !!model.addressLine2 || !!model.city || !!model.state || !!model.postalCode || !!model.country',
  'props.disabled':
    '(!!model.isEdit && model.category !== "EXTERNAL")  || (model.category === "INTEGRATED" && model.cardOrBankAccount === "bankAccount")',
};

export const addressFields = [
  formRow([{ type: '#addressLine1', props: { label: 'Billing address 1', minLength: 1, maxLength: 50 }, expressions }]),
  formRow([
    formInput({
      key: 'addressLine2',
      label: 'Billing address 2',
      props: { minLength: 1, maxLength: 50 },
      expressions: { 'props.disabled': '!!model.isEdit && model.category !== "EXTERNAL"' },
    }),
  ]),
  formRow([
    formInput({ key: 'city', label: 'City', className: 'city-field', props: { minLength: 1, maxLength: 25 }, expressions }),
    formInput({
      key: 'state',
      label: 'State',
      className: 'state-field',
      props: { minLength: 2, maxLength: 3 },
      validators: { validation: [{ name: 'isValidStateCode' }] },
      expressions,
    }),
    formInput({
      key: 'postalCode',
      label: 'Zip Code',
      className: 'postal-code-field',
      props: { minLength: 1, maxLength: 10 },
      expressions,
    }),
  ]),
  formRow([
    formInput({
      key: 'country',
      label: 'Country',
      parsers: [(value?: string) => value?.toUpperCase()],
      props: { minLength: 3, maxLength: 3 },
      validators: { validation: [{ name: 'isValidCountryCode' }] },
      validation: {
        messages: {
          minLength: 'Must be 3 characters',
        },
      },
      expressions,
    }),
  ]),
];
