import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { AccountVerificationHistoryComponent } from '@shared/components';
import { VerificationStatus } from '@shared/models';

@Component({
  selector: 'app-details-account-verification',
  templateUrl: './details-account-verification.component.html',
  styleUrls: ['./details-account-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsAccountVerificationComponent {
  modalService = inject(ModalService);

  @Input() verificationStatus?: VerificationStatus;

  @Input() verificationStatusHistory?: VerificationStatus[];

  showAccountVerificationHistoryModal() {
    const verificationHistoryModalRef = this.modalService.open(AccountVerificationHistoryComponent, {
      className: 'account-verification-history-modal',
    });

    verificationHistoryModalRef.componentInstance.historyItems = this.verificationStatusHistory;
  }
}
