<div class="move-how-section">
  <div class="move-how-direction-section">
    <app-move-how-direction
      [items]="directionItems"
      [disabledTypes]="directionDisabledTypes"
      [selectedItem]="selectedMoveItem"
      (changed)="selectMoveItem($event)"
      className="move-money-direction"
    ></app-move-how-direction>
  </div>

  <div class="move-how-solution-section">
    <app-move-how-solution
      [items]="solutionItems"
      [disabledTypes]="solutionDisabledTypes"
      [selectedItem]="selectedMoveHowType"
      (changed)="selectMoveHowType($event)"
    ></app-move-how-solution>
  </div>
</div>
