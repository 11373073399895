import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { IdentityVerificationProcessingSummary } from '@shared/models';
import { NotificationService } from '@shared/services';

@Component({
  selector: 'app-upload-document-link',
  templateUrl: './upload-document-link.component.html',
  styleUrls: ['./upload-document-link.component.scss'],
})
export class UploadDocumentLinkComponent {
  applicationDetails?: IdentityVerificationProcessingSummary;

  get docUploadLink(): string {
    return this.applicationDetails?.docUploadUrl || '';
  }

  constructor(public activeModal: ActiveModal, private notificationService: NotificationService) {}

  openExternalLink() {
    window.open(`${this.applicationDetails?.docUploadUrl}`, '_blank');
  }

  onCopyLinkToClipboard() {
    this.notificationService.displaySuccess('Link copied to clipboard');
  }
}
