<h1 class="title">{{ name }}</h1>

<div class="subtitle">Version {{ version }}</div>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="scroll-wrapper">
  <ngx-extended-pdf-viewer
    [src]="downloadUrl"
    [showBorders]="false"
    [showToolbar]="false"
    [textLayer]="false"
    [sidebarVisible]="false"
    backgroundColor="#F7F7F7"
    [useBrowserLocale]="true"
    [authorization]="true"
    (pdfLoadingFailed)="handleError($event)"
  ></ngx-extended-pdf-viewer>
</div>

<div class="buttons">
  <button rktButton type="button" (click)="activeModal.close()" title="OK">OK</button>
</div>
