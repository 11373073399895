type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

/*
    A utility function similar to Object.entries() that returns an array of key-value pairs from an object,
    but with added type inference to ensure the types of keys and values are preserved in the resulting array.
*/
export const objectEntries = <T extends object>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>;
};
