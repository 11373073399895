import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['./multiple-file-upload.component.scss'],
})
export class MultipleFileUploadComponent {
  @ViewChild('fileSelector') public fileSelector!: ElementRef;

  @Input() fileTypes = ['text/csv'];

  @Input() uploadedFileLabel?: string;

  @Input() title = 'Drag & drop files here';

  isDragging = false;

  @Output() public selectedFiles = new EventEmitter<File[]>();

  constructor(private modalService: ModalService) {}

  get fileFormats(): string {
    const formats = this.fileTypes.map((item: string) => {
      const extension = item.split('.').pop()?.toUpperCase();

      if (item.includes('jpeg')) {
        return 'JPEG';
      } else if (item.includes('png')) {
        return 'PNG';
      } else if (item.includes('pdf')) {
        return 'PDF';
      } else if (item.includes('jpg')) {
        return 'JPG';
      } else {
        return extension;
      }
    });
    return formats.join(', ');
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
    const fileList = event.dataTransfer?.files;
    if (fileList && fileList.length) {
      this.onFileSelected(fileList);
    }
  }

  onFileUpload(event: Event) {
    const fileList = (event.target as HTMLInputElement).files;
    if (fileList && fileList.length) {
      this.onFileSelected(fileList);
    }
  }

  public onFileSelected(fileList: FileList): void {
    const files = Array.from(fileList);
    const isValidFiles = files.some((file) => this.fileTypes.includes(file.type));
    if (isValidFiles) {
      const isFileSizeExceeded = files.some((file) => this.isFileExceededSize(file));
      if (isFileSizeExceeded) {
        this.showErrorSizeModal();
        return;
      }

      this.fileSelector.nativeElement.value = '';
      this.selectedFiles.emit(files);
    } else {
      this.showErrorFormatModal();
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  public stopDrag(event: DragEvent): void {
    this.isDragging = false;
    event.preventDefault();
    event.stopPropagation();
  }

  isFileExceededSize(file: File): boolean {
    const fileLimit = 1024 * 1024 * 5;
    return !!(file && (file.size === 0 || file.size > fileLimit));
  }

  showErrorFormatModal() {
    this.showErrorModal(`Incorrect format. Please select file of one of the types ${this.fileFormats} and re-upload.`);
  }

  showErrorSizeModal() {
    this.showErrorModal('The uploaded file cannot exceed 5MB in size.');
  }

  showErrorModal(errorText: string) {
    const errorModalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });
    errorModalRef.componentInstance.title = 'Upload Failed';
    errorModalRef.componentInstance.customText = errorText;
    errorModalRef.componentInstance.confirmBtnText = 'Try Again';

    errorModalRef.result.then(
      (result) => {
        if (result) {
          this.fileSelector.nativeElement.click();
        }
      },
      () => false,
    );
  }
}
