import { Component, Input, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { TransactionFormAction, transactionFormFeature } from '@shared/store';
import { FinancialAccountSelectionModalComponent } from '@shared/components';
import { FinancialAccountSelectionListItem } from '@shared/models';

@Component({
  selector: 'app-create-transaction-financial-account',
  templateUrl: './create-transaction-financial-account.component.html',
})
export class CreateTransactionFinancialAccountComponent {
  @Input() type: 'from' | 'to' = 'from';

  private readonly modalService = inject(ModalService);

  private readonly store = inject(Store);

  formAction$ = this.store.select(transactionFormFeature.selectFormAction);

  fromAccount$ = this.store.select(transactionFormFeature.selectFromAccount);

  toAccount$ = this.store.select(transactionFormFeature.selectToAccount);

  isDebitLegsDisabled$ = this.store.select(transactionFormFeature.selectIsDebitLegsDisabled);

  onSelectFinancialAccountClick(params?: {
    selectedFinancialAccount?: FinancialAccountSelectionListItem;
    formAction?: TransactionFormAction;
    fromAccount?: FinancialAccountSelectionListItem;
  }) {
    const financialAccountSelectionModalRef = this.modalService.open(FinancialAccountSelectionModalComponent, {
      className: 'create-transaction-financial-account-modal',
    });

    financialAccountSelectionModalRef.componentInstance.modalInitData({
      type: this.type,
      selectedFinancialAccount: params?.selectedFinancialAccount,
      fromAccount: params?.fromAccount,
      isEditMLT: params?.formAction === 'EDIT_MLT',
      isEditSLT: params?.formAction === 'EDIT_SLT',
      isEditScheduledSLT: params?.formAction === 'EDIT_SCHEDULED_SLT',
    });
  }
}
