import { Directive, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { AttachmentsListComponent, LinxConfirmModalComponent } from '..';
import { AttachmentCreateModel, AttachmentEntityType } from '@shared/models';
import { AttachmentService } from '@shared/services';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { ErrorUtils } from '@shared/utils';
import { ALLOWED_ATTACHMENT_FILE_FORMATS } from '@shared/constants';

@Directive({
  selector: '[appDragDropAttachment]',
})
export class DragDropAttachmentDirective implements OnDestroy {
  constructor(private modalService: ModalService, private attachmentService: AttachmentService) {}

  private destroy$ = new Subject<void>();

  allowedFileFormats = ALLOWED_ATTACHMENT_FILE_FORMATS;

  @Input() entityId?: string | null;
  @Input() entityType?: AttachmentEntityType;
  @Input() attachmentsList?: AttachmentsListComponent;

  @Output() filesDropped = new EventEmitter<Partial<AttachmentCreateModel>[]>();

  @HostBinding('class.attachments-drag-drop-hovering') isDragging = false;

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
    const fileList = event.dataTransfer?.files;

    if (fileList?.length) {
      const files = Array.from(fileList);
      const isValidFiles = files.some((file) => this.allowedFileFormats.includes(file.type));
      if (isValidFiles) {
        const isFileSizeExceeded = files.some((file) => this.isFileExceededSize(file));
        if (isFileSizeExceeded) {
          this.showErrorSizeModal();
          return;
        }

        if (!this.entityId || !this.entityType) {
          this.emitFiles(files);
          return;
        }

        this.createAttachments(files);
      } else {
        this.showErrorFormatModal();
      }
    }
  }

  emitFiles(files: File[]): void {
    const attachmentsData: Partial<AttachmentCreateModel>[] = [];
    files.forEach((file) => {
      const extension = file?.name.match(/^.*\.(?<extension>[a-z]+)$/i);
      attachmentsData.push({
        file: file,
        extension: extension?.groups?.extension.toUpperCase(),
      });
    });
    this.filesDropped.emit(attachmentsData);
  }

  isFileExceededSize(file: File | null): boolean {
    const fileLimit = 1024 * 1024 * 5;
    return !!(file && (file.size === 0 || file.size > fileLimit));
  }

  showErrorFormatModal() {
    this.showConfirmationModal(
      'Incorrect format. Please select file of one of the types JPEG ,JPG, PNG, PDF and re-upload.',
      'Upload Failed',
    );
  }

  showErrorSizeModal() {
    this.showConfirmationModal('The uploaded file cannot exceed 5MB in size.', 'Upload Failed');
  }

  showConfirmationModal(message: string, title: string, confirmType = 'error') {
    const errorModalRef = this.modalService.open(LinxConfirmModalComponent, {
      className: 'confirm-modal',
    });
    errorModalRef.componentInstance.title = title;
    errorModalRef.componentInstance.customText = message;
    errorModalRef.componentInstance.confirmType = confirmType;
    errorModalRef.componentInstance.confirmBtnText = 'Close';
  }

  createAttachments(files: File[]): void {
    if (!files?.length) {
      return;
    }

    forkJoin(
      files.map((file) => {
        const extension = file?.name.match(/^.*\.(?<extension>[a-z]+)$/i);
        const attachmentData = {
          file: file,
          extension: extension?.groups?.extension.toUpperCase(),
          entityType: this.entityType,
          entityId: this.entityId,
        } as AttachmentCreateModel;

        return this.attachmentService.createAttachment(attachmentData);
      }),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (this.attachmentsList) {
            this.showConfirmationModal('Attachments uploaded successfully.', 'Upload Successful', 'success');
            this.attachmentsList.getAttachments({ page: 0 });
          }
        },
        error: (error) => {
          this.showConfirmationModal('Unable to upload attachments. Please try again', 'Upload Failed');
          ErrorUtils.catchError('attachmentService.createAttachment', error);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
