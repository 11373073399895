import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableColumn, DatatableSorting, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { FilterField, FilterValues, RequestPageParams, SubmitIdentityVerificationApplicationParams } from '@shared/models';
import { IdentityVerificationService } from '@shared/services';
import { ErrorUtils, activeFilters, safeJSONparse } from '@shared/utils';
import { Subject, takeUntil } from 'rxjs';
import { watchlistEventsFilterFieldOptions } from '../form-fields';
import { isEmpty, isUndefined } from 'lodash-es';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { SuccessModalComponent } from '@shared/components/success-modal/success-modal.component';
import { SubmitIdentityVerificationComponent } from '../submit-identity-verification/submit-identity-verification.component';
import { WatchlistEventList } from '@shared/models/watchlist-events';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityVerificationErrorModalComponent } from '../identity-verification-error-modal/identity-verification-error-modal.component';
import { IDV_ERROR_MESSAGES_BY_CODES } from '@shared/constants';

@Component({
  selector: 'app-watchlist-events-list',
  templateUrl: './watchlist-events-list.component.html',
  styleUrls: ['./watchlist-events-list.component.scss'],
})
export class WatchlistEventsListComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() accountHolder!: string;

  @Output() toggleIdvApplications = new EventEmitter();

  watchlistEvents: WatchlistEventList['items'] = [];

  totalElements = 0;

  loading = false;

  customerType!: string;

  isFilterActive: boolean = false;

  activeFilters: FilterValues = {};

  filters: FilterField[] = watchlistEventsFilterFieldOptions;

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 200, isSortable: true, sortProp: 'createdAt', colType: 'date-time' },
    { name: 'ID', prop: 'shortId', fixedSize: 200, colType: 'short-id' },
    { name: 'Services', prop: 'services', flexSize: 200 },
    { name: 'Outcome', prop: 'outcome', flexSize: 200, colType: 'status-tag' },
    { name: 'Status', prop: 'latestStatus', fixedSize: 200, isSortable: false, colType: 'status-tag' },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  emptyListMessage = 'No watchlist events found.';

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get isCustomerBenificiary(): boolean {
    return this.activatedRoute.snapshot.data?.subType === 'beneficiary';
  }

  private destroy$ = new Subject<void>();

  constructor(
    private identityVerificationService: IdentityVerificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.customerType = this.activatedRoute.snapshot.data?.customerType === 'individual' ? 'individuals' : 'businesses';
    this.identityVerificationService.setWatchlistEventsFilterParams({});
  }

  ngOnInit(): void {
    this.initFilterList();
  }

  initFilterList(): void {
    this.identityVerificationService.watchlistEventsListFilter.pipe(takeUntil(this.destroy$)).subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.identityVerificationService.getWatchlistEventsFilterParams();
        } else {
          this.activeFilters = filters;
          this.isFilterActive = this.activeFiltersCount > 0;
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getWatchlistEvents({ page, size, sortParams }: FetchData): void {
    if (!this.accountHolder) {
      return;
    }

    this.loading = true;

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.identityVerificationService
      .getWatchlistEvents(`accountId=${this.accountHolder}`, {
        page: page ?? 0,
        size,
        sortParams: sortParams ?? this.sortParams,
        activeFilters: this.activeFilters,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (idvApplicationListItems) => {
          this.emptyListMessage = 'No Watchlist Events found.';
          this.onFetchComplete(idvApplicationListItems);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Customer Watchlist events.';
          this.onFetchComplete();
          ErrorUtils.catchError('identityVerificationService.getWatchlistEvents', error);
        },
      });
  }

  onFetchComplete(watchlistEventsListItems?: WatchlistEventList): void {
    const { items = [], totalElements = 0 } = watchlistEventsListItems ?? {};
    this.watchlistEvents = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  onSubmitIdentityVerification(): void {
    const customerModalRef = this.modalService.open(SubmitIdentityVerificationComponent, {
      className: 'entity-form-modal',
    });

    const customerType = this.customerType === 'individuals' ? 'INDIVIDUAL_CUSTOMER' : 'BUSINESS_CUSTOMER';
    customerModalRef.componentInstance.modalInitData({ holderId: this.accountHolder, accountHolderType: customerType });

    customerModalRef.result.then(
      (result) => {
        if (result) {
          this.submitIdentityVerification(result);
        }
      },
      () => false,
    );
  }

  submitIdentityVerification(submitIdentityData: SubmitIdentityVerificationApplicationParams): void {
    this.identityVerificationService
      .submitIdentityVerification(submitIdentityData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const identityVerificationSuccessModal = this.modalService.open(SuccessModalComponent, {
            className: 'success-modal',
          });

          identityVerificationSuccessModal.componentInstance.actionName = 'submitted';
          identityVerificationSuccessModal.componentInstance.type = 'Identity Verification';
          identityVerificationSuccessModal.result.finally(() => {
            this.getWatchlistEvents({ page: 0 });
          });
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  handleError(error: string | number | HttpErrorResponse): void {
    const errorModalRef = this.modalService.open(IdentityVerificationErrorModalComponent, { className: 'identity-verification-modal' });

    const errorObj = safeJSONparse(error as string);
    if (errorObj?.errors) {
      const errorBlocks = this.identityVerificationService.parseIDVSubmitError(errorObj.errors);
      errorModalRef.componentInstance.errorBlocks = errorBlocks;
      errorModalRef.componentInstance.modalSubTitle = 'Missing information. Please fill out the following customer data to try again:';
    } else if (errorObj.errorCode) {
      errorModalRef.componentInstance.messageText = IDV_ERROR_MESSAGES_BY_CODES[errorObj.errorCode];
    } else if (typeof error === 'string') {
      errorModalRef.componentInstance.messageText = error.match(/'.*?'/g)![2] || error;
    }

    errorModalRef.result.finally(() => {
      this.getWatchlistEvents({ page: 0 });
    });

    ErrorUtils.catchError('identityVerificationService.submitIdentityVerification error', error);
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.getWatchlistEvents({ page: 0 });
  }
}
