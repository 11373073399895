<ng-container *ngIf="merchantDetails$ | async as merchant; else merchantDetailsLoading">
  <ng-container *ngTemplateOutlet="merchantDetails; context: { $implicit: merchant, loading: false }"></ng-container>
</ng-container>
<ng-template #merchantDetailsLoading>
  <ng-container *ngTemplateOutlet="merchantDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #merchantDetails [appTemplateContext]="merchantDetailsContext" let-merchant let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="merchantDetails"></app-card-pages-breadcrumbs>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Merchant Details</app-text-with-placeholder>
      </div>
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Merchant ID"
        [value]="merchant?.id || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'merchant-name'"
        [showPlaceholder]="loading"
        label="Merchant Name"
        [value]="merchant?.merchantName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'phone-number'"
        [showPlaceholder]="loading"
        label="Phone Number"
        [value]="merchant?.phoneNumber | phone"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'trip-advisor'"
        [showPlaceholder]="loading"
        label="TripAdvisor"
        [value]="merchant?.tripAdvisor"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'yelp'"
        [showPlaceholder]="loading"
        label="Yelp"
        [value]="merchant?.yelp"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'keywords'"
        [showPlaceholder]="loading"
        label="Keywords"
        [value]="merchant?.keywords"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-section-title">
      <div class="title"><app-text-with-placeholder [showPlaceholder]="loading">Address</app-text-with-placeholder></div>
    </div>

    <div class="details-row">
      <ng-container *ngIf="merchant?.addresses?.length; else emptyAddress">
        <app-details-item
          *ngFor="let address of merchant?.addresses; let i = index"
          [class]="pageClassPrefix + 'address-' + (i + 1)"
          [showPlaceholder]="loading"
          [label]="'Address ' + (i + 1)"
          [value]="address.streetLine1"
        >
          <ng-container>
            <div>{{ address.streetLine2 }}</div>
            <div>{{ address.cityName }}, {{ address.stateCode }}</div>
            <div>{{ address.zipCode }} {{ address.countryCode }}</div>
          </ng-container>
        </app-details-item>
      </ng-container>

      <ng-template #emptyAddress>
        <app-details-item [value]="" [showPlaceholder]="loading"></app-details-item>
      </ng-template>
    </div>

    <div class="details-separator"></div>

    <div class="details-description-item">
      <div class="description-item-title-container">
        <div class="description-item-title">Merchant Description</div>
        <button rktButton btnType="link">View all</button>
      </div>
      <div class="description-item-content-container">
        <div class="description-item-content">
          <div class="content-text">
            {{ merchant?.merchantDescription?.description }}
          </div>
        </div>

        <div class="description-item-controls">
          <div class="description-item-actions">
            <button class="description-item-button description-item-edit" title="Edit">
              <rkt-icon name="edit"></rkt-icon>
            </button>
            <button class="description-item-button description-item-delete" title="Delete">
              <rkt-icon name="trash"></rkt-icon>
            </button>
          </div>
          <div class="description-item-date">{{ merchant?.merchantDescription?.createdAt | dateFormat }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
