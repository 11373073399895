import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as fromCustomerBeneficiary from './customer-beneficiary.reducer';

export const customerFeatureKey = 'customer';

export interface CustomerState {
  [fromCustomerBeneficiary.customerBeneficiaryPageFeatureKey]: fromCustomerBeneficiary.CustomerBeneficiaryState;
}

export interface State extends fromRoot.RootState {
  [customerFeatureKey]: CustomerState;
}

export const reducers = (state: CustomerState | undefined, action: Action) => {
  return combineReducers({
    [fromCustomerBeneficiary.customerBeneficiaryPageFeatureKey]: fromCustomerBeneficiary.reducer,
  })(state, action);
};

export const selectCustomerState = createFeatureSelector<CustomerState>(customerFeatureKey);

export const selectCustomerBeneficiaryState = createSelector(selectCustomerState, (state) => state.customerBeneficiaryPage);

export const selectCustomerBeneficiaryDetails = createSelector(
  selectCustomerBeneficiaryState,
  fromCustomerBeneficiary.getCustomerBeneficiary,
);

export const selectCustomerBeneficiaryDetailsLoading = createSelector(selectCustomerBeneficiaryState, fromCustomerBeneficiary.getLoading);

export const selectCustomerBeneficiaryDetailsError = createSelector(selectCustomerBeneficiaryState, fromCustomerBeneficiary.getError);
