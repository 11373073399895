import { Directive, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appBodyHighlight]',
})
export class BodyHighlightDirective {
  @Input() set appBodyHighlight(isHighlighted: boolean) {
    if (isHighlighted) {
      this.renderer.addClass(document.body, 'move-money-body-highlighted');
    } else {
      this.renderer.removeClass(document.body, 'move-money-body-highlighted');
    }
  }

  constructor(private renderer: Renderer2) {}
}
