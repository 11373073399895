import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, concatMap, filter, finalize, map, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { capitalize, compact } from 'lodash-es';
import { ActivatedRoute, Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { FinancialAccountDetails, FinancialAccountHolderType, Breadcrumb, AccountBalance } from '@shared/models';
import { CustomerService, FinancialAccountService, RecipientService, SubscriberService } from '@shared/services';
import { ErrorUtils, toTitleCase } from '@shared/utils';
import { keys } from 'lodash/index';

@Component({
  selector: 'app-financial-account-page',
  templateUrl: 'financial-account-page.component.html',
  styleUrls: ['financial-account-page.component.scss'],
})
export class FinancialAccountPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  public financialAccount: FinancialAccountDetails | null = null;

  public entityFinancialAccountId!: string;

  public entityId?: string | null;

  public isFinancialAccountLoading = true;

  public entityBreadcrumb?: string;

  public entityBreadcrumbURL?: string;

  public entitySubBreadcrumb?: string;

  public entitySubBreadcrumbURL?: string;

  public parentEntityBreadcrumb?: string;

  public parentEntityBreadcrumbURL?: string;

  public parentEntitySubBreadcrumb?: string;

  public parentEntitySubBreadcrumbURL?: string;

  public pageReturnURL!: string;

  public entityType?: FinancialAccountHolderType;

  public financialAccountCurrencyList: { label: string; value: string }[] = [];

  public currencyList: string[] = [];

  public selectedCurrency!: string;

  public accountBalanceValues!: AccountBalance;

  public accountBalanceList!: { [key: string]: AccountBalance };

  public transactionId?: string | null;

  public customerId?: string | null;

  public customerType?: 'business' | 'individual';

  tabItems: { label: string; href: string }[] = [{ label: 'Account Information', href: 'account-details' }];

  private destroy$ = new Subject<void>();

  get isFromTransaction() {
    const snapshot = this.activatedRoute.snapshot;
    return !!snapshot.paramMap.get('transactionId');
  }

  get breadcrumbList(): Breadcrumb[] {
    if (this.isFromTransaction) {
      return [
        {
          label: 'Transaction Activity',
          url: '/app/transactions/transactions',
        },
        {
          label: 'Transaction Details',
          url: `/app/transactions/transactions/${this.transactionId}`,
        },
        { label: 'Account Details' },
      ];
    }
    return compact([
      this.parentEntityBreadcrumb ? { label: this.parentEntityBreadcrumb, url: this.parentEntityBreadcrumbURL } : null,
      this.parentEntitySubBreadcrumb ? { label: this.parentEntitySubBreadcrumb, url: this.parentEntitySubBreadcrumbURL } : null,
      this.entityBreadcrumb ? { label: this.entityBreadcrumb, url: this.entityBreadcrumbURL } : null,
      this.entitySubBreadcrumb ? { label: this.entitySubBreadcrumb, url: this.entitySubBreadcrumbURL } : null,
      { label: 'Financial Accounts', url: this.pageReturnURL },
      { label: 'Account Details' },
    ]);
  }

  get isExternalFinancialAccount(): boolean {
    return this.financialAccount?.category === 'EXTERNAL';
  }

  get isIntegratedCardFinancialAccount(): boolean {
    return this.financialAccount?.type === 'CARD' && this.financialAccount?.category === 'INTEGRATED';
  }

  get titleName(): string {
    if (!this.financialAccount) {
      return '';
    }

    const { name, category, cardOrBankAccount, displayName } = this.financialAccount;

    if (this.entityType !== 'business-account' && displayName) {
      return displayName;
    }

    if (name) {
      return name;
    }

    return `${toTitleCase(category)} ${cardOrBankAccount === 'card' ? 'Card' : 'Bank'} Account`;
  }

  get isEscrowAccount(): boolean {
    return this.router.url.split('/').includes('escrow-account-details');
  }

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recipientService: RecipientService,
    private customerService: CustomerService,
    private financialAccountService: FinancialAccountService,
    protected layoutOptions: SubscriberService<FinancialAccountDetails>,
  ) {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (event) => {
          if (!this.financialAccount && (event as NavigationEnd).url?.split('/').slice(-1)[0] === 'account-activity') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'account-activity' },
            ];
          } else if (!this.financialAccount && (event as NavigationEnd).url?.split('/').slice(-1)[0] === 'child-accounts') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'account-activity' },
              { label: 'Child Accounts', href: 'child-accounts' },
            ];
          } else if (!this.financialAccount && (event as NavigationEnd).url?.split('/').slice(-1)[0] === 'external-account-activity') {
            this.tabItems = [
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'external-account-activity' },
            ];
          } else if (!this.financialAccount && (event as NavigationEnd).url?.split('/').slice(-1)[0] === 'escrow-account-details') {
            this.tabItems = [{ label: 'Account Information', href: 'escrow-account-details' }];
          }
          this.getRouteParams();
        },
      });
  }

  public ngOnInit(): void {
    this.layoutOptions.subscribe((financialAccount) => {
      if (!financialAccount) {
        this.getFinancialAccountById();
        return;
      }

      this.financialAccount = financialAccount;
    });
  }

  public ngAfterViewChecked(): void {
    if (this.financialAccount) {
      this.layoutOptions.next(this.financialAccount);
    }
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getRouteParams(): void {
    const { snapshot } = this.activatedRoute;
    this.transactionId = snapshot.paramMap.get('transactionId');
    this.customerId = snapshot.paramMap.get('customerId');
    this.customerType = snapshot.data['customerType'];
    this.entityFinancialAccountId = snapshot.paramMap.get('id')!;
    this.entityId = snapshot.paramMap.get('entityId')!;
    this.entityType = snapshot.data['accountHolderType'];

    this.mapEntityAccountDetails();
    this.getFinancialAccountById();
  }

  public getFinancialAccountById(): void {
    const observable = this.isEscrowAccount
      ? this.financialAccountService.getEscrowFinancialAccountById(this.entityFinancialAccountId)
      : this.financialAccountService.getFinancialAccountDetailsWithBalances(this.entityFinancialAccountId);
    observable
      .pipe(
        concatMap((financialAccount) => {
          if (financialAccount.accountHolderId && financialAccount.accountHolderType === 'RECIPIENT') {
            return this.recipientService.getRecipientById(financialAccount.accountHolderId).pipe(
              map((recipient) => {
                financialAccount.displayName = compact([recipient.firstName, recipient.lastName]).join(' ');
                return financialAccount;
              }),
              catchError(() => of(financialAccount)),
            );
          } else if (financialAccount.accountHolderId && financialAccount.accountHolderType === 'CUSTOMER') {
            return this.customerService.getCustomerById(financialAccount.accountHolderId).pipe(
              map((customer) => {
                financialAccount.displayName = compact([customer.firstName, customer.lastName]).join(' ');
                return financialAccount;
              }),
              catchError(() => of(financialAccount)),
            );
          } else {
            return of(financialAccount);
          }
        }),
        takeUntil(this.destroy$),
        finalize(() => (this.isFinancialAccountLoading = false)),
      )
      .subscribe({
        next: (financialAccount) => {
          const { category, embeddedAccountBalance, currency } = financialAccount;
          keys(embeddedAccountBalance).forEach((key) => {
            if (!this.currencyList.includes(key)) {
              this.financialAccountCurrencyList.push({ label: key, value: key });
              this.currencyList = [...this.currencyList, key];
            }
          });

          this.accountBalanceList = embeddedAccountBalance ?? {};
          this.selectedCurrency = currency ?? 'USD';
          if (embeddedAccountBalance) {
            this.accountBalanceValues = embeddedAccountBalance[this.selectedCurrency];
          }
          this.financialAccount = financialAccount;

          if (this.entityType === 'business-account' && !this.isEscrowAccount) {
            this.tabItems = compact([
              { label: 'Account Information', href: 'account-details' },
              ['INTERNAL', 'INTEGRATED'].includes(category) ? { label: 'Transaction Activity', href: 'account-activity' } : undefined,
              category === 'EXTERNAL' ? { label: 'Transaction Activity', href: 'external-account-activity' } : undefined,
              category === 'INTERNAL' ? { label: 'Child Accounts', href: 'child-accounts' } : undefined,
              category === 'INTEGRATED' ? { label: 'Statements', href: 'account-statements' } : undefined,
            ]);
          } else if (this.entityType === 'business-account' && this.isEscrowAccount) {
            this.tabItems = [{ label: 'Account Information', href: 'escrow-account-details' }];
          } else if (['INTERNAL', 'INTEGRATED'].includes(category)) {
            this.tabItems = compact([
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'account-activity' },
              category === 'INTEGRATED' ? { label: 'Statements', href: 'account-statements' } : undefined,
            ]);
          } else if (category === 'EXTERNAL') {
            this.tabItems = compact([
              { label: 'Account Information', href: 'account-details' },
              { label: 'Transaction Activity', href: 'external-account-activity' },
            ]);
          }
        },
        error: (error) => {
          ErrorUtils.catchError('financialAccountService.getFinancialAccountDetailsWithBalances error', error);
          this.handleAccountFetchError();
        },
      });
  }

  public mapEntityAccountDetails(): void {
    switch (this.entityType) {
      case 'individuals':
        this.entityBreadcrumb = 'Individuals';
        this.entitySubBreadcrumb = 'Individual details';
        this.entityBreadcrumbURL = '/app/customers/individuals';
        this.entitySubBreadcrumbURL = `/app/customers/individuals/${this.entityId}`;
        this.pageReturnURL = `/app/customers/individuals/${this.entityId}/financial-accounts`;
        break;

      case 'businesses':
        this.entityBreadcrumb = 'Businesses';
        this.entitySubBreadcrumb = 'Business details';
        this.entityBreadcrumbURL = '/app/customers/businesses';
        this.entitySubBreadcrumbURL = `/app/customers/businesses/${this.entityId}`;
        this.pageReturnURL = `/app/customers/businesses/${this.entityId}/financial-accounts`;
        break;

      case 'recipients':
        if (this.customerId) {
          const customerUrl = `/app/customers/${this.customerType === 'individual' ? 'individuals' : 'businesses'}`;
          this.parentEntityBreadcrumb = this.customerType === 'individual' ? 'Individuals' : 'Businesses';
          this.parentEntitySubBreadcrumb = `${capitalize(this.customerType)} details`;
          this.parentEntityBreadcrumbURL = customerUrl;
          this.parentEntitySubBreadcrumbURL = `${customerUrl}/${this.customerId}`;
          this.entityBreadcrumb = 'Recipients';
          this.entitySubBreadcrumb = 'Recipient details';
          this.entityBreadcrumbURL = `${customerUrl}/${this.customerId}/recipients`;
          this.entitySubBreadcrumbURL = `${customerUrl}/${this.customerId}/recipients/${this.entityId}`;
          this.pageReturnURL = `${customerUrl}/${this.customerId}/recipients/${this.entityId}/financial-accounts`;
        } else {
          this.entityBreadcrumb = 'Recipients';
          this.entitySubBreadcrumb = 'Recipient details';
          this.entityBreadcrumbURL = '/app/recipients';
          this.entitySubBreadcrumbURL = `/app/recipients/${this.entityId}`;
          this.pageReturnURL = `/app/recipients/${this.entityId}/financial-accounts`;
        }
        break;

      case 'business-account':
      default:
        this.pageReturnURL = '/app/settings/financial-accounts';
        break;
    }
  }

  private handleAccountFetchError(): void {
    this.router.navigate([this.pageReturnURL]);
  }

  setAccountCurrency(currency: string) {
    this.accountBalanceValues = this.accountBalanceList[currency];
  }
}
