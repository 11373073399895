<div class="rows">
  <div class="row">
    <div class="label">Narrative<span class="asterisk">*</span></div>
    <div class="value">
      <form [formGroup]="narrativeFrom">
        <rkt-form
          [form]="narrativeFrom"
          [fields]="narrativeFromFields"
          [model]="wireDetailsModel"
          (modelChange)="onWireDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="label">Memo</div>
    <div class="value">
      <form [formGroup]="memoForm">
        <rkt-form [form]="memoForm" [fields]="memoFormFields" [model]="wireDetailsModel" (modelChange)="onWireDetailsChange()"></rkt-form>
      </form>
    </div>
  </div>
</div>
