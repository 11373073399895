import { formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

interface FinancialAccountTypeFieldsParams {
  isCardOnly?: boolean;
  isBankAccountOnly?: boolean;
}

export const financialAccountTypeFields = ({ isCardOnly, isBankAccountOnly }: FinancialAccountTypeFieldsParams) => {
  const accountTypeOptions = [
    {
      value: 'bankAccount',
      label: 'Bank Account',
      disabled: isCardOnly,
    },
    {
      value: 'card',
      label: 'Card Account',
      disabled: isBankAccountOnly,
    },
  ];

  return [
    formRow([
      formRadioGroup({
        key: 'cardOrBankAccount',
        label: 'Account Type',
        props: {
          options: accountTypeOptions,
        },
        expressions: {
          'props.disabled': '!!model.isEdit',
        },
      }),
    ]),
  ];
};
