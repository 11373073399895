import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { FinancialAccountService, NotificationService } from '@shared/services';
import { FinancialAccountList, FinancialAccountListItem, RequestPageParams } from '@shared/models';

@Component({
  selector: 'app-financial-accounts-transaction-limits',
  templateUrl: './financial-accounts-transaction-limits.component.html',
})
export class FinancialAccountsTransactionLimitsComponent implements OnDestroy {
  public financialAccountTransactionLimits!: FinancialAccountList['items'];

  public columns: DatatableColumn[] = [
    { name: 'Account Name', prop: 'name', flexSize: 300 },
    { name: 'Account Number', prop: 'displayName', flexSize: 350 },
    { name: 'Account Category', prop: 'category', flexSize: 250 },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 250, colType: 'amount', colAlign: 'right' },
    { name: 'Single', prop: 'single', flexSize: 250, colType: 'amount', colAlign: 'right', className: 'limit-column' },
    { name: '1 Day', prop: 'daily', flexSize: 250, colType: 'amount', colAlign: 'right', className: 'limit-column' },
    { name: '7 Days', prop: 'weekly', flexSize: 250, colType: 'amount', colAlign: 'right', className: 'limit-column' },
    { name: '30 Days', prop: 'monthly', flexSize: 250, colType: 'amount', colAlign: 'right', className: 'limit-column' },
  ];

  public totalElements = 0;

  public loading = true;

  public activePage = 0;

  public page = 0;

  emptyListMessage = 'No Transaction Limits found.';

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFinancialAccountTransactionLimits({ page, size }: RequestPageParams): void {
    this.loading = true;

    if (page && this.activePage !== page) {
      this.page = page;
    }

    this.financialAccountService
      .getFinancialAccountsWithLimitsAndBalance({ page, size, state: ['ACTIVE', 'SUSPENDED', 'PENDING'] })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Transaction Limits found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Accounts.';
          this.onFetchComplete();
          console.error('getFinancialAccounts error', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [], totalElements = 0 } = response || {};
    this.financialAccountTransactionLimits = items;
    this.totalElements = totalElements;
    this.loading = false;
  }

  viewFinancialAccountDetails({ id, category }: FinancialAccountListItem): void {
    this.router.navigate(['app', 'settings', 'financial-accounts', id, category === 'EXTERNAL' ? 'account-details' : 'account-activity']);
  }
}
