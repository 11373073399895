<h1 class="title">Review</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="sub-title">Financial Account Details</div>

<ng-container *ngIf="reviewData?.category === 'INTEGRATED'">
  <div class="account-type">Integrated - Bank Account</div>

  <div class="account-info">
    <div class="financial-account-fields-type">Financial Account info</div>

    <div class="details-row">
      <app-details-item
        [showPlaceholder]="false"
        [class]="pageClassPrefix + 'name'"
        label="Financial Account Name"
        [value]="reviewData?.name"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="false"
        [class]="pageClassPrefix + 'account-holder-name'"
        label="Account Holder Name"
        [value]="reviewData?.accountHolderName"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="false"
        [class]="pageClassPrefix + 'subtype'"
        label="Account Subtype"
        [value]="reviewData?.subtype"
      ></app-details-item>
    </div>
  </div>
</ng-container>

<div class="review-buttons">
  <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close({ action: 'EDIT' })">Back to Edit</button>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Discard</button>
    <button rktButton type="submit" (click)="activeModal.close({ action: 'SAVE' })" title="Create">Create</button>
  </div>
</div>
