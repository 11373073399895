import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ErrorMessage,
  FilterValues,
  FinancialAccountSelectionListItem,
  MultiLegTransactionDetails,
  MultiLegTransactionListItem,
  ScheduledTransactionDetails,
  ScheduledTransactionListItem,
  TransactionDetails,
  TransactionHistory,
  TransactionListItem,
  TransactionListRequest,
} from '@shared/models';

export namespace TransactionsActions {
  export const loadCardTransactions = createAction(
    '[Transactions API] Load Card Transactions',
    props<{ params: TransactionListRequest }>(),
  );
  export const loadCardTransactionsSuccess = createAction(
    '[Transactions API] Load Card Transactions Success',
    props<{ transactionListItems: TransactionListItem[]; totalElements: number }>(),
  );
  export const loadCardTransactionsFailure = createAction(
    '[Transactions API] Load Card Transactions Failure',
    props<{ error: ErrorMessage }>(),
  );
  export const setCardTransactionsActiveFilters = createAction(
    '[Transactions] Set Card Transactions Filters',
    props<{ filters: FilterValues }>(),
  );
}

export const ScheduledTransactionActions = createActionGroup({
  source: 'Scheduled Transactions API',
  events: {
    Load: props<{ params: TransactionListRequest }>(),
    'Load Success': props<{ transactionListItems: ScheduledTransactionListItem[]; totalElements: number }>(),
    'Load Failure': props<{ error: ErrorMessage }>(),
    'Set Filters': props<{ filters: FilterValues }>(),
    'Load Details': emptyProps,
    'Load Details Success': props<{ scheduledTransactionDetails: ScheduledTransactionDetails }>(),
    'Load Details Failure': props<{ error: ErrorMessage }>(),
    'Load Transaction History Success': props<{ transactionHistory: TransactionHistory[] }>(),
    'Load Transaction History Failure': props<{ error: ErrorMessage }>(),
  },
});

export const MultiLegTransactionActions = createActionGroup({
  source: 'Multi-Leg Transactions API',
  events: {
    Load: props<{ params: TransactionListRequest }>(),
    'Load Success': props<{ transactionListItems: MultiLegTransactionListItem[]; totalElements: number }>(),
    'Load Failure': props<{ error: ErrorMessage }>(),
    'Set Filters': props<{ filters: FilterValues }>(),
    'Load Details': emptyProps,
    'Load Details Success': props<{ multiLegTransactionDetails: MultiLegTransactionDetails }>(),
    'Load Details Failure': props<{ error: ErrorMessage }>(),
    'Set Default Business Account Financial Account': props<{ financialAccount: FinancialAccountSelectionListItem | null }>(),
  },
});

export const SingleLegTransactionActions = createActionGroup({
  source: 'Single-Leg Transactions API',
  events: {
    'Load Details': emptyProps,
    'Load Details Success': props<{ singleLegTransactionDetails: TransactionDetails }>(),
    'Load Details Failure': props<{ error: ErrorMessage }>(),
    'Load Transaction History Success': props<{ transactionHistory: TransactionHistory[] }>(),
    'Load Transaction History Failure': props<{ error: ErrorMessage }>(),
  },
});
