<h1 class="title">Resend Activation Email</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<form [formGroup]="activationForm" class="modal-form">
  <rkt-form [form]="activationForm" [fields]="activationFields" [model]="activationModel"></rkt-form>
</form>

<div *ngIf="activationError" class="details-page-edit-form-error">{{ activationError }}</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="button" (click)="onSubmit()" [disabled]="formLoading || this.activationForm.invalid">Submit</button>
</div>
