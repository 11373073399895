import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { FinancialAccountService, NotificationService } from '@shared/services';
import { AccountBalance } from '@shared/models';
import { Store } from '@ngrx/store';
import { fromAggregatedAccountBalance } from '@shared/store';

export const CountryCodes: { [key: string]: string } = {
  USA: 'USD',
  AZ: 'AZN',
};

@Component({
  selector: 'app-business-account-balance',
  templateUrl: './business-account-balance.component.html',
})
export class BusinessAccountBalanceComponent implements OnInit, OnDestroy {
  @Input() isFullWidth = false;

  @Input() noBottomOffset = false;

  isLoading = false;

  accountBalanceValues!: AccountBalance | undefined;

  selectedCurrency!: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
    private store: Store,
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.select(fromAggregatedAccountBalance.selectAggregatedBalanceForSelectedCurrency),
      this.store.select(fromAggregatedAccountBalance.selectActiveAggregatedAccountBalanceCurrency),
    ])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([aggregateBalances, selectedActiveCurrency]) => {
          this.accountBalanceValues = aggregateBalances;
          this.selectedCurrency = selectedActiveCurrency;
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
