<ng-container *ngIf="selectedMoveHowType !== 'wire'">
  <div class="list-filters">
    <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
      <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
      Filter
      <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
    </button>
  </div>
  <app-filter-menu
    #filterMenu
    [initialValues]="activeFilters"
    [filters]="filters"
    (opened)="filterBtn.classList.add('opened')"
    (closed)="filterBtn.classList.remove('opened')"
    (saved)="applyFilters($event)"
  ></app-filter-menu>
</ng-container>

<div class="table-container">
  <rkt-table
    [rows]="businessFinancialAccounts"
    [columns]="columns"
    [limit]="10"
    (fetchData)="getBusinessFinancialAccounts($event)"
    [count]="totalFinancialAccountsElements"
    [loadingIndicator]="financialAccountsLoading"
    [stickyHeader]="stickyHeader"
    [offset]="activeFinancialAccountsPage"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    [selected]="selectedFinancialAccounts"
    [selectionType]="SelectionType.single"
    (rowSelected)="onBusinessFinancialAccountsSelect($event)"
    [footerHeight]="56"
  ></rkt-table>
</div>
