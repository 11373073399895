import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard, NoAuthGuard } from '@shared/guards';
import { routeNames } from '@shared/constants';
import { LoadingBarComponent } from '@shared/components';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: routeNames.ACCOUNT_AUTH,
    children: [
      {
        path: 'team-member',
        loadChildren: () => import('./modules/landing-employee-auth/landing-employee-auth.module').then((m) => m.LandingEmployeeAuthModule),
      },
    ],
    canActivate: [NoAuthGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':/app',
    component: LoadingBarComponent,
  },
  {
    path: '404',
    loadChildren: () => import('./modules/error-page-not-found/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '',
    redirectTo: `app/${routeNames.DASHBOARD}`,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
