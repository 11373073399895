import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NoteCreateProps, Note } from '@shared/models';
import { NoteService, NotificationService } from '@shared/services';
import { NoteItemComponent, NotesModalComponent } from '@shared/components';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-details-notes',
  templateUrl: './details-notes.component.html',
  styleUrls: ['./details-notes.component.scss'],
})
export class DetailsNotesComponent implements OnInit, OnDestroy {
  @ViewChild('latestNoteItem') noteItem!: NoteItemComponent;

  @Input() entityType!: NoteCreateProps['entityType'];

  @Input() entityId!: NoteCreateProps['entityId'];

  @Input() isCompact = false;

  @Output() hasNotesStateChanged = new EventEmitter<boolean>();

  latestNote?: Note;

  loading = true;

  get isShowPlaceholder() {
    return this.loading && !this.latestNote;
  }

  private destroy$ = new Subject<void>();

  constructor(private noteService: NoteService, private modalService: ModalService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.getLatestNote();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLatestNote(): void {
    this.loading = true;
    this.noteService
      .getNotes({ entityType: this.entityType, entityId: this.entityId, page: 0, size: 1 })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (notes: Note[]) => {
          this.latestNote = notes[0];
          this.hasNotesStateChanged.emit(!!notes.length);
        },
        error: (error) => {
          ErrorUtils.catchError('noteService.getNotes', error);
        },
      });
  }

  onViewAllBtnClick(): void {
    this.noteItem?.onNoteEditCancel();
    const notesModalRef = this.modalService.open(NotesModalComponent, { size: 'half-size', isDraggable: false, className: 'notes-list' });

    notesModalRef.componentInstance.entityType = this.entityType;
    notesModalRef.componentInstance.entityId = this.entityId;

    notesModalRef.result.then(
      () => this.getLatestNote(),
      () => this.getLatestNote(),
    );
  }
}
