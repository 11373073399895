import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { addYears, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Store } from '@ngrx/store';
import { compact, sortBy } from 'lodash-es';
import { v5 as uuidv5 } from 'uuid';
import {
  BusinessAccount,
  MoveMoneyRequest,
  TransactionListRequest,
  ScheduledTransactionListRaw,
  ScheduledTransactionList,
  ScheduledTransactionDetailsRaw,
  ScheduledTransactionDetails,
  RecipientRaw,
  CustomerDetailRaw,
  MultiLegPaymentReason,
  MultiLegPaymentReasonRaw,
  CreateTransactionSltData,
  TransactionHistoryRequest,
  TransactionHistoryList,
  TransactionHistoryListResponse,
  TeamMember,
  ScheduledTransactionV1DetailsRaw,
  ScheduledTransactionLegDetailsRaw,
  UpdateScheduledTransactionMltData,
  ScheduledTransactionDetailsResponse,
  ScheduledEventsListRaw,
  TransactionList,
  MultiLegTransactionList,
  MultiLegTransactionListRaw,
} from '@shared/models';
import { environment } from '@env';
import { BackendService } from '@shared/services/backend.service';
import { MultiLegTransactionsService, NotificationService, TeamMemberService } from '@shared/services';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { constants } from '@shared/constants';
import { ErrorUtils, systemTimeZone } from '@shared/utils';
import { AuditActions, fromAuth } from '@shared/store';
import { mapTransactionListData, transactionsRequestParams } from './transaction-mapping-utils';
import {
  mapFinancialAccountInfo,
  mapScheduledTransactionDetails,
  mapScheduledTransactionHistoryData,
  mapScheduledTransactionListData,
} from './scheduled-transactions-mapping-utils';
import { mapMultiLegTransactionListData } from './multi-leg-transactions-mapping-utils';
import { SKIP_ERROR_NOTIFICATION } from '@shared/interceptors';

@Injectable({
  providedIn: 'root',
})
export class ScheduledTransactionsService {
  private readonly transactionsBasePath = `${environment.transactionFlowEndpoint}/business-accounts`;

  private readonly transactionsBasePathV2 = `${environment.transactionV2Endpoint}`;

  private readonly apiService = inject(BackendService);

  private readonly teamMemberService = inject(TeamMemberService);

  private readonly notificationService = inject(NotificationService);

  private readonly store = inject(Store);

  private readonly multiLegTransactionsService = inject(MultiLegTransactionsService);

  teamMembersName: Record<string, TeamMember['name'] | undefined> = {};

  getScheduledTransactions(requestParams: TransactionListRequest): Observable<ScheduledTransactionList> {
    const params = transactionsRequestParams(requestParams);
    return this.apiService.get<ScheduledTransactionListRaw>(`${this.transactionsBasePathV2}/scheduled-transactions`, { params }).pipe(
      map((transactionsData) => mapScheduledTransactionListData(transactionsData)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getScheduledTransactionById(
    businessAccountId: BusinessAccount['id'],
    transactionId: ScheduledTransactionDetails['id'],
  ): Observable<ScheduledTransactionDetails> {
    return this.apiService
      .get<ScheduledTransactionDetailsResponse>(`${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}`, {
        observe: 'response',
      })
      .pipe(
        exhaustMap((transactionResponse) => {
          const { headers, body } = transactionResponse;
          return this.getAllPaymentReasons().pipe(
            map((paymentReasons) => ({
              ...body,
              paymentReasons,
              transactionETag: headers.get('ETag')?.replace(/"/g, ''),
            })),
          );
        }),
        switchMap((transactionResponse) => {
          if (transactionResponse.transactionType === 'MLT') {
            return of(transactionResponse);
          }

          return this.apiService
            .get<ScheduledTransactionV1DetailsRaw>(
              `${this.transactionsBasePath}/${businessAccountId}/scheduled-transactions/${transactionId}`,
            )
            .pipe(
              map((scheduledSltResponse) => ({
                ...transactionResponse,
                debits: [
                  {
                    solution: scheduledSltResponse.solution,
                    paymentReasonId: scheduledSltResponse.paymentReasonId,
                    settlementPriority: scheduledSltResponse.settlementPriority,
                  } as ScheduledTransactionLegDetailsRaw,
                ],
              })),
            );
        }),
        switchMap((transactionData) => {
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([transactionData.createdBy, transactionData.updatedBy]) }));

          const formattedZonedDate = formatInTimeZone(`${transactionData.startDateTime}Z`, systemTimeZone, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

          const estimatedDeliveryDate$ = this.multiLegTransactionsService.getDeliverySpeedOptions({
            debitFinancialAccountId: transactionData.debitFinancialAccounts[0].id!,
            creditFinancialAccountId: transactionData.creditFinancialAccounts[0].id!,
            dateTime: formattedZonedDate,
          });

          const nextTransactions$ = transactionData?.recurrenceRule
            ? this.getScheduledTransactionNextTransactionDates(businessAccountId, transactionId, transactionData.transactionType).pipe(
                catchError((error) => {
                  ErrorUtils.catchError('scheduledTransactionsService.getScheduledTransactionNextTransactionDates', error);
                  return of([]);
                }),
              )
            : of([]);
          const processedTransactions$ = transactionData?.recurrenceRule
            ? this.getScheduledTransactionProcessedTransactions({ page: 0, size: 1, scheduledTransactionId: transactionId }).pipe(
                map((transactions) => transactions.totalElements),
                catchError((error) => {
                  ErrorUtils.catchError('scheduledTransactionsService.getScheduledTransactionProcessedTransactions', error);
                  return of(undefined);
                }),
              )
            : of(undefined);

          return forkJoin([nextTransactions$, processedTransactions$, estimatedDeliveryDate$]).pipe(
            map(([nextTransactions, processedTransactions, deliverySpeedOptions]) => {
              return { ...transactionData, nextTransactions, processedTransactions, deliverySpeedOptions };
            }),
          );
        }),
        withLatestFrom(this.store.select(fromAuth.selectActiveBusinessAccountDetails)),
        switchMap(([transactionData, activeBusinessAccount]) => {
          if (
            transactionData.debitFinancialAccounts[0]?.accountHolderId &&
            transactionData.debitFinancialAccounts[0]?.accountHolderType === 'RECIPIENT'
          ) {
            return this.apiService
              .get<RecipientRaw>(
                `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${transactionData.debitFinancialAccounts[0].accountHolderId}`,
              )
              .pipe(
                map((recipient) => {
                  const transaction = { ...transactionData };
                  transaction.debitFinancialAccounts = [mapFinancialAccountInfo({ ...transaction.debitFinancialAccounts[0]!, recipient })];
                  return transaction;
                }),
                catchError(() => of({ ...transactionData })),
              );
          } else if (
            transactionData.debitFinancialAccounts[0]?.accountHolderId &&
            transactionData.debitFinancialAccounts[0]?.accountHolderType === 'CUSTOMER'
          ) {
            return this.apiService
              .get<CustomerDetailRaw>(
                `${environment.accountFlowService}/v2/customers/${transactionData.debitFinancialAccounts[0].accountHolderId}`,
                { context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true) },
              )
              .pipe(
                map((customer) => {
                  const transaction = { ...transactionData };
                  transaction.debitFinancialAccounts = [mapFinancialAccountInfo({ ...transaction.debitFinancialAccounts[0]!, customer })];
                  return transaction;
                }),
                catchError(() => of({ ...transactionData })),
              );
          } else if (
            !transactionData.debitFinancialAccounts[0]?.accountHolderId &&
            transactionData.debitFinancialAccounts[0]?.businessAccountId === activeBusinessAccount.id
          ) {
            return of({
              ...transactionData,
              debitFinancialAccounts: [
                mapFinancialAccountInfo({
                  ...transactionData.debitFinancialAccounts[0],
                  businessAccount: activeBusinessAccount,
                }),
              ],
            });
          } else {
            return of(transactionData);
          }
        }),
        withLatestFrom(this.store.select(fromAuth.selectActiveBusinessAccountDetails)),
        switchMap(([transactionData, activeBusinessAccount]) => {
          if (
            transactionData.creditFinancialAccounts[0]?.accountHolderId &&
            transactionData.creditFinancialAccounts[0]?.accountHolderType === 'RECIPIENT'
          ) {
            return this.apiService
              .get<RecipientRaw>(
                `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${transactionData.creditFinancialAccounts[0].accountHolderId}`,
              )
              .pipe(
                map((recipient) => {
                  const transaction = { ...transactionData };
                  transaction.creditFinancialAccounts[0] = mapFinancialAccountInfo({
                    ...transaction.creditFinancialAccounts[0]!,
                    recipient,
                  });
                  return transaction;
                }),
                catchError(() => of({ ...transactionData })),
              );
          } else if (
            transactionData.creditFinancialAccounts[0]?.accountHolderId &&
            transactionData.creditFinancialAccounts[0]?.accountHolderType === 'CUSTOMER'
          ) {
            return this.apiService
              .get<CustomerDetailRaw>(
                `${environment.accountFlowService}/v2/customers/${transactionData.creditFinancialAccounts[0].accountHolderId}`,
                { context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true) },
              )
              .pipe(
                map((customer) => {
                  const transaction = { ...transactionData };
                  transaction.creditFinancialAccounts[0] = mapFinancialAccountInfo({
                    ...transaction.creditFinancialAccounts[0]!,
                    customer,
                  });
                  return transaction;
                }),
                catchError(() => of({ ...transactionData })),
              );
          } else if (
            !transactionData.creditFinancialAccounts[0]?.accountHolderId &&
            transactionData.creditFinancialAccounts[0]?.businessAccountId === activeBusinessAccount.id
          ) {
            return of({
              ...transactionData,
              creditFinancialAccounts: [
                mapFinancialAccountInfo({
                  ...transactionData.creditFinancialAccounts[0],
                  businessAccount: activeBusinessAccount,
                }),
              ],
            });
          } else {
            return of(transactionData);
          }
        }),
        map((transactionResponse) => mapScheduledTransactionDetails(transactionResponse)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getScheduledTransactionNextTransactionDates(
    businessAccountId: BusinessAccount['id'],
    transactionId: ScheduledTransactionDetails['id'],
    transactionType: ScheduledTransactionDetails['transactionType'],
  ): Observable<string[]> {
    const dateTo = format(addYears(new Date(), 3), 'yyyy-MM-dd');
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('dateTo', dateTo);

    const url =
      transactionType === 'MLT'
        ? `${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}/future-scheduled-times`
        : `${this.transactionsBasePath}/${businessAccountId}/scheduled-transactions/${transactionId}/scheduled-times`;

    return this.apiService
      .get<string[]>(url, {
        params,
      })
      .pipe(
        map((response) => response?.slice(0, 3) ?? []),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getScheduledTransactionProcessedTransactions(
    requestParams: TransactionListRequest,
  ): Observable<TransactionList | MultiLegTransactionList> {
    const { page, size, sortParams, scheduledTransactionId } = requestParams;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('size', size ?? constants.TABLE_ROWS)
      .append('page', page ?? 0);

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.apiService
      .get<ScheduledEventsListRaw>(`${this.transactionsBasePathV2}/scheduled-transactions/${scheduledTransactionId}/scheduled-events`, {
        params,
      })
      .pipe(
        map((response) => {
          return response?.transactionQuickFaInfoPageDto
            ? mapTransactionListData(response?.transactionQuickFaInfoPageDto)
            : mapMultiLegTransactionListData(response?.multiLegTransactionOverviewPage as MultiLegTransactionListRaw);
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  createScheduledTransaction(
    businessAccountId: BusinessAccount['id'],
    moveMoneyData: MoveMoneyRequest,
    idempotencyKey?: string,
  ): Observable<ScheduledTransactionDetails> {
    let headers = new HttpHeaders();
    if (idempotencyKey) {
      headers = headers.set('Idempotency-Key', idempotencyKey);
    }

    return this.apiService
      .post<ScheduledTransactionDetailsRaw>(`${this.transactionsBasePath}/${businessAccountId}/scheduled-transactions`, moveMoneyData, {
        headers,
      })
      .pipe(
        map((transaction) => mapScheduledTransactionDetails(transaction)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  cancelScheduledTransaction(transactionId: ScheduledTransactionDetails['id']): Observable<boolean> {
    return this.apiService.update<void>(`${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}/cancel`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  suspendScheduledTransaction(transactionId: ScheduledTransactionDetails['id']): Observable<boolean> {
    return this.apiService.update<void>(`${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}/suspend`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  unsuspendScheduledTransaction(transactionId: ScheduledTransactionDetails['id']): Observable<boolean> {
    return this.apiService.update<void>(`${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}/unsuspend`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  editScheduledTransaction(
    businessAccountId: BusinessAccount['id'],
    transactionId: ScheduledTransactionDetails['id'],
    editData: CreateTransactionSltData,
  ): Observable<ScheduledTransactionDetails> {
    const transactionDataString = transactionId + JSON.stringify(editData);
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .update<ScheduledTransactionDetailsRaw>(
        `${this.transactionsBasePath}/${businessAccountId}/scheduled-transactions/${transactionId}`,
        editData,
        { headers },
      )
      .pipe(
        map((transaction) => mapScheduledTransactionDetails(transaction)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  editScheduledMltTransaction(
    transactionId: ScheduledTransactionDetails['id'],
    editData: UpdateScheduledTransactionMltData,
    transactionETag: string,
  ): Observable<ScheduledTransactionDetails> {
    const transactionDataString = transactionId + JSON.stringify(editData);
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey).set('If-Match', transactionETag);

    return this.apiService
      .update<ScheduledTransactionDetailsRaw>(`${this.transactionsBasePathV2}/scheduled-transactions/${transactionId}`, editData, {
        headers,
      })
      .pipe(
        map((transaction) => mapScheduledTransactionDetails(transaction)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getAllPaymentReasons(): Observable<MultiLegPaymentReason[]> {
    return this.apiService.get<MultiLegPaymentReasonRaw[]>(`${environment.transactionFlowEndpoint}/payment-reasons`).pipe(
      map((response = []) => {
        return sortBy(response, 'reason')
          .filter(({ state, types }) => state === 'ACTIVE' && types?.length)
          .map((reasons) => {
            const { id, reason, allowedSolutions, types } = reasons;
            return { label: reason, value: id, allowedSolutions, types: types! };
          });
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getTransactionHistory({
    businessAccountId,
    transactionId,
    page,
    size,
  }: TransactionHistoryRequest): Observable<TransactionHistoryList> {
    const pageSize = size ?? constants.TABLE_ROWS;

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('pageSize', `${pageSize}`);

    return this.apiService
      .get<TransactionHistoryListResponse>(
        `${this.transactionsBasePath}/${businessAccountId}/scheduled-transactions/${transactionId}/histories`,
        { params },
      )
      .pipe(
        tap({
          next: (response) => {
            const { content = [] } = response || {};
            content
              .filter((item) => !!item.createdBy && item.createdBy.id !== businessAccountId)
              .forEach((item) => {
                if (!this.teamMembersName[item.createdBy?.id!]) {
                  this.teamMembersName[item.createdBy?.id!] = undefined;
                }
              });
          },
        }),
        switchMap((transactionHistoryResponse) =>
          forkJoin(
            Object.keys(this.teamMembersName).map((teamMemberId) => {
              if (Object.keys(this.teamMembersName).includes(teamMemberId) && this.teamMembersName[teamMemberId]) {
                return of({});
              }
              return this.teamMemberService.getSubjectsQuickInfo([teamMemberId]).pipe(
                map(([teamMember]) => {
                  this.teamMembersName[teamMemberId] = teamMember?.name ?? undefined;
                }),
                catchError(() => of({})),
              );
            }),
          ).pipe(
            defaultIfEmpty({}),
            map(() => mapScheduledTransactionHistoryData(transactionHistoryResponse, this.teamMembersName)),
          ),
        ),
      );
  }
}
