<div class="table-header-container">
  <div class="list-filters">
    <ng-container *ngIf="selectedMoveDirection !== 'pull'">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
      <app-filter-menu
        #filterMenu
        [initialValues]="activeFilters"
        [filters]="filters"
        (opened)="filterBtn.classList.add('opened')"
        (closed)="filterBtn.classList.remove('opened')"
        (saved)="applyFilters($event)"
      ></app-filter-menu>
    </ng-container>
  </div>

  <button *ngIf="!isMoveFieldsDisabled" rktButton btnType="link" class="add-entity-btn" (click)="addAccountHolder()">
    <rkt-icon name="plus"></rkt-icon> Add Account
  </button>
</div>

<div class="table-container">
  <rkt-table
    [rows]="entitiesFinancialAccounts"
    [columns]="columns"
    [limit]="10"
    (fetchData)="getEntitiesFinancialAccounts($event)"
    [count]="totalEntityFinancialAccountElements"
    [loadingIndicator]="financialAccountsLoading"
    [stickyHeader]="stickyHeader"
    [offset]="activePage"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    [selected]="selectedEntityFinancialAccounts"
    [selectionType]="SelectionType.single"
    (rowSelected)="onEntityFinancialAccountSelect($event)"
    [footerHeight]="56"
  ></rkt-table>
</div>
