import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { startCase, camelCase } from 'lodash-es';
import { Subscription } from 'rxjs';

export const SetPageTitle = (router: Router, titleService: Title) => (): Subscription =>
  router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe({
    next: (path) => {
      const routePath = path.url.split('/');
      let route = routePath.pop()?.split('?')[0] || '';

      if (/\d/.test(route)) {
        route = routePath[routePath.length - 1];
      }

      if (route === 'settings') {
        route = 'My Business';
      }

      const title = ['landing'].includes(route) || !route.length ? 'RocketKOR' : `${startCase(camelCase(route))}`;

      titleService.setTitle(`Portal - ${title}`);
    },
  });
