<ng-container *ngIf="disputeDetails$ | async as dispute; else disputeDetailsLoading">
  <ng-container *ngTemplateOutlet="disputeDetails; context: { $implicit: dispute, loading: false }"></ng-container>
</ng-container>
<ng-template #disputeDetailsLoading>
  <ng-container *ngTemplateOutlet="disputeDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #disputeDetails [appTemplateContext]="disputeDetailsContext" let-dispute let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="disputeDetails"></app-card-pages-breadcrumbs>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Dispute Details</app-text-with-placeholder>
      </div>
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Dispute ID"
        [value]="dispute?.id || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'financial-account-id'"
        [showPlaceholder]="loading"
        label="Financial Account ID"
        [value]="dispute?.financialAccountId || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'provider-dispute-id'"
        [showPlaceholder]="loading"
        label="Provider Dispute ID"
        [value]=""
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dispute-type'"
        [showPlaceholder]="loading"
        label="Dispute Type"
        [value]="dispute?.disputeTypeLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dispute-transaction-id'"
        [showPlaceholder]="loading"
        label="Dispute Transaction ID"
        [noBreak]="true"
        [value]="dispute?.disputedTransactionId || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dispute-amount'"
        [showPlaceholder]="loading"
        label="Dispute Amount"
        [noBreak]="true"
        [value]="(dispute?.disputedTransactionAmount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$ '"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dispute-case-number'"
        [showPlaceholder]="loading"
        label="Dispute Case Number"
        [value]="dispute?.disputeCaseNumber"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'dispute-reason'"
        [showPlaceholder]="loading"
        label="Dispute Reason"
        [value]="dispute?.disputeReasonLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'provider-dispute-id'"
        [showPlaceholder]="loading"
        label="Dispute Status"
        [value]="dispute?.disputeStatusLabel"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Dispute Description</app-text-with-placeholder>
      </div>
    </div>

    <div class="dispute-description" *ngIf="!loading" [class.not-available]="!dispute?.disputeDescription">
      {{ dispute?.disputeDescription | notAvailable }}
    </div>

    <div class="details-separator"></div>

    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Dispute Questions</app-text-with-placeholder>
      </div>
    </div>

    <div class="dispute-description not-available" *ngIf="!dispute?.disputeDescription && !loading">
      {{ dispute?.disputeDescription | notAvailable }}
    </div>

    <div class="details-row two-cols-row" *ngIf="dispute?.disputeQuestions?.length && allQuestions">
      <app-details-item
        *ngFor="let disputeQuestion of dispute?.disputeQuestions"
        [showPlaceholder]="false"
        [label]="
          (disputeQuestion.questionCode && allQuestions ? allQuestions[disputeQuestion.questionCode] : disputeQuestion.questionCode) ||
          'Question code: ' + disputeQuestion.questionCode
        "
        [value]="disputeQuestion.answer"
      ></app-details-item>
    </div>

    <div class="details-row two-cols-row" *ngIf="dispute?.disputeQuestions?.length && !allQuestions">
      <app-details-item
        *ngFor="let disputeQuestion of dispute?.disputeQuestions"
        [showPlaceholder]="true"
        [label]="disputeQuestion.questionCode || ''"
        [value]="disputeQuestion.answer"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="create-update-section">
      <div class="details-row two-cols-row">
        <app-details-item
          [class]="pageClassPrefix + 'created-at'"
          [showPlaceholder]="loading"
          label="Created At"
          [value]="dispute?.logDateTime | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'created-by'"
          [showPlaceholder]="loading"
          label="Created By"
          [value]="dispute?.createdBy"
        ></app-details-item>
      </div>

      <div class="details-row two-cols-row">
        <app-details-item
          [class]="pageClassPrefix + 'updated-at'"
          [showPlaceholder]="loading"
          label="Updated At"
          [value]="dispute?.updatedAt | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'updated-by'"
          [showPlaceholder]="loading"
          label="Updated By"
          [value]="dispute?.updatedBy"
        ></app-details-item>
      </div>
    </div>
  </div>
</ng-template>
