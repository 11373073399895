<div class="transaction-amount-options-wrapper">
  <div class="transaction-amount-options-title">Move funds from account based on<span class="asterisk">*</span></div>

  <div class="transaction-amount-options">
    <div
      class="transaction-amount-option-item"
      *ngFor="let amountOption of amountOptionItems$ | async"
      [class.disabled]="(isDebitLegsDisabled$ | async) || amountOption.isDisabled"
    >
      <label [for]="amountOption.value" class="transaction-amount-option-radio-input-label">
        <span class="transaction-amount-option-radio-input" [class.checked]="amountOptionField.value === amountOption.value"></span>

        <input
          hidden
          [id]="amountOption.value"
          [value]="amountOption.value"
          [attr.disabled]="(isDebitLegsDisabled$ | async) || amountOption.isDisabled ? true : null"
          [formControl]="amountOptionField"
          (change)="onAmountOptionSelect(amountOption.value)"
          name="transaction-amount-option"
          type="radio"
          class="form-check-input"
        />

        <span class="transaction-amount-option-item-value" [title]="amountOption.label">
          {{ amountOption.label }}
        </span>

        <app-tooltip class="tooltip" *ngIf="amountOption.tooltip" [tooltip]="amountOption.tooltip"></app-tooltip>
      </label>
    </div>
  </div>
</div>
