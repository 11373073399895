import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getFormattedAmount } from '@shared/utils';
import { EnabledSolutionConfig, TransactionSettlementPriority, TransactionSolution } from '@shared/models';
import { AbstractMoveMoneyBase } from '@shared/components';

@Component({
  selector: 'app-move-amount-control',
  templateUrl: 'move-amount-control.component.html',
  styleUrls: ['move-amount-control.component.scss'],
})
export class MoveAmountControlComponent implements OnInit, OnChanges {
  @Input() transactionWindowType?: TransactionSettlementPriority;

  @Input() enabledSolutionConfigs?: EnabledSolutionConfig[] | null;

  @Input() achEntryType?: string;

  @Input() transactionType?: TransactionSolution;

  @Input() transactionCurrency?: string;

  @Input() isMoveFieldsDisabled = false;

  @Input() initialValue?: string | number;

  @Input() moveAmountError?: boolean;

  @Output() changed = new EventEmitter<string>();

  @Output() blurred = new EventEmitter<void>();

  @Output() errored = new EventEmitter<boolean>();

  get solutionMinLimit() {
    if (!this.enabledSolutionConfigs) return 0;
    return (
      this.enabledSolutionConfigs.find((solutionConfig) => solutionConfig.solutionName === this.transactionType)?.solutionConfig
        .transactionMinAmount ?? 0
    );
  }

  get amountCurrency(): string {
    return this.moveAmount.value && this.transactionCurrency ? this.transactionCurrency : '$';
  }

  moveAmount = new UntypedFormControl();

  isMinimumAmount = false;

  isMaximumAmount = false;

  get maxAmount(): number | null {
    if (!this.enabledSolutionConfigs) return null;
    return (
      this.enabledSolutionConfigs.find((solutionConfig) => solutionConfig.solutionName === this.transactionType)?.solutionConfig
        .transactionMaxAmount ?? null
    );
  }

  constructor(private moveMoneyService: AbstractMoveMoneyBase) {}

  ngOnInit(): void {
    if (this.initialValue) {
      const value = getFormattedAmount(parseFloat(`${this.initialValue}`)).formattedValue;
      this.setValue(value);
      this.changed.emit(value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.moveAmount.value &&
      changes.transactionWindowType?.currentValue &&
      changes.transactionWindowType?.previousValue !== changes.transactionWindowType?.currentValue
    ) {
      this.moveAmount.setValue(this.moveAmount.value);
    }
  }

  setInitialValues() {
    const moveAmount = this.moveMoneyService.moveAmount;

    if (moveAmount) {
      this.moveAmount.setValue(moveAmount);
    }
  }

  onBlur(): void {
    if (!this.moveAmount.value) {
      this.moveAmount.reset();
    } else {
      const value = parseFloat(this.moveAmount.value);
      this.moveAmount.setValue(getFormattedAmount(value).formattedValue);

      if (!this.isMaximumAmount && !this.isMinimumAmount) {
        this.blurred.emit();
      }
    }
  }

  onChange(value: string): void {
    if (this.moveAmount.dirty) {
      if (this.maxAmount) {
        this.isMaximumAmount = parseFloat(value) > this.maxAmount;
      }

      this.isMinimumAmount = !value || parseFloat(value) < this.solutionMinLimit;
    }

    this.changed.emit(value);
    this.errored.emit(this.isMaximumAmount || this.isMinimumAmount);
  }

  resetValue(): void {
    this.moveAmount.reset();
    this.errored.emit(false);
  }

  setValue(value?: string): void {
    this.moveAmount.setValue(value);
  }

  isDirty(): boolean {
    return this.moveAmount.dirty;
  }
}
