import { format, parse } from 'date-fns';

export const formatRecurrenceRule = (rule?: string): string => {
  if (!rule) {
    return '';
  }

  if (rule === 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR') {
    return 'Every weekday (Monday to Friday)';
  }

  const freqMap: Record<string, string> = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Annually',
  };

  const freqPluralMap: Record<string, string> = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year',
  };

  const dayMap: Record<string, string> = {
    SU: 'Sunday',
    MO: 'Monday',
    TU: 'Tuesday',
    WE: 'Wednesday',
    TH: 'Thursday',
    FR: 'Friday',
    SA: 'Saturday',
  };

  const monthMap: Record<string, string> = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };

  const getOrdinal = (number: number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const ruleChunks = rule.split(';').reduce((obj: Record<string, string>, chunk) => {
    const chunkParsed = chunk.split('=');
    return { ...obj, [chunkParsed[0]]: chunkParsed[1] };
  }, {});

  const freq = ruleChunks.FREQ;
  const interval = ruleChunks.INTERVAL;
  const count = ruleChunks.COUNT;
  const until = ruleChunks.UNTIL;
  const untilParsed = until ? format(parse(until.split('T')[0], 'yyyyMMdd', new Date()), 'MM/dd/yy') : undefined;
  const byMonth = ruleChunks.BYMONTH;
  const byMonthDay = ruleChunks.BYMONTHDAY;
  const byDay = ruleChunks.BYDAY;
  const isWeekly = freq === 'WEEKLY';
  const isMonthly = freq === 'MONTHLY';
  const isYearly = freq === 'YEARLY';
  const byPos = parseInt(ruleChunks.BYSETPOS, 10);
  const weekLabels = ['first', 'second', 'third', 'fourth'];
  const weekLabel = byPos === -1 ? 'last' : weekLabels[byPos - 1];

  const freqLabel = interval === '1' || !interval ? freqMap[freq] : `Every ${interval} ${freqPluralMap[freq]}s`;
  const weekDayLabel =
    isWeekly && byDay
      ? ` on ${byDay
          .split(',')
          .map((day) => dayMap[day])
          .join(', ')}`
      : '';
  const monthDayLabel = isMonthly && byMonthDay ? ` on the ${getOrdinal(parseInt(byMonthDay, 10))}` : '';
  const yearDayLabel = isYearly && byMonthDay ? ` on ${monthMap[byMonth]} ${byMonthDay}` : '';
  const monthWeekAndDayLabel = byDay && byPos ? ` on the ${weekLabel} ${dayMap[byDay]}` : '';
  const occurrencesCountLabel = count === '1' ? ', 1 occurrence' : `, ${count} occurrences`;
  const occurrencesLabel = count ? occurrencesCountLabel : '';
  const untilLabel = untilParsed ? `, until ${untilParsed}` : '';

  return `${freqLabel}${weekDayLabel}${monthDayLabel}${monthWeekAndDayLabel}${yearDayLabel}${occurrencesLabel}${untilLabel}`;
};
