import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { isEmpty, isUndefined } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { DatatableColumn, DatatableSorting, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { NotificationService, TransactionService } from '@shared/services';
import { TransactionListItem, TransactionList, FilterValues, FilterField, RequestPageParams } from '@shared/models';
import { activeFilters, ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-transaction-batch-activity-list',
  templateUrl: './transaction-batch-activity-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionBatchActivityListComponent implements OnInit, AfterViewChecked, OnDestroy {
  transactionBatchId?: string | null;

  loading = true;

  searchText?: string;

  transactions: TransactionListItem[] = [];

  transactionsListItems: TransactionListItem[] = [];

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'All Time', value: undefined },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'maskedDebitAccountNumber',
      displayName: 'From Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'maskedCreditAccountNumber',
      displayName: 'To Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'amount',
      displayName: 'Amount',
      type: 'AMOUNT',
    },
    {
      name: 'latestStatus',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Cleared', value: 'CLEARED' },
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'New', value: 'NEW' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Settled', value: 'SETTLED' },
        { label: 'Reversed', value: 'REVERSED' },
      ],
    },
  ];

  activeFilters: FilterValues = {};

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Transaction ID', prop: 'id', colType: 'short-id', flexSize: 160 },
    { name: 'Move From', prop: 'moveFrom', colType: 'two-line-col', flexSize: 315 },
    { name: 'Move To', prop: 'moveTo', colType: 'two-line-col', flexSize: 300 },
    { name: 'Solution', prop: 'solutionName', flexSize: 175 },
    { name: 'Type', prop: 'transactionType', flexSize: 120 },
    { name: 'Amount', prop: 'amount', flexSize: 151, isSortable: true, colType: 'amount', colAlign: 'right' },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'latestStatus',
      fixedSize: 140,
      isSortable: true,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  totalElements = 0;

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  page = 0;

  emptyListMessage = 'No Transaction Activity found.';

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParamMap?.get('page');
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  protected destroy$: Subject<void> = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.transactionBatchId = this.activatedRoute.snapshot?.parent?.paramMap.get('id');

    this.transactionService.batchTransactionActivityFilters.subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.transactionService.getFilterParams(true);
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  ngOnInit(): void {
    this.transactionService.setFilterParams(this.activeFilters, true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.ref.markForCheck();
  }

  onSearch(event?: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }

    this.searchText = event.toLowerCase();
    const filteredRows = this.transactionsListItems.filter(
      (item) => item.id?.toLowerCase().indexOf(this.searchText!) !== -1 || !this.searchText,
    );
    this.transactions = filteredRows;
    this.totalElements = filteredRows.length;
    this.updateQueryParams(0);
  }

  onSearchReset(): void {
    if (!this.searchText) {
      return;
    }

    this.searchText = undefined;
    this.transactions = this.transactionsListItems;
    this.totalElements = this.transactionsListItems.length;
    this.getTransactions({ page: this.activePage });
  }

  updateQueryParams(page?: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getTransactions({ page, size, sortParams }: FetchData): void {
    this.loading = true;

    if (!this.transactionBatchId) {
      return;
    }

    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.transactionService
      .getTransactions({
        page: page || 0,
        size,
        sortParams: sortParams || this.sortParams,
        batchId: this.transactionBatchId,
        activeFilters: { ...this.activeFilters },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Transaction Activity found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Transactions.';
          this.onFetchComplete();
          ErrorUtils.catchError('transactionService.getTransactions error', error);
        },
      });
  }

  onFetchComplete(response?: TransactionList) {
    this.transactions = response?.items || [];
    this.transactionsListItems = response?.items || [];
    this.totalElements = response?.totalElements || 0;
    this.loading = false;
    this.ref.markForCheck();
  }

  onRowClick({ id }: TransactionListItem) {
    this.router.navigateByUrl(`/app/transactions/transactions/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.transactionService.setFilterParams(this.activeFilters, true);
    this.getTransactions({ page: 0 });
  }
}
