export namespace Patterns {
  export const GENERIC_NAME = /^[\s\w!"#$%&'()*+,\-./:;<=>?@[\]`{|}~ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïñòóôõöùúûüýÿ]*$/u;

  export const MONTH_NUMBER = /^(?:0?[1-9]|1[0-2])$/u;

  export const YEAR_NUMBER = /^20\d{2}$/u;

  export const NUMBER_WITH_FRACTIONAL_PART = /^-?\d+\.\d+$/u;

  export const NUMBER_ONLY = /^-?\d+(?:\.\d{0,2})?$/u;

  export const INITIALS = /\b\w/gu;

  export const EMAIL = /^[\w%+\-.]+@[\d\-.A-Za-z]+\.[A-Za-z]{2,4}$/u;

  export const WORKSPACE = /^[\d\-a-z]{1,64}$/u;

  export const PLACE_OF_BIRTH = /^[\s\d\-a-z]{1,60}$/iu;

  export const SOCIAL_SECURITY_NUMBER = /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;

  export const WEBSITE = /^https?:\/\/(?:w{3}\.)?[\w#%+\-.:=@~]{1,256}\.[\d()A-Za-z]{1,6}\b[\w#%&()+\-./:=?@~]*$/;

  export const MASKED_NUMBER = /[^\d*]|\*(?=\*)/gu;

  export const PERCENT100 = /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?)$/u;

  export const POSITIVE_INTEGER = /^[1-9]\d*$/u;
}
