import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { keys } from 'lodash/index';
import { ErrorUtils } from '@shared/utils';
import { AccountBalance, FinancialAccountBalances } from '@shared/models';
import { NotificationService } from '@shared/services';
import { Store } from '@ngrx/store';
import { fromAuth, fromAggregatedAccountBalance } from '@shared/store';
import { AggregatedAccountBalanceActions } from '@shared/store/aggregated-account-balance-store/aggregated-account-balance.actions';
import { CountryCodes } from '@shared/components';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
})
export class CurrencySelectComponent implements OnInit {
  isLoading = true;

  selectedCurrency!: string;

  businessAccountCountryCode$ = this.store.select(fromAuth.selectBusinessAccountCountryCode).pipe(take(1));

  aggregateAccountBalance$ = this.store.select(fromAggregatedAccountBalance.selectAggregatedAccountBalanceDetails);

  businessAccountCurrencyList: { label: string; value: string }[] = [];

  currencyList: string[] = [];

  accountBalanceList!: FinancialAccountBalances | null;

  @Output() accountBalanceValues = new EventEmitter<AccountBalance>();

  private destroy$ = new Subject<void>();

  constructor(private notificationService: NotificationService, private store: Store) {}

  ngOnInit() {
    this.store.dispatch(AggregatedAccountBalanceActions.initialize());

    combineLatest([this.businessAccountCountryCode$, this.aggregateAccountBalance$])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([countryCode, accountBalances]) => {
          this.selectedCurrency = CountryCodes[countryCode] ?? 'USD';
          this.accountBalanceList = accountBalances;

          this.store.dispatch(AggregatedAccountBalanceActions.setCurrency({ currency: this.selectedCurrency }));
          if (this.accountBalanceList && this.accountBalanceList[this.selectedCurrency]) {
            this.store.dispatch(
              AggregatedAccountBalanceActions.setAggregateAccountBalance({
                aggregateAccountBalance: this.accountBalanceList[this.selectedCurrency],
              }),
            );
          }

          keys(accountBalances).forEach((key) => {
            if (!this.currencyList.includes(key)) {
              this.businessAccountCurrencyList.push({ label: key, value: key });
              this.currencyList = [...this.currencyList, key];
            }
          });
        },
        error: (error) => {
          ErrorUtils.catchError('financialAccountService.accountCurrency', error);
        },
      });
  }

  onCurrencySelect(currency: string) {
    this.selectedCurrency = currency;
    this.store.dispatch(AggregatedAccountBalanceActions.setCurrency({ currency }));
    if (this.accountBalanceList && this.accountBalanceList[this.selectedCurrency]) {
      this.store.dispatch(
        AggregatedAccountBalanceActions.setAggregateAccountBalance({
          aggregateAccountBalance: this.accountBalanceList[this.selectedCurrency],
        }),
      );
    }
  }
}
