import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { TeamMemberService } from '@shared/services';
import { AuditActions, fromAudit } from '@shared/store';

@Injectable()
export class AuditEffects {
  constructor(private actions$: Actions, private teamMemberService: TeamMemberService, private store: Store) {}

  loadTeamMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuditActions.loadTeamMembers),
      concatLatestFrom(() => this.store.select(fromAudit.selectTeamMembersInfo)),
      mergeMap(([payload, teamMemberInfo]) => {
        return this.teamMemberService.getEmployeesDetails(payload.ids, teamMemberInfo).pipe(
          map((response) => AuditActions.loadTeamMembersSuccess({ teamMembers: response })),
          catchError((error) => {
            return of(AuditActions.loadTeamMembersError({ error }));
          }),
        );
      }),
    );
  });
}
