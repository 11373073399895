import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { LegalService } from '@shared/services';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyComponent {
  constructor(public activeModal: ActiveModal, private legalService: LegalService) {}

  downloadPrivacyPolicy() {
    this.legalService.downloadPrivacyPolicy();
  }
}
