import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { VerificationStatus } from '@shared/models';

@Component({
  selector: 'app-account-verification-history',
  templateUrl: './account-verification-history.component.html',
  styleUrls: ['./account-verification-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountVerificationHistoryComponent {
  activeModal = inject(ActiveModal);

  historyItems?: VerificationStatus[];
}
