<h1 class="title">Documents</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="doc-items">
  <div class="doc-item" *ngFor="let tab of agreementTabs; index as i">
    <div class="doc-number" [class.active]="selectedTab === tab.value" *ngIf="!documentSigned[tab.value]">{{ i + 1 }}</div>
    <rkt-icon class="doc-signed" name="check" *ngIf="documentSigned[tab.value]"></rkt-icon>
    {{ tab.label | titlecase }}
  </div>
</div>

<div class="scroll-wrapper">
  <ng-container *ngIf="selectedTab && document">
    <ngx-extended-pdf-viewer
      [src]="document"
      [showBorders]="false"
      [showToolbar]="false"
      [textLayer]="false"
      backgroundColor="#F7F7F7"
      [useBrowserLocale]="true"
      [authorization]="true"
      (pageChange)="onPageChange($event)"
      (pagesLoaded)="onPagesLoaded($event)"
    ></ngx-extended-pdf-viewer>
  </ng-container>
</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="prevTab()" title="Back">Back</button>
  <button
    rktButton
    type="submit"
    (click)="nextTab()"
    [disabled]="!selectedTab || !isScrolledToTheEnd[selectedTab]"
    title="Agree and continue"
  >
    Agree and continue
  </button>
</div>
