<div class="create-transaction-scheduler">
  <div class="scheduler-toggle-button-wrapper">
    <button
      class="scheduler-toggle-button"
      [disabled]="schedulerFormModel.isEdit"
      [class.checked]="schedulerFormModel.isScheduleEnabled"
      (click)="onSchedulerEnabledToggle()"
    >
      <rkt-icon [name]="schedulerFormModel.isScheduleEnabled ? 'checkbox-on' : 'checkbox-off'"></rkt-icon>
      Schedule a time
    </button>
  </div>

  <div class="create-transaction-scheduler-form-container" [hidden]="!schedulerFormModel.isScheduleEnabled">
    <form class="create-transaction-scheduler-form" [formGroup]="schedulerNameForm">
      <rkt-form
        [form]="schedulerNameForm"
        [fields]="schedulerNameFormFields"
        [model]="schedulerFormModel"
        (modelChange)="onModelChange()"
      ></rkt-form>
    </form>

    <div class="create-transaction-scheduler-form">
      <form [formGroup]="schedulerForm">
        <rkt-form
          [form]="schedulerForm"
          [fields]="schedulerFormFields"
          [model]="schedulerFormModel"
          (modelChange)="onModelChange()"
        ></rkt-form>
      </form>

      <div *ngIf="startTimeError" class="start-time-error">{{ startTimeError }}</div>

      <div class="create-transaction-scheduler-sent-count" *ngIf="schedulerFormModel.alreadySentCount as alreadySentCount">
        Already sent {{ alreadySentCount }} {{ alreadySentCount > 1 ? 'transactions' : 'transaction' }}
      </div>
    </div>
  </div>
</div>
