<div class="scheduler-toggle-button-wrapper">
  <button
    class="scheduler-toggle-button"
    [disabled]="schedulerFormModel.isEdit"
    [class.checked]="schedulerFormModel.isScheduleEnabled"
    (click)="onSchedulerEnabledToggle()"
  >
    <rkt-icon [name]="schedulerFormModel.isScheduleEnabled ? 'checkbox-on' : 'checkbox-off'"></rkt-icon>
    Schedule a time
  </button>
</div>

<form class="move-money-scheduler-form" [formGroup]="schedulerForm" [hidden]="!schedulerFormModel.isScheduleEnabled">
  <rkt-form [form]="schedulerForm" [fields]="schedulerFormFields" [model]="schedulerFormModel" (modelChange)="onModelChange()"></rkt-form>
</form>

<div *ngIf="startTimeError" class="start-time-error">{{ startTimeError }}</div>

<div class="move-money-scheduler-sent-count" *ngIf="schedulerFormModel.alreadySentCount as alreadySentCount">
  Already sent {{ alreadySentCount }} {{ alreadySentCount > 1 ? 'transactions' : 'transaction' }}
</div>
