import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { uniqueId } from 'lodash-es';
import { concatLatestFrom } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import { TransactionFormActions, transactionFormFeature } from '@shared/store';
import { DeliverySpeedOption } from '@shared/models';

@Component({
  selector: 'app-create-transaction-delivery-speed',
  styleUrls: ['./create-transaction-delivery-speed.component.scss'],
  templateUrl: './create-transaction-delivery-speed.component.html',
})
export class CreateTransactionDeliverySpeedComponent implements OnInit, OnDestroy {
  store = inject(Store);

  deliverySpeed = new FormControl<string | null>(null);

  id = uniqueId();

  formAction$ = this.store.select(transactionFormFeature.selectFormAction);

  deliverySpeedOptions$ = this.store.select(transactionFormFeature.selectDeliverySpeedOptions);

  selectedDeliveryOption$ = this.store.select(transactionFormFeature.selectDeliveryOption);

  fromAccountMethod$ = this.store.select(transactionFormFeature.selectFromAccountMethod);

  toAccountMethod$ = this.store.select(transactionFormFeature.selectToAccountMethod);

  transactionType$ = this.store.select(transactionFormFeature.selectTransactionType);

  isMltDisbursementStage$ = this.store.select(transactionFormFeature.selectIsMltDisbursementStage);

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.store
      .select(transactionFormFeature.selectDeliverySpeedOptions)
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFormFeature.selectFromAccountMethod),
          this.store.select(transactionFormFeature.selectFromAccountProcessingPriority),
          this.store.select(transactionFormFeature.selectToAccountMethod),
          this.store.select(transactionFormFeature.selectToAccountProcessingPriority),
          this.store.select(transactionFormFeature.selectDeliveryOption),
        ]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([
          deliverySpeedOptions,
          fromAccountMethod,
          fromAccountProcessingPriority,
          toAccountMethod,
          toAccountProcessingPriority,
          currentDeliveryOption,
        ]) => {
          if (!deliverySpeedOptions.length) {
            return;
          }

          const selectedDeliverySpeed = deliverySpeedOptions.find(
            (item) =>
              item.fromAccountMethod === fromAccountMethod &&
              item.fromAccountProcessingPriority === fromAccountProcessingPriority &&
              item.toAccountMethod === toAccountMethod &&
              item.toAccountProcessingPriority === toAccountProcessingPriority,
          );

          if (
            (selectedDeliverySpeed && this.deliverySpeed.value !== selectedDeliverySpeed.value) ||
            (selectedDeliverySpeed && currentDeliveryOption && selectedDeliverySpeed.estDate !== currentDeliveryOption.estDate)
          ) {
            this.deliverySpeed.setValue(selectedDeliverySpeed.value);
            this.onDeliverySpeedChange(selectedDeliverySpeed);
          } else {
            const selectedOption = deliverySpeedOptions[0];
            this.deliverySpeed.setValue(selectedOption.value);
            this.onDeliverySpeedChange(selectedOption);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDeliverySpeedChange(option: DeliverySpeedOption) {
    this.store.dispatch(
      TransactionFormActions.setSelectedDeliveryOption({
        fromAccountMethod: option.fromAccountMethod,
        toAccountMethod: option.toAccountMethod,
        deliveryOption: option,
        fromAccountPaymentReason: option.fromAccountPaymentReason,
        fromAccountProcessingPriority: option.fromAccountProcessingPriority,
        toAccountProcessingPriority: option.toAccountProcessingPriority,
      }),
    );
  }
}
