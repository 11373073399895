import { HttpErrorResponse } from '@angular/common/http';

export class AppError {
  type = 'AppError';

  operation: string;

  message?: string;

  constructor(operation: string, message?: string) {
    this.operation = operation;
    this.message = message;
  }

  getMessage(): { [key: string]: string | undefined } {
    return {
      type: this.type,
      operation: this.operation,
      message: this.message,
    };
  }

  toString(): string {
    return `${this.operation} ${this.message}`;
  }
}

export type ErrorMessage = string | number | null | HttpErrorResponse | undefined;
