<div class="list-page-header">
  <div class="list-page-title">MLT Activity</div>

  <div class="list-page-controls">
    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount$ | async as activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
    </div>
  </div>
</div>

<div class="transaction-list multi-leg-transaction-list">
  <rkt-table
    #multiLegTransactionsTableRef
    [rows]="(multiLegTransactions$ | async) || []"
    [columns]="columns"
    (fetchData)="getMultiLegTransactions($event)"
    [count]="(totalElements$ | async)!"
    [loadingIndicator]="(isLoading$ | async)!"
    [offset]="activePage"
    [messages]="{ emptyMessage: 'No MLT Activity found.' }"
    emptyValuePlaceholder="--"
    dateTimeFormat="MM/dd/yy HH:mm"
    [sortParam]="sortParams"
    [rowHeight]="64"
    [isItemsAlignCenter]="true"
    [detailsTemplate]="detailsTemplate"
    (rowActivated)="onRowClick($event)"
  ></rkt-table>
</div>

<app-filter-menu
  #filterMenu
  [initialValues]="(activeFilters$ | async) || {}"
  [filters]="filters"
  (opened)="filterBtn.classList.add('opened')"
  (closed)="filterBtn.classList.remove('opened')"
  (saved)="applyFilters($event)"
></app-filter-menu>

<ng-template #detailsTemplate [appTemplateContext]="detailsRowTemplateContext" let-row="row">
  <div class="mlt-activity-details-row" *ngIf="row.totalAmount | amount: row.currency">
    <div class="col-head-row">
      <div class="col col-type">Type</div>
      <div class="col col-processed">Processed</div>
      <div class="col col-amount-and-details">
        <div class="col-head-row">
          <div class="col col-amount">Current / Total Amount</div>
          <div class="col col-details">Details</div>
        </div>
      </div>
    </div>

    <div class="col-body-row">
      <div class="col col-type">Funding</div>
      <div class="col col-processed">{{ row.debitLegsProcessed }} / {{ row.debitLegs.length }}</div>
      <div class="col col-amount-and-details">
        <div class="col-body-row" *ngFor="let debitLeg of row.debitLegs">
          <div class="col col-amount">{{ debitLeg.amount | amount: row.currency }} / {{ row.totalAmount | amount: row.currency }}</div>
          <div class="col col-details">
            <div class="col-details-inner">
              <div class="account">
                <div class="status-tag" [ngClass]="'status-tag-' + (debitLeg.latestStatus.status || 'pending').toLowerCase()">
                  {{ debitLeg.latestStatus.status || 'pending' | titlecase }}
                </div>
                {{ debitLeg.accountInfo || '' | rktSplit: '::':1 }}
              </div>
              <div class="amount">
                <span class="highlighted">{{ (debitLeg.isProcessed ? debitLeg.amount : 0) | amount: row.currency }}</span> /
                {{ row.totalAmount | amount: row.currency }}
              </div>
              <div class="solution">
                <rkt-icon *ngIf="debitLeg.solutionIcon" [name]="debitLeg.solutionIcon" class="solution-icon"></rkt-icon>
                {{ debitLeg.solutionName | notAvailable }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-body-row">
      <div class="col col-type">Disbursement</div>
      <div class="col col-processed">{{ row.creditLegsProcessed }} / {{ row.creditLegs.length }}</div>
      <div class="col col-amount-and-details">
        <div class="col-body-row" *ngFor="let creditLeg of row.creditLegs">
          <div class="col col-amount">
            {{
              (creditLeg.isProcessed && !['CANCELLED', 'FAILED'].includes(creditLeg.latestStatus.status) ? creditLeg.amount : 0)
                | amount: row.currency
            }}
            / {{ row.totalAmount | amount: row.currency }}
          </div>
          <div class="col col-details">
            <div class="col-details-inner">
              <div class="account">
                <div class="status-tag" [ngClass]="'status-tag-' + (creditLeg.latestStatus.status || 'pending').toLowerCase()">
                  {{ creditLeg.latestStatus.status || 'pending' | titlecase }}
                </div>
                {{ creditLeg.accountInfo || '' | rktSplit: '::':1 }}
              </div>
              <div class="amount">
                <span class="highlighted" [class.error]="creditLeg.latestStatus.status === 'CANCELLED'">{{
                  (creditLeg.isProcessed && !['CANCELLED', 'FAILED'].includes(creditLeg.latestStatus.status) ? creditLeg.amount : 0)
                    | amount: row.currency
                }}</span>
                /
                {{ row.totalAmount | amount: row.currency }}
              </div>
              <div class="solution">
                <rkt-icon *ngIf="creditLeg.solutionIcon" [name]="creditLeg.solutionIcon" class="solution-icon"></rkt-icon>
                {{ creditLeg.solutionName | notAvailable }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
