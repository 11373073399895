<ng-container *ngIf="cardAccount$ | async as cadAccount; else cadAccountLoading">
  <ng-container *ngTemplateOutlet="cardAccountPage; context: { $implicit: cadAccount, loading: false }"></ng-container>
</ng-container>
<ng-template #cadAccountLoading>
  <ng-container *ngTemplateOutlet="cardAccountPage; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #cardAccountPage [appTemplateContext]="cardAccountPageContext" let-cardAccount let-loading="loading">
  <div class="entity-details-container card-account-details-page">
    <div class="entity-details-header">
      <div class="entity-details-title">Card Financial Account Details</div>
    </div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'name'"
        [showPlaceholder]="loading"
        label="Financial Account Name"
        [value]="cardAccount?.name"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'id'"
        [showPlaceholder]="loading"
        label="Financial Account ID"
        [title]="cardAccount?.id | uppercase"
        [value]="cardAccount?.id || '' | rktShortId"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'category'"
        [showPlaceholder]="loading"
        label="Account Category"
        [value]="cardAccount?.category"
      ></app-details-item>
    </div>

    <div class="financial-account-fields">
      <button
        rktButton
        btnType="link"
        (click)="onCardReissueClick(cardAccount.id)"
        title="Reissue Card"
        [disabled]="loading"
        class="reissue-btn"
      >
        <rkt-icon name="plus"></rkt-icon>
        Reissue Card
      </button>

      <div class="financial-account-fields-type">
        <div class="icon">
          <div class="loading-placeholder dark-bg" *ngIf="loading"></div>
          <rkt-icon *ngIf="!loading" name="card"></rkt-icon>
        </div>
        <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">{{ cardAccount?.type }}</app-text-with-placeholder>
      </div>

      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'name-on-card'"
          [showPlaceholder]="loading"
          label="Name on Card"
          [isDarkBg]="true"
          [value]="cardAccount?.card?.fullNameOnCard"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'card-number'"
          [showPlaceholder]="loading"
          label="Card Number"
          [isDarkBg]="true"
          [value]="'**** ' + cardAccount?.card?.cardNumberTail"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'expiry'"
          [showPlaceholder]="loading"
          label="Card Exp. Date"
          [isDarkBg]="true"
          [value]="cardAccount?.card?.expiry"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'card-interface'"
          [showPlaceholder]="loading"
          label="Card Interface"
          [isDarkBg]="true"
          [value]="cardAccount?.cardProviderDetails?.cardInterface | titlecase"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + ' card-issue-type'"
          [showPlaceholder]="loading"
          label=" Card Issue Type"
          [isDarkBg]="true"
          [value]="cardAccount?.cardProviderDetails?.cardIssueType | titlecase"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'address'"
          [showPlaceholder]="loading"
          label="Address"
          [isDarkBg]="true"
          [value]="cardAccount?.card?.billingAddress?.addressLine1"
        >
          <ng-container *ngIf="cardAccount?.card?.billingAddress?.addressLine1">
            <div>{{ cardAccount?.card?.billingAddress?.addressLine2 }}</div>
            <div>{{ cardAccount?.card?.billingAddress?.city }}, {{ cardAccount?.card?.billingAddress?.state }}</div>
            <div>{{ cardAccount?.card?.billingAddress?.postalCode }} {{ cardAccount?.card?.billingAddress?.country }}</div>
          </ng-container>
        </app-details-item>
      </div>
    </div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'card-status'"
        [showPlaceholder]="loading"
        label="Card Status"
        [value]="cardAccount?.cardProviderDetails?.cardStatusLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'card-solution'"
        [showPlaceholder]="loading"
        label="Card Solution"
        [value]="cardAccount?.cardProviderDetails?.solution"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'card-starting-numbers'"
        [showPlaceholder]="loading"
        label="Card Starting Numbers"
        [value]="cardAccount?.cardProviderDetails?.startingNumbers"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'card-program'"
        [showPlaceholder]="loading"
        label="Card Program"
        [value]="cardAccount?.cardProviderDetails?.programName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'shipping-method'"
        [showPlaceholder]="loading"
        label="Shipping Method"
        [value]="cardAccount?.cardProviderDetails?.shippingMethodLabel"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'extra-embossing-line'"
        [showPlaceholder]="loading"
        label="Extra Embossing Line"
        [value]="cardAccount?.cardProviderDetails?.extraEmbossingLine"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'referral-source'"
        [showPlaceholder]="loading"
        label="Referral Source"
        [value]="cardAccount?.cardProviderDetails?.referralSource"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'issued-language-code'"
        [showPlaceholder]="loading"
        label="Issued Language Code"
        [value]="cardAccount?.cardProviderDetails?.issuedLanguageName"
      ></app-details-item>
    </div>

    <div class="details-separator"></div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'accepted-terms-date'"
        [showPlaceholder]="loading"
        label="Accepted Terms Date"
        [value]="cardAccount?.cardProviderDetails?.acceptedTermsDate | dateFormat: 'MM/dd/yy':'ISO'"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'Read Privacy Policy Date'"
        [showPlaceholder]="loading"
        label="Read Privacy Policy Date"
        [value]="cardAccount?.cardProviderDetails?.readPrivacyPolicyDate | dateFormat: 'MM/dd/yy':'ISO'"
      ></app-details-item>
    </div>

    <ng-container *ngIf="cardAccount?.cardProviderDetails?.reissueReason">
      <div class="details-separator"></div>

      <div class="details-row details-row-with-offset">
        <app-details-item
          [class]="pageClassPrefix + 'reissue-date'"
          [showPlaceholder]="loading"
          label="Reissued Date"
          [value]="cardAccount?.cardProviderDetails?.reissueDateTime | dateFormat"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'reissue-reason'"
          [showPlaceholder]="loading"
          label="Reissued Reason"
          [value]="cardAccount?.cardProviderDetails?.reissueReasonLabel"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'reissue-type'"
          [showPlaceholder]="loading"
          label="Reissued Type"
          [value]="cardAccount?.cardProviderDetails?.reissueTypeLabel"
        ></app-details-item>
      </div>
    </ng-container>

    <div class="details-separator"></div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'created-at'"
        [showPlaceholder]="loading"
        label="Created At"
        [value]="cardAccount?.createdAt | dateFormat"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'created-by'"
        [showPlaceholder]="loading"
        label="Created By"
        [value]="cardAccount?.createdBy?.id | teamMemberName | async"
      ></app-details-item>
    </div>

    <div class="details-row">
      <app-details-item
        [class]="pageClassPrefix + 'updated-at'"
        [showPlaceholder]="loading"
        label="Updated At"
        [value]="cardAccount?.updatedAt | dateFormat"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'updated-by'"
        [showPlaceholder]="loading"
        label="Updated By"
        [value]="cardAccount?.updatedBy?.id | teamMemberName | async"
      ></app-details-item>
    </div>

    <app-details-notes *ngIf="cardAccount?.id" entityType="FINANCIAL_ACCOUNT" [entityId]="cardAccount.id"></app-details-notes>
  </div>
</ng-template>
