import { Component } from '@angular/core';
import { compact } from 'lodash-es';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { Beneficiary } from '@shared/models';
import { toTitleCase } from '@shared/utils';

@Component({
  selector: 'app-beneficiary-review-modal',
  templateUrl: './beneficiary-review-modal.component.html',
  styleUrls: ['./beneficiary-review-modal.component.scss'],
})
export class BeneficiaryReviewModalComponent {
  pageClassPrefix = 'beneficiary-review-';

  beneficiary!: Beneficiary | null;

  get beneficiaryGender() {
    return this.beneficiary?.sex === 'NONBINARY' ? 'Non-Binary' : toTitleCase(this.beneficiary?.sex);
  }

  constructor(public activeModal: ActiveModal) {}

  modalInitData({ beneficiaryData }: { beneficiaryData: Beneficiary }) {
    this.beneficiary = {
      ...beneficiaryData,
      email: beneficiaryData?.primaryEmail?.value,
      phoneNumber: beneficiaryData?.primaryPhoneNumber?.number,
      placeOfBirthDisplayValue: compact([beneficiaryData?.placeOfBirth?.city, beneficiaryData?.placeOfBirth?.countryCode]).join(', '),
    };
  }
}
