import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { constants } from '@shared/constants';
import { BrowserTracing } from '@sentry/tracing';
import { getEnvironment } from '@shared/services';

interface SentryExceptionTag {
  requestURL: string;
  pageURL: string;
  errorMessage: string;
  errorStatusCode: number;
  timestamp: string;
}

export const initializeSentry = (release: string) => {
  const isLocalhost = location.hostname === 'localhost';

  Sentry.init({
    dsn: constants.SENTRY_DSN,
    environment: getEnvironment().name,
    release,
    attachStacktrace: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: !isLocalhost,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['https://*.rocketkor-nonprod.net', 'https://*.rocketkor.net'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
  });
};

/* Sentry providers configuration */
export const sentryProviders: () => Provider[] = () => {
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => ({}),
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
};

export const captureSentryException = (sentryException: SentryExceptionTag) => {
  Sentry.setTag('StatusCode', sentryException.errorStatusCode);
  Sentry.captureException(sentryException);
};
