import { FormlyFieldConfig } from '@ngx-formly/core';
import { RktFormFieldConfig, formRow, formInput, formRepeatSection } from '@rocketfinancialcorp/rocket-ui/form';
import { Patterns } from '@shared/components';

export const addressFormFields = (isPhysicalAddressRequired: boolean): RktFormFieldConfig[] => {
  return [
    ...getAddressFields({
      type: 'Physical',
      key: 'physicalAddresses',
      isRequired: isPhysicalAddressRequired,
    }),
    ...getAddressFields({
      type: 'Mailing',
      key: 'mailingAddresses',
      isRequired: false,
    }),
    ...getAddressFields({
      type: 'Shipping',
      key: 'shippingAddresses',
      isRequired: false,
    }),
  ];
};

export const getAddressFields = ({
  type,
  key,
  isRequired,
}: {
  type: 'Physical' | 'Mailing' | 'Shipping';
  key: string;
  isRequired?: boolean;
}): RktFormFieldConfig[] => {
  const expressions = {
    'props.required': (field: FormlyFieldConfig) => {
      if (isRequired) {
        return true;
      }

      return (
        !!field.model?.addressLine1 ||
        !!field.model?.addressLine2 ||
        !!field.model?.city ||
        !!field.model?.state ||
        !!field.model?.postalCode ||
        !!field.model?.country
      );
    },
  };

  return [
    formRepeatSection({
      key: `${key}`,
      unstyled: true,
      addText: `Add ${type} Address`,
      removeText: 'Remove',
      header: `${type} Address`,
      subheader: type === 'Physical' ? 'Must not be a PO box' : undefined,
      notAllowEmpty: true,
      fieldGroup: [
        formRow(
          [
            formInput({
              key: 'firstName',
              label: 'First Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid first name' } },
            }),
            formInput({
              key: 'middleName',
              label: 'Middle Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid middle name' } },
            }),
            formInput({
              key: 'lastName',
              label: 'Last Name',
              props: {
                pattern: Patterns.GENERIC_NAME,
                minLength: 1,
                maxLength: 40,
              },
              validation: { messages: { pattern: 'Enter a valid last name' } },
            }),
          ],
          { isHidden: type !== 'Shipping' },
        ),
        formRow([
          {
            type: '#addressLine1',
            props: {
              label: `Address 1`,
              countryRestrictions: ['US'],
              minLength: 1,
              maxLength: 50,
            },
            expressions,
          },
        ]),
        formRow([
          formInput({
            key: 'addressLine2',
            label: `Address 2`,
            props: { minLength: 1, maxLength: 50 },
          }),
        ]),
        formRow([
          formInput({ key: 'city', label: 'City', className: 'city-field', props: { minLength: 1, maxLength: 25 }, expressions }),
          formInput({
            key: 'state',
            label: 'State',
            className: 'state-field',
            props: { minLength: 2, maxLength: 3 },
            validators: { validation: [{ name: 'isValidStateCode' }] },
            expressions,
          }),
          formInput({
            key: 'postalCode',
            label: 'Zip Code',
            className: 'postal-code-field',
            props: { minLength: 1, maxLength: 10 },
            expressions,
          }),
        ]),
        formRow([
          formInput({
            key: 'country',
            label: 'Country',
            parsers: [(value?: string) => value?.toUpperCase()],
            props: { minLength: 3, maxLength: 3 },
            validators: { validation: [{ name: 'isValidCountryCode' }] },
            validation: {
              messages: {
                minLength: 'Must be 3 characters',
              },
            },
            expressions,
          }),
        ]),
      ],
    }),
  ];
};
