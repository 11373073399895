<div class="title">Status History</div>

<div class="history-items">
  <ng-container *ngFor="let item of historyItems; let idx = index">
    <div *ngIf="idx > 0" class="separator-line"></div>

    <div class="history-item">
      <div class="history-item-header">
        <div class="history-date">{{ item.updatedAt | dateFormat | notAvailable }}</div>
        <div [class]="'inline-status inline-status-' + item.status | lowercase">{{ item.status | titlecase }}</div>
      </div>
      <div class="history-reason">
        {{ item.reason }}
      </div>
      <div class="history-updated-by">Updated by {{ item.updatedBy?.id | teamMemberName | async | notAvailable }}</div>
    </div>
  </ng-container>
</div>

<div class="buttons">
  <button rktButton title="Close" (click)="activeModal.close(true)">Close</button>
</div>
