<app-attention-modal type="error" title="Attention" [messageTemplate]="message" [buttonsTemplate]="buttons"></app-attention-modal>

<ng-template #message>
  If you change your selection, the data you have captured on this page will be lost.<br />
  Click OK to change your selection, or Cancel to continue with current selection.
</ng-template>

<ng-template #buttons>
  <button rktButton btnType="tertiary" title="Cancel" (click)="activeModal.close()">Cancel</button>
  <button rktButton title="OK" (click)="activeModal.close(true)">OK</button>
</ng-template>
