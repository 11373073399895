import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { NotificationService } from '@shared/services';
import { Store } from '@ngrx/store';
import { fromAuth } from '@shared/store';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class WatcherInterceptor implements HttpInterceptor {
  private watcherSpecialTenant$ = this.store.select(fromAuth.selectSpecialTenant).pipe(take(1));

  private excludedUrlsRegex: RegExp[] = [];

  private excludedUrls: string[] = ['exchange-token', 'token', 'logout'];

  constructor(private store: Store, private notificationService: NotificationService) {
    this.excludedUrlsRegex = this.excludedUrls.map((urlPattern) => new RegExp(urlPattern, 'i'));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.watcherSpecialTenant$.pipe(
      switchMap((isSpecialTenant) => {
        const passThrough = !!this.excludedUrlsRegex.find((regex) => regex.exec(request.url));
        if (isSpecialTenant) {
          if (request.method !== 'GET' && !passThrough) {
            this.notificationService.displayError('Operation not allowed in Watcher mode');
            return EMPTY;
          }
        }
        return next.handle(request);
      }),
    );
  }
}
