import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FinancialAccountsActions, fromFinancialAccount } from '@shared/store';
import { NavigationService } from '@shared/services';

@Component({
  selector: 'app-account-holder-transaction-activity',
  templateUrl: 'account-holder-transaction-activity.component.html',
})
export class AccountHolderTransactionActivityComponent implements OnInit, OnDestroy {
  public participatingFinancialAccountIds$ = this.store.select(fromFinancialAccount.selectCustomerFinancialAccountIds);

  constructor(private navigationService: NavigationService, private store: Store) {
    this.navigationService.dispatchOnNavigationEnd(FinancialAccountsActions.loadFinancialAccountsIds());
  }

  ngOnInit() {
    this.store.dispatch(FinancialAccountsActions.initialize());
  }

  public ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
  }
}
