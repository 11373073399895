<div class="entity-actions">
  <button
    class="action-button action-button-delete"
    [disabled]="isControlsDisabled || isSuspensionControlsDisabled"
    (click)="btnClicked.emit('REMOVE')"
    title="Remove Recipient"
  >
    <rkt-icon name="trash"></rkt-icon>
  </button>

  <button
    *ngIf="teamMember?.userStatus !== 'SUSPENDED'"
    class="action-button action-button-suspend"
    [disabled]="isControlsDisabled || isSuspensionControlsDisabled"
    (click)="btnClicked.emit('SUSPEND')"
    title="Suspend Team Member"
  >
    <rkt-icon name="suspend"></rkt-icon>
  </button>
  <button
    *ngIf="teamMember?.userStatus === 'SUSPENDED'"
    class="action-button action-button-unsuspend"
    [disabled]="isControlsDisabled || isSuspensionControlsDisabled"
    (click)="btnClicked.emit('UNSUSPEND')"
    title="Unsuspend Team Member"
  >
    <rkt-icon name="resume"></rkt-icon>
  </button>

  <button
    class="action-button action-button-edit"
    [disabled]="isControlsDisabled"
    (click)="btnClicked.emit('EDIT')"
    title="Edit Team Member"
  >
    <rkt-icon name="edit"></rkt-icon>
  </button>
</div>
