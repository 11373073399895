import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { APP_ENV_CONFIG } from '@shared/services';

@Component({
  selector: 'app-portal-client',
  templateUrl: './portal-client-app.html',
})
export class PortalClientAppComponent implements OnInit {
  envConfig = inject(APP_ENV_CONFIG);

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.envConfig.name !== 'develop') {
      this.elementRef.nativeElement.removeAttribute('ng-version');
    }
  }
}
