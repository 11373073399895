import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { format, parse } from 'date-fns';
import { compact, flatten } from 'lodash-es';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { Address, AddressType, AddressesByType, Beneficiary, BeneficiaryRequestParams, FormModel } from '@shared/models';
import { BusinessAccountService, CustomerService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils, formAddressSerializer, formatSocialSecurityNumber, getAddressesOfType } from '@shared/utils';
import { phoneNumberFormat } from '../../entity/add-entity-with-financial-account-modal';
import { editFormFields, editOwningFormFields } from './beneficiary-form-fields';

interface BeneficiaryFormModalParams {
  customerId: string;
  beneficiaryType: 'customer' | 'business';
  beneficiaryData?: Beneficiary;
  actionType?: 'add' | 'edit';
  errorMessage?: string;
}

interface BeneficiaryForm {
  firstName: string;
  middleName: string;
  lastName: string;
  socialIdCountryCode: string;
  primaryEmail: string;
  primaryPhoneNumber: string;
  dateOfBirth: string;
  cityOfBirth: string;
  countryOfBirth: string;
  title: string;
  sex: string;
  controller: boolean;
  socialId: string;
  ownershipPercentage: string;
  physicalAddresses: Address[];
  mailingAddresses: Address[];
  shippingAddresses: Address[];
  maskedSocialId: string;
  citizenshipCountryCode: string;
}

@Component({
  selector: 'app-beneficiary-edit-form',
  templateUrl: './beneficiary-edit-form.component.html',
  styleUrls: ['./beneficiary-edit-form.component.scss'],
})
export class BeneficiaryEditFormComponent implements AfterViewChecked, OnDestroy {
  customerId!: string;

  beneficiaryId?: string;

  actionType?: 'add' | 'edit';

  beneficiaryType?: 'customer' | 'business';

  editFormModel: FormModel<BeneficiaryForm> = {};

  editForm = new FormGroup({});

  editOwningForm = new FormGroup({});

  editAddressForm = new FormGroup({});

  editFormFields: RktFormFieldConfig[] = editFormFields;

  editOwningFormFields: RktFormFieldConfig[] = editOwningFormFields;

  editAddressFormFields: RktFormFieldConfig[] = [];

  submitError = '';

  loading = false;

  get isAddressFormInvalid(): boolean {
    return (
      this.isPhysicalAddressInvalid || this.isMailingAddressInvalid || this.isShippingAddressInvalid || this.isCreateAddressFormInvalid
    );
  }

  public isPhysicalAddressInvalid: boolean = false;

  public isMailingAddressInvalid: boolean = false;

  public isShippingAddressInvalid: boolean = false;

  public isCreateAddressFormInvalid: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private customerService: CustomerService,
    private businessAccountService: BusinessAccountService,
  ) {}

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.markFieldsAsUnTouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData(data: BeneficiaryFormModalParams): void {
    const { customerId, beneficiaryData, errorMessage, actionType = 'add', beneficiaryType } = data;

    this.actionType = actionType;
    this.customerId = customerId;
    this.beneficiaryType = beneficiaryType;

    if (beneficiaryData) {
      this.setBeneficiaryData(beneficiaryData, errorMessage);
    }
  }

  setBeneficiaryData(beneficiaryData: Beneficiary, errorMessage?: string) {
    const {
      firstName,
      middleName,
      lastName,
      primaryEmail,
      primaryPhoneNumber,
      dateOfBirth,
      placeOfBirth,
      title,
      sex,
      controller,
      socialId,
      socialIdCountryCode,
      ownershipPercentage,
      addresses,
      citizenshipCountryCode,
    } = beneficiaryData;

    this.beneficiaryId = beneficiaryData.id;

    this.editFormModel = {
      ...this.editFormModel,
      firstName,
      middleName,
      lastName,
      primaryEmail: primaryEmail?.value,
      primaryPhoneNumber: primaryPhoneNumber?.number,
      dateOfBirth,
      cityOfBirth: placeOfBirth?.city,
      countryOfBirth: placeOfBirth?.countryCode,
      title,
      sex,
      controller,
      citizenshipCountryCode,
      socialId: socialId ? socialId.replace(/[*-]/gu, '') : '',
      socialIdCountryCode,
      maskedSocialId: this.actionType === 'edit' ? socialId : undefined,
      ownershipPercentage: `${ownershipPercentage ?? ''}`,
      physicalAddresses: getAddressesOfType(addresses ?? [], 'PHYSICAL'),
      mailingAddresses: getAddressesOfType(addresses ?? [], 'MAILING'),
      shippingAddresses: getAddressesOfType(addresses ?? [], 'SHIPPING'),
    };

    setTimeout(() => {
      this.markFieldsAsTouched();

      if (errorMessage) {
        this.submitError = errorMessage;
      }
    }, 0);
  }

  onSave() {
    const {
      firstName,
      middleName,
      lastName,
      primaryEmail,
      primaryPhoneNumber,
      dateOfBirth,
      cityOfBirth,
      countryOfBirth,
      title,
      sex,
      controller,
      socialId,
      socialIdCountryCode,
      ownershipPercentage,
      physicalAddresses,
      mailingAddresses,
      shippingAddresses,
      citizenshipCountryCode,
    } = this.editFormModel;

    const mappedAddresses = compact(
      flatten([physicalAddresses!, shippingAddresses!, mailingAddresses!]).map((addr) => formAddressSerializer(addr)),
    );

    const formattedDOB = dateOfBirth ? format(parse(dateOfBirth!, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd') : undefined;

    const beneficiaryData: BeneficiaryRequestParams = {
      firstName: firstName!,
      middleName: middleName ?? undefined,
      lastName: lastName!,
      primaryEmail: primaryEmail ? { value: primaryEmail! } : undefined,
      primaryPhoneNumber: primaryPhoneNumber ? { number: phoneNumberFormat(primaryPhoneNumber)! } : undefined,
      dateOfBirth: formattedDOB,
      placeOfBirth: cityOfBirth || countryOfBirth ? { city: cityOfBirth, countryCode: countryOfBirth } : undefined,
      title: title ?? undefined,
      sex,
      citizenshipCountryCode,
      controller: controller!,
      socialId: formatSocialSecurityNumber(socialId)!,
      socialIdCountryCode: socialIdCountryCode!,
      ownershipPercentage: ownershipPercentage ? parseFloat(ownershipPercentage) : undefined,
      addresses: mappedAddresses,
    };

    if (this.actionType === 'add') {
      this.activeModal.close({ action: 'REVIEW', beneficiaryData });
    } else if (this.actionType === 'edit') {
      this.updateBeneficiary({ ...beneficiaryData });
    }
  }

  markFieldsAsTouched(): void {
    this.editForm.markAllAsTouched();
    this.editOwningForm.markAllAsTouched();
    this.editAddressForm.markAllAsTouched();
  }

  markFieldsAsUnTouched(): void {
    this.editForm.markAsUntouched();
    this.editOwningForm.markAsUntouched();
    this.editAddressForm.markAsUntouched();
  }

  onFormUpdate(): void {
    if (this.submitError) {
      this.submitError = '';
    }
    this.ref.markForCheck();
  }

  updateBeneficiary(beneficiaryData: BeneficiaryRequestParams) {
    if (this.beneficiaryType === 'customer') {
      this.updateCustomerBeneficiary(beneficiaryData);
    } else {
      this.updateBusinessAccountBeneficiary(beneficiaryData);
    }
  }

  updateCustomerBeneficiary(beneficiaryData: BeneficiaryRequestParams) {
    this.loading = true;
    this.customerService
      .updateBeneficiary({
        customerId: this.customerId,
        beneficiaryId: this.beneficiaryId!,
        beneficiaryData,
      })
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (beneficiary) => {
          this.activeModal.close({ beneficiary });
        },
        error: (error) => {
          this.submitError = typeof error === 'string' ? error : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('customerService.updateBeneficiary error', error);
        },
      });
  }

  updateBusinessAccountBeneficiary(beneficiaryData: BeneficiaryRequestParams) {
    this.loading = true;
    this.businessAccountService
      .updateBeneficiary({
        beneficiaryId: this.beneficiaryId!,
        beneficiaryData,
      })
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (beneficiary) => {
          this.activeModal.close({ beneficiary });
        },
        error: (error) => {
          this.submitError = typeof error === 'string' ? error : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('businessAccountService.updateBeneficiary error', error);
        },
      });
  }

  setNewAddresses({ physicalAddresses, mailingAddresses, shippingAddresses }: AddressesByType): void {
    this.editFormModel = {
      ...this.editFormModel,
      physicalAddresses,
      mailingAddresses,
      shippingAddresses,
    };
  }

  setIsAddressFormInvalid(invalid: boolean, addressType?: AddressType) {
    switch (addressType) {
      case 'PHYSICAL':
        this.isPhysicalAddressInvalid = invalid;
        break;
      case 'MAILING':
        this.isMailingAddressInvalid = invalid;
        break;
      case 'SHIPPING':
        this.isShippingAddressInvalid = invalid;
        break;
      default:
        this.isCreateAddressFormInvalid = invalid;
    }
  }

  setEditedAddresses(addresses: Address[], addressType: AddressType): void {
    this.editFormModel = {
      ...this.editFormModel,
      [`${addressType.toLowerCase()}Addresses`]: addresses,
    };
  }
}
