import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { compact, each, reverse } from 'lodash-es';
import { HttpContext } from '@angular/common/http';
import { environment } from '@env';
import { BackendService } from '@shared/services/backend.service';
import { BusinessAccountService, StorageService } from '@shared/services';
import { constants } from '@shared/constants';
import {
  BusinessAccount,
  FilterValues,
  IdentityVerificationApplication,
  IdentityVerificationApplicationList,
  IdentityVerificationApplicationListRaw,
  IdentityVerificationApplicationRaw,
  IdentityVerificationProcessingSummary,
  IdentityVerificationProfile,
  IdentityVerificationProfileRaw,
  IDVError,
  RequestPageParams,
  SubmitIdentityVerificationApplicationParams,
} from '@shared/models';
import { CUSTOM_ERROR_MESSAGE, IS_EXTENDED_ERRORS } from '@shared/interceptors';
import { identityProcessingSummarySerializer, IdentityProfileIdMapper, idvRequestParams } from '../../utils/identity-verification-utils';
import { toTitleCase } from '@shared/utils';
import { WatchlistEvent, WatchlistEventList, WatchlistEventListRaw, WatchlistEventRaw } from '@shared/models/watchlist-events';
import { ErrorBlock } from '@shared/components';
import { AuditActions } from '@shared/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class IdentityVerificationService {
  idvListFilter = new BehaviorSubject<FilterValues>({});
  watchlistEventsListFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private backendService: BackendService,
    private businessAccountService: BusinessAccountService,
    private storageService: StorageService,
    private store: Store,
  ) {}

  public getCustomerIDVApplications(
    fetchParamData: string,
    requestPageParams: RequestPageParams,
  ): Observable<IdentityVerificationApplicationList> {
    const params = idvRequestParams(requestPageParams);

    return this.backendService
      .get<IdentityVerificationApplicationListRaw>(`${environment.identityService}/identity-verifications?${fetchParamData}`, { params })
      .pipe(
        map((response) => {
          const { content = [], totalElements = 0 } = response || {};
          return {
            items: content?.map((item) => IdentityVerificationService.mapIDVApplicationData(item)),
            totalElements,
          };
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCustomerIDVApplication(applicationId: string): Observable<IdentityVerificationApplication> {
    return this.backendService
      .get<IdentityVerificationApplication>(`${environment.identityService}/identity-verifications/${applicationId}`)
      .pipe(
        tap({
          next: (customerIDVInfo) => {
            const { createdBy, updatedBy } = customerIDVInfo;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => IdentityVerificationService.mapIDVApplicationData(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public setIdvFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_IDV_FILTER, filters);
    this.updateIndividualFilterParams(filters);
  }

  public getIdvFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_IDV_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateIndividualFilterParams(filters);
    }
  }

  public getIdentityVerificationProfiles(): Observable<IdentityVerificationProfile[]> {
    return this.backendService
      .get<IdentityVerificationProfileRaw[]>(`${environment.identityService}/identity-verification-profiles`, {
        context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch IDV Profiles.'),
      })
      .pipe(
        map((profiles) => profiles.map((idvProfile) => IdentityVerificationService.mapIDVApplicationProfile(idvProfile))),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  private updateIndividualFilterParams(filters: FilterValues): void {
    this.idvListFilter.next(filters);
  }

  static mapIDVApplicationData(identityApplicationData: IdentityVerificationApplicationRaw): IdentityVerificationApplication {
    const processingSummaryDetails: IdentityVerificationProcessingSummary[] = [];
    identityApplicationData?.entities?.forEach((entity) => processingSummaryDetails.push(identityProcessingSummarySerializer(entity)));

    const mappedStatusHistory = identityApplicationData?.statusHistory?.map((statusItem) => {
      return {
        ...statusItem,
        createdBy: 'System',
        message: statusItem.message.split('_').join(' '),
        status: toTitleCase(statusItem.status),
      };
    });

    return {
      ...identityApplicationData,
      displayName:
        compact([identityApplicationData?.firstName, identityApplicationData?.lastName])?.join(' ') || identityApplicationData?.legalName,
      shortId: identityApplicationData.id.split('-')[0],
      customerAccountShortId: identityApplicationData?.accountId?.split('-')[0],
      identityProfileIdName: IdentityProfileIdMapper.get(identityApplicationData.identityVerificationProfileId),
      customerAccountType: identityApplicationData?.accountType?.split('_')[0],
      statusHistory: reverse(mappedStatusHistory),
      summary: processingSummaryDetails,
      latestStatus: toTitleCase(identityApplicationData.latestStatus),
    };
  }

  static mapIDVApplicationProfile(idvProfile: IdentityVerificationProfileRaw): IdentityVerificationProfile {
    return {
      ...idvProfile,
      label: idvProfile.name,
      value: idvProfile.id,
    };
  }

  public submitIdentityVerification(submitData: SubmitIdentityVerificationApplicationParams) {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post(
            `${environment.identityService}/identity-verifications`,
            { ...submitData, businessAccountId },
            { context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
          )
          .pipe(
            map((response) => response),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  public getWatchlistEvents(fetchParamData: string, requestPageParams: RequestPageParams): Observable<WatchlistEventList> {
    const params = idvRequestParams(requestPageParams);

    return this.backendService
      .get<WatchlistEventListRaw>(`${environment.identityService}/watchlist-events?${fetchParamData}`, { params })
      .pipe(
        map((response) => {
          const { content = [], totalElements = 0 } = response || {};
          return {
            items: content?.map((item) => IdentityVerificationService.mapWatchlistEventsData(item)),
            totalElements,
          };
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  static mapWatchlistEventsData(watchlistEventsData: WatchlistEventRaw): WatchlistEvent {
    return {
      ...watchlistEventsData,
      shortId: watchlistEventsData.id.split('-')[0],
      customerAccountShortId: watchlistEventsData?.accountId?.split('-')[0],
      services: watchlistEventsData?.servicesRun?.join(','),
      latestStatus: toTitleCase(watchlistEventsData.status),
    };
  }

  public setWatchlistEventsFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_WATCHLIST_EVENTS_FILTER, filters);
    this.updateWatchlistEventsFilterParams(filters);
  }

  public getWatchlistEventsFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_WATCHLIST_EVENTS_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateWatchlistEventsFilterParams(filters);
    }
  }

  private updateWatchlistEventsFilterParams(filters: FilterValues): void {
    this.watchlistEventsListFilter.next(filters);
  }

  parseIDVSubmitError(errors: IDVError[]) {
    const errorBlocks: ErrorBlock[] = [];
    each(errors, (error) => {
      const [type, , name] = error.path.split('.');
      const title = `${type.includes('customer') ? 'Customer' : toTitleCase(type.replace(/_/g, ' '))} ${name}`;

      const errorBlock: ErrorBlock = {
        title,
        fields: [],
      };

      const fieldsRaw = RegExp(/: (?<fields>.+) (?:are|is) required/).exec(error.message);

      let fields: string[] = [];
      if (fieldsRaw?.groups) {
        fields = fieldsRaw.groups.fields.split(',').map((group) => {
          return group
            .split('/')
            .map((field) => {
              const [category, data] = field.split('.');
              if (category === 'INFO' && data === 'SSN') return data;
              if (category === 'ADDRESS') {
                return `${toTitleCase(data.replace(/_/g, ' '))} ${toTitleCase(category)}`;
              }
              if (!data) return toTitleCase(category.replace(/_/g, ' '));

              return toTitleCase(data.replace(/_/g, ' '));
            })
            .join(' or ');
        });
      }

      errorBlock.fields = fields;
      errorBlocks.push(errorBlock);
    });
    return errorBlocks;
  }
}
