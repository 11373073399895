import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';
import { compact } from 'lodash-es';
import {
  ScheduledTransactionListRaw,
  ScheduledTransactionList,
  ScheduledTransactionListItem,
  ScheduledTransactionDetailsRaw,
  ScheduledTransactionDetails,
  RecipientRaw,
  CustomerDetailRaw,
  ScheduledTransactionFinancialAccount,
  TransactionHistoryListResponse,
  TransactionHistoryList,
  TeamMember,
} from '@shared/models';
import {
  getMaskedAccountNumber,
  getSubtypeDisplayName,
  toTitleCase,
  formatRecurrenceRule,
  getProcessingPriorityIconName,
  getProcessingPriorityName,
  systemTimeZone,
} from '@shared/utils';

export const mapScheduledTransactionListData = (transactionsData: ScheduledTransactionListRaw): ScheduledTransactionList => {
  const { content = [], totalElements = 0, hasNext = false } = transactionsData ?? {};

  const items: ScheduledTransactionListItem[] = content.map((item) => {
    const bankNameMoveFrom = item.debitFinancialAccounts?.[0]?.bankAccount?.bankName;
    const bankNameMoveTo = item.creditFinancialAccounts?.[0]?.bankAccount?.bankName;
    const nameOnCardMoveTo = item.creditFinancialAccounts?.[0]?.card
      ? [item.creditFinancialAccounts?.[0]?.card.firstName, item.creditFinancialAccounts?.[0]?.card.lastName].join(' ')
      : '';
    const fromAcc = compact([
      getMaskedAccountNumber(item.debitFinancialAccounts?.[0]?.maskedAccountNumber),
      getSubtypeDisplayName(item.debitFinancialAccounts?.[0]?.subtype),
    ]).join(' | ');

    const toAcc = compact([
      getMaskedAccountNumber(item.creditFinancialAccounts?.[0]?.maskedAccountNumber),
      getSubtypeDisplayName(item.creditFinancialAccounts?.[0]?.subtype),
    ]).join(' | ');

    const startDateTimeParsed = item.startDateTime
      ? formatInTimeZone(`${item.startDateTime}.000Z`, systemTimeZone, 'MM/dd/yy HH:mm')
      : undefined;

    return {
      id: item.id,
      moveFrom: `${item.debitFinancialAccounts?.[0]?.name ?? ''}::${compact([bankNameMoveFrom, fromAcc]).join(' ')}`,
      moveTo: `${item.creditFinancialAccounts?.[0]?.name ?? ''}::${compact([bankNameMoveTo ?? nameOnCardMoveTo, toAcc]).join(' ')}`,
      status: item.status === 'DELETED' ? 'CANCELLED' : item.status,
      scheduleType: item.scheduleType === 'recurring' ? 'Recurring' : 'Once-off',
      name: item.name ?? (startDateTimeParsed ? `Scheduled Transaction for ${format(new Date(startDateTimeParsed), 'MM/dd/yy')}` : ''),
      amount: item.amount,
      currency: item.currency,
      transactionType: item.recordType === 'mlt' ? 'Multi-leg' : toTitleCase(item.transactionType),
      rowClassName: compact([
        item.scheduleType === 'recurring' ? 'schedule-type-recurring' : undefined,
        (item.recordType === 'mlt' ? 'mlt' : item.transactionType?.toLowerCase()) + '-row',
      ]).join(' '),
      createdAt: item.createdAt,
    };
  });

  return {
    items,
    totalElements,
    hasNext,
  };
};

export const mapScheduledTransactionDetails = (response: ScheduledTransactionDetailsRaw): ScheduledTransactionDetails => {
  const {
    recurrenceRule,
    metadata = {},
    debitFinancialAccounts,
    creditFinancialAccounts,
    startDateTime,
    nextTransactions = [],
    status,
    nextScheduledDate,
    nextScheduledTime,
    scheduleType,
    amount,
    debits,
    credits,
    paymentReasons,
  } = response;

  const { estLabel, estLabelDate, estLabelDay, estLabelTime } = getTransactionEstimatedDate(response) ?? {};

  const bankNameMoveFrom = debitFinancialAccounts?.[0].bankAccount?.bankName;
  const bankNameMoveTo = creditFinancialAccounts?.[0].bankAccount?.bankName;
  const nameOnCardMoveTo = compact([creditFinancialAccounts?.[0].card?.firstName, creditFinancialAccounts?.[0].card?.lastName]).join(' ');

  const fromAcc = compact([
    getMaskedAccountNumber(debitFinancialAccounts?.[0].maskedAccountNumber),
    getSubtypeDisplayName(debitFinancialAccounts?.[0].subtype),
  ]).join(' | ');

  const toAcc = compact([
    getMaskedAccountNumber(creditFinancialAccounts?.[0].maskedAccountNumber),
    getSubtypeDisplayName(creditFinancialAccounts?.[0].subtype),
  ]).join(' | ');

  return {
    ...response,
    amount: `${amount}`,
    nextTransactions: nextTransactions.map((dateTime) => formatInTimeZone(dateTime, systemTimeZone, 'MM/dd/yy HH:mm')),
    isRecurring: scheduleType === 'recurring',
    recurrenceRuleParsed: formatRecurrenceRule(recurrenceRule),
    startDateTime: formatInTimeZone(`${startDateTime}.000Z`, systemTimeZone, 'MM/dd/yy HH:mm'),
    nextTransactionAt:
      nextScheduledDate && nextScheduledTime
        ? formatInTimeZone(`${nextScheduledDate}T${nextScheduledTime}Z`, systemTimeZone, 'MM/dd/yy HH:mm')
        : undefined,
    status: status === 'DELETED' ? 'CANCELLED' : status,
    debitFinancialAccounts: debitFinancialAccounts?.length
      ? [
          {
            ...debitFinancialAccounts[0],
            accountHolderInfo: getAccountHolderInfo(debitFinancialAccounts[0]),
            accountInfo: `${debitFinancialAccounts?.[0]?.name ?? ''}::${compact([bankNameMoveFrom, fromAcc]).join(' ')}`,
          },
        ]
      : [],
    creditFinancialAccounts: creditFinancialAccounts?.length
      ? [
          {
            ...creditFinancialAccounts[0],
            accountHolderInfo: getAccountHolderInfo(creditFinancialAccounts[0]),
            accountInfo: `${creditFinancialAccounts[0]?.name ?? ''}::${compact([bankNameMoveTo ?? nameOnCardMoveTo, toAcc]).join(' ')}`,
          },
        ]
      : [],
    metadataItems: Object.keys(metadata).map((key) => ({ key, value: metadata[key as keyof typeof metadata] })),
    debits: debits?.length
      ? [
          {
            ...debits[0],
            processingPriority: getProcessingPriorityName(debits[0].settlementPriority),
            processingPriorityIcon: getProcessingPriorityIconName(debits[0].settlementPriority),
            paymentReason: paymentReasons?.find(({ value }) => value === debits[0].paymentReasonId)?.label,
          },
        ]
      : [],
    credits: credits?.length
      ? [
          {
            ...credits[0],
            processingPriority: getProcessingPriorityName(credits[0].settlementPriority),
            processingPriorityIcon: getProcessingPriorityIconName(credits[0].settlementPriority),
            paymentReason: paymentReasons?.find(({ value }) => value === credits[0].paymentReasonId)?.label,
          },
        ]
      : [],
    estLabelDate,
    estLabelDay,
    estLabelTime,
    estLabel,
  };
};

const getTransactionEstimatedDate = (transaction: ScheduledTransactionDetailsRaw) => {
  const debitLeg = transaction.debits?.[0];
  const creditLeg = transaction.credits?.[0];
  if (!debitLeg || (transaction.transactionType === 'MLT' && !debitLeg && !creditLeg)) {
    return;
  }

  const deliveryOption = transaction.deliverySpeedOptions?.find((item) =>
    item.fromAccountMethod === debitLeg.solution &&
    item.fromAccountProcessingPriority === debitLeg.settlementPriority &&
    transaction.transactionType === 'MLT'
      ? item.toAccountMethod === creditLeg!.solution && item.toAccountProcessingPriority === creditLeg!.settlementPriority
      : true,
  );

  if (!deliveryOption) {
    return;
  }
  return {
    estLabelDate: deliveryOption.estLabelDate,
    estLabelDay: deliveryOption.estLabelDay,
    estLabelTime: deliveryOption.estLabelTime,
    estLabel: deliveryOption.label,
  };
};

const getAccountHolderInfo = (financialAccount: ScheduledTransactionFinancialAccount) => {
  const accountHolder: RecipientRaw | CustomerDetailRaw | undefined = financialAccount.customer ?? financialAccount.recipient;
  const accountHolderIndividualOrBusiness = financialAccount.customer?.type ?? financialAccount.recipient?.recipientType;
  const accountHolderBusinessName =
    (accountHolder as CustomerDetailRaw)?.doingBusinessAsName ?? (accountHolder as RecipientRaw)?.businessName;
  const accountHolderName =
    accountHolderBusinessName ??
    [accountHolder?.firstName, accountHolder?.lastName].join(' ') ??
    [financialAccount.card?.firstName, financialAccount.card?.lastName].join(' ') ??
    financialAccount.bankAccount?.nameOnAccount;
  const accountHolderType = toTitleCase(
    compact([accountHolderIndividualOrBusiness, financialAccount.accountHolderType ?? 'Business Account']).join(' '),
  );

  return `${accountHolderName ?? ''}::${accountHolderType ?? ''}`;
};

export const mapFinancialAccountInfo = (financialAccount: ScheduledTransactionFinancialAccount): ScheduledTransactionFinancialAccount => {
  const { maskedAccountNumber, bankAccount, name, card, subtype } = financialAccount;
  const bankName = bankAccount?.bankName;
  const nameOnCard = compact([card?.firstName, card?.lastName]).join(' ');
  const accNo = compact([getMaskedAccountNumber(maskedAccountNumber), getSubtypeDisplayName(subtype)]).join(' | ');
  const accountInfo = `${name ?? ''}::${compact([bankName ?? nameOnCard, accNo]).join(' ')}`;
  const address = card?.address ?? bankAccount?.address;

  return {
    ...financialAccount,
    accountInfo,
    address,
  };
};

export const mapScheduledTransactionHistoryData = (
  response: TransactionHistoryListResponse,
  teamMembersNames: Record<string, TeamMember['name'] | undefined>,
): TransactionHistoryList => {
  const descriptionMessages: { [key: string]: string } = {
    CREATED: 'green',
    NEW_SCHEDULED_INSTANCE: 'green',
    SUSPENDED: 'pending',
    UNSUSPENDED: 'green',
    CANCELLED: 'red',
    FAILED_SCHEDULED_INSTANCE: 'red',
    COMPLETED: 'green',
    EDITED: 'green',
  };

  const messages: { [key: string]: string } = {
    CREATED: 'New scheduled transaction created',
    NEW_SCHEDULED_INSTANCE: 'New transaction created',
    SUSPENDED: 'Scheduled transaction status changed to ‘SUSPENDED’',
    UNSUSPENDED: 'Scheduled transaction status changed to ‘UNSUSPENDED’',
    CANCELLED: 'Scheduled transaction status changed to ‘CANCELLED’',
    FAILED_SCHEDULED_INSTANCE: 'New transaction creation failed',
    COMPLETED: 'Scheduled transaction status changed to ‘COMPLETED’',
    EDITED: 'Scheduled transaction edited',
  };

  const { content = [], totalElements = 0, hasNext = false, number = 0 } = response || {};
  const items = content.map((item) => {
    const employeeName = item.createdBy && teamMembersNames[item.createdBy.id] ? teamMembersNames[item.createdBy.id] : undefined;

    return {
      ...item,
      createdAt: item.occurredAt!,
      employeeName: employeeName ?? 'System',
      description: messages[item.status!] ?? item.status,
      status: descriptionMessages[item.status!] ?? 'green',
    };
  });

  return {
    items,
    totalElements,
    hasNext,
    number,
  };
};
