import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFeatureFlagEnabledByKey } from '@shared/store';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectFeatureFlagEnabledByKey(route.data?.featureKey));
  }
}
