export const documentTypeDisplayName = (value?: string): string | undefined => {
  if (!value) {
    return;
  }

  const documentTypes: Record<string, string> = {
    'driving-license': "Driver's License",
    passport: 'Passport',
    formation: 'Formation',
    tax: 'Tax',
    ownership: 'Ownership',
    other: 'Other',
  };

  return documentTypes[value] || undefined;
};

export const documentTypeIcon = (value?: string): string | undefined => {
  if (!value) {
    return;
  }

  const documentTypes: Record<string, string> = {
    'driving-license': 'driver-license',
    passport: 'passport',
  };

  return documentTypes[value] || 'document';
};
