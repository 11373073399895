import { createAction, props } from '@ngrx/store';
import { CreatedUpdatedBy, ErrorMessage, SubjectQuickInfo } from '@shared/models';

export namespace AuditActions {
  export const loadTeamMembers = createAction('[Team Members API] Load Team Members', props<{ ids: CreatedUpdatedBy[] }>());

  export const loadTeamMembersSuccess = createAction(
    '[Team Members API] Load Team Members Success',
    props<{ teamMembers: SubjectQuickInfo[] }>(),
  );

  export const loadTeamMembersError = createAction('[Team Members API] Load Team Members Error', props<{ error: ErrorMessage }>());
}
