import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject, exhaustMap, filter, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ScheduledTransactionDetails, ScheduledTransactionFinancialAccount, TemplateContext } from '@shared/models';
import { ErrorUtils, getSolutionDisplayName, getSolutionIconName } from '@shared/utils';
import {
  ConfirmModalComponent,
  SuccessModalComponent,
  ErrorModalComponent,
  MoveMoneyWrapperService,
  AbstractMoveMoneyBase,
  MultiLegTransactionEditModalComponent,
  AttachmentsListComponent,
} from '@shared/components';
import { ScheduledTransactionsService } from '@shared/services';
import {
  ScheduledTransactionActions,
  TransactionFormActions,
  fromAuth,
  fromTransaction,
  selectMltUpdateData,
  selectScheduledTransactionDetailsWithHistory,
  selectSchedulerData,
  selectSltCreateData,
  transactionFeature,
} from '@shared/store';
import { ERROR_MAP, MESSAGE } from '@shared/constants';

@Component({
  selector: 'app-scheduled-transaction-details',
  templateUrl: './scheduled-transaction-details.component.html',
  providers: [
    {
      provide: AbstractMoveMoneyBase,
      useClass: MoveMoneyWrapperService,
    },
  ],
})
export class ScheduledTransactionDetailsComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('attachmentsList') attachmentsList!: AttachmentsListComponent;

  businessAccountId$ = this.store.select(fromAuth.selectBusinessAccountId);

  pageClassPrefix = 'transaction-details-';

  pageReturnURL = '/app/transactions/scheduled-transactions';

  breadcrumbs = [{ label: 'Scheduled Transactions', url: this.pageReturnURL }, { label: 'Scheduled Transaction Details' }];

  scheduledTransaction$ = this.store.select(selectScheduledTransactionDetailsWithHistory).pipe(
    tap({
      next: (transaction) => {
        const { transactionDetails } = transaction ?? {};
        if (!transactionDetails) {
          return;
        }
        this.isMlt = transactionDetails.transactionType === 'MLT';
        this.isRecurring = transactionDetails.isRecurring;
        this.isPaused = transactionDetails.status === 'SUSPENDED';
        this.isCancelled = transactionDetails.status === 'CANCELLED';
        this.isFinished = transactionDetails.status === 'FINISHED';
      },
    }),
  );

  toAccountLink = '';

  fromAccountLink = '';

  scheduledTransactionDetailsContext?: TemplateContext<ScheduledTransactionDetails>;

  financialAccountContext?: TemplateContext<ScheduledTransactionFinancialAccount>;

  isRecurring = false;

  isPaused = false;

  isCancelled = false;

  isFinished = false;

  isShowPrevious = false;

  isShowNext = false;

  isShowMetadata = false;

  isMlt = false;

  getSolutionIconName = getSolutionIconName;

  getSolutionDisplayName = getSolutionDisplayName;

  get scheduledTransactionId(): string {
    return this.route.snapshot.paramMap.get('scheduledTransactionId') ?? '';
  }

  private destroy$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private scheduledTransactionService: ScheduledTransactionsService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    public ref: ChangeDetectorRef,
    public moveMoneyService: AbstractMoveMoneyBase,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(ScheduledTransactionActions.loadDetails());
        },
      });
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.createTransactionPageReset());

    this.destroy$.next();
    this.destroy$.complete();
  }

  onEdit(): void {
    if (this.isMlt) {
      this.store.dispatch(TransactionFormActions.formUpdateScheduledMltInitialize());
    } else {
      this.store.dispatch(TransactionFormActions.formUpdateScheduledSltInitialize());
    }

    this.onEditStage();
  }

  onEditStage(): void {
    const editModalRef = this.modalService.open(MultiLegTransactionEditModalComponent, {
      className: 'multi-leg-transaction-edit-modal',
    });

    editModalRef.result.then(
      (action?: 'EDIT_SCHEDULED_SLT_CONFIRM' | 'EDIT_SCHEDULED_MLT_CONFIRM') => {
        if (action && ['EDIT_SCHEDULED_MLT_CONFIRM', 'EDIT_SCHEDULED_SLT_CONFIRM'].includes(action)) {
          this.showEditConfirmationModal();
        }
      },
      () => false,
    );
  }

  showEditConfirmationModal() {
    if (!this.isRecurring) {
      this.onActionSuccess('Scheduled Transaction successfully updated.');
    }

    const editConfirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    editConfirmModalRef.componentInstance.title = 'Apply changes to Scheduled Transaction?';
    editConfirmModalRef.componentInstance.customText = 'Scheduled Transactions will resume as configured.';
    editConfirmModalRef.componentInstance.confirmType = 'warning';

    editConfirmModalRef.result.then(
      (result) => {
        if (result) {
          if (this.isMlt) {
            this.editScheduledMltTransaction();
          } else {
            this.editScheduledTransaction();
          }
        }
      },
      () => false,
    );
  }

  editScheduledTransaction() {
    this.store
      .select(fromTransaction.selectSolutionCurrency(this.moveMoneyService.moveHow.moveHowType!))
      .pipe(
        concatLatestFrom(() => [
          this.store.select(fromAuth.selectBusinessAccountId),
          this.store.select(transactionFeature.selectScheduledTransactionDetails),
          this.store.select(selectSltCreateData),
          this.store.select(selectSchedulerData),
        ]),
        exhaustMap(([, businessAccountId, scheduledTransactionDetails, createSltTransactionData, schedulerData]) =>
          this.scheduledTransactionService
            .editScheduledTransaction(businessAccountId, scheduledTransactionDetails!.id, {
              ...createSltTransactionData!,
              ...schedulerData,
              currency: undefined,
              status: scheduledTransactionDetails?.status,
            })
            .pipe(takeUntil(this.destroy$)),
        ),
      )
      .subscribe({
        next: (result) => {
          if (result) {
            this.onActionSuccess(`Scheduled Transaction successfully updated.`);
          }
        },
        error: (err) => {
          this.onActionError('editScheduledTransaction', err);
        },
      });
  }

  editScheduledMltTransaction() {
    this.store
      .select(fromTransaction.selectSolutionCurrency(this.moveMoneyService.moveHow.moveHowType!))
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFeature.selectScheduledTransactionDetails),
          this.store.select(selectMltUpdateData),
          this.store.select(selectSchedulerData),
        ]),
        exhaustMap(([, scheduledTransactionDetails, updateMltTransactionData, schedulerData]) =>
          this.scheduledTransactionService
            .editScheduledMltTransaction(
              scheduledTransactionDetails!.id,
              {
                ...updateMltTransactionData!,
                ...schedulerData!,
                timeZone: 'UTC',
              },
              scheduledTransactionDetails!.transactionETag!,
            )
            .pipe(takeUntil(this.destroy$)),
        ),
      )
      .subscribe({
        next: (result) => {
          if (result) {
            this.onActionSuccess(`Scheduled Transaction successfully updated.`);
          }
        },
        error: (err) => {
          this.onActionError('editScheduledTransaction', err);
        },
      });
  }

  onPause(scheduledTransactionId: string) {
    const pauseConfirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    pauseConfirmModalRef.componentInstance.customText = this.isRecurring
      ? 'Upcoming transactions will not be created until the schedule is resumed. Click Confirm to continue.'
      : 'Scheduled transaction will not be created until the schedule is resumed. Click Confirm to continue.';
    pauseConfirmModalRef.componentInstance.title = 'Pause Scheduled Transaction';
    pauseConfirmModalRef.componentInstance.confirmType = 'warning';

    pauseConfirmModalRef.result.then(
      (result) => {
        if (result) {
          this.suspendScheduledTransaction(scheduledTransactionId);
        }
      },
      () => false,
    );
  }

  suspendScheduledTransaction(scheduledTransactionId: string) {
    this.scheduledTransactionService
      .suspendScheduledTransaction(scheduledTransactionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.onActionSuccess('Scheduled Transaction successfully paused.');
        },
        error: (err) => {
          this.onActionError('suspendScheduledTransaction', err);
        },
      });
  }

  onUnpause(scheduledTransactionId: string) {
    const unpauseConfirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });
    unpauseConfirmModalRef.componentInstance.customText = 'Click Confirm to continue.';
    unpauseConfirmModalRef.componentInstance.title = 'Resume Scheduled Transaction';
    unpauseConfirmModalRef.componentInstance.confirmType = 'warning';

    unpauseConfirmModalRef.result.then(
      (result) => {
        if (result) {
          this.unsuspendScheduledTransaction(scheduledTransactionId);
        }
      },
      () => false,
    );
  }

  unsuspendScheduledTransaction(scheduledTransactionId: string) {
    this.scheduledTransactionService
      .unsuspendScheduledTransaction(scheduledTransactionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.onActionSuccess(`Scheduled Transaction successfully resumed.`);
        },
        error: (err) => {
          this.onActionError('unsuspendScheduledTransaction', err);
        },
      });
  }

  onCancel(scheduledTransactionId: string) {
    const unpauseConfirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    unpauseConfirmModalRef.componentInstance.customText = this.isRecurring
      ? 'All future Scheduled Transactions will be cancelled. Click Confirm to continue.'
      : 'Scheduled Transaction will be cancelled. Click Confirm to continue.';
    unpauseConfirmModalRef.componentInstance.title = 'Cancel Scheduled Transaction';
    unpauseConfirmModalRef.componentInstance.confirmType = 'error';

    unpauseConfirmModalRef.result.then(
      (result) => {
        if (result) {
          this.cancelScheduledTransaction(scheduledTransactionId);
        }
      },
      () => false,
    );
  }

  cancelScheduledTransaction(scheduledTransactionId: string) {
    this.scheduledTransactionService
      .cancelScheduledTransaction(scheduledTransactionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.onActionSuccess('Scheduled Transaction successfully cancelled.');
        },
        error: (err) => {
          this.onActionError('cancelScheduledTransaction', err);
        },
      });
  }

  onActionSuccess(title: string) {
    const successModalRef = this.modalService.open(SuccessModalComponent, { className: 'confirm-modal' });
    successModalRef.componentInstance.customTitle = title;
    successModalRef.result.finally(() => this.reloadPage());
  }

  onActionError(action: string, error?: string | number) {
    ErrorUtils.catchError(`scheduledTransactionService.${action}`, error);
    const modalTitle = error === 403 ? MESSAGE.PERMISSION_DENIED_NOTIFICATION_TITLE : 'Error';
    let messageText = error === 403 ? MESSAGE.PERMISSION_DENIED_NOTIFICATION : MESSAGE.MOVE_MONEY_UNKNOWN_ERROR;

    if (typeof error === 'string') {
      messageText = ERROR_MAP.has(error) ? ERROR_MAP.get(error)! : error;
    }

    const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
    errorModalRef.componentInstance.modalTitle = modalTitle;
    errorModalRef.componentInstance.messageText = messageText;
    errorModalRef.result.finally(() => this.reloadPage());
  }

  reloadPage(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => {
        this.router.navigate(['app', 'transactions', 'scheduled-transactions', this.scheduledTransactionId]);
      },
      () => null,
    );
  }
}
