import { Injectable } from '@angular/core';
import {
  FinancialAccountListItem,
  LinxCustomer,
  LinxRequestMoveMoneyDetails,
  LinxRequestDebitAuthorizationDetails,
  LinxRequestDocumentDetails,
  LinxRequestOptionType,
  LinxRequestRowType,
} from '@shared/models';

export abstract class AbstractLinxRequestBase {
  abstract expandedRow?: LinxRequestRowType;
  abstract linxWorkflowTitle?: string;
  abstract linxCustomer?: LinxCustomer;
  abstract customerFinancialAccountDetails?: FinancialAccountListItem;
  abstract enhanceProfileDetails?: { [key: string]: string[] };
  abstract newFinancialAccountDetails?: string;
  abstract documentAcceptanceDetails?: LinxRequestDocumentDetails[];
  abstract debitAuthorizationDetails?: LinxRequestDebitAuthorizationDetails;
  abstract moveMoneyDetails?: LinxRequestMoveMoneyDetails;
  abstract selectedRequestOptions: LinxRequestOptionType[];
  abstract isRequestOptionsAvailable(): boolean;
  abstract isRowVisible(type: LinxRequestRowType): boolean;
  abstract isRowExpanded(type: LinxRequestRowType): boolean;
  abstract isRowHasValue(type: LinxRequestRowType): boolean;
  abstract isEditDisabled(): boolean;
  abstract onReset(): void;
  abstract resetRequestRowDetails(type: LinxRequestRowType): void;
  abstract handleRowExpand(type?: LinxRequestRowType): void;
}

@Injectable()
export class LinxRequestWrapperService implements AbstractLinxRequestBase {
  public expandedRow?: LinxRequestRowType;

  linxWorkflowTitle?: string;

  linxCustomer?: LinxCustomer;

  customerFinancialAccountDetails?: FinancialAccountListItem;

  enhanceProfileDetails?: { [key: string]: string[] };

  newFinancialAccountDetails?: string;

  documentAcceptanceDetails?: LinxRequestDocumentDetails[];

  debitAuthorizationDetails?: LinxRequestDebitAuthorizationDetails;

  moveMoneyDetails?: LinxRequestMoveMoneyDetails;

  selectedRequestOptions: LinxRequestOptionType[] = [];

  get isEnhanceProfileDetailsRowHasValue(): boolean {
    return !!this.enhanceProfileDetails;
  }

  get isExistingFinancialAccountDetailsHasValue(): boolean {
    return this.customerFinancialAccountDetails ? true : false;
  }

  get isNewFinancialAccountDetailsHasValue(): boolean {
    return this.newFinancialAccountDetails ? true : false;
  }

  get isFinancialAccountDetailsRowHasValue(): boolean {
    return this.isNewFinancialAccountDetailsHasValue || this.isExistingFinancialAccountDetailsHasValue;
  }

  get isDocumentAcceptanceDetailsRowHasValue(): boolean {
    return this.documentAcceptanceDetails?.length ? true : false;
  }

  get isDebitAuthorizationDetailsRowHasValue(): boolean {
    return this.debitAuthorizationDetails ? true : false;
  }

  get isMoveMoneyDetailsRowHasValue(): boolean {
    return this.moveMoneyDetails ? true : false;
  }

  isRequestOptionsAvailable(): boolean {
    return this.selectedRequestOptions.length > 0;
  }

  handleRowExpand(type?: LinxRequestRowType): void {
    if (type) {
      this.expandedRow = type;
      return;
    }
    this.expandedRow = this.selectedRequestOptions.find((option) => option.isChecked && !option.hasValue)?.type;
  }

  isRowVisible(type: LinxRequestRowType): boolean {
    return this.selectedRequestOptions.find((option) => option.type === type)?.isChecked || false;
  }

  isRowExpanded(type: LinxRequestRowType): boolean {
    return this.expandedRow === type;
  }

  isEditDisabled(): boolean {
    return this.expandedRow ? true : false;
  }

  isRowHasValue(type: string): boolean {
    switch (type) {
      case 'enhance-profile':
        return this.isEnhanceProfileDetailsRowHasValue;
      case 'financial-account':
        return this.isFinancialAccountDetailsRowHasValue;
      case 'document-acceptance':
        return this.isDocumentAcceptanceDetailsRowHasValue;
      case 'debit-authorization':
        return this.isDebitAuthorizationDetailsRowHasValue;
      case 'move-money':
        return this.isMoveMoneyDetailsRowHasValue;
      default:
        return false;
    }
  }

  resetRequestRowDetails(type: LinxRequestRowType): void {
    switch (type) {
      case 'enhance-profile':
        this.enhanceProfileDetails = undefined;
        break;
      case 'financial-account':
        this.newFinancialAccountDetails = undefined;
        this.customerFinancialAccountDetails = undefined;
        break;
      case 'document-acceptance':
        this.documentAcceptanceDetails = undefined;
        break;
      case 'debit-authorization':
        this.debitAuthorizationDetails = undefined;
        break;
      case 'move-money':
        this.moveMoneyDetails = undefined;
        break;
      default:
        break;
    }
  }

  onReset(): void {
    this.customerFinancialAccountDetails = undefined;
    this.newFinancialAccountDetails = undefined;
    this.enhanceProfileDetails = undefined;
    this.documentAcceptanceDetails = undefined;
    this.debitAuthorizationDetails = undefined;
    this.moveMoneyDetails = undefined;
    this.expandedRow = undefined;
    this.selectedRequestOptions = [];
  }
}
