import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Role, RoleOption } from '@shared/models';
import { BackendService } from '../backend.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private backendService: BackendService) {}

  getRoleOptions(): Observable<RoleOption[]> {
    return this.backendService.get<Role[]>(`${environment.accountPermissionEndpoint}/roles/standard`).pipe(
      map((response) => this.mapRoleOptions(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  mapRoleOptions(response: Role[]): RoleOption[] {
    const roles = response || [];
    return roles.map((role) => ({
      label: role.name,
      value: role.name,
      id: role.id,
    }));
  }
}
