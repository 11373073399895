import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { MoveMoneyConfirmChangesModalComponent } from '@shared/components';
import {
  EnabledSolutionPermissions,
  MoveHowDirectionItem,
  MoveHowSolutionItem,
  TransactionDirection,
  TransactionSolution,
  TransactionSolutionAccess,
} from '@shared/models';

export interface MoveHowSelection {
  moveDirection?: TransactionDirection;
  moveHowType?: TransactionSolution;
}

interface MoveHowChange {
  moveItem?: TransactionDirection;
  moveHowType?: TransactionSolution;
}

@Component({
  selector: 'app-move-how-selection',
  templateUrl: 'move-how-selection.component.html',
  styleUrls: ['move-how-selection.component.scss'],
})
export class MoveHowSelectionComponent {
  @Input() selectedMoveItem?: TransactionDirection;

  @Input() selectedMoveHowType?: TransactionSolution;

  @Input() enabledSolutionPermissions?: EnabledSolutionPermissions | null;

  @Output() selected = new EventEmitter<MoveHowSelection>();

  @Output() cleared = new EventEmitter<void>();

  directionItems: MoveHowDirectionItem[] = [
    { type: 'push', label: 'Send', iconName: 'send' },
    { type: 'pull', label: 'Request', iconName: 'money-request' },
  ];

  solutionItems: MoveHowSolutionItem[] = [
    { type: 'ach', label: 'ACH', iconName: 'ach' },
    { type: 'push-to-card', label: 'Push To Card', iconName: 'card' },
    { type: 'transfer', label: 'Transfer', iconName: 'transfer' },
    { type: 'wire', label: 'Wire', iconName: 'bank' },
  ];

  directionTypes: TransactionDirection[] = ['push', 'pull'];

  solutionTypes: TransactionSolution[] = ['ach', 'push-to-card', 'transfer', 'wire'];

  get createPermissions(): TransactionSolutionAccess | undefined {
    return this.enabledSolutionPermissions?.create;
  }

  get directionDisabledTypes(): TransactionDirection[] {
    const permissions = this.createPermissions;

    if (!permissions) {
      return this.directionTypes;
    }

    const disabledTypes: TransactionDirection[] = [];
    const isAch = permissions.ach;

    if (Object.values(permissions).every((value) => !value)) {
      disabledTypes.push('push');
    }

    if (!isAch || (this.selectedMoveHowType && this.selectedMoveHowType !== 'ach')) {
      disabledTypes.push('pull');
    }

    return disabledTypes;
  }

  get solutionDisabledTypes(): TransactionSolution[] {
    const permissions = this.createPermissions;

    if (!permissions) {
      return this.solutionTypes;
    }

    const disabledTypes = this.solutionTypes.filter((type) => {
      const isTypeDisabled = !permissions[type];
      const isPull = this.selectedMoveItem === 'pull';
      const isPushOnlyType = ['push-to-card', 'transfer', 'wire'].includes(type);

      if (isTypeDisabled || (isPull && isPushOnlyType)) {
        return true;
      }

      return false;
    });

    return disabledTypes;
  }

  constructor(private modalService: ModalService) {}

  selectMoveItem(moveItem: TransactionDirection): void {
    if (this.selectedMoveItem === moveItem) {
      return;
    }

    this.selectMove(moveItem, this.selectedMoveHowType, { moveItem });
  }

  selectMoveHowType(moveHowType: TransactionSolution): void {
    if (this.selectedMoveHowType === moveHowType) {
      return;
    }

    this.selectMove(this.selectedMoveItem, moveHowType, { moveHowType });
  }

  selectMove(moveItem: TransactionDirection | undefined, moveHowType: TransactionSolution | undefined, params: MoveHowChange): void {
    if (!this.selectedMoveItem || !this.selectedMoveHowType) {
      this.selectedMoveItem = params.moveItem ?? moveItem;
      this.selectedMoveHowType = params.moveHowType ?? moveHowType;

      this.onMoveHowSelect();
      return;
    }

    const confirmModalRef = this.modalService.open(MoveMoneyConfirmChangesModalComponent, { className: 'confirm-modal' });

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.onMoveHowChange(params);
        }
      },
      () => false,
    );
  }

  onMoveHowChange({ moveItem, moveHowType }: MoveHowChange) {
    if (!moveItem && !moveHowType) {
      return;
    }

    this.cleared.emit();
    this.selectedMoveItem = moveItem;
    this.selectedMoveHowType = moveHowType;
    this.onMoveHowSelect();
  }

  onMoveHowSelect() {
    this.selected.emit({
      moveDirection: this.selectedMoveItem,
      moveHowType: this.selectedMoveHowType,
    });
  }

  resetValue(): void {
    this.selectedMoveItem = undefined;
    this.selectedMoveHowType = undefined;
  }
}
