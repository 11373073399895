import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAlert, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { NavigationService } from '@shared/services';
import { AlertCreateModalComponent } from '@shared/components';

@Component({
  templateUrl: './alert-details.component.html',
})
export class AlertDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'alert-details-';

  alertDetailsContext?: TemplateContext<CardAlert>;

  alertDetails$ = this.store.select(fromFinancialAccount.selectCardAccountAlertDetails);

  constructor(private navigationService: NavigationService, private store: Store, private modalService: ModalService) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadAlertDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails());
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    this.navigationService.ngOnDestroy();
  }

  onEditBtnClick(alert?: CardAlert) {
    if (!alert) {
      return;
    }

    const alertModalRef = this.modalService.open(AlertCreateModalComponent, { className: 'entity-form-modal' });

    alertModalRef.componentInstance.modalInitData(alert);

    alertModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadAlertDetails());
    });
  }
}
