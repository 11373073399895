import { DocumentDetails } from '@shared/models';
import { documentTypeDisplayName, documentTypeIcon } from '@shared/utils';

export const mapDocumentDetails = (item: DocumentDetails): DocumentDetails => {
  return {
    ...item,
    documentTypeDisplayName: documentTypeDisplayName(item.documentType),
    documentTypeIcon: documentTypeIcon(item.documentType),
  };
};
