<div class="linx-request-move-money">
  <div class="collapsible-row">
    <div class="default">1</div>
    <app-linx-request-move-money-how-selection
      [selectedMoveHowSelection]="moveMoneyService.moveHow"
      (selected)="onMoveHowSelect($event)"
      (cleared)="onMoveHowReset()"
      [customerFinancialAccountType]="customerFinancialAccountType"
    >
    </app-linx-request-move-money-how-selection>
  </div>

  <div class="collapsible-row-column" [hidden]="!isRowExpanded('move-how-wire-details')">
    <div class="collapsible-row-header">
      <div class="default">2</div>
      <div class="collapsible-row-label">Wire Details</div>
    </div>

    <div class="registry-form">
      <app-move-how-wire-details
        [isSameDayWindowDisabled]="isSameDayWireWindowDisabled"
        [isNextDayWireWindowDisabled]="isNextDayWireWindowDisabled"
        (selected)="onMoveHowWireDetailsSelect($event)"
      ></app-move-how-wire-details>
    </div>
  </div>

  <div class="collapsible-row-column" [hidden]="!isRowExpanded('move-how-ach-details')">
    <div class="collapsible-row-header">
      <div class="default">2</div>
      <div class="collapsible-row-label">ACH Details</div>
    </div>

    <div class="registry-form">
      <app-move-how-ach-details
        [isLinXMoveMoney]="true"
        [isSameDayWindowDisabled]="isSameDayAchWindowDisabled"
        (selected)="onMoveHowAchDetailsSelect($event)"
        (blurred)="onMoveHowAchDetailsBlur()"
      ></app-move-how-ach-details>
    </div>
  </div>

  <div class="collapsible-row">
    <div class="default">{{ isWireOrAch ? 3 : 2 }}</div>
    <div class="collapsible-row-label" *ngIf="!isRowExpanded('move-reason-amount')">Reason & Amount</div>
    <div class="move-reason-amount-wrapper" *ngIf="isRowExpanded('move-reason-amount')">
      <div class="payment-reasons-wrapper">
        <app-move-reason-selection
          [initialValue]="selectedMoveMoneyDetails?.paymentReasonId"
          [directionType]="moveMoneyService.moveHow.moveDirection"
          [moveHowType]="moveMoneyService.moveHow.moveHowType"
          [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
          (selected)="onPaymentReasonSelect($event)"
        ></app-move-reason-selection>
      </div>

      <div class="move-amount-wrapper">
        <app-move-amount-control
          *ngIf="!isTemplate"
          [transactionType]="moveMoneyService.moveHow.moveHowType"
          [transactionWindowType]="moveMoneyService.moveHowAchDetails.moveAchType"
          [achEntryType]="moveMoneyService.moveHowAchDetails.moveAchEntryType"
          [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
          [moveAmountError]="moveMoneyService.moveAmountError"
          [enabledSolutionConfigs]="enabledSolutionConfigs$ | async"
          (changed)="moveMoneyService.onAmountChange($event)"
          (blurred)="onReasonAmountElBlur()"
          (errored)="moveMoneyService.onAmountError($event)"
        ></app-move-amount-control>

        <app-move-wire-selection
          *ngIf="moveMoneyService.moveHow.moveHowType === 'wire'"
          (changed)="onWireDetailsChange($event)"
          (blurred)="onReasonAmountElBlur()"
        ></app-move-wire-selection>
      </div>
    </div>
  </div>

  <div class="collapsible-row-column">
    <div class="collapsible-row-header">
      <div class="default">{{ isWireOrAch ? 4 : 3 }}</div>
      <div class="collapsible-row-label">{{ businessAccountSelectionLabel }}</div>
    </div>

    <ng-container *ngIf="isRowExpanded('move-from')">
      <div class="registry-form-preview" [hidden]="showBusinessFinancialAccountSelection">
        <div class="preview-content">
          <div class="preview-content-item" *ngIf="!moveMoneyService.selectedDebitFinancialAccount?.accountHolderType">
            {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}
          </div>

          <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.customer">
            <span class="preview-label"
              >{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.type | titlecase }}
              {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
            >
            {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.displayName }}
          </div>

          <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.recipient">
            <span class="preview-label"
              >{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.recipientType | titlecase }}
              {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
            >
            {{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.displayName }}
          </div>

          <div class="preview-content-item">
            <rkt-icon [name]="moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'bank' : 'card'"></rkt-icon>
            {{ moveMoneyService.selectedDebitFinancialAccount?.name }}
            *
            {{
              moveMoneyService.selectedDebitFinancialAccount?.bankAccount?.accountNumberTail ||
                moveMoneyService.selectedDebitFinancialAccount?.card?.cardNumberTail ||
                moveMoneyService.selectedDebitFinancialAccount?.lastFourNumbers?.replace('*', '')
            }}
            {{ moveMoneyService.selectedDebitFinancialAccount?.subtype | titlecase }}
            {{ moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'Acc' : 'Card' }}
          </div>

          <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.category === 'INTERNAL'">
            <span class="preview-label">Available Balance:&nbsp;</span>
            {{
              (moveMoneyService.selectedDebitFinancialAccount?.availableBalance | number: '1.2-2') || ''
                | mask: 'separator':','
                | prefix: '$ '
            }}
          </div>

          <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.customer">
            <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryEmail?.value }}</div>

            <div class="preview-content-item">
              {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryPhoneNumber?.number }}
            </div>
          </div>

          <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.recipient">
            <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.email }}</div>

            <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.phoneNumber }}</div>
          </div>
        </div>

        <button class="preview-edit" [disabled]="submitLoader" (click)="editBusinessFinancialAccount()">
          <rkt-icon name="edit"></rkt-icon>
        </button>
      </div>

      <div class="registry-form" [hidden]="!showBusinessFinancialAccountSelection">
        <app-linx-request-business-financial-account-selection
          [initialSelectedFinancialAccountId]="selectedMoveMoneyDetails?.selectedMoveFromId"
          (selectedFinancialAccount)="onDebitFinancialAccountSelect($event)"
          [debitAllowedTypes]="debitAllowedAccountTypes$ | async"
          [creditAllowedTypes]="creditAllowedAccountTypes$ | async"
          [selectedMoveHowType]="moveMoneyService.moveHow.moveHowType"
          [selectedMoveDirection]="moveMoneyService.moveHow.moveDirection"
        >
        </app-linx-request-business-financial-account-selection>
      </div>
    </ng-container>
  </div>

  <div class="linx-request-move-money-note">
    <app-move-note-control (changed)="moveMoneyService.onNoteChange($event)" [isMoveFieldsDisabled]="submitLoader"></app-move-note-control>
  </div>

  <div class="move-money-buttons">
    <button
      *ngIf="!isEditTemplateMode"
      rktButton
      btnType="tertiary"
      class="move-money-btn btn-clear"
      title="Clear"
      (click)="onClearBtnClick()"
      [disabled]="isDataUnchanged || submitLoader"
    >
      Clear
    </button>

    <button
      *ngIf="isEditTemplateMode"
      rktButton
      btnType="tertiary"
      class="move-money-btn btn-clear"
      title="Cancel"
      (click)="cancel.emit()"
      [disabled]="submitLoader"
    >
      Cancel
    </button>

    <button
      rktButton
      title="Continue"
      (click)="expandNotFilledRow()"
      *ngIf="moveMoneyService.expandedRow"
      [disabled]="!moveMoneyService.isRowHasValue(moveMoneyService.expandedRow)"
    >
      Continue
    </button>

    <button
      rktButton
      title="Continue"
      (click)="moveMoneyReview()"
      [disabled]="submitLoader"
      *ngIf="!isSubmitDisabled && !moveMoneyService.expandedRow"
    >
      Continue
    </button>
  </div>
</div>
