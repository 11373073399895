<div class="table-header table-header-offset-sm table-header-with-controls">
  <div class="table-header-search"></div>

  <div class="table-header-filters"></div>

  <div class="table-header-controls">
    <button
      *ngIf="cardAccountId$ | async as financialAccountId"
      rktButton
      btnType="secondary"
      appRemoveFocus
      title="Add a Restriction"
      (click)="onCreateRestrictionBtnClick({ financialAccountId })"
      class="add-entity-btn"
      [disabled]="(loading$ | async)!"
    >
      <rkt-icon name="plus"></rkt-icon>
      Add a Restriction
    </button>
  </div>
</div>

<rkt-table
  [rows]="(restrictions$ | async)!"
  [columns]="columns"
  [count]="(totalElements$ | async)!"
  [loadingIndicator]="(loading$ | async)!"
  [messages]="{ emptyMessage: 'No Restrictions found.' }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
  [externalPaging]="false"
  (rowActivated)="onRowClick($event)"
></rkt-table>
