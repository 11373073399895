<div class="account-limits-container">
  <div class="account-limits-title">Business Account Transaction Limits</div>

  <div class="limits">
    <ng-container *ngIf="businessAccountTransactionLimits.length || !loading; else loadingPlaceholder">
      <app-transaction-limit-item *ngFor="let item of businessAccountTransactionLimits" [limitItem]="item"></app-transaction-limit-item>
    </ng-container>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <div class="loading-container">
    <div class="row" *ngFor="let row of [1, 2, 3, 4]">
      <div class="label"><div class="loading-placeholder"></div></div>
      <div class="value" [class.single]="row === 1">
        <div class="loading-placeholder"></div>
        <div class="loading-placeholder" *ngIf="row !== 1"></div>
      </div>
    </div>
  </div>
</ng-template>
