import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'lodash-es';
import { AppError } from '@shared/models';
import { MESSAGE } from '@shared/constants';

export class ErrorUtils {
  public static catchError(operation: string, error?: string | number | null | HttpErrorResponse): Observable<null> {
    console.error(new AppError(operation, error ? `${error}` : undefined).getMessage()); //eslint-disable-line @typescript-eslint/no-base-to-string
    return of(null);
  }

  public static parseExtendedError(error: unknown) {
    let errorMessage: string = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
    const { errors, message } = safeJSONparse(error as string) || {};

    if (errors?.length) {
      errorMessage = map(
        errors,
        (errorItem: { path?: string; message?: string }) => `${errorItem.path ? errorItem.path + ': ' : ''}${errorItem.message}`,
      ).join('\r\n');
    } else if (message) {
      errorMessage = message;
    }

    return errorMessage;
  }
}

export const safeJSONparse = (value: string, handleError?: (error: unknown) => void) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    handleError?.(e);
    return undefined;
  }
};
