import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { AddFinancialAccountComponent, ConfirmModalComponent, SuccessModalComponent } from '@shared/components';
import { FinancialAccountService, NotificationService, SubscriberService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { FinancialAccountDetails, FinancialAccountHolderType } from '@shared/models';
import { Store } from '@ngrx/store';
import { CardAccountActions } from '@shared/store';

@Component({
  selector: 'app-financial-account-actions',
  templateUrl: './financial-account-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialAccountActionsComponent {
  @Input() financialAccount!: FinancialAccountDetails;

  @Input() financialAccountHolderType?: FinancialAccountHolderType;

  private modalAction?: 'suspend' | 'unsuspend' | 'remove';

  get isLoading(): boolean {
    return !this.financialAccount;
  }

  get financialAccountState(): FinancialAccountDetails['state'] {
    return this.financialAccount?.state;
  }

  get updatedState() {
    switch (this.modalAction) {
      case 'remove':
        return 'DELETED';
      case 'suspend':
        return 'SUSPENDED';
      default:
        return 'ACTIVE';
    }
  }

  get modalName(): string {
    const { card, bankAccount, displayName } = this.financialAccount;

    if (card) {
      return `${card.firstName} ${card.lastName} | ${displayName}`;
    }

    if (bankAccount) {
      return `${bankAccount.bankName} | ${displayName}`;
    }

    return '';
  }

  get isIntegratedCard(): boolean {
    return this.financialAccount.category === 'INTEGRATED' && !!this.financialAccount.card;
  }

  constructor(
    protected layoutOptions: SubscriberService<FinancialAccountDetails>,
    private financialAccountService: FinancialAccountService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private router: Router,
    private store: Store,
  ) {}

  public onStateChange() {
    this.modalAction = this.financialAccountState === 'SUSPENDED' ? 'unsuspend' : 'suspend';
    this.showConfirmDialog();
  }

  public onRemove() {
    this.modalAction = 'remove';
    this.showConfirmDialog();
  }

  public onEdit() {
    const accountModalRef = this.modalService.open(AddFinancialAccountComponent, {
      className: 'entity-form-modal',
    });

    accountModalRef.componentInstance.modalInitData({
      accountHolderType: this.financialAccount.accountHolderType,
      id: this.financialAccount.accountHolderId,
      editData: this.financialAccount,
    });

    accountModalRef.result.then(
      (financialAccount) => {
        if (financialAccount && !this.isIntegratedCard) this.layoutOptions.next({ ...financialAccount });
        if (financialAccount && this.isIntegratedCard) this.store.dispatch(CardAccountActions.loadCardAccountDetails());
      },
      () => false,
    );
  }

  private showConfirmDialog(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    confirmModalRef.componentInstance.actionName = this.modalAction;
    confirmModalRef.componentInstance.type = 'FinancialAccount';
    confirmModalRef.componentInstance.displayName = this.modalName;

    confirmModalRef.result.then(
      (result) => {
        if (!result) {
          return;
        }
        if (this.modalAction === 'remove') {
          this.deleteFinancialAccount();
        } else {
          this.updateFinancialAccountState();
        }
      },
      () => false,
    );
  }

  private updateFinancialAccountState(): void {
    const { accountHolderId, accountHolderType } = this.financialAccount;

    this.financialAccountService
      .updateFinancialAccountState({
        financialAccountId: this.financialAccount.id,
        state: this.modalAction!,
        accountHolderType,
        accountHolderId,
      })
      .subscribe({
        next: () => {
          this.layoutOptions.next({ ...this.financialAccount, state: this.updatedState });
          this.showStateChangeSuccessModal();
        },
        error: (error) => this.onFinancialAccountStateUpdateFailure(error),
      });
  }

  private deleteFinancialAccount(): void {
    const { accountHolderId, accountHolderType } = this.financialAccount;
    this.financialAccountService
      .deleteFinancialAccount({
        financialAccountId: this.financialAccount.id,
        accountHolderType,
        accountHolderId,
      })
      .subscribe({
        next: () => this.showStateChangeSuccessModal(),
        error: (error) => this.onFinancialAccountStateUpdateFailure(error),
      });
  }

  private onFinancialAccountStateUpdateFailure(error?: number): void {
    if (error !== HttpStatusCode.Forbidden) {
      ErrorUtils.catchError('financialAccountService.updateFinancialAccountState', error);
    }
  }

  private showStateChangeSuccessModal(): void {
    const successModalRef = this.modalService.open(SuccessModalComponent, {
      size: 'lg',
      className: 'success-modal',
    });

    const suspendedOrUnSuspended = this.financialAccountState === 'ACTIVE' ? 'suspended' : 'unsuspended';
    successModalRef.componentInstance.actionName = this.updatedState === 'DELETED' ? 'removed' : suspendedOrUnSuspended;
    successModalRef.componentInstance.type = 'FinancialAccount';

    successModalRef.result.finally(() => {
      if (this.updatedState !== 'DELETED') {
        return;
      }

      switch (this.financialAccountHolderType) {
        case 'business-account':
          this.router.navigate(['app', 'settings', 'business-profile']);
          break;

        case 'recipients':
          this.router.navigate(['app', 'recipients', this.financialAccount.accountHolderId, 'summary']);
          break;

        case 'individuals':
          this.router.navigate(['app', 'customers', 'individuals', this.financialAccount.accountHolderId, 'financial-accounts']);
          break;

        case 'businesses':
          this.router.navigate(['app', 'customers', 'businesses', this.financialAccount.accountHolderId, 'financial-accounts']);
          break;

        default:
          break;
      }
    });
  }
}
