<div class="address-select-header">
  <div class="address-select-title">Choose New Primary Address</div>
  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="address-select-subheader">Please choose a new primary address before the remove action.</div>

<div class="address-select-options">
  <ng-container *ngFor="let address of addresses; let i = index">
    <label [for]="address.id" class="address-radio-input-label">
      <span class="address-radio-input" [class.checked]="selectedAddress.value?.id === address.id"></span>

      <input
        hidden
        [id]="address.id"
        [value]="address.id"
        [formControl]="selectedAddress"
        (change)="onAddressSelect(address)"
        name="address-select"
        type="radio"
        class="form-check-input"
      />
      <app-details-item
        [isPrimary]="address.primary"
        [label]="getAddressLabel(i)"
        [value]="address?.addressLine1"
        [showPlaceholder]="false"
      >
        <ng-container>
          <div>{{ address?.addressLine2 }}</div>
          <div>{{ address?.city }}, {{ address?.state }}</div>
          <div>{{ address?.postalCode }} {{ address?.country }}</div>
        </ng-container>
      </app-details-item>
    </label>
  </ng-container>
</div>

<div class="address-select-footer">
  <button rktButton btnType="tertiary" title="Cancel" (click)="activeModal.close()">Cancel</button>
  <button rktButton title="Confirm" (click)="activeModal.close(this.selectedAddress.value)">Confirm</button>
</div>
