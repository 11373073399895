import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TransactionLimitItem } from '@shared/models';
import { NotificationService, TransactionLimitsService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  selector: 'app-business-account-transaction-limits',
  templateUrl: './business-account-transaction-limits.component.html',
  styleUrls: ['./business-account-transaction-limits.component.scss'],
})
export class BusinessAccountTransactionLimitsComponent implements OnInit, OnDestroy {
  public businessAccountTransactionLimits: TransactionLimitItem[] = [];

  public loading = true;

  private destroy$ = new Subject<void>();

  constructor(public limitsService: TransactionLimitsService, public notificationService: NotificationService) {}

  ngOnInit(): void {
    this.limitsService
      .getBusinessAccountsTransactionLimits()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: (response) => {
          this.businessAccountTransactionLimits = response || [];
        },
        error: (error) => {
          ErrorUtils.catchError('limitsService.getBusinessAccountsTransactionLimits error', error);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
