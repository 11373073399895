<h1 class="title">Submit Identity Verification</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form identity-verification">
  <form [formGroup]="identityVerificationForm">
    <rkt-form [form]="identityVerificationForm" [fields]="formFields" [model]="formModel"></rkt-form>
  </form>
</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="submit" (click)="onSubmit()" title="Submit" [disabled]="identityVerificationForm.invalid">Submit</button>
</div>
