import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BusinessAccount, LimitUpdateRequest, LimitUpdateResponse, TransactionLimitItem, TransactionLimitRawItem } from '@shared/models';
import { environment } from '@env';
import { BusinessAccountService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { CUSTOM_ERROR_MESSAGE } from '@shared/interceptors';
import { mapLimits } from './transaction-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class TransactionLimitsService {
  constructor(private backendService: BackendService, private businessAccountService: BusinessAccountService) {}

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  public getBusinessAccountsTransactionLimits(): Observable<TransactionLimitItem[]> {
    return this.businessAccountService.getSelectedBusinessAccount().pipe(
      switchMap((businessAccount) =>
        this.backendService
          .get<TransactionLimitRawItem[]>(
            `${environment.limitsDomainEndpoint}/business-accounts/${businessAccount!.id}/limits/utilisation?scopeType=${
              businessAccount!.parent ? 'PARENT_BA' : 'CHILD_BA'
            }&scopeId=${businessAccount!.id}`,
          )
          .pipe(
            map((response) => mapLimits(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getTransactionLimits(scopeId: string, scopeType: 'FA_KFA' | 'CUSTOMER'): Observable<TransactionLimitItem[]> {
    const queryParam = `?scopeType=${scopeType}&scopeId=${scopeId}`;

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<TransactionLimitRawItem[]>(
            `${environment.limitsDomainEndpoint}/business-accounts/${businessAccountId}/limits/utilisation${queryParam}`,
            { context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch Transaction Limits.') },
          )
          .pipe(
            map((response) => {
              const limits = response.filter((item) => item.scopeType === scopeType && item.scopeId === scopeId);
              return mapLimits(limits);
            }),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateTransactionLimits(req: LimitUpdateRequest[]): Observable<unknown> {
    if (req.length > 1) {
      const body: { [key: string]: { [amount: string]: number | null } } = {};
      req.forEach((item) => {
        body[item.id] = { amount: item.amount, count: null };
      });
      return this.getSelectedBusinessAccountId().pipe(
        switchMap((businessAccountId) =>
          this.backendService
            .update<LimitUpdateResponse[]>(`${environment.limitsDomainEndpoint}/business-accounts/${businessAccountId}/limits`, body)
            .pipe(
              map((response) => response),
              catchError((errorRes) => throwError(() => errorRes)),
            ),
        ),
      );
    } else {
      const id = req[0].id;
      const body = { amount: req[0].amount };
      return this.getSelectedBusinessAccountId().pipe(
        switchMap((businessAccountId) =>
          this.backendService
            .update<LimitUpdateResponse[]>(`${environment.limitsDomainEndpoint}/business-accounts/${businessAccountId}/limits/${id}`, body)
            .pipe(
              map((response) => response),
              catchError((errorRes) => throwError(() => errorRes)),
            ),
        ),
      );
    }
  }
}
