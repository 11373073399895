import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-document-file-preview-modal',
  templateUrl: './document-file-preview-modal.component.html',
  styleUrls: ['./document-file-preview-modal.component.scss'],
})
export class DocumentFilePreviewModalComponent {
  type = '';

  url = '';

  name = '';

  fileFormat!: 'JPG' | 'PNG' | 'PDF';

  constructor(public activeModal: ActiveModal) {}

  modalInitData({ type, url, name, fileFormat }: { url: string; type: string; name: string; fileFormat: 'JPG' | 'PNG' | 'PDF' }) {
    this.type = type;

    this.url = url;

    this.name = name;

    this.fileFormat = fileFormat;
  }
}
