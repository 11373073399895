import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { compact, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import {
  EnabledSolutionConfig,
  FinancialAccountListItem,
  LinxRequestMoveMoneyDetails,
  MoveHowAchDetails,
  MoveHowWireDetails,
  MoveWireDetails,
  PaymentReason,
  TransactionDirection,
  TransactionSolution,
} from '@shared/models';
import { achEntryTypeCodes } from '@shared/services';
import { isBankingHoliday, isTimeExpired } from '@shared/utils';
import { fromTransaction, TransactionSolutionActions } from '@shared/store';
import {
  AbstractMoveMoneyBase,
  ConfirmModalComponent,
  MoveAmountControlComponent,
  MoveHowAchDetailsComponent,
  MoveHowSelection,
  MoveHowWireDetailsComponent,
  MoveMoneyWrapperService,
  MoveNoteControlComponent,
  MoveReasonSelectionComponent,
  MoveRowType,
  MoveWireSelectionComponent,
} from '@shared/components';
import { LinxRequestMoveMoneyHowSelectionComponent } from '../linx-request-move-money-how-selection/linx-request-move-money-how-selection.component';

@Component({
  selector: 'app-linx-request-move-money',
  templateUrl: './linx-request-move-money.component.html',
  styleUrls: ['./linx-request-move-money.component.scss'],
  providers: [
    {
      provide: AbstractMoveMoneyBase,
      useClass: MoveMoneyWrapperService,
    },
  ],
})
export class LinxRequestMoveMoneyComponent implements OnChanges, OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() selectedMoveMoneyDetails?: LinxRequestMoveMoneyDetails;

  @Input() isTemplate = false;

  @Input() isEditTemplateMode = false;

  @Input() customerFinancialAccountType?: FinancialAccountListItem['type'] | undefined;

  @Output() continue = new EventEmitter<LinxRequestMoveMoneyDetails>();

  @Output() cleared = new EventEmitter();

  @Output() cancel = new EventEmitter();

  @ViewChild(LinxRequestMoveMoneyHowSelectionComponent) moveHowSelection!: LinxRequestMoveMoneyHowSelectionComponent;

  @ViewChild(MoveHowAchDetailsComponent) moveHowAchDetailsSelection!: MoveHowAchDetailsComponent;

  @ViewChild(MoveHowWireDetailsComponent) moveHowWireDetailsSelection!: MoveHowWireDetailsComponent;

  @ViewChild(MoveReasonSelectionComponent) reasonSelect!: MoveReasonSelectionComponent;

  @ViewChild(MoveAmountControlComponent) amountField!: MoveAmountControlComponent;

  @ViewChild(MoveWireSelectionComponent) wireDetailsSelection!: MoveWireSelectionComponent;

  @ViewChild(MoveNoteControlComponent) noteField!: MoveNoteControlComponent;

  enabledSolutionConfigs$ = this.store.select(fromTransaction.selectEnabledSolutionConfigs);

  debitAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('debit'));

  creditAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('credit'));

  submitLoader = false;

  isSubmitCompleted = false;

  transactionTimeZone?: string;

  sameDayAchSubmitDeadline?: string;

  sameDayWireSubmitDeadline?: string;

  isSameDayAchWindowDisabled = false;

  isSameDayWireWindowDisabled = false;

  isNextDayWireWindowDisabled = false;

  expandedRows?: MoveRowType[] = ['move-how'];

  showBusinessFinancialAccountSelection = true;

  protected destroy$ = new Subject<void>();

  get isSubmitDisabled(): boolean {
    const { moveHowType } = this.moveMoneyService.moveHow;
    const rows = [
      this.moveMoneyService.isRowHasValue('move-how'),
      moveHowType === 'ach' ? this.moveMoneyService.isRowHasValue('move-how-ach-details') : true,
      this.isTemplate && this.moveMoneyService.paymentReason ? true : this.moveMoneyService.isRowHasValue('move-reason-amount'),
      this.moveMoneyService.isRowHasValue('move-from'),
    ];

    return compact(rows).length !== rows.length;
  }

  get isDataUnchanged(): boolean {
    const { moveHowType } = this.moveMoneyService.moveHow;
    return (
      compact([
        !isEmpty(this.moveMoneyService.moveHow),
        moveHowType === 'ach' && !isEmpty(this.moveMoneyService.moveHowAchDetails),
        moveHowType === 'wire' && !isEmpty(!!this.moveMoneyService.wireDetails),
        !!this.moveMoneyService.paymentReason,
        !!this.moveMoneyService.moveAmount,
        !!this.moveMoneyService.selectedDebitFinancialAccount,
        !!this.moveMoneyService.note,
      ]).length === 0
    );
  }

  get achEntryTypeDisplayName(): string {
    const { moveAchEntryType } = this.moveMoneyService.moveHowAchDetails;

    return achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '';
  }

  get moveAchEntryType(): string {
    const { moveAchEntryType = '' } = this.moveMoneyService.moveHowAchDetails;
    return moveAchEntryType;
  }

  get isWireOrAch(): boolean {
    return this.moveMoneyService.moveHow.moveHowType === 'wire' || this.moveMoneyService.moveHow.moveHowType === 'ach';
  }

  get businessAccountSelectionLabel(): string {
    const { moveDirection } = this.moveMoneyService.moveHow;
    return this.customerFinancialAccountType === 'BANK' && moveDirection === 'pull' ? 'To Account' : 'From Account';
  }

  constructor(
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
    public moveMoneyService: AbstractMoveMoneyBase,
    private store: Store,
  ) {
    this.moveMoneyService.handleRowExpand('move-how');
  }

  ngOnChanges(): void {
    if (this.selectedMoveMoneyDetails && this.isEditTemplateMode) {
      const { selectedMoveItem, selectedMoveHowType } = this.selectedMoveMoneyDetails;

      this.moveMoneyService.moveHow = {
        moveDirection: selectedMoveItem as TransactionDirection,
        moveHowType: selectedMoveHowType as TransactionSolution,
      };

      const isACH = selectedMoveHowType === 'ach';
      const isWire = selectedMoveHowType === 'wire';
      const rows: MoveRowType[] = compact([
        'move-how',
        isACH ? 'move-how-ach-details' : undefined,
        isWire ? 'move-how-wire-details' : undefined,
        'move-reason-amount',
        'move-from',
      ]);
      this.expandedRows = rows;
    }
  }

  private setInitialDetails(): void {
    if (!this.selectedMoveMoneyDetails) return;
    const { paymentReasonId, paymentReasonName, selectedMoveHowType, wireDetails, achDetails } = this.selectedMoveMoneyDetails;

    const isACH = selectedMoveHowType === 'ach';
    const isWire = selectedMoveHowType === 'wire';

    if (isACH) {
      this.moveMoneyService.moveHowAchDetails.moveAchEntryType = 'PPD';
      this.moveMoneyService.moveHowAchDetails.rkorACHIndividualId = achDetails?.rkorACHIndividualId;
      this.moveMoneyService.moveHowAchDetails.moveAchType = 'NEXT_DAY';
      this.moveHowAchDetailsSelection.setInitialValues();
    }
    if (isWire) {
      this.moveMoneyService.moveHowWireDetails.moveWireType = 'NEXT_DAY';
      this.moveHowWireDetailsSelection.setInitialValues();

      this.moveMoneyService.paymentReason = {
        reason: paymentReasonName as string,
        id: paymentReasonId as string,
      };
      this.moveMoneyService.wireDetails = wireDetails as MoveWireDetails;
      this.wireDetailsSelection.setInitialValues();
    }
  }

  ngOnInit(): void {
    this.store.dispatch(TransactionSolutionActions.loadEnabledSolutions());
    this.enabledSolutionConfigs$.subscribe({ next: (config) => this.onConfigFetched(config) });
  }

  ngAfterViewInit(): void {
    if (this.isEditTemplateMode) {
      this.setInitialDetails();
    }
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onMoveHowSelect(moveHow: MoveHowSelection): void {
    this.moveMoneyService.moveHow = moveHow;
    const { moveDirection, moveHowType } = moveHow;

    if (moveDirection && moveHowType) {
      this.moveHowAchDetailsSelection?.setAchEntryTypeOptions();
      this.expandNotFilledRow();
    }
  }

  onMoveHowAchDetailsSelect(achDetails: MoveHowAchDetails): void {
    this.moveMoneyService.moveHowAchDetails = achDetails;
    this.expandNotFilledRow();
  }

  onMoveHowWireDetailsSelect(wireDetails: MoveHowWireDetails): void {
    this.moveMoneyService.moveHowWireDetails = wireDetails;
    this.expandNotFilledRow();
  }

  onMoveHowAchDetailsBlur(): void {
    if (this.moveMoneyService.isRowHasValue('move-how-ach-details')) {
      this.expandNotFilledRow();
    }
  }

  onPaymentReasonSelect(paymentReason: PaymentReason): void {
    this.moveMoneyService.paymentReason = paymentReason;

    this.onReasonAmountElBlur();
  }

  onReasonAmountElBlur(): void {
    if (this.isTemplate || this.moveMoneyService.isRowHasValue('move-reason-amount')) {
      this.expandNotFilledRow();
    }
  }

  onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedDebitFinancialAccount = financialAccount;
    this.moveMoneyService.debitFinancialAccountError = false;
    this.showBusinessFinancialAccountSelection = false;
    this.expandNotFilledRow();
  }

  editBusinessFinancialAccount(): void {
    this.showBusinessFinancialAccountSelection = true;
  }

  resetForm(): void {
    this.moveMoneyService.moveHow = {};
    this.moveHowSelection?.resetValue();
    this.onMoveHowReset();
  }

  onClearBtnClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    confirmModalRef.componentInstance.customText =
      'The data you have captured on this page will be lost. Are you sure you want to clear all information and selections?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.resetForm();
        }
      },
      () => false,
    );
  }

  expandNotFilledRow() {
    const isACH = this.moveMoneyService.moveHow.moveHowType === 'ach';
    const isWire = this.moveMoneyService.moveHow.moveHowType === 'wire';
    const rows: MoveRowType[] = compact([
      'move-how',
      isACH ? 'move-how-ach-details' : undefined,
      isWire ? 'move-how-wire-details' : undefined,
      'move-reason-amount',
      'move-from',
    ]);

    const row = rows.find((item) => {
      if (this.isTemplate && item === 'move-reason-amount' && this.moveMoneyService.paymentReason) return false;
      return !this.moveMoneyService.isRowHasValue(item);
    });
    if (row) {
      this.expandedRows?.push(row);
    }
    this.moveMoneyService.handleRowExpand(row);
  }

  onMoveHowReset(): void {
    this.reasonSelect?.resetValue();
    this.amountField?.resetValue();
    this.moveHowAchDetailsSelection?.resetValue();
    this.moveHowWireDetailsSelection?.resetValue();
    this.wireDetailsSelection?.resetValue();
    this.noteField?.resetValue();
    this.moveMoneyService.moveHowAchDetails = {};
    this.moveMoneyService.moveAmountError = undefined;
    this.moveMoneyService.selectedDebitFinancialAccount = undefined;
    this.showBusinessFinancialAccountSelection = true;
    this.cleared.emit();
    this.expandedRows = ['move-how'];
  }

  onConfigFetched(config: EnabledSolutionConfig[] | null): void {
    if (!config) return;

    this.transactionTimeZone = config[0]?.solutionConfig?.transactionWindows[0]?.timeZone;
    this.sameDayAchSubmitDeadline = this.getEndTime(config, 'ach');
    this.sameDayWireSubmitDeadline = this.getEndTime(config, 'wire');

    if (isBankingHoliday() || isTimeExpired(this.sameDayAchSubmitDeadline, this.transactionTimeZone)) {
      this.isSameDayAchWindowDisabled = true;
    }

    if (isBankingHoliday() || isTimeExpired(this.sameDayWireSubmitDeadline, this.transactionTimeZone)) {
      this.isSameDayWireWindowDisabled = true;
    }

    this.isNextDayWireWindowDisabled = !this.isSameDayWireWindowDisabled;
  }

  getEndTime(config: EnabledSolutionConfig[], solution: string): string {
    return config
      .filter((item) => item.solutionName === solution)[0]
      ?.solutionConfig?.transactionWindows.filter((window) => window.priority === 'SAME_DAY')[0]?.endTime;
  }

  onWireDetailsChange(wireDetails: MoveWireDetails): void {
    this.moveMoneyService.wireDetails = wireDetails;
  }

  public isRowExpanded(type: MoveRowType): boolean {
    const row = this.expandedRows?.find((item) => item === type);
    return row ? true : false;
  }

  moveMoneyReview() {
    const { moveDirection, moveHowType } = this.moveMoneyService.moveHow;
    const { reason, id } = this.moveMoneyService.paymentReason ?? {};

    const moveMoneyData = {
      selectedMoveItem: moveDirection,
      selectedMoveHowType: moveHowType,
      paymentReasonName: reason,
      paymentReasonId: id,
      moveAmountValue: this.moveMoneyService.moveAmount,
      selectedMoveFrom: this.moveMoneyService.selectedDebitFinancialAccount,
      note: this.moveMoneyService.note,
      achDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowDirectionDisplayValue: this.moveMoneyService.moveHowDirectionDisplayValue,
      moveHowSolutionDisplayValue: this.moveMoneyService.moveHowSolutionDisplayValue,
      moveHowSolutionIcon: this.moveMoneyService.moveHowSolutionIcon,
      achEntryTypeDisplayName: this.achEntryTypeDisplayName,
      moveAchEntryType: this.moveAchEntryType,
      moveHowAchDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowWireDetails: this.moveMoneyService.moveHowWireDetails,
      wireDetails: this.moveMoneyService.wireDetails,
    };

    this.continue.emit(moveMoneyData);
  }
}
