<rkt-table
  [rows]="financialAccountTransactionLimits"
  [columns]="columns"
  [limit]="10"
  (fetchData)="getFinancialAccountTransactionLimits($event)"
  (rowActivated)="viewFinancialAccountDetails($event)"
  [count]="totalElements"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
></rkt-table>
