<form [formGroup]="documentAcceptanceForm">
  <rkt-form [form]="documentAcceptanceForm" [fields]="documentAcceptanceFields" [model]="formModel"></rkt-form>

  <div class="linx-request-buttons">
    <button rktButton btnType="tertiary" class="linx-request-btn btn-clear" title="Clear" (click)="resetForm()">Clear</button>

    <button rktButton title="Continue" [disabled]="isContinueDisabled || documentAcceptanceForm.invalid" (click)="onClickContinue()">
      Continue
    </button>
  </div>
</form>
