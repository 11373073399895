import { formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

export const accountHolderTypeFields = [
  formRow([
    formRadioGroup({
      key: 'accountHolderType',
      label: 'Account Holder Type',
      props: {
        options: [
          { value: 'CUSTOMER', label: 'Customer' },
          { value: 'RECIPIENT', label: 'Recipient' },
        ],
      },
    }),
  ]),
];
