import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-accepted-document-file-preview-modal',
  templateUrl: './accepted-document-file-preview-modal.component.html',
  styleUrls: ['./accepted-document-file-preview-modal.component.scss'],
})
export class AcceptedDocumentFilePreviewModalComponent {
  downloadUrl = '';

  version = '';

  name = '';

  constructor(public activeModal: ActiveModal) {}

  modalInitData({ downloadUrl, version, name }: { downloadUrl: string; version: string; name: string }) {
    this.downloadUrl = downloadUrl;
    this.version = version;
    this.name = name;
  }

  handleError(error: Error) {
    this.activeModal.close({ ...error, type: 'error' });
  }
}
