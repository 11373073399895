import { createSelector } from '@ngrx/store';
import { compact, pick, some } from 'lodash-es';
import {
  CreateTransactionMltData,
  CreateTransactionSltData,
  MultiLegTransactionAchDetailsModel,
  MultiLegTransactionWireDetailsModel,
  bankAccountSubtypes,
  cardAccountSubtypes,
} from '@shared/models';
import { AmountOption } from '@shared/components';
import {
  BANK_HOLIDAYS_DATES,
  getConfigEndTimeForSolution,
  getTransactionWindowConfigForSolution,
  isBankingHoliday,
  isScheduledTimeExpired,
} from '@shared/utils';
import { transactionFormFeature, transactionFormState } from './transaction-form.state';

export const selectTransactionFormFinancialAccountsActiveFiltersCount = createSelector(
  transactionFormFeature.selectFinancialAccountsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter((value) => value !== 'BUSINESS_ACCOUNT,CUSTOMER,RECIPIENT').length;
  },
);

export const selectPaymentReasonOptions = (type: 'from' | 'to') => {
  if (type === 'to') {
    return createSelector(
      transactionFormFeature.selectPaymentReasons,
      transactionFormFeature.selectToAccountMethod,
      (paymentReasons, method) => {
        if (!method || !paymentReasons) {
          return [];
        }
        return paymentReasons.filter((reasonItem) => reasonItem.allowedSolutions.includes(method) && reasonItem.types.includes('PUSH'));
      },
    );
  } else if (type === 'from') {
    return createSelector(
      transactionFormFeature.selectPaymentReasons,
      transactionFormFeature.selectFromAccountMethod,
      transactionFormFeature.selectTransactionType,
      transactionFormFeature.selectFromAccount,
      transactionFormFeature.selectToAccount,
      (paymentReasons, method, transactionType, fromAccount, toAccount) => {
        if (!method || !paymentReasons || !fromAccount || !toAccount) {
          return [];
        }

        // ACH PULL - MLT or from [EXTERNAL:BANK] to [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK]
        if (
          transactionType === 'MLT' ||
          (bankAccountSubtypes.includes(fromAccount.subtype) &&
            ['EXTERNAL'].includes(fromAccount.category) &&
            bankAccountSubtypes.includes(toAccount.subtype) &&
            ['INTEGRATED', 'INTERNAL'].includes(toAccount.category))
        ) {
          return paymentReasons.filter((reasonItem) => reasonItem.allowedSolutions.includes(method) && reasonItem.types.includes('PULL'));
        }

        // ACH PUSH
        return paymentReasons.filter((reasonItem) => reasonItem.allowedSolutions.includes(method) && reasonItem.types.includes('PUSH'));
      },
    );
  } else {
    return createSelector(() => []);
  }
};

export const selectPaymentReasonByType = (type: 'from' | 'to') => {
  return createSelector(
    transactionFormFeature.selectFromAccountPaymentReason,
    transactionFormFeature.selectToAccountPaymentReason,
    (fromPaymentReason, toPaymentReason) => {
      if (!type || !(fromPaymentReason || toPaymentReason)) {
        return null;
      }

      if (type === 'from') {
        return fromPaymentReason;
      } else if (type === 'to') {
        return toPaymentReason;
      }

      return null;
    },
  );
};

export const selectUnavailableProcessingPriorities = (type: 'from' | 'to') => {
  return createSelector(
    transactionFormFeature.selectUnavailableProcessingPriorities,
    transactionFormFeature.selectFromAccountMethod,
    transactionFormFeature.selectToAccountMethod,
    selectDisabledScheduledProcessingPriorities,
    transactionFormFeature.selectFromAccountProcessingPriority,
    transactionFormFeature.selectFormAction,
    (
      unavailableProcessingPriorities,
      fromMethod,
      toMethod,
      disabledScheduledProcessingPriorities,
      fromAccountProcessingPriority,
      formAction,
    ) => {
      const disabledPriorities = disabledScheduledProcessingPriorities ?? unavailableProcessingPriorities;
      const isFromNextDayPriority = fromAccountProcessingPriority === 'NEXT_DAY';
      if ((type === 'from' && !fromMethod) || !disabledPriorities) {
        return { isSameDayDisabled: true, isNextDayDisabled: true };
      } else if ((type === 'to' && !toMethod) || !unavailableProcessingPriorities) {
        return { isSameDayDisabled: true, isNextDayDisabled: true };
      } else if (type === 'from' && fromMethod === 'ach') {
        return { isSameDayDisabled: disabledPriorities.achSameDay, isNextDayDisabled: false };
      } else if (type === 'to' && toMethod === 'ach') {
        return { isSameDayDisabled: disabledPriorities.achSameDay, isNextDayDisabled: false };
      } else if (type === 'from' && fromMethod === 'wire') {
        return {
          isSameDayDisabled: disabledPriorities.wireSameDay,
          isNextDayDisabled: disabledPriorities.wireNextDay,
        };
      } else if (type === 'to' && toMethod === 'wire') {
        return {
          isSameDayDisabled: disabledPriorities.wireSameDay,
          isNextDayDisabled: isFromNextDayPriority || formAction === 'EDIT_MLT' ? false : disabledPriorities.wireNextDay,
        };
      }

      return { isSameDayDisabled: true, isNextDayDisabled: true };
    },
  );
};

export const selectProcessingPriorityByType = (type: 'from' | 'to') => {
  return createSelector(
    transactionFormFeature.selectFromAccountProcessingPriority,
    transactionFormFeature.selectToAccountProcessingPriority,
    (fromAccountProcessingPriority, toAccountProcessingPriority) => {
      if (type === 'from' && fromAccountProcessingPriority) {
        return fromAccountProcessingPriority;
      } else if (type === 'to' && toAccountProcessingPriority) {
        return toAccountProcessingPriority;
      }

      return null;
    },
  );
};

export const selectDisabledScheduledProcessingPriorities = createSelector(
  transactionFormFeature.selectScheduler,
  transactionFormFeature.selectEnabledSolutionConfigs,
  (scheduler, enabledSolutionConfigs) => {
    if (!enabledSolutionConfigs) {
      return null;
    }

    if (!scheduler?.scheduleDateTime) {
      return null;
    }

    const transactionTimeZone = enabledSolutionConfigs[0]?.solutionConfig?.transactionWindows[0]?.timeZone;
    const sameDayAchSubmitDeadline = getConfigEndTimeForSolution(enabledSolutionConfigs, 'ach');
    const sameDayWireSubmitDeadline = getConfigEndTimeForSolution(enabledSolutionConfigs, 'wire');

    const isAchSameDayDisabled = sameDayAchSubmitDeadline
      ? isBankingHoliday(scheduler.scheduleDateTime) ||
        isScheduledTimeExpired(scheduler.scheduleDateTime, sameDayAchSubmitDeadline, transactionTimeZone)
      : true;
    const isWireSameDayDisabled = sameDayWireSubmitDeadline
      ? isBankingHoliday(scheduler.scheduleDateTime) ||
        isScheduledTimeExpired(scheduler.scheduleDateTime, sameDayWireSubmitDeadline, transactionTimeZone)
      : true;
    const isWireNextDayDisabled = false;

    return {
      achSameDay: isAchSameDayDisabled,
      wireSameDay: isWireSameDayDisabled,
      wireNextDay: isWireNextDayDisabled,
    };
  },
);

export const selectTransactionFormFromAccountPaymentReasonName = createSelector(
  transactionFormFeature.selectFromAccountPaymentReason,
  transactionFormFeature.selectPaymentReasons,
  (fromAccountPaymentReason, paymentReasons) => {
    if (!fromAccountPaymentReason || !paymentReasons?.length) {
      return;
    }

    return paymentReasons.find((item) => item.value === fromAccountPaymentReason)?.label;
  },
);

export const selectTransactionFormToAccountPaymentReasonName = createSelector(
  transactionFormFeature.selectToAccountPaymentReason,
  transactionFormFeature.selectPaymentReasons,
  (toAccountPaymentReason, paymentReasons) => {
    if (!toAccountPaymentReason || !paymentReasons?.length) {
      return;
    }

    return paymentReasons.find((item) => item.value === toAccountPaymentReason)?.label;
  },
);

export const selectEnabledSolutionsByType = (type: 'from' | 'to') => {
  if (!type) {
    return createSelector(() => transactionFormState.enabledSolutions);
  }

  return createSelector(
    transactionFormFeature.selectEnabledSolutions,
    transactionFormFeature.selectFromAccount,
    transactionFormFeature.selectToAccount,
    transactionFormFeature.selectTransactionType,
    transactionFormFeature.selectFormAction,
    (enabledSolutions, fromAccount, toAccount, transactionType, formAction) => {
      if (!fromAccount || !toAccount) {
        return transactionFormState.enabledSolutions;
      }

      /* EDIT SLT */
      if (formAction === 'EDIT_SLT') {
        return { ach: false, 'push-to-card': false, transfer: false, wire: false };
      }

      /**
         Inherit solution value from BE but disable by selected FA types

         MLT - multi-leg transaction
         SLT - single-leg transaction
         BA - business account FA
         C - customer FA
         BA-C - business account FA and customer FA
         EXTERNAL:BANK - bank subtypes ['CHECKING', 'SAVING']
         EXTERNAL:CARD - card subtypes ['CREDIT', 'DEBIT', 'GIFT', 'PREPAID']
        */

      /* MLT */
      if (transactionType === 'MLT') {
        if (type === 'from') {
          /* MLT - only ACH for From Account [EXTERNAL:BANK]*/
          return { ...enabledSolutions, 'push-to-card': false, transfer: false, wire: false };
        } else if (type === 'to' && bankAccountSubtypes.includes(toAccount.subtype)) {
          /* MLT - ACH and WIRE from [EXTERNAL:BANK] to [EXTERNAL:BANK] */
          return { ...enabledSolutions, 'push-to-card': false, transfer: false };
        } else if (type === 'to' && cardAccountSubtypes.includes(toAccount.subtype)) {
          /* MLT - P2C from [EXTERNAL:BANK] to [EXTERNAL:CARD] */
          return { ...enabledSolutions, ach: false, transfer: false, wire: false };
        }
      }

      /* SLT - ACH PULL from [EXTERNAL:BANK] to [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK] */
      if (
        bankAccountSubtypes.includes(fromAccount.subtype) &&
        ['EXTERNAL'].includes(fromAccount.category) &&
        bankAccountSubtypes.includes(toAccount.subtype) &&
        ['INTEGRATED', 'INTERNAL'].includes(toAccount.category)
      ) {
        return { ...enabledSolutions, 'push-to-card': false, transfer: false, wire: false };
      }

      /* SLT - ACH PUSH and WIRE from [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK] to [EXTERNAL:BANK]*/
      if (
        bankAccountSubtypes.includes(fromAccount.subtype) &&
        ['INTEGRATED', 'INTERNAL'].includes(fromAccount.category) &&
        bankAccountSubtypes.includes(toAccount.subtype) &&
        ['EXTERNAL'].includes(toAccount.category)
      ) {
        return { ...enabledSolutions, 'push-to-card': false, transfer: false };
      }

      /* SLT - P2C from [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK] to [EXTERNAL:CARD] */
      if (
        bankAccountSubtypes.includes(fromAccount.subtype) &&
        ['INTEGRATED', 'INTERNAL'].includes(fromAccount.category) &&
        cardAccountSubtypes.includes(toAccount.subtype) &&
        ['EXTERNAL'].includes(toAccount.category)
      ) {
        return { ...enabledSolutions, ach: false, transfer: false, wire: false };
      }

      /* SLT - TRANSFER from [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK] to [BA-C:INTERNAL:BANK, BA-C:INTEGRATED:BANK] */
      if (
        bankAccountSubtypes.includes(fromAccount.subtype) &&
        ['INTEGRATED', 'INTERNAL'].includes(fromAccount.category) &&
        bankAccountSubtypes.includes(toAccount.subtype) &&
        ['INTEGRATED', 'INTERNAL'].includes(toAccount.category)
      ) {
        return { ...enabledSolutions, ach: false, 'push-to-card': false, wire: false };
      }

      return enabledSolutions;
    },
  );
};

const selectIsCreateTransactionDebitLegNotFilled = createSelector(
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectFromAccountMethod,
  transactionFormFeature.selectFromAccountPaymentReason,
  transactionFormFeature.selectFromAccountAchDetails,
  transactionFormFeature.selectFromAccountWireDetails,
  transactionFormFeature.selectFromAccountProcessingPriority,
  transactionFormFeature.selectTransactionType,
  (fromAccount, fromMethod, fromPaymentReason, fromAchDetails, fromWireDetails, fromProcessingPriority, transactionType) => {
    const isFromAchDetailsNotSet = transactionType === 'SLT' && fromMethod === 'ach' ? !isAchDetailsFilled(fromAchDetails) : undefined;
    const isFromWireDetailsNotSet = fromMethod === 'wire' ? !isWireDetailsFilled(fromWireDetails) : undefined;
    const isFromProcessingPriorityNotSet = ['ach', 'wire'].includes(fromMethod!) ? !fromProcessingPriority : undefined;

    return some(
      compact([
        !fromAccount,
        !fromMethod,
        !fromPaymentReason,
        isFromAchDetailsNotSet,
        isFromWireDetailsNotSet,
        isFromProcessingPriorityNotSet,
      ]),
    );
  },
);

const selectIsCreateTransactionCreditLegNotFilled = createSelector(
  transactionFormFeature.selectTransactionType,
  transactionFormFeature.selectToAccount,
  transactionFormFeature.selectToAccountMethod,
  transactionFormFeature.selectToAccountPaymentReason,
  transactionFormFeature.selectToAccountAchDetails,
  transactionFormFeature.selectToAccountWireDetails,
  transactionFormFeature.selectToAccountProcessingPriority,
  (transactionType, toAccount, toMethod, toPaymentReason, toAchDetails, toWireDetails, toProcessingPriority) => {
    const isToMethodNotSet = transactionType === 'MLT' ? !toMethod : undefined;
    const isToPaymentReasonNotSet = transactionType === 'MLT' ? !toPaymentReason : undefined;
    const isToProcessingPriority = transactionType === 'MLT' && ['ach', 'wire'].includes(toMethod!) ? !toProcessingPriority : undefined;
    const isToAchDetailsNotSet = toMethod === 'ach' ? !isAchDetailsFilled(toAchDetails) : undefined;
    const isToWireDetailsNotSet = toMethod === 'wire' ? !isWireDetailsFilled(toWireDetails) : undefined;

    return some(
      compact([!toAccount, isToMethodNotSet, isToPaymentReasonNotSet, isToProcessingPriority, isToAchDetailsNotSet, isToWireDetailsNotSet]),
    );
  },
);

export const selectIsSchedulerNotFilled = createSelector(transactionFormFeature.selectScheduler, (scheduler) => {
  if (!scheduler) {
    return false;
  }

  return some(compact([!scheduler.scheduleDateTime, scheduler.isError, scheduler.recurrenceRule ? !scheduler.name : undefined]));
});

export const selectIsCreateTransactionDisabled = createSelector(
  transactionFormFeature.selectAmount,
  selectIsCreateTransactionDebitLegNotFilled,
  selectIsCreateTransactionCreditLegNotFilled,
  selectIsSchedulerNotFilled,

  (amount, isDebitLegNotFilled, isCreditLegNotFilled, isSchedulerNotFilled) => {
    return some(compact([!amount, Number(amount) === 0, isDebitLegNotFilled, isCreditLegNotFilled, isSchedulerNotFilled]));
  },
);

export const selectIsUpdateTransactionDisabled = createSelector(
  transactionFormFeature.selectAmount,
  selectIsCreateTransactionDebitLegNotFilled,
  selectIsCreateTransactionCreditLegNotFilled,
  (amount, isDebitLegNotFilled, isCreditLegNotFilled) => {
    return some(compact([!amount, Number(amount) === 0, isDebitLegNotFilled, isCreditLegNotFilled]));
  },
);

export const selectIsCreateTransactionUnchanged = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectAmountOption,
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectScheduler,
  transactionFormFeature.selectNote,

  (amountValue, amountOptionValue, fromAccountValue, schedulerValue, noteValue) => {
    const { amount, amountOption, fromAccount, scheduler, note } = transactionFormState ?? {};
    return (
      amountValue === amount &&
      amountOptionValue === amountOption &&
      fromAccountValue === fromAccount &&
      schedulerValue === scheduler &&
      noteValue === note
    );
  },
);

const isAchDetailsFilled = (achDetails?: MultiLegTransactionAchDetailsModel | null): boolean => {
  const { rkorACHEntryType, rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } = achDetails ?? {};

  if (!rkorACHEntryType) {
    return false;
  }

  switch (rkorACHEntryType) {
    case 'CIE':
    case 'PPD':
    case 'TEL':
      return !!rkorACHIndividualId;

    case 'ARC':
    case 'BOC':
    case 'RCK':
      return !!rkorACHCheckSerialNumber;

    case 'POP':
      return !!rkorACHCheckSerialNumber && !!rkorACHTerminalCity && !!rkorACHTerminalState;

    default:
      return true;
  }
};

const isWireDetailsFilled = (wireDetails?: MultiLegTransactionWireDetailsModel | null): boolean => {
  const { description } = wireDetails ?? {};

  return !!description;
};

export const selectMltCreateData = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectAmountOption,
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectToAccount,
  transactionFormFeature.selectToAccountPaymentReason,
  transactionFormFeature.selectFromAccountPaymentReason,
  transactionFormFeature.selectToAccountAchDetails,
  transactionFormFeature.selectToAccountWireDetails,
  transactionFormFeature.selectToAccountMethod,
  transactionFormFeature.selectFromAccountMethod,
  transactionFormFeature.selectFromAccountProcessingPriority,
  transactionFormFeature.selectToAccountProcessingPriority,
  (
    amount,
    amountOption,
    fromAccount,
    toAccount,
    toPaymentReasonId,
    fromPaymentReasonId,
    toAchDetails,
    toWireDetails,
    toMethod,
    fromMethod,
    fromProcessingPriority,
    toProcessingPriority,
  ) => {
    if (
      !amount ||
      !fromAccount ||
      !toAccount ||
      !toMethod ||
      !toPaymentReasonId ||
      (toMethod === 'wire' && !toWireDetails?.description) ||
      (toMethod === 'ach' && !isAchDetailsFilled(toAchDetails))
    ) {
      return;
    }

    return {
      currency: fromAccount.currency,
      debits: [
        {
          financialAccountId: fromAccount.id,
          amount: amountOption === 'EXACT_AMOUNT' ? amount : undefined,
          amountPercentage: amountOption === 'PERCENTAGE' ? amount : undefined,
          settlementPriority: fromProcessingPriority,
          paymentReasonId: fromPaymentReasonId,
          solution: fromMethod,
        },
      ],
      credits: [
        {
          financialAccountId: toAccount.id,
          solution: toMethod,
          paymentReasonId: toPaymentReasonId,
          amount: amountOption === 'EXACT_AMOUNT' ? amount : undefined,
          amountPercentage: amountOption === 'PERCENTAGE' ? 100 : undefined,
          settlementPriority: toProcessingPriority ?? (toMethod === 'push-to-card' ? 'IMMEDIATE' : undefined),
        },
      ],
      metadata: pick(toAchDetails, [
        'rkorACHEntryType',
        'rkorACHIndividualId',
        'rkorACHCheckSerialNumber',
        'rkorACHTerminalCity',
        'rkorACHTerminalState',
      ]),
      description: toWireDetails?.description,
      memo: toWireDetails?.memo,
    } as CreateTransactionMltData;
  },
);

export const selectCreateTransactionDebitLegData = createSelector(
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectFromAccountMethod,
  transactionFormFeature.selectFromAccountPaymentReason,
  transactionFormFeature.selectFromAccountAchDetails,
  transactionFormFeature.selectFromAccountWireDetails,
  transactionFormFeature.selectFromAccountProcessingPriority,
  transactionFormFeature.selectPaymentReasons,
  (fromAccount, fromMethod, fromPaymentReasonId, fromAchDetails, fromWireDetails, fromProcessingPriority, paymentReasons) => {
    return {
      fromAccount,
      fromMethod,
      fromPaymentReason: paymentReasons?.find((item) => item.value === fromPaymentReasonId)?.label,
      fromAchDetails,
      fromWireDetails,
      fromProcessingPriority,
      fromPaymentReasonId,
    };
  },
);

export const selectCreateTransactionCreditLegData = createSelector(
  transactionFormFeature.selectToAccount,
  transactionFormFeature.selectToAccountMethod,
  transactionFormFeature.selectToAccountPaymentReason,
  transactionFormFeature.selectToAccountAchDetails,
  transactionFormFeature.selectToAccountWireDetails,
  transactionFormFeature.selectToAccountProcessingPriority,
  transactionFormFeature.selectPaymentReasons,
  (toAccount, toMethod, toPaymentReasonId, toAchDetails, toWireDetails, toProcessingPriority, paymentReasons) => {
    return {
      toAccount,
      toMethod,
      toPaymentReason: paymentReasons?.find((item) => item.value === toPaymentReasonId)?.label,
      toAchDetails,
      toWireDetails,
      toProcessingPriority,
      toPaymentReasonId,
    };
  },
);

export const selectSchedulerData = createSelector(transactionFormFeature.selectScheduler, (scheduler) => {
  if (!scheduler) {
    return;
  }

  const { scheduleDateTime, recurrenceRule, name } = scheduler;

  return {
    startDateTime: scheduleDateTime!,
    recurrenceRule: recurrenceRule?.length ? recurrenceRule : undefined,
    name: name ? name : undefined,
  };
});

export const selectCreateTransactionReviewData = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectAmountOption,
  transactionFormFeature.selectCurrency,
  selectCreateTransactionDebitLegData,
  selectCreateTransactionCreditLegData,
  transactionFormFeature.selectScheduler,
  transactionFormFeature.selectTransactionType,
  transactionFormFeature.selectNote,
  transactionFormFeature.selectDeliveryOption,
  (amount, amountOption, currency, debitLeg, creditLeg, schedulerData, transactionType, note, deliveryOption) => {
    return {
      amount,
      amountOption,
      currency,
      transactionType,
      note,
      ...debitLeg,
      ...creditLeg,
      ...schedulerData,
      deliveryOption,
    };
  },
);

export const selectSltCreateData = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectAmountOption,
  selectCreateTransactionDebitLegData,
  selectCreateTransactionCreditLegData,
  (amount, amountOption, debitLeg, creditLeg) => {
    const { fromAccount, fromPaymentReasonId, fromMethod, fromProcessingPriority, fromWireDetails, fromAchDetails } = debitLeg ?? {};
    const { toAccount } = creditLeg ?? {};

    if (
      !amount ||
      !fromAccount ||
      !toAccount ||
      !fromPaymentReasonId ||
      !fromMethod ||
      (['ach', 'wire'].includes(fromMethod) && !fromProcessingPriority)
    ) {
      return;
    }

    const isRequest = bankAccountSubtypes.includes(fromAccount.subtype) && ['EXTERNAL'].includes(fromAccount.category);

    const sltCreateData: CreateTransactionSltData = {
      debitFinancialAccountId: fromAccount.id,
      creditFinancialAccountId: toAccount.id,
      paymentReasonId: fromPaymentReasonId,
      amount: amountOption === 'EXACT_AMOUNT' ? amount : undefined,
      debitBalancePercent: amountOption === 'PERCENTAGE' ? amount : undefined,
      currency: fromAccount.currency,
      solution: fromMethod,
      transactionType: isRequest ? 'REQUEST' : 'SEND',
      settlementPriority: fromProcessingPriority ?? (['push-to-card', 'transfer'].includes(fromMethod) ? 'IMMEDIATE' : undefined),
    };

    if (fromMethod === 'ach') {
      sltCreateData.metadata = pick(fromAchDetails, [
        'rkorACHEntryType',
        'rkorACHIndividualId',
        'rkorACHCheckSerialNumber',
        'rkorACHTerminalCity',
        'rkorACHTerminalState',
      ]);
    }

    if (fromMethod === 'wire') {
      sltCreateData.description = fromWireDetails?.description;
      sltCreateData.memo = fromWireDetails?.memo;
    }

    return sltCreateData;
  },
);

export const selectToAccountForWireWithoutAddress = createSelector(
  transactionFormFeature.selectFromAccountMethod,
  transactionFormFeature.selectToAccountMethod,
  transactionFormFeature.selectToAccount,
  (fromMethod, toMethod, toAccount) => {
    if ((fromMethod === 'wire' || toMethod === 'wire') && !toAccount?.bankAccount?.billingAddress) {
      return toAccount;
    }

    return undefined;
  },
);

export const selectCancelReviewData = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectCurrency,
  transactionFormFeature.selectNote,
  transactionFormFeature.selectToAccount,
  (amount, currency, note, toAccount) => ({ amount, currency, note, toAccount }),
);

export const selectAmountOptionItems = createSelector(
  transactionFormFeature.selectScheduler,
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectAmount,
  (scheduler, fromAccount): AmountOption[] => {
    const isPercentageAvailable = fromAccount && ['INTERNAL', 'INTEGRATED'].includes(fromAccount.category) && scheduler;

    return [
      { label: 'Amount', value: 'EXACT_AMOUNT' },
      {
        label: 'Percentage',
        value: 'PERCENTAGE',
        tooltip: 'Percentage of the balance to use as the scheduled transaction amount',
        isDisabled: !isPercentageAvailable,
      },
      /*
        Pending BE implementation
        {
          label: 'Exact amount left',
          value: 'EXACT_AMOUNT_LEFT',
          tooltip: 'Keep the amount entered and move the rest of the balance from the account',
          isDisabled: true,
        }
      */
    ];
  },
);

export const selectDisabledSameDayScheduleDayTime = createSelector(
  transactionFormFeature.selectFromAccountProcessingPriority,
  transactionFormFeature.selectFromAccountMethod,
  transactionFormFeature.selectScheduler,
  transactionFormFeature.selectEnabledSolutionConfigs,
  (fromAccountProcessingPriority, fromMethod, scheduler, enabledSolutionConfigs) => {
    const sameDayAchSubmitDeadline = enabledSolutionConfigs
      ? getTransactionWindowConfigForSolution(enabledSolutionConfigs, 'ach', 'SAME_DAY')
      : undefined;
    const sameDayWireSubmitDeadline = enabledSolutionConfigs
      ? getTransactionWindowConfigForSolution(enabledSolutionConfigs, 'wire', 'SAME_DAY')
      : undefined;
    const nextDayWireSubmitDeadline = enabledSolutionConfigs
      ? getTransactionWindowConfigForSolution(enabledSolutionConfigs, 'wire', 'NEXT_DAY')
      : undefined;

    if (!fromAccountProcessingPriority || fromAccountProcessingPriority === 'NEXT_DAY') {
      const isScheduledForBankingHoliday = scheduler?.scheduleDateTime ? isBankingHoliday(scheduler.scheduleDateTime) : false;
      return {
        disabledDates: undefined,
        disabledWeekdays: undefined,
        minTime:
          (!isScheduledForBankingHoliday && fromMethod === 'wire' ? nextDayWireSubmitDeadline?.startTime?.slice(0, -3) : undefined) ??
          '00:00',
        maxTime: (fromMethod === 'wire' ? nextDayWireSubmitDeadline?.endTime?.slice(0, -3) : undefined) ?? '23:59',
      };
    }

    return {
      disabledDates: BANK_HOLIDAYS_DATES,
      disabledWeekdays: [6, 7],
      minTime:
        (fromMethod === 'wire' ? sameDayWireSubmitDeadline?.startTime?.slice(0, -3) : undefined) ??
        (fromMethod === 'ach' ? sameDayAchSubmitDeadline?.startTime?.slice(0, -3) : undefined) ??
        '00:00',
      maxTime:
        (fromMethod === 'wire' ? sameDayWireSubmitDeadline?.endTime?.slice(0, -3) : undefined) ??
        (fromMethod === 'ach' ? sameDayAchSubmitDeadline?.endTime?.slice(0, -3) : undefined) ??
        '23:59',
    };
  },
);

export const selectMltUpdateData = createSelector(
  transactionFormFeature.selectAmount,
  transactionFormFeature.selectAmountOption,
  transactionFormFeature.selectFromAccount,
  transactionFormFeature.selectToAccount,
  transactionFormFeature.selectToAccountPaymentReason,
  transactionFormFeature.selectToAccountAchDetails,
  transactionFormFeature.selectToAccountWireDetails,
  transactionFormFeature.selectToAccountMethod,
  transactionFormFeature.selectFromAccountProcessingPriority,
  transactionFormFeature.selectToAccountProcessingPriority,
  (
    amount,
    amountOption,
    fromAccount,
    toAccount,
    toPaymentReasonId,
    toAchDetails,
    toWireDetails,
    toMethod,
    fromProcessingPriority,
    toProcessingPriority,
  ) => {
    if (
      !amount ||
      !fromAccount ||
      !toAccount ||
      !toMethod ||
      !toPaymentReasonId ||
      (toMethod === 'wire' && !toWireDetails?.description) ||
      (toMethod === 'ach' && !isAchDetailsFilled(toAchDetails))
    ) {
      return;
    }

    return {
      updateMultiLegSpec: {
        currency: fromAccount.currency,
        debits: [
          {
            financialAccountId: fromAccount.id,
            amount: amountOption === 'EXACT_AMOUNT' ? amount : undefined,
            amountPercentage: amountOption === 'PERCENTAGE' ? amount : undefined,
            settlementPriority: fromProcessingPriority ?? undefined,
            solution: 'ach',
            paymentReasonId: 'funds_transfer',
          },
        ],
        credits: [
          {
            financialAccountId: toAccount.id,
            solution: toMethod,
            paymentReasonId: toPaymentReasonId,
            amount: amountOption === 'EXACT_AMOUNT' ? amount : undefined,
            amountPercentage: amountOption === 'PERCENTAGE' ? 100 : undefined,
            settlementPriority: toProcessingPriority ?? (toMethod === 'push-to-card' ? 'IMMEDIATE' : undefined),
          },
        ],
        metadata: pick(toAchDetails, [
          'rkorACHEntryType',
          'rkorACHIndividualId',
          'rkorACHCheckSerialNumber',
          'rkorACHTerminalCity',
          'rkorACHTerminalState',
        ]),
        description: toWireDetails?.description,
        memo: toWireDetails?.memo,
      } as CreateTransactionMltData,
    };
  },
);
