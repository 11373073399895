import { inject, Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env';
import {
  AISearchResultsRaw,
  BusinessAccount,
  Report,
  ReportExtractType,
  ReportList,
  ReportListRaw,
  ReportRaw,
  RequestPageParams,
  SupersetAuthResponse,
} from '@shared/models';
import { constants, MESSAGE } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { APP_ENV_CONFIG, NotificationService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { ErrorUtils } from '@shared/utils';
import { endOfMonth, startOfMonth } from 'date-fns';
import { isUndefined } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  envConfig = inject(APP_ENV_CONFIG);

  constructor(private backendService: BackendService, private notificationService: NotificationService) {}

  public getReports(
    activeBusinessAccountId: BusinessAccount['id'],
    { page, size, sortParams, activeFilters }: RequestPageParams,
  ): Observable<ReportList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    params = params.append('businessAccountId', activeBusinessAccountId);

    if (page) {
      params = params.append('page', page);
    }

    if (activeFilters) {
      const { extractType, yearFrom, monthFrom, yearTo, monthTo, financialAccountId } = activeFilters;
      if (extractType) {
        params = params.append('extractType', extractType);
      }

      if (financialAccountId) {
        params = params.append('financialAccountId', financialAccountId);
      }

      if (yearFrom && !isUndefined(monthFrom)) {
        params = params.append(
          'extractCreatedDateFrom',
          startOfMonth(new Date(parseInt(yearFrom, 10), parseInt(monthFrom, 10))).toISOString(),
        );
      }

      if (yearTo && !isUndefined(monthTo)) {
        params = params.append('extractCreatedDateTo', endOfMonth(new Date(parseInt(yearTo, 10), parseInt(monthTo, 10))).toISOString());
      }
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.backendService.get<ReportListRaw>(`${environment.insightsEndpoint}/reports`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response || {};

        return {
          items: content?.map((item) => ReportService.mapReport(item)),
          totalElements,
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  downloadReport(id: string) {
    return this.backendService.get<ReportRaw>(`${environment.insightsEndpoint}/reports/${id}`).subscribe({
      next: (report) => {
        if (report.signedJsonUrl) {
          window.open(report.signedJsonUrl, '_self');
        } else {
          this.notificationService.displayError('There is no download link for this report', 'Error');
        }
      },
      error: (error) => {
        this.notificationService.displayError('We are unable to complete this request. Please contact your administrator.', 'Error');
        ErrorUtils.catchError('reportService.downloadReport error', error);
      },
    });
  }

  getReportTypes(): Observable<ReportExtractType[]> {
    return this.backendService.get<ReportExtractType[]>(`${environment.insightsEndpoint}/reports/types`).pipe(
      map((reportTypes) => {
        return reportTypes?.map((reportType) => ({
          ...reportType,
          value: reportType?.id,
        }));
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getAISearchResults(search?: string): Observable<AISearchResultsRaw> {
    if (!search) {
      return of({});
    }
    return this.backendService.post<AISearchResultsRaw>(`${environment.insightsEndpoint}/english-to-insights`, { query: search }).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  initializeSuperset(): Observable<string> {
    return this.backendService
      .postExternalPath<SupersetAuthResponse>('https://insights.dev.rocketkor-nonprod.net/loginapi/', {})
      .pipe(catchError(() => throwError(() => new Error(MESSAGE.AUTH_PROVIDER_VERIFICATION_ERROR))))
      .pipe(
        map((response) => response?.embedded_url),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  fetchSuperSetDashboardURL(): Observable<SupersetAuthResponse> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('dashboard_name', 'Insights Dashboard');

    return this.backendService
      .getExternalPath<SupersetAuthResponse>('https://insights.dev.rocketkor-nonprod.net/api/dashboards/search', { params })
      .pipe(catchError(() => throwError(() => new Error(MESSAGE.AUTH_PROVIDER_VERIFICATION_ERROR))))
      .pipe(
        switchMap((response) => {
          return this.initializeSuperset().pipe(
            map(() => response),
            catchError((error) => throwError(() => new Error(error.message))),
          );
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  private static mapReport(report: ReportRaw): Report {
    return {
      ...report,
      downloadReportBtn: report.statementPath ? 'downloadReport' : undefined,
    };
  }
}
