import { createAction, props } from '@ngrx/store';

export namespace MessagesActions {
  export const displayError = createAction(
    '[Message] Display Error Toast',
    props<{
      message: string;
      title?: string;
    }>(),
  );

  export const displaySuccess = createAction(
    '[Message] Display Success Toast',
    props<{
      message: string;
      title?: string;
    }>(),
  );

  export const displayWarning = createAction(
    '[Message] Display Warning Toast',
    props<{
      message: string;
      title?: string;
    }>(),
  );
}
