<rkt-table
  [rows]="(statements$ | async)!"
  [columns]="columns"
  [count]="(totalElements$ | async)!"
  [loadingIndicator]="(loading$ | async)!"
  [externalPaging]="false"
  [messages]="{ emptyMessage: 'No Statements found.' }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
  [isInteractive]="false"
  [isClickable]="false"
  (colButtonClicked)="onColButtonClick($event)"
></rkt-table>
