import { Pipe, PipeTransform } from '@angular/core';
import { validate as uuidValidate } from 'uuid';

@Pipe({
  name: 'uuidFormat',
})
export class UuidFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!uuidValidate(value) || !value) {
      return value;
    }

    return value.split('-')[0]?.toUpperCase();
  }
}
