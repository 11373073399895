<div class="filter-title">
  <div class="filter-name-wrapper" (click)="toggleFilter()">
    <rkt-icon *ngIf="isCollapsable" class="filter-toggler" [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"></rkt-icon>
    <div class="filter-name">{{ filterName }}</div>
  </div>
  <button
    *ngIf="selectedValue && selectedValue !== defaultValue"
    rktButton
    btnType="danger"
    class="filter-reset-btn"
    (click)="changed.emit()"
  >
    Reset
  </button>
</div>

<ng-container *ngIf="!isCollapsed">
  <div *ngFor="let option of options; let i = index" class="rkt-form-radio">
    <label class="rkt-form-radio-label" [for]="id + '_' + i" [class.disabled]="option.disabled">
      <input
        hidden
        type="radio"
        [id]="id + '_' + i"
        class="rkt-form-radio-input"
        [name]="id"
        [attr.value]="option.value"
        [(ngModel)]="selectedValue"
        (change)="changed.emit(option.value)"
        [value]="option.value"
        [attr.disabled]="option.disabled ? true : null"
      />
      <div class="rkt-form-radio-icon"></div>

      <div class="rkt-form-radio-value">
        {{ option.label }}
      </div>
    </label>
  </div>
</ng-container>
