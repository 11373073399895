import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { FinancialAccountDetails, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { CardReissueModalComponent } from '@shared/components';

@Component({
  selector: 'app-card-account-details',
  templateUrl: './card-account-details.component.html',
  styleUrls: ['./card-account-details.component.scss'],
})
export class CardAccountDetailsComponent {
  pageClassPrefix = 'card-details-';

  cardAccount$ = this.store.select(fromFinancialAccount.selectCardAccountDetails);

  cardAccountPageContext?: TemplateContext<FinancialAccountDetails>;

  constructor(private store: Store, private modalService: ModalService) {}

  onCardReissueClick(financialAccountId: string) {
    const cardReissueModalRef = this.modalService.open(CardReissueModalComponent, {
      className: 'entity-form-modal',
    });

    cardReissueModalRef.componentInstance.modalInitData({ financialAccountId });

    cardReissueModalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(CardAccountActions.loadCardAccountDetails());
        }
      },
      () => false,
    );
  }
}
