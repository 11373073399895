<div class="modal-header-block title">
  <h2>{{ isEdit ? 'Edit' : 'Add' }} a Restriction</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="details-page-edit-form">
  <form [formGroup]="restrictionForm">
    <rkt-form [form]="restrictionForm" [fields]="restrictionFields" [model]="formModel"></rkt-form>
  </form>

  <div class="separator-line"></div>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>

    <button rktButton type="submit" (click)="onSubmit()" [disabled]="loading || !restrictionForm.valid" [title]="isEdit ? 'Save' : 'Add'">
      {{ isEdit ? 'Save' : 'Add' }}
    </button>
  </div>
</div>
