import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '@shared/services';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
})
export class GlobalLoaderComponent {
  loading$: BehaviorSubject<boolean>;

  constructor(private loaderService: LoaderService) {
    this.loading$ = this.loaderService.isLoading$;
  }
}
