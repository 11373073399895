import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { differenceInMilliseconds, format, isWeekend, parse, set } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

/**
 * US bank holidays as defined by the Federal Reserve
 * https://www.chicagofed.org/utilities/about-us/bank-holidays
 */
const BANK_HOLIDAYS = [
  '2023-12-25',
  // 2024
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-10-14',
  '2024-11-11',
  '2024-11-28',
  '2024-12-25',
  // 2025
  '2025-01-01',
  '2025-01-20',
  '2025-02-17',
  '2025-05-26',
  '2025-06-19',
  '2025-07-04',
  '2025-09-01',
  '2025-10-13',
  '2025-11-11',
  '2025-11-27',
  '2025-12-25',
  // 2026
  '2026-01-01',
  '2026-01-19',
  '2026-02-16',
  '2026-05-25',
  '2026-06-19',
  '2026-07-04',
  '2026-09-07',
  '2026-10-12',
  '2026-11-11',
  '2026-11-26',
  '2026-12-25',
  // 2027
  '2027-01-01',
  '2027-01-18',
  '2027-02-15',
  '2027-05-31',
  '2027-06-19',
  '2027-07-04',
  '2027-09-06',
  '2027-10-11',
  '2027-11-11',
  '2027-11-25',
  '2027-12-25',
];

export const BANK_HOLIDAYS_DATES = BANK_HOLIDAYS.map((date) => {
  const dateParsed = date.split('-');
  return { year: Number(dateParsed[0]), month: Number(dateParsed[1]), day: Number(dateParsed[2]) };
});

interface TimeObject {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

const getParsedTime = (time: string): TimeObject => {
  const parsedTime = time.split(':');
  return {
    hours: parseInt(parsedTime[0], 10),
    minutes: parseInt(parsedTime[1], 10),
    seconds: parseInt(parsedTime[2], 10),
    milliseconds: parseInt(parsedTime[3], 10) || 0,
  };
};

export const isTimeExpired = (time?: string, timeZone?: string): boolean | null => {
  if (!time) {
    return null;
  }

  const estDate = utcToZonedTime(new Date(), timeZone ? timeZone : 'America/New_York');
  const targetDate = set(estDate, getParsedTime(time));
  return differenceInMilliseconds(estDate, targetDate) > 0;
};

export const isBankingHoliday = (serverDateTime?: string): boolean => {
  const currentDate = serverDateTime ? new Date(serverDateTime) : new Date();
  return isWeekend(currentDate) || BANK_HOLIDAYS.includes(format(currentDate, 'yyyy-MM-dd'));
};

export const dateToObject = (value: string, delimiter: string) => {
  if (!value) {
    return null;
  }

  const date = value.split(delimiter);
  return {
    day: parseInt(date[0], 10),
    month: parseInt(date[1], 10),
    year: parseInt(date[2], 10),
  };
};

export const formatDate = (date: number | Date) => {
  const dateFormatted = format(date, 'dd MM yyyy').split(' ');

  return NgbDate.from({
    day: parseInt(dateFormatted[0], 10),
    month: parseInt(dateFormatted[1], 10),
    year: parseInt(dateFormatted[2], 10),
  });
};

export const systemTimeZone: string = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

export const formatDateTimeToUTC = ({
  date,
  time,
  dateInputFormat = 'MM/dd/yyyy',
  timezone = systemTimeZone,
}: {
  date?: string;
  time?: string;
  dateInputFormat?: string;
  timezone?: string;
}): string | undefined => {
  if (!date || !time) {
    return;
  }
  return zonedTimeToUtc(`${format(parse(date, dateInputFormat, new Date()), 'yyyy-MM-dd')} ${time}`, timezone).toISOString();
};

export const isTimeExpiredAtTimeZone = (time: string, timeZone: string): boolean => {
  const estDate = utcToZonedTime(new Date(), timeZone);
  const targetDate = set(estDate, getParsedTime(time));
  return differenceInMilliseconds(estDate, targetDate) > 0;
};

export const isScheduledTimeExpired = (time: string, deadlineTime: string, timeZone: string): boolean => {
  const estDate = utcToZonedTime(new Date(time), timeZone);
  const targetDate = set(estDate, getParsedTime(deadlineTime));
  return differenceInMilliseconds(estDate, targetDate) > 0;
};
