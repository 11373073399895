import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Recipient } from '@shared/models';

export type RecipientDetailsAction = 'SUSPEND' | 'UNSUSPEND' | 'REMOVE';

@Component({
  selector: 'app-recipient-details-header',
  templateUrl: './recipient-details-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientDetailsHeaderComponent {
  @Input() recipient!: Recipient;

  @Input() isControlsDisabled = true;

  @Output() btnClicked = new EventEmitter<RecipientDetailsAction>();
}
