<h1 class="title">Add {{ accountHolderTypeName }}</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form">
  <form [formGroup]="entityTypeForm">
    <rkt-form
      [hidden]="accountHolderTypes.length === 1"
      [form]="entityTypeForm"
      (modelChange)="onAccountHolderChange($event)"
      [fields]="accountHolderTypeFields"
      [model]="formModel"
    ></rkt-form>

    <div class="form-step">
      <div class="step-number">1</div>
      <div class="step-name">{{ accountHolderTypeName }} Details</div>
    </div>

    <rkt-form
      [hidden]="!isCustomerAvailable || !isInternalAvailable"
      [form]="entityTypeForm"
      (modelChange)="onAccountCategoryChange($event)"
      [fields]="accountCategoryFields"
      [model]="formModel"
    ></rkt-form>
  </form>

  <form [formGroup]="entityRecipientForm">
    <rkt-form
      [hidden]="formModel.accountHolderType !== 'RECIPIENT'"
      [form]="entityRecipientForm"
      [fields]="recipientCreateFields"
      [model]="formModel"
    ></rkt-form>
  </form>

  <form [formGroup]="entityCustomerForm">
    <rkt-form
      [hidden]="formModel.accountHolderType !== 'CUSTOMER'"
      [form]="entityCustomerForm"
      [fields]="customerCreateFields"
      [model]="formModel"
    ></rkt-form>
  </form>

  <div class="note-container">
    <app-note-form-item [errors]="note.errors" [value]="note.value">
      <textarea
        class="note-field"
        #noteField
        (blur)="onNoteFieldBlur()"
        name="new-note"
        id="new-note"
        [formControl]="note"
        maxlength="1000"
      ></textarea>
    </app-note-form-item>
  </div>

  <div class="separator-line"></div>

  <div class="form-step">
    <div class="step-number">2</div>
    <div class="step-name">Financial Account Details</div>
  </div>

  <form [formGroup]="cardForm">
    <rkt-form
      [form]="cardForm"
      [fields]="financialAccountTypeFields"
      (modelChange)="onFinancialAccountTypeChanged()"
      [model]="formModel"
    ></rkt-form>

    <rkt-form class="name-field" [form]="cardForm" [fields]="financialAccountFields" [model]="formModel"></rkt-form>
  </form>

  <form [formGroup]="cardInfoForm">
    <div [hidden]="isInternal || formModel.cardOrBankAccount === 'bankAccount'">
      <div class="form-title">Card Info</div>

      <rkt-form (modelChange)="onCardChange()" [form]="cardInfoForm" [fields]="cardFields" [model]="formModel"></rkt-form>
    </div>
  </form>

  <div [hidden]="isInternal || formModel.cardOrBankAccount === 'bankAccount'">
    <form [formGroup]="cardAddressForm">
      <div class="form-title">Billing Info</div>

      <rkt-form [form]="cardAddressForm" [fields]="addressFields" [model]="formModel"></rkt-form>
    </form>
  </div>

  <form [formGroup]="bankForm">
    <div *ngIf="isInternal">
      <app-form-select-field
        label="Parent Financial Account"
        placeholder="Select a Parent Account"
        [required]="true"
        [items]="parentFinancialAccountListItems"
        [loading]="parentFinancialAccountListLoading"
        (changed)="onParentFinancialAccountSelect($event)"
        (scrolledToEnd)="onParentFinancialAccountSelectScrollToEnd()"
      ></app-form-select-field>

      <rkt-form [form]="bankForm" [fields]="internalAccountFields" [model]="formModel"></rkt-form>
    </div>

    <div [hidden]="isInternal || formModel.cardOrBankAccount === 'card'">
      <div class="form-title">Banking Info</div>

      <rkt-form (modelChange)="onAccountNumberChange()" [form]="bankForm" [fields]="bankFields" [model]="formModel"></rkt-form>
    </div>
  </form>

  <form [formGroup]="bankAddressForm" [hidden]="isInternal || formModel.cardOrBankAccount === 'card'">
    <div class="form-title">Billing Info</div>

    <div class="bank-account-address-description">
      *Plan to use a <span class="solution-name">wire transfer</span> on this account? Enter the address below, as a wire transfer requires
      an address to proceed.
    </div>

    <rkt-form [form]="bankAddressForm" [fields]="addressFields" [model]="formModel"></rkt-form>
  </form>

  <div *ngIf="formError" class="details-page-edit-form-error">{{ formError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="
        formLoading ||
        !entityTypeForm.valid ||
        (isRecipient && !entityRecipientForm.valid) ||
        (isCustomer && !entityCustomerForm.valid) ||
        (isBank && !isInternal && !bankForm.valid) ||
        (isCard && !cardForm.valid) ||
        (isCard && !cardInfoForm.valid) ||
        (isCard && !cardAddressForm.valid) ||
        (isBank && !bankAddressForm.valid) ||
        (isInternal && !parentFinancialAccount)
      "
      [title]="'Add ' + accountHolderTypeName"
    >
      Add
    </button>
  </div>
</div>
