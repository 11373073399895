import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ErrorUtils } from '@shared/utils';
import { BeneficiaryRequestParams } from '@shared/models';
import { BusinessAccountService, CustomerService, NotificationService } from '@shared/services';
import { BeneficiaryEditFormComponent, BeneficiaryReviewModalComponent } from '@shared/components';
import { MESSAGE } from '@shared/constants';

@Component({
  selector: 'app-beneficiary-add',
  templateUrl: 'beneficiary-add.component.html',
})
export class BeneficiaryAddComponent {
  @Input() holderId!: string;
  @Input() loading = true;
  @Input() isEmpty = true;
  @Input() refetchBeneficiaryList!: () => void;

  private destroy$ = new Subject<void>();

  constructor(
    private customerService: CustomerService,
    private businessAccountService: BusinessAccountService,
    private modalService: ModalService,
    private notificationService: NotificationService,
  ) {}

  onAddBeneficiaryBtnClick(holderId?: string): void {
    this.onAddBeneficiaryAction({ action: 'ADD', holderId });
  }

  onAddBeneficiaryAction({
    action,
    holderId,
    beneficiaryData,
    errorMessage,
  }: {
    action: 'ADD' | 'EDIT' | 'REVIEW' | 'SAVE';
    holderId?: string;
    beneficiaryData?: BeneficiaryRequestParams;
    errorMessage?: string;
  }): void {
    switch (action) {
      case 'ADD':
      case 'EDIT':
        this.addBeneficiaryStep({ holderId: holderId, beneficiaryData, errorMessage });
        break;

      case 'REVIEW':
        this.addBeneficiaryReviewStep({ holderId: holderId, beneficiaryData: beneficiaryData! });
        break;

      case 'SAVE':
        if (holderId) {
          this.createCustomerBeneficiary({ holderId: holderId, beneficiaryData: beneficiaryData! });
        } else {
          this.createBusinessBeneficiary(beneficiaryData!);
        }
        break;

      default:
        this.refetchBeneficiaryList();
        break;
    }
  }

  addBeneficiaryStep({
    holderId,
    beneficiaryData,
    errorMessage,
  }: {
    holderId?: string;
    beneficiaryData?: BeneficiaryRequestParams;
    errorMessage?: string;
  }) {
    const addBeneficiaryModalRef = this.modalService.open(BeneficiaryEditFormComponent, {
      className: 'entity-form-modal',
    });

    addBeneficiaryModalRef.componentInstance.modalInitData({ holderId, beneficiaryData, errorMessage });

    addBeneficiaryModalRef.result.then(
      (result) => {
        this.onAddBeneficiaryAction({ action: result?.action, holderId, beneficiaryData: result?.beneficiaryData });
      },
      () => {
        this.refetchBeneficiaryList();
      },
    );
  }

  addBeneficiaryReviewStep({ holderId, beneficiaryData }: { holderId?: string; beneficiaryData: BeneficiaryRequestParams }) {
    const accountCreateReviewModalRef = this.modalService.open(BeneficiaryReviewModalComponent, {
      className: 'entity-form-modal entity-review-modal',
    });

    accountCreateReviewModalRef.componentInstance.modalInitData({ customerId: holderId, beneficiaryData });

    accountCreateReviewModalRef.result.then(
      (result) => {
        this.onAddBeneficiaryAction({ action: result?.action, holderId, beneficiaryData });
      },
      () => {
        this.refetchBeneficiaryList();
      },
    );
  }

  createCustomerBeneficiary({ holderId, beneficiaryData }: { holderId: string; beneficiaryData: BeneficiaryRequestParams }) {
    this.customerService
      .addBeneficiary({ customerId: holderId, beneficiaryData })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.notificationService.displaySuccess(`Beneficial Owner created successfully.`, 'Success');
          this.refetchBeneficiaryList();
        },
        error: (error) => {
          const errorMessage = typeof error === 'string' ? error : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('customerService.addBeneficiary error', error);
          this.onAddBeneficiaryAction({ action: 'EDIT', holderId, beneficiaryData, errorMessage });
        },
      });
  }

  createBusinessBeneficiary(beneficiaryData: BeneficiaryRequestParams) {
    this.businessAccountService
      .addBeneficiary(beneficiaryData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.notificationService.displaySuccess(`Beneficial Owner created successfully.`, 'Success');
          this.refetchBeneficiaryList();
        },
        error: (error) => {
          const errorMessage = typeof error === 'string' ? error : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('businessAccountService.addBeneficiary error', error);
          this.onAddBeneficiaryAction({ action: 'EDIT', beneficiaryData, errorMessage });
        },
      });
  }
}
