import { compact } from 'lodash-es';
import { formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';

export const accountCategoryFields = ({ isIntegratedAvailable }: { isIntegratedAvailable?: boolean }) => {
  const options = compact([
    { value: 'EXTERNAL', label: 'EXTERNAL' },
    { value: 'INTERNAL', label: 'INTERNAL' },
    isIntegratedAvailable ? { value: 'INTEGRATED', label: 'INTEGRATED' } : undefined,
  ]);

  return [
    formRow([
      formRadioGroup({
        key: 'category',
        label: 'Account Category',
        props: { options },
        expressions: {
          'props.disabled': '!!model.isEdit',
        },
      }),
    ]),
  ];
};
