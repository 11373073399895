import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { isUndefined } from 'lodash-es';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DatatableColumn, DatatableSorting, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { RequestPageParams } from '@shared/models';

interface MerchantListItem {
  createdAt: Date;
  id: string;
  merchantName: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
}

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
})
export class MerchantListComponent implements AfterViewChecked {
  merchants$: Observable<MerchantListItem[]> = of([]);

  totalElements$ = of(0);

  loading$ = of(false);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Merchant ID', prop: 'id', colType: 'short-id', flexSize: 140 },
    { name: 'Merchant Name', prop: 'merchantName', flexSize: 200 },
    { name: 'City', prop: 'city', flexSize: 160 },
    { name: 'State', prop: 'state', flexSize: 100 },
    { name: 'Country', prop: 'country', flexSize: 200 },
    { name: 'Phone Number', prop: 'phoneNumber', flexSize: 200 },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  constructor(public ref: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  getMerchants({ page, sortParams }: FetchData): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }
  }

  onRowClick({ id }: MerchantListItem): void {
    this.router.navigate([id], { relativeTo: this.activatedRoute });
  }
}
