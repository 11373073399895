<div class="move-how-type-items" [ngClass]="className">
  <div
    *ngFor="let item of items"
    [class]="'type-item type-item--' + item.type"
    [class.disabled-type]="isDisabledType(item.type)"
    [class.active]="selectedItem === item.type"
    (click)="onItemClick(item.type)"
  >
    <div class="type-icon" [ngClass]="'type-icon-' + item.type">
      <rkt-icon *ngIf="item.iconName" [name]="item.iconName"></rkt-icon>
    </div>
    <div class="type-label">{{ item.label }}</div>
  </div>
</div>
