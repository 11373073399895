import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { CardDispute } from '@shared/models';
import { NavigationService } from '@shared/services';
import { DisputeCreateModalComponent } from '@shared/components';

@Component({
  templateUrl: './dispute-list.component.html',
})
export class DisputeListComponent implements OnDestroy {
  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  disputes$ = this.store.select(fromFinancialAccount.selectCardAccountDisputeList);

  totalElements$ = this.store.select(fromFinancialAccount.selectDisputeListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectDisputeListLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'logDateTime', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Dispute ID', prop: 'disputeCaseNumber', fixedSize: 140 },
    { name: 'Dispute Transaction ID', prop: 'disputedTransactionId', colType: 'short-id', flexSize: 160 },
    { name: 'Dispute Type', prop: 'disputeTypeLabel', flexSize: 160 },
    { name: 'Dispute Reason', prop: 'disputeReasonLabel', flexSize: 200 },
    { name: 'Dispute Status', prop: 'disputeStatusLabel', fixedSize: 140 },
    { name: 'Dispute Amount', prop: 'disputedTransactionAmount', colType: 'amount', flexSize: 140, colAlign: 'right' },
  ];

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadDisputes());
  }

  ngOnDestroy() {
    this.navigationService.ngOnDestroy();
  }

  onRowClick({ disputeCaseNumber }: CardDispute): void {
    this.router.navigate([disputeCaseNumber], { relativeTo: this.activatedRoute });
  }

  onCreateDisputeBtnClick({ financialAccountId }: { financialAccountId?: string }) {
    if (!financialAccountId) {
      return;
    }

    const disputeModalRef = this.modalService.open(DisputeCreateModalComponent, { className: 'entity-form-modal' });

    disputeModalRef.componentInstance.modalInitData({ financialAccountId });

    disputeModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadDisputes());
    });
  }
}
