import { createAction, props } from '@ngrx/store';
import { AdaRegistrationList, ErrorMessage, RequestPageParams } from '@shared/models';

export namespace AdaRegistrationListActions {
  export const loadAdaRegistrations = createAction(
    '[ADA Registrations API] Load ADA Registrations',
    props<{ params: RequestPageParams }>(),
  );

  export const loadAdaRegistrationsSuccess = createAction(
    '[ADA Registrations API] Load ADA Registrations Success',
    props<{ adaRegistrations: AdaRegistrationList['items']; totalElements: number }>(),
  );

  export const loadAdaRegistrationsError = createAction(
    '[ADA Registrations API] Load ADA Registrations Error',
    props<{ error: ErrorMessage }>(),
  );
}
