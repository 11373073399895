import { Component, EventEmitter, Input, Output, ChangeDetectorRef, OnDestroy, AfterViewChecked, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { head, some } from 'lodash-es';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import {
  FinancialAccountListItem,
  FinancialAccountList,
  RequestPageParams,
  TransactionDetails,
  FilterField,
  FilterValues,
  TransactionSolution,
} from '@shared/models';
import { FinancialAccountService, NotificationService } from '@shared/services';
import { activeFilters, ErrorUtils } from '@shared/utils';
import { FilterMenuComponent } from '@shared/components';

@Component({
  selector: 'app-business-financial-account-selection',
  templateUrl: 'business-financial-account-selection.component.html',
  styleUrls: ['business-financial-account-selection.component.scss'],
})
export class BusinessFinancialAccountSelectionComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('filterMenu') filterMenu!: FilterMenuComponent;

  @Input() transaction?: TransactionDetails;

  @Input() stickyHeader!: boolean;

  @Input() allowedTypes?: string[];

  @Input() selectedMoveHowType?: TransactionSolution;

  @Input() set selectedDebitFinancialAccount(value: Readonly<FinancialAccountListItem> | undefined) {
    if (value && this._selectedDebitFinancialAccount !== value) {
      this._selectedDebitFinancialAccount = value;

      if (this.selectedMoveHowType === 'transfer') {
        this.onFetchComplete();
        this.getBusinessFinancialAccounts({ page: 0, size: 10 });
      }

      if (!this.transaction) {
        this.resetValue();
      }
    }
  }

  @Output() selected = new EventEmitter<FinancialAccountListItem>();

  @Output() updated = new EventEmitter();

  filters: FilterField[] = [
    {
      name: 'accountHolderTypes',
      displayName: 'Account Holder Type',
      type: 'RADIO',
      options: [
        { label: 'All Type', value: undefined },
        { label: 'Business Account', value: 'BUSINESS_ACCOUNT' },
        { label: 'Customer', value: 'CUSTOMER' },
      ],
    },
  ];

  activeFilters: FilterValues = {};

  financialAccountsLoading = true;

  totalFinancialAccountsElements = 0;

  financialAccountsPage = 0;

  SelectionType = SelectionType;

  selectedFinancialAccounts: FinancialAccountListItem[] = [];

  selectedBusinessFinancialAccount?: FinancialAccountListItem;

  columns: DatatableColumn[] = [
    { name: 'Account Type', prop: 'accountHolderTypeDisplayName', flexSize: 250 },
    { name: 'Account Name', prop: 'name', flexSize: 300 },
    { name: 'Account Info', prop: 'accountInfo', flexSize: 333 },
    { name: 'Account Category', prop: 'category', flexSize: 200 },
    { name: 'Status', prop: 'state', fixedSize: 140, colType: 'status-tag', colAlign: 'right' },
    { name: 'Account Balance', prop: 'accountBalance', flexSize: 250, colType: 'amount', colAlign: 'right' },
    { name: 'Available Balance', prop: 'availableBalance', flexSize: 250, colType: 'amount', colAlign: 'right' },
  ];

  businessFinancialAccounts: FinancialAccountListItem[] = [];

  emptyListMessage = 'No Financial Accounts found.';

  private _selectedDebitFinancialAccount?: FinancialAccountListItem;

  private destroy$ = new Subject<void>();

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  get activeFinancialAccountsPage() {
    return this.financialAccountsPage;
  }

  get selectedDebitFinancialAccount() {
    return this._selectedDebitFinancialAccount;
  }

  constructor(
    public ref: ChangeDetectorRef,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getBusinessFinancialAccounts({ page, size }: RequestPageParams): void {
    this.financialAccountsLoading = true;

    const selectedAccountHolderType = this.activeFilters['accountHolderTypes'];
    const accountHolderTypes = this.selectedMoveHowType === 'wire' ? ['BUSINESS_ACCOUNT'] : ['CUSTOMER', 'BUSINESS_ACCOUNT'];

    this.financialAccountService
      .getFinancialAccountsWithBalances({
        page,
        size,
        type: 'BANK',
        state: ['ACTIVE', 'SUSPENDED'],
        accountTypes: this.allowedTypes,
        accountHolderTypes: selectedAccountHolderType ? [selectedAccountHolderType] : accountHolderTypes,
        skipAccountHolderDetails: true,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Accounts found.';
          this.onFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Accounts.';
          this.onFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountsWithBalances error', error);
        },
      });
  }

  onFetchComplete(response?: FinancialAccountList): void {
    const { items = [], totalElements = 0 } = response || {};
    this.businessFinancialAccounts = this.mapFinancialAccounts(items);
    this.financialAccountsLoading = false;
    this.totalFinancialAccountsElements = totalElements;
    this.selectedFinancialAccounts = this.businessFinancialAccounts.filter(
      (item) => item.id === this.transaction?.creditFinancialAccountId || item.id === this.transaction?.debitFinancialAccountId,
    );
    this.updated.emit();
  }

  mapFinancialAccounts(financialAccounts: FinancialAccountList['items']): FinancialAccountListItem[] {
    if (!financialAccounts.length) {
      return [];
    }

    const updatedFinancialAccounts = financialAccounts.map((item) => {
      const isTransfer = this.selectedMoveHowType === 'transfer';
      const isMoveFromIntegrated = this.selectedDebitFinancialAccount?.category === 'INTEGRATED';
      const isMoveFromBusinessAccount = this.selectedDebitFinancialAccount && !this.selectedDebitFinancialAccount.accountHolderType;
      const isMoveFromCustomerAccount =
        this.selectedDebitFinancialAccount && this.selectedDebitFinancialAccount.accountHolderType === 'CUSTOMER';
      const isNotActive = item.state !== 'ACTIVE';
      const isNotAllowedType = !this.allowedTypes?.includes(item.category);
      const isSelected = this.selectedDebitFinancialAccount ? item.id === this.selectedDebitFinancialAccount?.id : false;
      const isNotSameParentForChild =
        isTransfer && isMoveFromCustomerAccount && item.accountHolderType
          ? item.parentId !== this.selectedDebitFinancialAccount?.parentId
          : false;
      const isNotSameParent =
        isTransfer && isMoveFromCustomerAccount && !item.accountHolderType
          ? item.id !== this.selectedDebitFinancialAccount?.parentId
          : false;
      const isNotChildOfParent =
        isTransfer && isMoveFromBusinessAccount && item.category !== 'INTEGRATED' && item.accountHolderType
          ? item.parentId !== this.selectedDebitFinancialAccount?.id
          : false;
      const isNotSameBusinessParent =
        isMoveFromIntegrated && isMoveFromBusinessAccount
          ? item.category !== 'INTEGRATED' || item.parentId !== this.selectedDebitFinancialAccount?.parentId
          : false;
      const isCustomerIntegratedAccount = !isMoveFromBusinessAccount
        ? item.category === 'INTEGRATED' && item.accountHolderType === 'CUSTOMER'
        : false;

      return {
        ...item,
        isDisabled: some([
          isNotActive,
          isNotAllowedType,
          isSelected,
          isNotSameParentForChild,
          isNotSameParent,
          isNotChildOfParent,
          isNotSameBusinessParent,
          isCustomerIntegratedAccount,
        ]),
        displayName: `${item.displayName}`,
      };
    });

    let accounts = [...updatedFinancialAccounts];
    const defaultAccount = head(accounts.filter((account) => account.defaultFlag));

    if (defaultAccount) {
      accounts = accounts.filter((acc) => acc !== defaultAccount);
      accounts.unshift(defaultAccount);
    }

    return accounts;
  }

  onBusinessFinancialAccountsSelect(data: unknown = {}): void {
    const { selected } = data as { selected?: FinancialAccountListItem[] };
    if (!selected?.length) {
      this.selectedFinancialAccounts = [];
      this.selectedBusinessFinancialAccount = undefined;
      return;
    }

    this.selectedBusinessFinancialAccount = selected[0];
    this.selected.emit(this.selectedBusinessFinancialAccount);
  }

  resetValue(): void {
    this.selectedFinancialAccounts = this.businessFinancialAccounts.filter(
      (item) => item.id === this.transaction?.creditFinancialAccountId || item.id === this.transaction?.debitFinancialAccountId,
    );
    this.selected.emit();
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.getBusinessFinancialAccounts({});
  }

  closeMenu() {
    if (this.filterMenu?.isVisible) {
      this.filterMenu.toggle({} as MouseEvent);
    }
  }
}
