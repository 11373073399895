<h1 class="title">Reissue Card</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form add-customer">
  <form [formGroup]="reissueCardForm">
    <rkt-form [form]="reissueCardForm" [fields]="reissueCardFields" [model]="formModel"></rkt-form>
  </form>

  <div *ngIf="formError" class="details-page-edit-form-error">{{ formError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSubmit()" title="Save" [disabled]="!reissueCardForm.valid">Save</button>
  </div>
</div>
