<div class="create-entity-attachment">
  <div class="attachments-title">
    Attachments ({{ attachmentsData.length }})
    <button rktButton btnType="link" class="attachments-btn" (click)="onAddAttachment()">
      <rkt-icon name="plus"></rkt-icon>
      Add
    </button>
  </div>

  <div
    appDragDropAttachment
    (filesDropped)="onFilesDropped($event)"
    class="file-upload-selected-container"
    *ngIf="attachmentsData.length; else fileNotUploaded"
  >
    <div class="uploaded-file-item" *ngFor="let file of attachmentsData; index as i">
      <rkt-icon class="file-status" name="check"></rkt-icon>
      <div class="name">{{ file.name ?? file.file?.name }}</div>
      <button (click)="onEditFileBtnClick(i)" class="attachment-action-button attachment-action-button-edit">
        <rkt-icon name="edit"></rkt-icon>
      </button>
      <button type="button" (click)="onDeleteFileBtnClick(i)" class="attachment-action-button attachment-action-button-delete">
        <rkt-icon name="trash"></rkt-icon>
      </button>
    </div>
  </div>

  <ng-template #fileNotUploaded>
    <app-multiple-file-upload title="Drag & drop files here" [fileTypes]="allowedFileFormats" (selectedFiles)="onFileUploaded($event)">
    </app-multiple-file-upload>
  </ng-template>

  <div class="files-preview"></div>
</div>
