import { FormlyFieldConfig } from '@ngx-formly/core';
import { RktFormFieldConfig, formRow, formInput, formRepeatSection } from '@rocketfinancialcorp/rocket-ui/form';

export const customerAddressFormFields = ({
  isEdit,
  customerType,
}: {
  isEdit: boolean;
  customerType?: 'BUSINESS' | 'INDIVIDUAL';
}): RktFormFieldConfig[] => {
  return [
    ...getCustomerAddressFields({
      type: 'Physical',
      key: 'physicalAddresses',
      customerType,
      isRequired: isEdit,
    }),
    ...getCustomerAddressFields({
      type: 'Mailing',
      key: 'mailingAddresses',
      isRequired: isEdit,
      isEdit,
    }),
    ...getCustomerAddressFields({
      type: 'Shipping',
      key: 'shippingAddresses',
      isRequired: isEdit,
      isEdit,
    }),
  ];
};

export const getCustomerAddressFields = ({
  type,
  key,
  customerType,
  isRequired,
}: {
  type: 'Physical' | 'Mailing' | 'Shipping';
  customerType?: 'BUSINESS' | 'INDIVIDUAL';
  key: string;
  isRequired?: boolean;
  isEdit?: boolean;
}): RktFormFieldConfig[] => {
  const expressions = {
    'props.required': (field: FormlyFieldConfig) => {
      if (isRequired) {
        return true;
      }

      return (
        !!field.model?.addressLine1 ||
        !!field.model?.addressLine2 ||
        !!field.model?.city ||
        !!field.model?.state ||
        !!field.model?.postalCode ||
        !!field.model?.country
      );
    },
  };

  return [
    formRepeatSection({
      key: `${key}`,
      unstyled: true,
      addText: `Add ${type} Address`,
      removeText: 'Remove',
      header: `${type} Address`,
      subheader: type === 'Physical' ? 'Must not be a PO box' : undefined,
      fieldGroup: [
        formRow([
          {
            type: '#addressLine1',
            props: {
              label: `${type} Address 1`,
              countryRestrictions: ['US'],
              minLength: 1,
              maxLength: 50,
            },
            expressions: {
              ...expressions,
              'props.label': () => {
                if (type === 'Physical') {
                  return customerType === 'BUSINESS' ? 'Registered Business Address 1' : 'Residential Address 1';
                } else {
                  return `${type} Address 1`;
                }
              },
            },
          },
        ]),
        formRow([
          formInput({
            key: 'addressLine2',
            label: `${type} Address 2`,
            props: { minLength: 1, maxLength: 50 },
            expressions: {
              'props.label': () => {
                if (type === 'Physical') {
                  return customerType === 'BUSINESS' ? 'Registered Business Address 2' : 'Residential Address 2';
                } else {
                  return `${type} Address 2`;
                }
              },
            },
          }),
        ]),
        formRow([
          formInput({ key: 'city', label: 'City', className: 'city-field', props: { minLength: 1, maxLength: 25 }, expressions }),
          formInput({
            key: 'state',
            label: 'State',
            className: 'state-field',
            props: { minLength: 2, maxLength: 3 },
            validators: { validation: [{ name: 'isValidStateCode' }] },
            expressions,
          }),
          formInput({
            key: 'postalCode',
            label: 'Zip Code',
            className: 'postal-code-field',
            props: { minLength: 1, maxLength: 10 },
            expressions,
          }),
        ]),
        formRow([
          formInput({
            key: 'country',
            label: 'Country',
            parsers: [(value?: string) => value?.toUpperCase()],
            props: { minLength: 3, maxLength: 3 },
            validators: { validation: [{ name: 'isValidCountryCode' }] },
            validation: {
              messages: {
                minLength: 'Must be 3 characters',
              },
            },
            expressions,
          }),
        ]),
      ],
    }),
  ];
};
