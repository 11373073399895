import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Customer } from '@shared/models';

export type CustomerDetailsAction = 'SUSPEND' | 'UNSUSPEND' | 'REMOVE';

@Component({
  selector: 'app-customer-details-header',
  templateUrl: './customer-details-header.component.html',
})
export class CustomerDetailsHeaderComponent {
  @Input() customer!: Customer | null;

  @Input() isControlsDisabled = true;

  @Output() btnClicked = new EventEmitter<CustomerDetailsAction>();
}
