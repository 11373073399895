<div class="note-wrapper">
  <div class="note-section-title">Note</div>

  <div class="move-note-section">
    <app-note-form-item [errors]="moveNotes.errors" [value]="moveNotes.value" [isShowTitle]="false" [disabled]="isMoveFieldsDisabled">
      <textarea
        name="move-how-notes"
        id="move-how-notes"
        (ngModelChange)="onChange($event)"
        (blur)="onNoteFieldBlur()"
        [formControl]="moveNotes"
        class="move-how-notes-textarea"
        [appFieldDisable]="isMoveFieldsDisabled"
        maxlength="1000"
      ></textarea>
    </app-note-form-item>
  </div>
</div>
