<nav class="pager">
  <div class="page-list">
    <div class="page-item">
      <button class="page-button" (click)="onPageChange(activePage - 1)" [class.disabled]="isFirstPage" [disabled]="isFirstPage">
        <rkt-icon name="chevron-left" class="rkt-icon"></rkt-icon>
      </button>
    </div>

    <div *ngFor="let page of [].constructor(totalPages); let i = index" class="page-item" (click)="onPageChange(i)">
      <button class="page-button" [class.active]="i === activePage">{{ i + 1 }}</button>
    </div>

    <div class="page-item">
      <button class="page-button" (click)="onPageChange(activePage + 1)" [class.disabled]="isLastPage" [disabled]="isLastPage">
        <rkt-icon name="chevron-right" class="rkt-icon"></rkt-icon>
      </button>
    </div>
  </div>
</nav>
