<div class="table-header table-header-offset-sm table-header-with-controls">
  <div class="table-header-search"></div>

  <div class="table-header-filters"></div>

  <div class="table-header-controls">
    <button
      *ngIf="cardAccountId$ | async as financialAccountId"
      rktButton
      btnType="secondary"
      appRemoveFocus
      title="Create a Dispute"
      (click)="onCreateDisputeBtnClick({ financialAccountId })"
      class="add-entity-btn"
      [disabled]="(loading$ | async)!"
    >
      <rkt-icon name="plus"></rkt-icon>
      Create a Dispute
    </button>
  </div>
</div>

<rkt-table
  [rows]="(disputes$ | async)!"
  [columns]="columns"
  [count]="(totalElements$ | async)!"
  [loadingIndicator]="(loading$ | async)!"
  [externalPaging]="false"
  [messages]="{ emptyMessage: 'No Disputes found.' }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
  (rowActivated)="onRowClick($event)"
></rkt-table>
