export const MESSAGE = {
  PERMISSION_DENIED: 'Permission denied. Please contact Administrator.',
  PERMISSION_DENIED_TITLE: 'Permission denied',
  PERMISSION_DENIED_NOTIFICATION: 'You need permission to perform this action.',
  PERMISSION_DENIED_NOTIFICATION_TITLE: 'Permission Denied',
  SESSION_EXPIRED_NOTIFICATION: 'Please login again to resume.',
  SESSION_UNAVAILABLE_NOTIFICATION: 'The server encountered an error and could not complete your request.',
  PAGE_NOT_FOUND_NOTIFICATION: 'Page not found.',
  SESSION_EXPIRED_NOTIFICATION_TITLE: 'Session Expired',
  GENERIC_ERROR: 'Oops! The information entered was incorrect. Please check and try again.',
  LOGIN_ERROR: 'Oops! The email or password entered was incorrect. Please try again.',
  AUTH_PROVIDER_VERIFICATION_ERROR: 'Oops! Unable to verify auth provider. Please contact administrator.',
  SUPERSET_AUTH_ERROR: 'Oops! Unable to authenticate with Superset. Please contact administrator.',
  UNKNOWN_ERROR: 'Oops! We are unable to complete this request. Please contact administrator.',
  MOVE_MONEY_INSUFFICIENT_BALANCE: 'Insufficient Balance in Move From Account to execute the transaction',
  MOVE_MONEY_INSUFFICIENT_LIMIT: 'Transaction exceeds the following limits:',
  MOVE_MONEY_UNKNOWN_ERROR: 'We are unable to complete this request. Please contact your administrator.',
  NO_BUSINESS_ACCOUNT: 'Unable to fetch Business Account',
  TEAM_MEMBER_ALREADY_EXIST: 'A Team Member account with this email address already exists.',
  CUSTOMER_ALREADY_EXIST: 'A Customer account with this email address already exists.',
  CARD_ACCOUNT_ACTIVATE_NOT_ALLOWED: 'We are unable to complete this request. Please contact your administrator.',
};
