import { compact, startCase } from 'lodash-es';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env';
import {
  BusinessAccount,
  Recipient,
  RequestPageParams,
  RecipientListRaw,
  RecipientList,
  RecipientRaw,
  AddRecipientParams,
  UpdateRecipientParams,
} from '@shared/models';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { toTitleCase, toPhone } from '@shared/utils';
import { BusinessAccountService } from '@shared/services';
import { BackendService } from '@shared/services/backend.service';
import { AuditActions } from '@shared/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class RecipientService {
  constructor(private businessAccountService: BusinessAccountService, private backendService: BackendService, private store: Store) {}

  public getRecipients(
    activeBusinessAccountId: BusinessAccount['id'],
    { page, size, searchString, sortParams }: RequestPageParams,
    accountHolderId?: string,
  ): Observable<RecipientList> {
    const pageSize = size || constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    if (page) {
      params = params.append('page', page);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (accountHolderId) {
      params = params.append('accountHolderId', accountHolderId);
    }

    return this.backendService
      .get<RecipientListRaw>(`${environment.financialAccountFlow}/business-accounts/${activeBusinessAccountId}/recipients`, { params })
      .pipe(
        map((response) => {
          const { content = [], totalElements = 0 } = response || {};

          return {
            items: content?.map((item) => RecipientService.mapRecipient(item)),
            totalElements,
          };
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public addRecipient(recipientData: AddRecipientParams): Observable<Recipient> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<RecipientRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients`, recipientData)
          .pipe(
            map((recipient) => RecipientService.mapRecipient(recipient)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getRecipientById(recipientId: Recipient['id']): Observable<Recipient> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<RecipientRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${recipientId}`)
          .pipe(
            tap({
              next: (recipient) => {
                const { createdBy, updatedBy } = recipient;
                this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
              },
            }),
            map((recipient) => RecipientService.mapRecipient(recipient)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateRecipient(recipientId: Recipient['id'], recipientData: UpdateRecipientParams): Observable<Recipient> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<RecipientRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${recipientId}`,
            recipientData,
          )
          .pipe(
            map((recipient) => RecipientService.mapRecipient(recipient)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  private static mapRecipient(recipient: RecipientRaw): Recipient {
    return {
      ...recipient,
      displayName: recipient.businessName || compact([recipient.firstName, recipient.lastName]).join(' '),
      email: recipient.email?.toLowerCase(),
      recipientTypeName: toTitleCase(recipient.recipientType),
      phoneNumber: toPhone(recipient.phoneNumber),
      shortId: recipient.id.split('-')[0],
      avatarInitials: startCase(recipient.businessName?.charAt(0) || recipient.firstName.charAt(0)),
    };
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }
}
