import { formInput, formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { Patterns } from '../patterns';

const createRecipientNameOptions = {
  expressions: {
    'props.disabled': 'model.accountHolderType === "CUSTOMER" && model.customerType === "BUSINESS"',
  },
};

const createRecipientOptions = {
  expressions: {
    'props.disabled': 'model.accountHolderType !== "RECIPIENT"',
  },
};
const createBusinessRecipientOptions = {
  expressions: {
    'props.required': 'model.recipientType === "BUSINESS"',
    hide: "model.recipientType === 'INDIVIDUAL'",
    'props.disabled': 'model.accountHolderType !== "RECIPIENT" ',
  },
};

export const recipientCreateFields = [
  formRow([
    formRadioGroup({
      key: 'recipientType',
      label: 'Recipient Type',
      props: {
        options: [
          { value: 'INDIVIDUAL', label: 'Individual' },
          { value: 'BUSINESS', label: 'Business' },
        ],
      },
    }),
  ]),
  formRow([
    formInput({
      key: 'businessName',
      label: 'Business Name on Account',
      props: { pattern: Patterns.GENERIC_NAME, minLength: 1, maxLength: 100 },
      validation: { messages: { pattern: 'Enter a valid Business Name on Account' } },
      ...createBusinessRecipientOptions,
    }),
  ]),
  formRow([
    formInput({
      key: 'firstName',
      label: 'First name',
      props: { required: true, minLength: 1, maxLength: 40 },
      expressions: {
        'props.disabled': 'model.accountHolderType === "CUSTOMER" && model.customerType === "BUSINESS"',
        'props.required': 'model.recipientType === "INDIVIDUAL"',
      },
    }),
    formInput({ key: 'middleName', label: 'Middle name', props: { minLength: 1, maxLength: 40 }, ...createRecipientNameOptions }),
    formInput({
      key: 'lastName',
      label: 'Last name',
      props: { minLength: 1, maxLength: 40 },
      ...createRecipientNameOptions,
    }),
  ]),
  formRow([
    {
      type: '#email',
      props: { pattern: Patterns.EMAIL, minLength: 1, maxLength: 100, required: false },
      validation: {
        messages: {
          pattern: 'Enter a valid email',
        },
      },
      ...createRecipientOptions,
    },
    { type: '#phoneNumber', props: { required: false }, ...createRecipientOptions },
  ]),
];
