import { Patterns } from '@shared/components';

export const getIntegerPartOfNumber = (number: number) => {
  const value = Math.trunc(Math.abs(number));
  return value.toString();
};

export const getFractionalPartOfNumber = (number: number) => {
  const trimmedNumber = number.toFixed(2);
  const regex = Patterns.NUMBER_WITH_FRACTIONAL_PART;
  const hasFractionalPart = regex.test(trimmedNumber);

  if (!hasFractionalPart) {
    return '00';
  }

  return trimmedNumber.slice(-2);
};

export const getFormattedAmount = (number?: number): { formattedValue?: string; integerPart?: string; fractionalPart?: string } => {
  if (number === undefined) {
    return {};
  }

  const integerPart = getIntegerPartOfNumber(number);
  const fractionalPart = getFractionalPartOfNumber(number);

  return {
    formattedValue: `${integerPart}.${fractionalPart}`,
    integerPart,
    fractionalPart,
  };
};
