import { PushNotification } from '@shared/models';

export const mapPushNotification = (notification: PushNotification): PushNotification => {
  return {
    ...notification,
    shortId: formatShortId(notification.id),
    createdByShortId: formatShortId(notification?.createdBy?.id),
    updatedByShortId: formatShortId(notification?.updatedBy?.id),
  };
};

const formatShortId = (id: string): string => {
  return id.split('-')[0]?.toUpperCase();
};
