<div class="modal-header-block title">
  <h2>{{ isEdit ? 'Edit' : 'Add' }} an Alert</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="details-page-edit-form">
  <form [formGroup]="alertForm">
    <rkt-form [form]="alertForm" [fields]="alertFields" [model]="formModel" (modelChange)="onModelChange()"></rkt-form>
  </form>

  <div class="separator-line"></div>

  <div *ngIf="validationError" class="details-page-edit-form-error">{{ validationError }}</div>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>

    <button
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="loading || !alertForm.valid || !!validationError"
      [title]="isEdit ? 'Save' : 'Add'"
    >
      {{ isEdit ? 'Save' : 'Add' }}
    </button>
  </div>
</div>
