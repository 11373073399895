import { createReducer, on } from '@ngrx/store';
import { AdaRegistration, ErrorMessage } from '@shared/models';
import { AdaRegistrationActions } from './ada-registration-details.actions';

export const adaRegistrationPageFeatureKey = 'detailsPage';

export interface AdaRegistrationDetailsState {
  adaRegistartion: AdaRegistration | null;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialAdaRegistrationDetailsState: AdaRegistrationDetailsState = {
  adaRegistartion: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<AdaRegistrationDetailsState>(
  initialAdaRegistrationDetailsState,
  on(AdaRegistrationActions.initialize, (state): AdaRegistrationDetailsState => {
    return {
      ...state,
      isLoading: true,
      adaRegistartion: null,
      error: null,
    };
  }),
  on(AdaRegistrationActions.loadAdaRegistrationDetails, (state): AdaRegistrationDetailsState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(AdaRegistrationActions.loadAdaRegistrationDetailsSuccess, (state, action): AdaRegistrationDetailsState => {
    return {
      ...state,
      adaRegistartion: action.adaRegistration,
      isLoading: false,
    };
  }),
  on(AdaRegistrationActions.loadAdaRegistrationDetailsError, (state, action): AdaRegistrationDetailsState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getAdaRegistration = (state: AdaRegistrationDetailsState) => state.adaRegistartion;
export const getLoading = (state: AdaRegistrationDetailsState) => state.isLoading;
export const getError = (state: AdaRegistrationDetailsState) => state.error;
