import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { PaymentReason, TransactionDirection, TransactionSolution } from '@shared/models';
import { NotificationService, TransactionService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { AbstractMoveMoneyBase } from '@shared/components';

@Component({
  selector: 'app-move-reason-selection',
  templateUrl: 'move-reason-selection.component.html',
  styleUrls: ['move-reason-selection.component.scss'],
})
export class MoveReasonSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isMoveFieldsDisabled = false;

  @Input() initialValue?: PaymentReason['id'];

  @Input() moveHowType?: TransactionSolution;

  @Input() directionType?: TransactionDirection;

  paymentReasonField = new UntypedFormControl();

  isLoading = false;

  @Output() selected = new EventEmitter<PaymentReason>();

  get paymentReasons(): PaymentReason[] {
    if (!this.moveHowType) {
      return this._paymentReasons;
    }

    return this._paymentReasons.filter((reason) => reason.allowedSolutions?.includes(this.moveHowType!));
  }

  private _paymentReasons: PaymentReason[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private transactionService: TransactionService,
    private moveMoneyService: AbstractMoveMoneyBase,
  ) {}

  ngOnInit(): void {
    if (this.initialValue) {
      this.setValue(this.initialValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue } = changes.directionType ?? {};
    if (currentValue !== previousValue) {
      this.getPaymentReasons();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setInitialValues(): void {
    const { id } = this.moveMoneyService.paymentReason ?? {};
    if (id) {
      this.paymentReasonField.setValue(id);
    }
  }

  getPaymentReasons(): void {
    if (!this.directionType) {
      return;
    }

    this.transactionService
      .getPaymentReasons(this.directionType)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (paymentReasons) => {
          this._paymentReasons = paymentReasons;
        },
        error: (error?: string) => {
          ErrorUtils.catchError('transactionService.getPaymentReasons', error);
          return [];
        },
      });
  }

  onPaymentReasonSelect(paymentReason: PaymentReason): void {
    this.selected.emit(paymentReason);
  }

  setValue(reasonId: PaymentReason['id']): void {
    this.paymentReasonField.setValue(reasonId);
  }

  resetValue(): void {
    this.paymentReasonField.reset();
    this.selected.emit();
  }
}
