import { Component, ElementRef, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-details-item',
  templateUrl: './details-item.component.html',
  styleUrls: ['./details-item.component.scss'],
})
export class DetailsItemComponent {
  @HostBinding('class.details-item-container') commonClass = true;

  @Input() label = '';

  @Input() value?: string | null;

  @Input() noBreak = false;

  @Input() showPlaceholder = true;

  @Input() title?: string | null;

  @Input() isPrimary?: boolean;

  @Input() isValueMuted?: boolean;

  @Input() isDarkBg?: boolean;

  @Input() showPlaceholderLabel?: boolean;

  @Input() multiline = false;

  @Input() contentValue?: boolean;

  @Input() iconName?: string;

  @Input() labelBtnTemplate!: TemplateRef<ElementRef>;
}
