import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { FormModel, LinxRequestDebitAuthorizationDetails } from '@shared/models';
import { linxRequestDebitAuthorizationFields, notesField } from './linx-request-debit-authorization-utils';
import { getFormattedAmount } from '@shared/utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-linx-request-debit-authorization',
  templateUrl: './linx-request-debit-authorization.component.html',
})
export class LinxRequestDebitAuthorizationComponent implements AfterViewChecked, OnDestroy {
  @Output() continue = new EventEmitter<LinxRequestDebitAuthorizationDetails>();

  debitAuthorizationForm = new UntypedFormGroup({});
  noteForm = new UntypedFormGroup({});

  formModel: FormModel<Partial<LinxRequestDebitAuthorizationDetails>> = {};

  debitAuthorizationFields = linxRequestDebitAuthorizationFields({
    authorizationFieldsInitHook: () => this.resetFixedAuthorizationDetails(),
  });

  fixedTerm?: number;

  fixedAmount = new UntypedFormControl();

  notesField: RktFormFieldConfig[] = notesField;

  private destroy$ = new Subject<void>();

  get isManualAuthorization(): boolean {
    const { authorization } = this.formModel;
    return authorization === 'MANUAL';
  }

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public resetFixedAuthorizationDetails(): void {
    this.fixedAmount.reset();
    this.fixedTerm = undefined;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickContinue(): void {
    this.continue.emit({
      ...this.formModel,
      fixedAmount: this.fixedAmount.value,
      fixedTerm: this.fixedTerm,
    });
  }

  onClearBtnClick(): void {
    this.debitAuthorizationForm.reset();
    this.resetFixedAuthorizationDetails();
  }

  onFixedtermChange(term: number): void {
    this.fixedTerm = term;
  }

  onFixedAmountBlur(): void {
    if (!this.fixedAmount.value) {
      this.fixedAmount.reset();
    } else {
      const value = parseFloat(this.fixedAmount.value);
      this.fixedAmount.setValue(getFormattedAmount(value).formattedValue);
    }
  }
}
