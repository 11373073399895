<div class="attachments-container">
  <div class="attachments-header">
    <div class="attachments-title">
      Attachments ({{ totalElements }})
      <button rktButton btnType="link" class="attachments-btn" (click)="onAddAttachment()">
        <rkt-icon name="plus"></rkt-icon>
        Add
      </button>
    </div>
    <div class="attachment-toggle-buttons">
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'tiles'"
        (click)="onToggleAttachmentsView('tiles')"
      >
        <rkt-icon name="tiles"></rkt-icon>
      </button>
      <button
        rktButton
        btnType="link"
        class="attachment-toggle-btn"
        [class.active]="attachmentsView === 'list'"
        (click)="onToggleAttachmentsView('list')"
      >
        <rkt-icon name="list"></rkt-icon>
      </button>
    </div>
  </div>
  <rkt-table-search (changed)="onSearch($event)" placeholder="Search by Name / ID"></rkt-table-search>
</div>

<ng-container *ngIf="loading || attachments.length; else emptyList">
  <rkt-table
    *ngIf="attachmentsView === 'list'; else carouselView"
    [rows]="attachments"
    [columns]="columns"
    [limit]="10"
    (fetchData)="getAttachments($event)"
    (rowActivated)="onRowClick($event)"
    (colButtonClicked)="onColButtonClick($event)"
    [count]="totalElements"
    [loadingIndicator]="loading"
    [offset]="activePage"
    [sortParam]="sortParams"
    dateTimeFormat="MM/dd/yy HH:mm"
    [messages]="{ emptyMessage: 'No Attachments found.' }"
    emptyValuePlaceholder="--"
  >
  </rkt-table>
</ng-container>

<ng-template #emptyList>
  <div class="empty-container">
    <div class="empty-container-icon">
      <div class="empty-list-placeholder"></div>
    </div>
    <div class="empty-container-description">No attachments yet</div>
  </div>
</ng-template>

<ng-template #carouselView>
  <rkt-carousel [items]="attachments" [responsiveBreakpoints]="responsiveBreakpoints">
    <ng-template let-item rktCarouselItem="item">
      <div class="file-item" (click)="onRowClick(item)">
        <div class="file-name">
          <rkt-icon [name]="'attachment'"></rkt-icon>
          <span class="file-name-text">{{ item.name }}</span>
        </div>
        <div class="file-image">
          <img [src]="item.thumbnailUrl?.value" *ngIf="item.thumbnailUrl?.value" />
          <div class="banner-text">{{ getAttachmentScanLabel(item) }}</div>
          <div class="action-buttons">
            <rkt-icon class="trash-button" [name]="'trash'" (click)="onActionButtonClick('DELETE', item.id, $event)"></rkt-icon>
            <rkt-icon class="edit-button" [name]="'edit'" (click)="onActionButtonClick('EDIT', item.id, $event)"></rkt-icon>
            <rkt-icon class="download-button" [name]="'download'" (click)="onActionButtonClick('DOWNLOAD', item.id, $event)"></rkt-icon>
          </div>
        </div>
      </div>
    </ng-template>
  </rkt-carousel>
</ng-template>
