import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, expand, finalize, reduce, Subject, takeUntil } from 'rxjs';
import { TransactionDetails, TransactionHistory, TransactionHistoryList } from '@shared/models';
import { TransactionService } from '@shared/services';

@Component({
  selector: 'app-history-item',
  templateUrl: 'history-item.component.html',
  styleUrls: ['history-item.component.scss'],
})
export class HistoryItemComponent implements OnInit, OnDestroy {
  @Input() currentTransactionId!: TransactionDetails['id'];

  @Input() item!: TransactionHistory;

  @Input() isExpandedByDefault = false;

  @Input() isFirstItem = false;

  @Input() isLastItem = false;

  @Input() loading = false;

  @Input() isSubItem = false;

  historySubItemsPageNumber = 0;

  isSubItemsExpanded = false;

  isSubItemsLoading = false;

  transactionHistorySubItems?: TransactionHistory[];

  private destroy$ = new Subject<void>();

  get hasSubItems(): boolean {
    return !!this.item?.parentTransactionId;
  }

  constructor(private transactionService: TransactionService) {}

  ngOnInit() {
    if (this.isExpandedByDefault && this.item?.parentTransactionId) {
      this.expandSubItems();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  expandSubItems() {
    this.isSubItemsExpanded = !this.isSubItemsExpanded;
    this.getHistorySubItems();
  }

  getHistorySubItems() {
    if (!this.isSubItemsExpanded) {
      return;
    }

    const { parentTransactionId, transactionId } = this.item;
    const subItemTransactionId = parentTransactionId === this.currentTransactionId ? transactionId : this.item.parentTransactionId!;

    this.isSubItemsLoading = true;

    return this.transactionService
      .getTransactionHistory({ transactionId: subItemTransactionId, size: 200, page: this.historySubItemsPageNumber })
      .pipe(
        expand((response) => {
          if (response.hasNext) {
            this.historySubItemsPageNumber += 1;

            return this.transactionService.getTransactionHistory({
              transactionId: subItemTransactionId,
              size: 200,
              page: this.historySubItemsPageNumber,
            });
          } else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.isSubItemsLoading = false;
        }),
        reduce<TransactionHistoryList, TransactionHistory[]>((acc, current) => acc.concat(current.items), []),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (result) => {
          this.transactionHistorySubItems = result;
        },
      });
  }
}
