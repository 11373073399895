import { createReducer, on } from '@ngrx/store';
import { ErrorMessage } from '@shared/models';
import { FinancialAccountsActions } from './financial-accounts.actions';

export const financialAccountsFeatureKey = 'financialAccounts';

export interface FinancialAccountIdsState {
  financialaccountsIds: string[] | null;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialState: FinancialAccountIdsState = {
  financialaccountsIds: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(FinancialAccountsActions.initialize, (state): FinancialAccountIdsState => {
    return {
      ...state,
      isLoading: true,
      financialaccountsIds: null,
      error: null,
    };
  }),
  on(FinancialAccountsActions.loadFinancialAccountsIds, (state): FinancialAccountIdsState => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(FinancialAccountsActions.loadFinancialAccountsIdsSuccess, (state, action): FinancialAccountIdsState => {
    return {
      ...state,
      financialaccountsIds: action.financialAccountsIds,
      isLoading: false,
    };
  }),
  on(FinancialAccountsActions.loadFinancialAccountsIdsError, (state): FinancialAccountIdsState => {
    return {
      ...state,
      isLoading: false,
    };
  }),
);
