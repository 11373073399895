<div class="modal-header-block title">
  <h2>{{ isEditMode ? 'Update Attachment' : 'Attach File' }}</h2>
  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="details-page-edit-form integrated-bank-account-form">
  <div class="file-drop" *ngIf="!isEditMode">
    <app-file-upload
      title="Drag & drop files here"
      [fileTypes]="allowedFileFormats"
      (selectedFile)="onFileUploaded($event)"
    ></app-file-upload>
  </div>

  <form [formGroup]="attachmentForm">
    <rkt-form [form]="attachmentForm" [fields]="attachmentFields" [model]="formModel"></rkt-form>
  </form>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      *ngIf="!isEditMode"
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="isLoading || !(attachmentForm.valid && (formModel.isFileUploaded || attachmentUploadData))"
      [title]="'Upload'"
    >
      Upload
    </button>

    <button
      *ngIf="isEditMode"
      rktButton
      type="submit"
      [disabled]="isLoading || !attachmentForm.valid"
      (click)="activeModal.close(formModel)"
      [title]="'Update'"
    >
      Update
    </button>
  </div>
</div>
