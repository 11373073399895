<div class="beneficiary-list">
  <ng-container *ngIf="beneficiaries; else beneficiariesLoading">
    <a
      *ngFor="let beneficiary of beneficiaries"
      [routerLink]="[beneficiary.id, 'detailed-profile']"
      title="View Beneficial Owner Details"
      class="beneficiary-list-item"
    >
      <div class="beneficiary-avatar">{{ beneficiary.initials }}</div>
      <div class="beneficiary-summary">
        <div class="beneficiary-name">
          {{ beneficiary.fullName }}
          <div [class]="'inline-status inline-status-' + beneficiary.verification | lowercase">
            {{ beneficiary.verification | titlecase }} verification
          </div>
        </div>

        <div class="beneficiary-details">
          <div class="beneficiary-phone">
            <rkt-icon name="phone"></rkt-icon>

            <div class="item-value" [class.not-available]="!beneficiary.phoneNumber" [title]="beneficiary.phoneNumber">
              {{ beneficiary.phoneNumber | phone | notAvailable }}
            </div>
          </div>

          <div class="beneficiary-email">
            <rkt-icon name="mail"></rkt-icon>

            <div class="item-value" [class.not-available]="!beneficiary.email" [title]="beneficiary.email">
              {{ beneficiary.email | notAvailable }}
            </div>
          </div>

          <div class="beneficiary-controller">
            Controller
            <rkt-icon [class.checked]="beneficiary.controller" [name]="beneficiary.controller ? 'check' : 'close'"></rkt-icon>
          </div>
        </div>
      </div>

      <div class="beneficiary-details-arrow">
        <rkt-icon name="arrow-right"></rkt-icon>
      </div>
    </a>
  </ng-container>

  <ng-template #beneficiariesLoading>
    <div *ngFor="let i of [].constructor(3)" class="beneficiary-list-item beneficiary-list-item-placeholder">
      <div class="beneficiary-avatar"><div class="loading-placeholder"></div></div>

      <div class="beneficiary-summary">
        <div class="beneficiary-name"><div class="loading-placeholder"></div></div>

        <div class="beneficiary-details">
          <div class="beneficiary-phone">
            <div class="icon"><div class="loading-placeholder"></div></div>
            <div class="item-value"><div class="loading-placeholder"></div></div>
          </div>

          <div class="beneficiary-email">
            <div class="icon"><div class="loading-placeholder"></div></div>
            <div class="item-value"><div class="loading-placeholder"></div></div>
          </div>

          <div class="beneficiary-controller">
            <div class="item-label"><div class="loading-placeholder"></div></div>
            <div class="icon"><div class="loading-placeholder"></div></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="!loading && beneficiaries?.length === 0" class="empty-state">
  <div class="image"></div>

  <div class="message">Add the first Beneficial Owner</div>

  <button rktButton btnType="primary" title="Add a Beneficial Owner" class="add-entity-btn" (click)="onAddBeneficiaryBtnClick()">
    <rkt-icon name="plus"></rkt-icon>
    Add a Beneficial Owner
  </button>
</div>
