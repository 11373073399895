<div class="note-field-label" *ngIf="isShowTitle">{{ title || 'Notes' }}</div>

<div class="note-field-wrapper" [class.disabled]="disabled" [class.error]="errors">
  <ng-content></ng-content>
</div>

<div class="note-attention">Do not enter the card/account details in notes</div>

<div class="note-validation-error" *ngIf="errors && errors.isEmptySpaces">
  {{ errors && errors.isEmptySpaces.message }}
</div>
