import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Beneficiaries } from '@shared/models';

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss'],
})
export class BeneficiaryListComponent {
  @Input() beneficiaries!: Beneficiaries[] | null;
  @Input() loading!: boolean;
  @Output() addBeneficiaryEmitter = new EventEmitter();

  onAddBeneficiaryBtnClick() {
    this.addBeneficiaryEmitter.emit();
  }
}
