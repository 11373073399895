import { createAction, props } from '@ngrx/store';
import { DocumentDetails, ErrorMessage } from '@shared/models';

export namespace DocumentActions {
  export const loadDocument = createAction('[Documents API] Load Document');

  export const loadDocumentSuccess = createAction('[Documents API] Load Document Success', props<{ document: DocumentDetails }>());

  export const loadDocumentError = createAction('[Documents API] Load Document Error', props<{ error: ErrorMessage }>());

  export const initialize = createAction('[Documents Page] Initialize');
}
