import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private destroy$ = new Subject<void>();

  private navigationSubscription?: Subscription;

  constructor(private router: Router, private store: Store) {}

  dispatchOnNavigationEnd(action: Action): void {
    this.navigationSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(action);
        },
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
