<div class="details-page-header-wrapper" *ngIf="!loading; else loadingPlaceholder">
  <ng-container *ngIf="cardAccount$ | async as financialAccount">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
        </a>

        <h1 class="details-page-title">
          {{ financialAccount.name }}
        </h1>
        <div class="status-tag" [class.no-value]="!financialAccount.state" [ngClass]="'status-tag-' + financialAccount.state.toLowerCase()">
          {{ financialAccount.state | titlecase }}
        </div>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
    </div>
    <div class="page-header-controls" [class.no-actions]="!financialAccount">
      <app-financial-account-actions
        [financialAccount]="financialAccount"
        [financialAccountHolderType]="entityType"
      ></app-financial-account-actions>
    </div>
  </ng-container>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL" [withStatus]="false"></app-details-page-header-placeholder>
</ng-template>
