import { createAction, props } from '@ngrx/store';
import { ErrorMessage, ReportList, RequestPageParams } from '@shared/models';

export namespace ReportsActions {
  export const loadReports = createAction('[Reports API] Load Reports', props<{ params: RequestPageParams }>());

  export const loadReportsSuccess = createAction(
    '[Reports API] Load Reports Success',
    props<{ reports: ReportList['items']; totalElements: number }>(),
  );

  export const loadReportsError = createAction('[Reports API] Load Reports Error', props<{ error: ErrorMessage }>());

  export const initialize = createAction('[Reports API] Initialize');
}
