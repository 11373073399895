<div class="modal-header-block title">
  <button *ngIf="!isEdit" class="modal-back-btn-icon" (click)="activeModal.close({ action: 'EDIT' })">
    <rkt-icon name="arrow-left"></rkt-icon>
  </button>

  <h2>{{ isEdit ? 'Edit Document' : 'Upload Document' }}</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="document-type">
  <rkt-icon [name]="documentTypeData.icon"></rkt-icon>
  {{ documentTypeData.label }}
</div>

<div class="details-page-edit-form integrated-bank-account-form">
  <form [formGroup]="documentForm">
    <rkt-form [form]="documentForm" [fields]="documentFields" [model]="formModel"></rkt-form>
  </form>

  <div class="file-drop">
    <app-file-upload
      title="Drag & drop files here"
      [fileTypes]="['image/jpeg', 'image/png', 'application/pdf']"
      [uploadedFileLabel]="formModel.isFileUploaded ? documentTypeData.label : undefined"
      (selectedFile)="onFileUploaded($event)"
    ></app-file-upload>
  </div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="isLoading || !(documentForm.valid && (formModel.isFileUploaded || documentUploadData))"
      [title]="isEdit ? 'Save' : 'Upload'"
    >
      {{ isEdit ? 'Save' : 'Upload' }}
    </button>
  </div>
</div>
