import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardRestriction, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { NavigationService } from '@shared/services';
import { RestrictionCreateModalComponent } from '@shared/components';

@Component({
  templateUrl: './restriction-details.component.html',
})
export class RestrictionDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'restriction-details-';

  restrictionDetailsContext?: TemplateContext<CardRestriction>;

  cardAccountId$ = this.store.select(fromFinancialAccount.selectCardAccountDetailsId);

  restrictionDetails$ = this.store.select(fromFinancialAccount.selectCardAccountRestrictionDetails);

  constructor(private navigationService: NavigationService, private store: Store, private modalService: ModalService) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadRestrictionDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails());
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    this.navigationService.ngOnDestroy();
  }

  onEditBtnClick(restriction?: CardRestriction) {
    if (!restriction) {
      return;
    }

    const restrictionModalRef = this.modalService.open(RestrictionCreateModalComponent, {
      className: 'entity-form-modal restriction-form-modal',
    });

    restrictionModalRef.componentInstance.modalInitData({ ...restriction });

    restrictionModalRef.result.finally(() => {
      this.store.dispatch(CardAccountActions.loadRestrictionDetails());
    });
  }
}
