import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriberService<T> {
  protected observable = new Subject<T>();

  protected emitChangeSource = new Subject<T>();

  changeEmitted = this.emitChangeSource.asObservable(); // eslint-disable-line @typescript-eslint/member-ordering

  public next(item: T): void {
    this.observable.next(item);
  }

  public subscribe(callback: (item: T) => void): void {
    this.observable.subscribe(callback); // eslint-disable-line rxjs/prefer-observer
  }

  public emitChange(change: T): void {
    this.emitChangeSource.next(change);
  }
}
