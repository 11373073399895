<app-attention-modal type="error" title="Attention" [messageTemplate]="message" [buttonsTemplate]="buttons"></app-attention-modal>

<ng-template #message>
  <ng-container *ngIf="attentionType === 'sameDayACHLimit'">
    The upper limit for Same Day ACH transactions is $ 100,000.00. Please submit a lower amount.
  </ng-container>

  <ng-container *ngIf="attentionType === 'sameDayWindowClosed'">
    The last window for Same Day ACH transactions is closed. Do you want to use the Next Day ACH?
  </ng-container>
</ng-template>

<ng-template #buttons>
  <button rktButton type="button" (click)="activeModal.close(true)" title="Continue">Continue</button>
</ng-template>
