<div class="row">
  <div class="label">Settlement Priority<span class="asterisk">*</span></div>
  <div class="value">
    <div class="processing-priority-wrapper">
      <div class="processing-priority-item" [class.disabled]="isSameDayDisabled">
        <label
          [for]="type + '-processing-priority-same-day'"
          class="processing-priority-radio-input-label"
          [class.disabled]="isSameDayDisabled"
        >
          <span class="processing-priority-radio-input" [class.checked]="processingPriority.value === 'SAME_DAY'"></span>

          <input
            hidden
            [id]="type + '-processing-priority-same-day'"
            value="SAME_DAY"
            [formControl]="processingPriority"
            (change)="onProcessingPrioritySelect()"
            [attr.disabled]="isSameDayDisabled ? true : null"
            [name]="type + '-processing-priority'"
            type="radio"
            class="form-check-input"
          />
          Same Day
        </label>
      </div>

      <div class="processing-priority-item" [class.disabled]="isNextDayDisabled">
        <label
          [for]="type + '-processing-priority-next-day'"
          class="processing-priority-radio-input-label"
          [class.disabled]="isNextDayDisabled"
        >
          <span class="processing-priority-radio-input" [class.checked]="processingPriority.value === 'NEXT_DAY'"></span>

          <input
            hidden
            [id]="type + '-processing-priority-next-day'"
            value="NEXT_DAY"
            [formControl]="processingPriority"
            (change)="onProcessingPrioritySelect()"
            [attr.disabled]="isNextDayDisabled ? true : null"
            [name]="type + '-processing-priority'"
            type="radio"
            class="form-check-input"
          />
          Next Day
        </label>
      </div>
    </div>
  </div>
</div>
