<div class="details-item">
  <ng-container *ngIf="!showPlaceholder; else loadingPlaceholder">
    <div class="details-item-label" [class.primary]="isPrimary">
      <rkt-icon [name]="iconName" *ngIf="iconName"></rkt-icon>
      {{ label }}
      <ng-container *ngTemplateOutlet="labelBtnTemplate"></ng-container>
    </div>

    <div
      class="details-item-value"
      [class.muted]="isValueMuted"
      [class.no-break]="noBreak"
      [class.loading]="showPlaceholder"
      [title]="contentValue ? '' : (title || value | notAvailable)"
      [class.empty-value]="!showPlaceholder && !value && !contentValue"
    >
      {{ showPlaceholder || contentValue ? '' : (value | notAvailable) }}
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>

<ng-template #loadingPlaceholder>
  <app-details-item-placeholder
    [isDarkBg]="isDarkBg"
    [isPrimary]="isPrimary"
    [label]="label"
    [showLabel]="showPlaceholderLabel"
    [multiline]="multiline"
  ></app-details-item-placeholder>
</ng-template>
