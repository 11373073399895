interface Dimensions {
  width?: number;
  height?: number;
}

interface ViewPort {
  width: number;
  height: number;
}

const getHiddenElementDimensions = (el: HTMLDivElement): Dimensions => {
  const element = el;
  const dimensions: Dimensions = {};
  element.style.visibility = 'hidden';
  element.style.display = 'flex';
  dimensions.width = element.offsetWidth;
  dimensions.height = element.offsetHeight;
  element.style.visibility = 'visible';
  element.style.display = 'flex';

  return dimensions;
};

const getViewport = (): ViewPort => {
  const win = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const gScrollWidth = g.scrollWidth;
  const w = win.innerWidth || e.clientWidth || g.clientWidth;
  const h = win.innerHeight || e.clientHeight || g.clientHeight;

  return { width: w - gScrollWidth, height: h };
};

export const filterPositioning = {
  absolutePosition: (el: HTMLDivElement, target?: HTMLElement): void => {
    if (!target) {
      return;
    }

    const element = el;
    const elementDimensions = element.offsetParent
      ? { width: element.offsetWidth, height: element.offsetHeight }
      : getHiddenElementDimensions(element);
    const elementOuterHeight = elementDimensions.height || 0;
    const elementOuterWidth = elementDimensions.width || 0;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const viewport = getViewport();

    const topOffset = 60;
    const bottomOffset = 60;

    const fromTopToTarget = targetOffset.top - topOffset;
    const fromTargetToBottom = viewport.height - (targetOffset.top + targetOuterHeight + bottomOffset);

    const { top, left, bottom, maxHeight } = absolutePositionDirection({
      fromTopToTarget,
      fromTargetToBottom,
      elementOuterHeight,
      targetOffset,
      bottomOffset,
      targetOuterHeight,
      elementOuterWidth,
      targetOuterWidth,
      viewport,
    });

    element.style.top = top ? `${top}px` : 'auto';
    element.style.left = `${left}px`;
    element.style.bottom = bottom ? `${bottom}px` : 'auto';

    if (maxHeight) {
      element.style.maxHeight = `${maxHeight}px`;
    }
  },
};

const absolutePositionDirection = ({
  fromTopToTarget,
  fromTargetToBottom,
  elementOuterHeight,
  targetOffset,
  bottomOffset,
  targetOuterHeight,
  elementOuterWidth,
  targetOuterWidth,
  viewport,
}: {
  fromTopToTarget: number;
  fromTargetToBottom: number;
  elementOuterHeight: number;
  targetOffset: DOMRect;
  bottomOffset: number;
  targetOuterHeight: number;
  elementOuterWidth: number;
  targetOuterWidth: number;
  viewport: ViewPort;
}): { top?: number; left?: number; bottom?: number; maxHeight?: number } => {
  let top, left, bottom, maxHeight;

  if (fromTopToTarget > fromTargetToBottom) {
    // open above target
    if (elementOuterHeight > fromTopToTarget) {
      // if menu height is larger then available space
      top = 60;
      bottom = undefined;
      maxHeight = fromTopToTarget;
    } else {
      // if menu height is smaller then available space
      top = targetOffset.top - elementOuterHeight;
      bottom = undefined;
      maxHeight = elementOuterHeight;
    }
  } else {
    // open below target
    if (elementOuterHeight > fromTargetToBottom) {
      // if menu height is larger then available space
      {
        top = targetOffset.top + targetOuterHeight;
        bottom = bottomOffset;
        maxHeight = fromTargetToBottom;
      }
    } else {
      // if menu height is smaller then available space
      top = targetOffset.top + targetOuterHeight;
      if (top < 60) {
        top = 60;
      }
      bottom = undefined;
      maxHeight = elementOuterHeight;
    }
  }

  if (targetOffset.left + elementOuterWidth > viewport.width) {
    left = Math.max(0, targetOffset.left + targetOuterWidth - elementOuterWidth);
  } else {
    left = targetOffset.left;
  }

  return { top, left, bottom, maxHeight };
};
