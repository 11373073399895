import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoaderInterceptor, ErrorInterceptor, JwtInterceptor, WatcherInterceptor } from '@shared/interceptors';
import { LoaderService, sentryProviders, APP_ENV_CONFIG, getEnvironment } from '@shared/services';
import { getAppBaseHref, SetPageTitle } from '@shared/utils';
import { AuthGuard, NoAuthGuard, CanDeactivateGuard, FeatureToggleGuard } from '@shared/guards';
import { SharedModule } from '@shared/shared.module';
import {
  metaReducers,
  ROOT_REDUCERS,
  MessagesEffects,
  ModalsEffects,
  fromAuth,
  fromRecipient,
  fromTransaction,
  AuthEffects,
  RecipientEffects,
  TransactionSolutionEffects,
  fromDocument,
  DocumentEffects,
  fromCustomer,
  CustomerBeneficiaryEffects,
  fromFinancialAccount,
  FinancialAccountsEffects,
  CardAccountEffects,
  fromBusinessAccount,
  BusinessAccountBeneficiaryEffects,
  transactionFeature,
  TransactionsEffects,
  featureManagementFeature,
  FeatureManagementEffects,
  transactionFormFeature,
  TransactionFormEffects,
  fromReport,
  ReportEffects,
  fromAggregatedAccountBalance,
  AggregatedAccountBalanceEffects,
  AuditEffects,
  fromAudit,
  fromIndustries,
  IndustryEffects,
  fromAdaRegistration,
  AdaRegistrationEffects,
  AdaRegistrationDetailsEffects,
} from '@shared/store';
import { AppRoutingModule } from './app-routing.module';
import { PortalClientAppComponent } from './portal-client-app';

@NgModule({
  declarations: [PortalClientAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([MessagesEffects, ModalsEffects]),
    StoreModule.forFeature(fromRecipient.recipientFeatureKey, fromRecipient.reducers),
    StoreModule.forFeature(fromTransaction.transactionFeatureKey, fromTransaction.reducers),
    StoreModule.forFeature(fromDocument.documentFeatureKey, fromDocument.reducers),
    StoreModule.forFeature(fromCustomer.customerFeatureKey, fromCustomer.reducers),
    StoreModule.forFeature(fromAggregatedAccountBalance.aggregatedAccountBalanceFeatureKey, fromAggregatedAccountBalance.reducers),
    StoreModule.forFeature(fromBusinessAccount.businessAccountFeatureKey, fromBusinessAccount.reducers),
    StoreModule.forFeature(fromFinancialAccount.financialAccountFeatureKey, fromFinancialAccount.reducers),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    StoreModule.forFeature(fromReport.reportFeatureKey, fromReport.reducers),
    StoreModule.forFeature(transactionFeature),
    StoreModule.forFeature(transactionFormFeature),
    StoreModule.forFeature(featureManagementFeature),
    StoreModule.forFeature(fromAudit.auditFeatureKey, fromAudit.reducers),
    StoreModule.forFeature(fromIndustries.industryFeatureKey, fromIndustries.reducers),
    StoreModule.forFeature(fromAdaRegistration.adaRegistrationFeatureKey, fromAdaRegistration.reducers),
    EffectsModule.forFeature([
      AuthEffects,
      RecipientEffects,
      TransactionSolutionEffects,
      DocumentEffects,
      CustomerBeneficiaryEffects,
      BusinessAccountBeneficiaryEffects,
      CardAccountEffects,
      TransactionsEffects,
      FeatureManagementEffects,
      TransactionFormEffects,
      FinancialAccountsEffects,
      ReportEffects,
      AggregatedAccountBalanceEffects,
      AuditEffects,
      IndustryEffects,
      AdaRegistrationEffects,
      AdaRegistrationDetailsEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ navigationActionTiming: NavigationActionTiming.PostActivation }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: getEnvironment().production }),
  ],
  providers: [
    LoaderService,
    AuthGuard,
    NoAuthGuard,
    FeatureToggleGuard,
    CanDeactivateGuard,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: SetPageTitle, deps: [Router, Title], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WatcherInterceptor, multi: true },
    { provide: APP_BASE_HREF, useFactory: getAppBaseHref },
    { provide: APP_ENV_CONFIG, useFactory: getEnvironment },
    ...sentryProviders(),
  ],
  bootstrap: [PortalClientAppComponent],
})
export class PortalAppModule {}
