import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { filterDateToObject, filterFormatDate } from '@shared/utils';
import { isValid } from 'date-fns';
import { isString } from 'lodash-es';

@Injectable()
class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly delimiter = '/';

  parse(value: string): NgbDateStruct | null {
    return filterDateToObject(value, this.delimiter);
  }

  format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }

    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return [month, day, date.year].join(this.delimiter);
  }
}

@Component({
  selector: 'app-filter-custom-date-range-selector',
  templateUrl: './filter-custom-date-range-selector.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class FilterCustomDateRangeSelectorComponent implements AfterViewChecked, OnChanges {
  @ViewChild('datepicker') datepicker!: NgbDatepicker;

  @ViewChild('dpCustomDateFrom') dpCustomDateFrom!: ElementRef<HTMLInputElement>;

  @ViewChild('dpCustomDateTo') dpCustomDateTo!: ElementRef<HTMLInputElement>;

  @Input() props?: { [key: string]: string };

  @Input() selectedValue?: string;

  @Input() showCalendar?: boolean;

  @Input() calendarDisplayName?: string;

  @Output() changed = new EventEmitter<string>();

  @Output() errored = new EventEmitter<boolean>();

  @Output() closed = new EventEmitter();

  customDateModel: NgbDateStruct | null = null;

  fromDate: NgbDate | null = null;

  toDate: NgbDate | null = null;

  hoveredDate: NgbDate | null = null;

  minStartDate = filterFormatDate(new Date('2021-01-01T00:00'));

  maxDate: NgbDateStruct | null = null;

  inputFromValue = this.formatter.format(this.fromDate);

  inputToValue = this.formatter.format(this.toDate);

  constructor(public ref: ChangeDetectorRef, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue?.currentValue && isString(changes.selectedValue.currentValue)) {
      const parsedDate = changes.selectedValue.currentValue.split('::')!;
      const [type, from, to] = parsedDate;
      this.selectedValue = type;
      if (type === 'CUSTOM' && isValid(new Date(from)) && isValid(new Date(to))) {
        this.fromDate = filterFormatDate(new Date(from + 'T00:00'));
        this.toDate = filterFormatDate(new Date(to + 'T00:00'));
        this.inputFromValue = this.formatter.format(this.fromDate);
        this.inputToValue = this.formatter.format(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
        this.datepicker?.writeValue(undefined);
        this.customDateModel = null;
        this.inputFromValue = this.formatter.format(this.fromDate);
        this.inputToValue = this.formatter.format(this.toDate);
      }

      this.validateCustomDate();
    }
  }

  customFromDateInput(currentValue: NgbDate | null, input: string): void {
    const parsed = this.formatter.parse(input);
    const result = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;

    if (!result || (result && `${result.year}`.length !== 4) || result.year < 2020) {
      return;
    }

    this.datepicker?.writeValue(result);
    this.fromDate = result;
    this.datepicker?.navigateTo(result);
  }

  customFromDateBlur(currentValue: NgbDate | null) {
    if (this.dpCustomDateFrom?.nativeElement) {
      this.dpCustomDateFrom.nativeElement.value = this.formatter.format(currentValue);
    }
  }

  customToDateInput(currentValue: NgbDate | null, input: string): void {
    const parsed = this.formatter.parse(input);
    const result = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;

    if (!result || (result && `${result.year}`.length !== 4)) {
      return;
    }

    this.datepicker?.writeValue(result);
    this.toDate = result;
    this.datepicker?.navigateTo(result);
  }

  customToDateBlur(currentValue: NgbDate | null) {
    if (this.dpCustomDateTo?.nativeElement) {
      this.dpCustomDateTo.nativeElement.value = this.formatter.format(currentValue);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.inputFromValue = this.formatter.format(this.fromDate);
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.props?.maxMonths) {
      this.maxDate = this.calendar.getNext(this.fromDate, 'm', parseInt(this.props.maxMonths, 10));
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  isStartDate(date: NgbDate) {
    return this.fromDate && date.equals(this.fromDate);
  }

  isEndDate(date: NgbDate) {
    return this.toDate && date.equals(this.toDate);
  }

  onClearEndDateClick() {
    this.toDate = null;
    this.datepicker.writeValue(this.fromDate);
    this.validateCustomDate();
  }

  validateCustomDate() {
    if (this.selectedValue === 'CUSTOM' && (!this.fromDate || !this.toDate)) {
      this.errored.emit(true);
      return;
    }

    this.errored.emit(false);
  }

  onSave(): void {
    this.validateCustomDate();
    if (this.fromDate && this.toDate) {
      const fromDate = `${this.fromDate.year}-${(this.fromDate.month < 10 ? '0' : '') + this.fromDate.month}-${
        (this.fromDate.day < 10 ? '0' : '') + this.fromDate.day
      }`;
      const toDate = `${this.toDate.year}-${(this.toDate.month < 10 ? '0' : '') + this.toDate.month}-${
        (this.toDate.day < 10 ? '0' : '') + this.toDate.day
      }`;

      this.changed.emit(`CUSTOM::${fromDate}::${toDate}`);
      this.closed.emit();
    }
  }

  onClose(): void {
    this.closed.emit();
  }
}
