<div class="history-item" [class.is-last]="isLastItem" *ngIf="!loading">
  <div class="item-status" [ngClass]="'status--' + item.status" [class.status-link]="!isSubItem && item.parentTransactionId">
    <a
      [routerLink]="['/', 'app', 'transactions', 'transactions', item.parentTransactionId]"
      class="item-status-link"
      *ngIf="!isSubItem && item.parentTransactionId && item.parentTransactionId !== currentTransactionId"
    ></a>

    <a
      [routerLink]="['/', 'app', 'transactions', 'transactions', item.transactionId]"
      class="item-status-link"
      *ngIf="!isSubItem && item.parentTransactionId && item.parentTransactionId === currentTransactionId"
    ></a>
  </div>
  <div class="date">{{ item.createdAt | dateFormat }}</div>
  <div class="message">{{ item.description }}</div>
  <div class="parent-transaction-id" *ngIf="item.parentTransactionId && !isSubItem">
    Linked Transaction ID:
    <a
      [routerLink]="['/', 'app', 'transactions', 'transactions', item.transactionId]"
      *ngIf="item.parentTransactionId === currentTransactionId"
      >{{ item.transactionId.split('-')[0] | uppercase }}</a
    >

    <a
      [routerLink]="['/', 'app', 'transactions', 'transactions', item.parentTransactionId]"
      *ngIf="item.parentTransactionId !== currentTransactionId"
      >{{ item.parentTransactionId.split('-')[0] | uppercase }}</a
    >
  </div>
  <div class="author">Created by {{ item.employeeName }}</div>
  <button class="sub-items-toggle-btn" (click)="expandSubItems()" *ngIf="!isSubItem && hasSubItems">
    <rkt-icon [name]="isSubItemsExpanded ? 'chevron-up' : 'chevron-down'"></rkt-icon>
  </button>
  <div class="sub-items" *ngIf="isSubItemsExpanded">
    <ng-container *ngIf="!isSubItemsLoading; else historySubItemsLoading">
      <div class="history-item is-empty" *ngIf="!transactionHistorySubItems?.length">No History found.</div>

      <app-history-item
        *ngFor="let subItem of transactionHistorySubItems; let last = last"
        [item]="subItem"
        [isLastItem]="last"
        [isSubItem]="true"
      ></app-history-item>
    </ng-container>

    <ng-template #historySubItemsLoading>
      <app-history-item [loading]="true" [isFirstItem]="true"></app-history-item>
      <app-history-item [loading]="true"></app-history-item>
      <app-history-item [loading]="true" [isLastItem]="true"></app-history-item>
    </ng-template>
  </div>
</div>

<div class="history-item loading" [class.is-last]="isLastItem" *ngIf="loading">
  <div class="item-status status--loading"></div>

  <app-text-with-placeholder class="date" [showPlaceholder]="true"></app-text-with-placeholder>

  <app-text-with-placeholder class="placeholder-items message" [showPlaceholder]="true"></app-text-with-placeholder>

  <div class="placeholder-item-author author">
    <app-text-with-placeholder [showPlaceholder]="true"></app-text-with-placeholder>
  </div>
</div>
