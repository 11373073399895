export const ERROR_CODE = {
  userAlreadyExists: 409,
};

export const TRANSACTION_ERROR_CODES = ['non-sufficient-funds', 'insufficient-limit'];

export const CARD_ACCOUNT_ERROR_CODES = ['activate-not-allowed'];

export const IDV_ERROR_CODES = [
  'invalid-account',
  'not-found-customer',
  'not-found-business',
  'invalid-profile',
  'not-found-profile',
  'rule-validation',
  'duplicated-application',
];

export const IDV_ERROR_MESSAGES_BY_CODES: { [key: string]: string } = {
  'invalid-account': 'Invalid Account selected.',
  'not-found-customer': 'Customer Account not found.',
  'not-found-business': 'Business Account not found.',
  'invalid-profile': 'Invalid IDV profile selected.',
  'not-found-profile': 'IDV Profile not found.',
  'rule-validation': 'Missing Information, please fill out the following customer information and try again.',
  'duplicated-application': 'The selected Account has another IDV Application in Progress.',
};
