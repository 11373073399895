import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-move-money-confirm-changes-modal',
  templateUrl: 'move-money-confirm-changes-modal.component.html',
})
export class MoveMoneyConfirmChangesModalComponent {
  constructor(public activeModal: ActiveModal) {}
}
