<ng-container *ngIf="!isEmptyAddress; else emptyAddress">
  <ng-container *ngFor="let item of addressesByType | keyvalue: keepOriginalOrder">
    <ng-container *ngIf="item.value.length">
      <div class="details-section-title">
        <div class="title"><rkt-icon [name]="getAddressIconName(item.key)"></rkt-icon>{{ getAddressTitle(item.key) }}</div>
      </div>
      <div class="details-section address-section">
        <div class="details-row">
          <app-details-item
            *ngFor="let address of item.value; let i = index"
            [isPrimary]="address.primary"
            [showPlaceholder]="isLoading"
            [label]="getAddressLabel(address, i)"
            [value]="address.type === 'SHIPPING' && !!getContactFullName(address) ? getContactFullName(address) : address?.streetLine1"
          >
            <ng-container>
              <div *ngIf="address.type === 'SHIPPING' && !!getContactFullName(address)" class="address-margin">
                {{ address?.streetLine1 }}
              </div>
              <div>{{ address?.streetLine2 }}</div>
              <div>{{ address?.cityName }}, {{ address?.stateCode }}</div>
              <div>{{ address?.zipCode }} {{ address?.countryCode }}</div>
            </ng-container>
          </app-details-item>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyAddress>
  <div class="details-section-title">
    <div class="title"><rkt-icon name="location"></rkt-icon>Address</div>
  </div>
  <div class="details-section address-section">
    <div class="details-row">
      <app-details-item [value]="" [showPlaceholder]="isLoading"></app-details-item>
    </div>
  </div>
</ng-template>
