<h1 class="title">Add {{ customerType | titlecase }}</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form add-customer">
  <form [formGroup]="entityForm">
    <ng-container *ngIf="customerType === 'BUSINESS'">
      <div class="form-step">
        <div class="step-name">Business Details</div>
      </div>

      <rkt-form [form]="entityForm" [fields]="businessCustomerNameField" [model]="formModel" class="form-step-block"></rkt-form>
    </ng-container>

    <div class="form-step">
      <div class="step-name">{{ customerType === 'INDIVIDUAL' ? 'Basic Information' : 'Contact Person Information' }}</div>
    </div>

    <rkt-form [form]="entityForm" [fields]="customerCreateFields" [model]="formModel"></rkt-form>
  </form>

  <div class="note-container">
    <app-note-form-item [errors]="note.errors" [value]="note.value">
      <textarea
        class="note-field"
        (blur)="onNoteFieldBlur()"
        name="new-note"
        id="new-note"
        [formControl]="note"
        maxlength="1000"
      ></textarea>
    </app-note-form-item>
  </div>

  <div class="additional-information">
    <button
      class="additional-information-button"
      [class.active]="!isAdditionalInfoCollapsed"
      title="Additional Information"
      (click)="toggleCollapsable()"
    >
      Additional Information
      <rkt-icon [name]="isAdditionalInfoCollapsed ? 'chevron-down' : 'chevron-up'"></rkt-icon>
    </button>
  </div>

  <div [hidden]="isAdditionalInfoCollapsed">
    <form [formGroup]="entityIDVForm">
      <rkt-form [form]="entityIDVForm" [fields]="customerIDVFields" [model]="formModel"></rkt-form>
    </form>

    <app-address-add
      (changed)="setAddresses($event)"
      [initialAddresses]="this.addresses"
      (isFormInvalid)="setIsAddressFormInvalid($event)"
    ></app-address-add>
    <div class="separator-line"></div>
  </div>

  <div *ngIf="formError" class="details-page-edit-form-error">{{ formError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      rktButton
      type="submit"
      (click)="onSubmit()"
      [disabled]="!entityForm.valid || isAddressFormInvalid || !entityIDVForm.valid"
      title="Add"
    >
      Add {{ customerType | titlecase }}
    </button>
  </div>
</div>
