import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formInput, formRow, formSelect, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { AchStandardEntryClassCode, FormModel, TransactionSettlementPriority, MoveHowAchDetails } from '@shared/models';
import { achPullEntryTypeCodes, achPushEntryTypeCodes } from '@shared/services';
import { AbstractMoveMoneyBase } from '@shared/components';

@Component({
  selector: 'app-move-how-ach-details',
  templateUrl: './move-how-ach-details.component.html',
  styleUrls: ['./move-how-ach-details.component.scss'],
})
export class MoveHowAchDetailsComponent implements AfterViewChecked {
  @Input() isSameDayWindowDisabled?: boolean;

  @Input() isLinXMoveMoney = false;

  @Output() selected = new EventEmitter<MoveHowAchDetails>();

  @Output() blurred = new EventEmitter<boolean>();

  achDetailsForm = new FormGroup({});

  secCodeField: RktFormFieldConfig[] = [
    formRow([
      formSelect({
        key: 'rkorACHEntryType',
        label: 'Standard Entry Class (SEC) Code',
        className: 'sec-selector-field',
        props: {
          required: true,
          options: [],
          fieldTooltip:
            '3-letter code that describes a class of ACH transaction. Each class is distinguished by characteristics such as the parties involved in the transaction (Corporate vs Individual),  the type of authorization required for the transaction, Debit or Credit, Single vs Recurring and transaction limits',
        },
        expressions: {
          'props.disabled': () => this.isLinXMoveMoney,
        },
      }),
    ]),
  ];

  achDetailsField: FormlyFieldConfig[] = [
    formRow([
      formInput({
        key: 'rkorACHIndividualId',
        label: 'Individual ID',
        className: 'ach-details-field',
        props: {
          required: true,
          blur: () => this.onAchDetailsBlur(),
          fieldTooltip: 'Transaction ID',
        },
        expressions: {
          'props.maxLength': 'model.rkorACHEntryType === "CIE" ? 22 : 15',
          hide: "model.rkorACHEntryType ? !['CIE', 'PPD', 'TEL'].includes(model.rkorACHEntryType) : true",
        },
      }),
      formInput({
        key: 'rkorACHCheckSerialNumber',
        label: 'Check Serial Number',
        className: 'ach-details-field',
        props: {
          required: true,
          fieldTooltip: 'Serial number that appears on a check',
          blur: () => this.onAchDetailsBlur(),
        },
        expressions: {
          'props.maxLength': 'model.rkorACHEntryType && ["POP"].includes(model.rkorACHEntryType) ? 9 : 15',
          hide: "model.rkorACHEntryType ? !['ARC', 'BOC', 'POP', 'RCK'].includes(model.rkorACHEntryType) : true",
        },
      }),
      formInput({
        key: 'rkorACHTerminalCity',
        label: 'Terminal City',
        className: 'ach-details-field',
        props: {
          required: true,
          maxLength: 4,
          fieldTooltip: 'Truncated name or abbreviation of the city, town, village or township in which the electronic terminal is located',
          blur: () => this.onAchDetailsBlur(),
        },
        expressions: {
          hide: "model.rkorACHEntryType !== 'POP'",
        },
      }),
      formInput({
        key: 'rkorACHTerminalState',
        label: 'Terminal State',
        className: 'ach-details-field',
        props: {
          required: true,
          maxLength: 4,
          fieldTooltip: 'The state within the USA. where the electronic terminal is located',
          blur: () => this.onAchDetailsBlur(),
        },
        expressions: {
          hide: "model.rkorACHEntryType !== 'POP'",
        },
      }),
    ]),
  ];

  achDetailsModel: FormModel<{
    rkorACHEntryType?: AchStandardEntryClassCode;
    rkorACHIndividualId?: string;
    rkorACHCheckSerialNumber?: string;
    rkorACHTerminalCity?: string;
    rkorACHTerminalState?: string;
  }> = {
    rkorACHEntryType: undefined,
    rkorACHIndividualId: undefined,
    rkorACHCheckSerialNumber: undefined,
    rkorACHTerminalCity: undefined,
    rkorACHTerminalState: undefined,
  };

  achType = new FormControl<TransactionSettlementPriority | null>(null);

  constructor(public ref: ChangeDetectorRef, private moveMoneyService: AbstractMoveMoneyBase) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  setAchEntryTypeOptions(): void {
    const { moveDirection } = this.moveMoneyService.moveHow;
    if (!moveDirection) {
      return;
    }

    if (this.secCodeField[0]?.fieldGroup![0].props) {
      this.secCodeField[0].fieldGroup[0].props.options = moveDirection === 'pull' ? achPullEntryTypeCodes : achPushEntryTypeCodes;
    }

    if (this.isLinXMoveMoney) {
      this.achDetailsForm.setValue({ rkorACHEntryType: 'PPD' });
    }
  }

  setInitialValues(): void {
    this.setAchEntryTypeOptions();

    const { moveAchEntryType, moveAchType, rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } =
      this.moveMoneyService.moveHowAchDetails || {};

    this.achDetailsModel = {
      ...this.achDetailsModel,
      rkorACHEntryType: moveAchEntryType,
      rkorACHIndividualId,
      rkorACHCheckSerialNumber,
      rkorACHTerminalCity,
      rkorACHTerminalState,
    };

    if (moveAchType) {
      this.achType.setValue(moveAchType);
    }
  }

  onAchTypeSelect(achType?: TransactionSettlementPriority) {
    if (achType) {
      this.achType.setValue(achType);
    }
    this.onMoveHowAchDetailsSelect();
    this.blurred.emit(true);
  }

  onSecCodeChange(): void {
    this.achDetailsModel = {
      ...this.achDetailsModel,
      rkorACHIndividualId: undefined,
      rkorACHCheckSerialNumber: undefined,
      rkorACHTerminalCity: undefined,
      rkorACHTerminalState: undefined,
    };
    this.achDetailsForm.markAsUntouched();
    this.onMoveHowAchDetailsSelect();
    this.blurred.emit();
  }

  onAchDetailsChange(): void {
    this.onMoveHowAchDetailsSelect();
  }

  onAchDetailsBlur(): void {
    this.blurred.emit(true);
  }

  resetAchType() {
    this.achType.reset();
  }

  onMoveHowAchDetailsSelect() {
    this.selected.emit({
      moveAchType: this.achType?.value ?? undefined,
      moveAchEntryType: this.achDetailsModel.rkorACHEntryType,
      rkorACHIndividualId: this.achDetailsModel.rkorACHIndividualId,
      rkorACHCheckSerialNumber: this.achDetailsModel.rkorACHCheckSerialNumber,
      rkorACHTerminalCity: this.achDetailsModel.rkorACHTerminalCity,
      rkorACHTerminalState: this.achDetailsModel.rkorACHTerminalState,
    });
  }

  resetValue(): void {
    this.achType.reset();
    this.achDetailsForm.reset();
  }
}
