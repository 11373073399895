import { createAction, props } from '@ngrx/store';
import { UserCredentials } from '@shared/models';

export namespace LoginPageActions {
  export const initialize = createAction('[Login Page] Initialize');

  export const login = createAction('[Login Page] Login', props<{ userCredentials: UserCredentials }>());

  export const resetLoginError = createAction('[Login Page] Reset Login Error');

  export const loggedInRedirect = createAction('[Landing Page] Logged In Redirect');

  export const splashSreenAnimationEnd = createAction('[Landing Page] Splash Screen Animation End');
}
