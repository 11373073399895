<h1 class="title">Add Financial Account</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form integrated-bank-account-form">
  <div class="form-title">Financial Account Details</div>

  <form [formGroup]="bankForm">
    <rkt-form [form]="bankForm" [fields]="bankFields" [model]="formModel"></rkt-form>
  </form>

  <form [formGroup]="addressForm">
    <div class="form-title">Billing Info</div>
    <rkt-form [form]="addressForm" [fields]="addressFields" [model]="formModel"></rkt-form>
  </form>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSubmit()" [disabled]="!bankForm.valid" title="Continue">Continue</button>
  </div>
</div>
