import { Component, Input, OnInit } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { MESSAGE } from '@shared/constants';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-error-modal',
  templateUrl: 'error-modal.component.html',
})
export class ErrorModalComponent implements OnInit {
  @Input() messageText = 'We are unable to complete this request. Please contact your administrator.';

  errorType?: string | number;

  modalTitle!: string;

  modalSubTitle!: string;

  constructor(public activeModal: ActiveModal) {}

  ngOnInit() {
    this.modalTitle = this.errorType === 403 ? MESSAGE.PERMISSION_DENIED_TITLE : this.modalTitle || 'Error';
  }

  modalInitData(errorMessage: string) {
    if (!isEmpty(errorMessage)) this.messageText = errorMessage;
  }
}
