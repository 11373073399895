<rkt-table
  [rows]="(merchants$ | async)!"
  [columns]="columns"
  (fetchData)="getMerchants($event)"
  [count]="(totalElements$ | async)!"
  [loadingIndicator]="(loading$ | async)!"
  [offset]="activePage"
  [messages]="{ emptyMessage: 'No Merchants found.' }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
  [sortParam]="sortParams"
  (rowActivated)="onRowClick($event)"
></rkt-table>
