import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DatatableColumn, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { SelectionType } from '@swimlane/ngx-datatable';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import {
  Customer,
  EntityFinancialAccountDetailsRaw,
  FilterField,
  FilterValues,
  FinancialAccountListItem,
  FinancialAccountWithAccountHolderList,
  FinancialAccountWithAccountHolderListItem,
  Recipient,
  TransactionDetails,
  TransactionSolution,
} from '@shared/models';
import {
  FinancialAccountService,
  NotificationService,
  mapRecipientFinancialAccountWithEntity,
  mapCustomerFinancialAccountWithEntity,
} from '@shared/services';
import { activeFilters, ErrorUtils } from '@shared/utils';
import { AddEntityWithFinancialAccountModalComponent, FilterMenuComponent, MoveAccountAddressFormComponent } from '@shared/components';

@Component({
  selector: 'app-entity-financial-account-selection',
  templateUrl: 'entity-financial-account-selection.component.html',
  styleUrls: ['entity-financial-account-selection.component.scss'],
})
export class EntityFinancialAccountSelectionComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('filterMenu') filterMenu!: FilterMenuComponent;

  @Input() transaction?: TransactionDetails;

  @Input() selectedMoveHowType?: TransactionSolution;

  @Input() selectedMoveDirection?: string;

  @Input() allowedTypes?: string[];

  @Input() isMoveFieldsDisabled?: boolean;

  @Input() stickyHeader!: boolean;

  @Input() isCardAccount!: boolean;

  @Output() selected = new EventEmitter<FinancialAccountListItem>();

  @Output() updated = new EventEmitter();

  entitiesFinancialAccounts: FinancialAccountWithAccountHolderListItem[] = [];

  totalEntityFinancialAccountElements = 0;

  selectedEntityFinancialAccounts: FinancialAccountWithAccountHolderListItem[] = [];

  selectedFinancialAccount?: FinancialAccountWithAccountHolderListItem;

  columns: DatatableColumn[] = [
    { name: 'Account Name', prop: 'accountHolderName', flexSize: 300 },
    { name: 'Account Info', prop: 'accountInfo', flexSize: 350 },
    { name: 'Phone', prop: 'accountHolderPhone', flexSize: 250 },
    { name: 'Email', prop: 'accountHolderEmail', flexSize: 300 },
    { name: 'Account Type', prop: 'accountHolderTypeDisplayName', flexSize: 180 },
    { name: 'Status', prop: 'state', fixedSize: 140, colAlign: 'right', colType: 'status-tag' },
  ];

  page = 0;

  financialAccountsLoading = true;

  SelectionType = SelectionType;

  filters: FilterField[] = [
    {
      name: 'accountHolderTypes',
      displayName: 'Account Holder Type',
      type: 'RADIO',
      options: [
        { label: 'All Type', value: undefined },
        { label: 'Customer', value: 'CUSTOMER' },
        { label: 'Recipient', value: 'RECIPIENT' },
      ],
    },
  ];

  activeFilters: FilterValues = {};

  emptyListMessage = 'No Financial Accounts found.';

  get activePage() {
    return this.page;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    private financialAccountService: FinancialAccountService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEntityFinancialAccountSelect(data: unknown): void {
    const { selected } = data as { selected: FinancialAccountWithAccountHolderListItem[] };
    if (!selected.length) {
      this.selectedEntityFinancialAccounts = [];
      this.selectedFinancialAccount = undefined;
      return;
    }

    this.selectedFinancialAccount = selected[0];

    if (
      this.selectedFinancialAccount &&
      this.selectedMoveHowType === 'wire' &&
      !this.selectedFinancialAccount.bankAccount?.billingAddress
    ) {
      const addressModalRef = this.modalService.open(MoveAccountAddressFormComponent, {
        className: 'entity-form-modal',
      });

      addressModalRef.componentInstance.modalInitData({ financialAccount: this.selectedFinancialAccount });

      addressModalRef.result.then(
        (result) => {
          if (result) {
            this.selectedFinancialAccount = result;
            const item = this.entitiesFinancialAccounts.find(
              (financialAccount) => financialAccount.id === this.selectedFinancialAccount!.id,
            )?.bankAccount;
            if (item) {
              item.billingAddress = this.selectedFinancialAccount!.bankAccount?.billingAddress;
            }

            this.selected.emit(this.selectedFinancialAccount);
          } else {
            this.resetValue();
          }
        },
        () => {
          this.resetValue();
        },
      );
    } else {
      this.selected.emit(this.selectedFinancialAccount);
    }
  }

  getEntitiesFinancialAccounts({ page, size }: FetchData): void {
    this.financialAccountsLoading = true;

    const selectedAccountHolderType = this.activeFilters['accountHolderTypes'];

    const accountHolderTypes = this.selectedMoveDirection === 'pull' ? ['CUSTOMER'] : ['RECIPIENT', 'CUSTOMER'];

    if (page && this.activePage !== page) {
      this.page = page;
    }

    this.financialAccountService
      .getFinancialAccountsWithAccountHolder({
        page,
        size,
        accountHolderTypes: selectedAccountHolderType ? [selectedAccountHolderType] : accountHolderTypes,
        type: this.isCardAccount ? 'CARD' : 'BANK',
        accountTypes: this.allowedTypes,
        state: ['ACTIVE', 'SUSPENDED'],
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.emptyListMessage = 'No Financial Accounts found.';
          this.onFinancialAccountFetchComplete(response);
        },
        error: (error) => {
          this.emptyListMessage = 'Unable to fetch Financial Accounts.';
          this.onFinancialAccountFetchComplete();
          ErrorUtils.catchError('financialAccountService.getFinancialAccountsWithAccountHolder error', error);
        },
      });
  }

  onFinancialAccountFetchComplete(response?: FinancialAccountWithAccountHolderList): void {
    this.entitiesFinancialAccounts = response?.items || [];
    this.financialAccountsLoading = false;
    this.totalEntityFinancialAccountElements = response?.totalElements || 0;
    // TODO check is selected FA highlighted on page change
    this.selectedEntityFinancialAccounts = this.entitiesFinancialAccounts.filter(
      (item) => item.id === this.transaction?.debitFinancialAccountId || item.id === this.transaction?.creditFinancialAccountId,
    );
    this.updated.emit();
  }

  resetValue(): void {
    this.selectedEntityFinancialAccounts = this.entitiesFinancialAccounts.filter(
      (item) => item.id === this.transaction?.debitFinancialAccountId || item.id === this.transaction?.creditFinancialAccountId,
    );

    this.selected.emit();
  }

  addAccountHolder(): void {
    this.closeMenu();

    const entityModalRef = this.modalService.open(AddEntityWithFinancialAccountModalComponent, {
      className: 'entity-form-modal',
    });

    const accountHolderTypes = this.selectedMoveDirection === 'pull' ? ['CUSTOMER'] : ['CUSTOMER', 'RECIPIENT'];

    entityModalRef.componentInstance.modalInitData({
      accountHolderTypes,
      bankAccountOnly: this.selectedMoveHowType === 'ach',
      cardOnly: this.selectedMoveHowType === 'push-to-card',
    });

    entityModalRef.result.then(
      (modalData) => this.onAccountHolderAdd(modalData),
      () => false,
    );
  }

  onAccountHolderAdd(modalData?: {
    recipient?: Recipient;
    customer?: Customer;
    financialAccount?: EntityFinancialAccountDetailsRaw;
  }): void {
    if (!modalData || !modalData.financialAccount) {
      return;
    }

    const { recipient, customer, financialAccount } = modalData;

    this.page = 0;
    this.getEntitiesFinancialAccounts({});

    if (financialAccount.accountHolderType === 'RECIPIENT') {
      this.selectedFinancialAccount = mapRecipientFinancialAccountWithEntity({
        ...financialAccount,
        recipient,
      });
    } else if (financialAccount.accountHolderType === 'CUSTOMER') {
      this.selectedFinancialAccount = mapCustomerFinancialAccountWithEntity({
        ...financialAccount,
        customer,
      });
    }

    this.selected.emit(this.selectedFinancialAccount);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.getEntitiesFinancialAccounts({});
  }

  closeMenu() {
    if (this.filterMenu?.isVisible) {
      this.filterMenu.toggle({} as MouseEvent);
    }
  }
}
