<div class="list-page-header">
  <div class="toggle-buttons" *ngIf="!isCustomerBenificiary">
    <div class="toggle-button" (click)="toggleIdvApplications.emit()">IDV Applications</div>
    <div class="toggle-button active">Watchlist Events</div>
  </div>
  <div class="list-page-controls">
    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>

      <app-filter-menu
        #filterMenu
        [initialValues]="activeFilters"
        [filters]="filters"
        (opened)="filterBtn.classList.add('opened')"
        (closed)="filterBtn.classList.remove('opened')"
        (saved)="applyFilters($event)"
      ></app-filter-menu>
    </div>

    <ng-container *ngTemplateOutlet="submitIdentityVerificationTemplate"></ng-container>
  </div>
</div>

<rkt-table
  [rows]="watchlistEvents"
  [columns]="columns"
  [count]="totalElements"
  [limit]="10"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [sortParam]="sortParams"
  (fetchData)="getWatchlistEvents($event)"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
></rkt-table>

<ng-template #submitIdentityVerificationTemplate>
  <button
    *ngIf="!isCustomerBenificiary"
    rktButton
    title="Submit Identity Verification"
    class="add-entity-btn"
    (click)="onSubmitIdentityVerification()"
    [disabled]="loading"
  >
    Submit Identity Verification
  </button>
</ng-template>
