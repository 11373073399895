import { isString, isNil } from 'lodash-es';
import parsePhoneNumber, { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';

export const toPhone = (val?: string, region: CountryCode = 'US'): string => {
  if (!isString(val) || isNil(val)) {
    return '';
  }

  const phoneNumber = parsePhoneNumber(val, region);

  if (!isValidPhoneNumber(val, region) || !phoneNumber) {
    return val;
  }

  return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
};
