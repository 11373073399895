<div class="parent-id-field rkt-form">
  <div class="rkt-form-control">
    <div class="rkt-form-field">
      <label class="rkt-form-label" [for]="selectId">{{ label }} <span *ngIf="required" class="asterisk"></span></label>
      <div class="rkt-form-select-field">
        <ng-select
          #select
          [id]="selectId"
          [labelForId]="selectId"
          class="rkt-form-select"
          [items]="items"
          [(ngModel)]="selectedItem"
          [clearable]="false"
          [searchable]="false"
          (change)="onChange()"
          bindLabel="label"
          bindValue="value"
          [loading]="loading"
          [disabled]="disabled"
          (scrollToEnd)="onScrollToEnd()"
          appendTo="body"
          [placeholder]="placeholder"
        ></ng-select>
      </div>
    </div>
  </div>
</div>
