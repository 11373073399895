import { Component, Input } from '@angular/core';
import { TransactionLimitItem } from '@shared/models';

@Component({
  selector: 'app-transaction-limit-item',
  templateUrl: './transaction-limit-item.component.html',
  styleUrls: ['./transaction-limit-item.component.scss'],
})
export class TransactionLimitItemComponent {
  @Input() limitItem!: TransactionLimitItem;

  get isSingle(): boolean {
    return this.limitItem.type === 'single';
  }
}
