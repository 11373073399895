<ng-container *ngIf="['not-business', 'business'].includes(type)">
  <ng-container *ngTemplateOutlet="type === 'not-business' ? entityPaymentDetailsSelect : businessFinancialAccountSelect"></ng-container>
</ng-container>

<ng-template #entityPaymentDetailsSelect>
  <app-entity-financial-account-selection
    [transaction]="transaction"
    [isCardAccount]="isCardAccount"
    [isMoveFieldsDisabled]="isMoveFieldsDisabled"
    [selectedMoveHowType]="selectedMoveHowType"
    [selectedMoveDirection]="selectedMoveDirection"
    [stickyHeader]="stickyHeader"
    [allowedTypes]="accountTypes"
    (selected)="selectedFinancialAccount.emit($event)"
    (updated)="listUpdated.emit()"
  ></app-entity-financial-account-selection>
</ng-template>

<ng-template #businessFinancialAccountSelect>
  <app-business-financial-account-selection
    [transaction]="transaction"
    [stickyHeader]="stickyHeader"
    [allowedTypes]="accountTypes"
    [selectedMoveHowType]="selectedMoveHowType"
    [selectedDebitFinancialAccount]="selectedDebitFinancialAccount"
    (selected)="selectedFinancialAccount.emit($event)"
    (updated)="listUpdated.emit()"
  ></app-business-financial-account-selection>
</ng-template>
