<div class="transaction-list">
  <rkt-table
    [rows]="transactions"
    [columns]="isMLT ? mltColumns : columns"
    (fetchData)="getTransactions($event)"
    [count]="totalElements"
    [loadingIndicator]="loading"
    [offset]="0"
    [limit]="5"
    [placeholderItemsCount]="5"
    [stickyHeader]="false"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    [footerHeight]="loading && !totalElements ? 0 : 80"
    dateTimeFormat="MM/dd/yy HH:mm"
    [sortParam]="sortParams"
    [rowHeight]="64"
    [isItemsAlignCenter]="true"
    (rowActivated)="onRowClick($event)"
  ></rkt-table>
</div>
