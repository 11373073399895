import { formRadioGroup, formRow } from '@rocketfinancialcorp/rocket-ui/form';
import { FilterField } from '@shared/models';

export const identityProfileOptions = [
  formRow([
    formRadioGroup({
      key: 'identityVerificationProfileId',
      label: 'Select IDV profile',
      className: 'multi-row-radio-group',
      props: {
        required: true,
        options: [],
      },
    }),
  ]),
];

export const getIdentityFilterFieldOptions = (customerType: string): FilterField[] => {
  return [
    {
      name: 'identityVerificationProfileId',
      displayName: 'IDV Profile',
      type: 'RADIO',
      options:
        customerType === 'businesses'
          ? [
              { label: 'All', value: undefined },
              { value: 'kyb-profile-1', label: 'Business and Beneficial Owner Check' },
            ]
          : [
              { label: 'All', value: undefined },
              { value: 'kyc-profile-2', label: 'Basic KYC Check' },
              { value: 'kyc-profile-1', label: 'Complete KYC and Fraud Check' },
            ],
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Awaiting Information', value: 'AWAITING_INFORMATION' },
        { label: 'Completed', value: 'COMPLETED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'New', value: 'NEW' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Pending Review', value: 'PENDING_REVIEW' },
        { label: 'Processing', value: 'PROCESSING' },
      ],
    },
    {
      name: 'outcome',
      displayName: 'Outcome',
      type: 'INPUT',
    },
    {
      name: 'createdAt',
      displayName: 'Created At',
      type: 'DATE',
      options: [
        { label: 'All Time', value: undefined },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'completedAt',
      displayName: 'Completed At',
      type: 'DATE',
      options: [
        { label: 'All Time', value: undefined },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      props: {
        maxMonths: '15',
      },
    },
  ];
};

export const watchlistEventsFilterFieldOptions: FilterField[] = [
  {
    name: 'id',
    displayName: 'ID',
    type: 'INPUT',
  },
  {
    name: 'createdAt',
    displayName: 'Created At',
    type: 'DATE',
    options: [
      { label: 'All Time', value: undefined },
      { label: 'Today', value: 'TODAY' },
      { label: 'Yesterday', value: 'YESTERDAY' },
      { label: 'This week', value: 'THIS_WEEK' },
      { label: 'This month', value: 'THIS_MONTH' },
      { label: 'Custom range', value: 'CUSTOM' },
    ],
    props: {
      maxMonths: '15',
    },
  },
  {
    name: 'status',
    displayName: 'Status',
    type: 'RADIO',
    options: [
      { label: 'All', value: undefined },
      { label: 'Awaiting Information', value: 'AWAITING_INFORMATION' },
      { label: 'Completed', value: 'COMPLETED' },
      { label: 'Error', value: 'ERROR' },
      { label: 'New', value: 'NEW' },
      { label: 'Pending', value: 'PENDING' },
      { label: 'Pending Review', value: 'PENDING_REVIEW' },
      { label: 'Processing', value: 'PROCESSING' },
    ],
  },
  {
    name: 'outcome',
    displayName: 'Outcome',
    type: 'INPUT',
  },
  {
    name: 'services',
    displayName: 'Services',
    type: 'INPUT',
  },
];
