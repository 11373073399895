import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { compact } from 'lodash-es';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, finalize, map, takeUntil } from 'rxjs';
import {
  formDatepicker,
  formInput,
  formRadioGroup,
  formRepeatSection,
  formRow,
  formSelect,
  formTextarea,
} from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAccountService, NotificationService } from '@shared/services';
import { DISPUTE_REASONS, DISPUTE_TYPES } from '@shared/enums';
import { CardDispute, CardDisputeQuestion, CreateDisputeData, FormModel } from '@shared/models';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';

interface DisputeQuestion {
  question?: string;
  answer?: string;
}

interface DisputeCreateModel {
  transactionId: string;
  disputeType: CardDispute['disputeType'];
  disputeReason: string;
  disputeDescription: string;
  disputeAmount: string;
  disputeQuestions: DisputeQuestion[];
  transactionDetailsPage?: boolean;
  financialAccountId: string;
  questions: Record<string, string>;
}

@Component({
  selector: 'app-dispute-create-modal',
  templateUrl: './dispute-create-modal.component.html',
  styleUrls: ['./dispute-create-modal.component.scss'],
})
export class DisputeCreateModalComponent implements AfterViewChecked, OnDestroy {
  loading = false;

  submitError = '';

  formModel: FormModel<DisputeCreateModel> = { disputeQuestions: [{ question: undefined, answer: undefined }] };

  disputeForm = new FormGroup({});

  disputeSteps: Record<string, string>[] = [
    { label: 'Dispute Detail', value: 'disputeDetail' },
    { label: 'Dispute Questions', value: 'disputeQuestions' },
    { label: 'Review', value: 'disputeReview' },
  ];

  stepCompleted: Record<string, boolean> = {};

  selectedStep: 'disputeDetail' | 'disputeQuestions' | 'disputeReview' = 'disputeDetail';

  disputeOptionalQuestions$ = new BehaviorSubject<{ label: string; value: string }[]>([]);

  disputeFields: FormlyFieldConfig[] = [
    formRow([
      formInput({
        key: 'transactionId',
        label: 'Dispute Transaction ID',
        props: { required: true },
        expressions: {
          'props.disabled': 'model?.transactionDetailsPage',
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'disputeType',
        label: 'Dispute Type',
        props: { options: Object.keys(DISPUTE_TYPES).map((key) => ({ label: DISPUTE_TYPES[key], value: key })), required: true },
      }),
    ]),
    formRow([
      formSelect({
        key: 'disputeReason',
        label: 'Dispute Reason',
        props: { options: Object.keys(DISPUTE_REASONS).map((key) => ({ label: DISPUTE_REASONS[key], value: key })), required: true },
      }),
    ]),
    formRow([
      formTextarea({
        key: 'disputeDescription',
        label: 'Dispute Description',
        className: 'dispute-create-description',
        props: { withCounter: true, maxLength: 1000, description: 'Do not enter the card/account details' },
      }),
    ]),
    formRow([
      formInput({
        key: 'disputeAmount',
        label: 'Dispute Amount',
        className: 'dispute-create-amount',
        props: {
          required: true,
          pattern: /^\d+$|(?=^.+$)^\d+\.\d{0,2}$/,
        },
        validation: {
          messages: {
            pattern: 'Please enter a valid amount.',
          },
        },
      }),
    ]),
  ];

  allQuestions?: CardDisputeQuestion[];

  disputeQuestionForm = new FormGroup({});

  disputeQuestionFields: FormlyFieldConfig[] = [];

  disputeOptionalQuestionForm = new FormGroup({});

  selectedType?: string;

  disputeOptionalQuestionFields: FormlyFieldConfig[] = [
    formRepeatSection({
      key: 'disputeQuestions',
      addText: 'Add Dispute Question',
      removeText: 'Remove',
      unstyled: true,
      fieldGroup: [
        formRow([
          formSelect({
            key: 'question',
            label: 'Dispute Question',
            props: {
              options: this.disputeOptionalQuestions$.asObservable().pipe(
                map((items) => {
                  return items.map((item) => ({
                    ...item,
                    disabled: this.formModel.disputeQuestions?.find((questionItem) => questionItem?.question === item.value),
                  }));
                }),
              ),
            },
          }),
        ]),
        formRow([
          formInput({
            key: 'answer',
            label: '',
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                const question = this.allQuestions?.find((item) => item.questionCode === field.model?.question);
                const isNotTextField = question?.disputeQuestionDatatype !== 'FREE_TEXT' || question?.choices?.length;

                return !field.model?.question || isNotTextField;
              },
            },
          }),
          formDatepicker({
            key: 'answer',
            label: '',
            className: 'half-row-field',
            props: { placeholder: 'DD/MM/YYYY', maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }) },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                const isNotDateField =
                  this.allQuestions?.find((item) => item.questionCode === field.model?.question)?.disputeQuestionDatatype !== 'DATE';

                return !field.model?.question || isNotDateField;
              },
            },
          }),
          formRadioGroup({
            key: 'answer',
            label: '',
            props: { options: [] },
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                const isNotRadioField = !this.allQuestions?.find((item) => item.questionCode === field.model?.question)?.choices?.length;
                return !field.model?.question || isNotRadioField;
              },
              'props.options': (field: FormlyFieldConfig) => {
                return (
                  this.allQuestions
                    ?.find((item) => item.questionCode === field.model?.question)
                    ?.choices?.map((choice) => ({ label: choice, value: choice })) || []
                );
              },
            },
          }),
        ]),
      ],
    }),
  ];

  get reviewQuestions() {
    const { questions = {}, disputeQuestions = [] } = this.formModel || {};

    const requiredQuestions = Object.keys(questions).map((questionCode) => {
      if (!questions[questionCode]) {
        return;
      }

      const requiredItem = this.allQuestions?.find((item) => item.questionCode === questionCode);
      return { ...requiredItem, answer: questions[questionCode] };
    });

    const optionalQuestions = disputeQuestions.map((question) => {
      if (!question.answer) {
        return;
      }

      const optionalItem = this.allQuestions?.find((item) => item.questionCode === question.question);
      return { ...optionalItem, answer: question.answer };
    });

    return compact([...requiredQuestions, ...optionalQuestions]);
  }

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
  ) {}

  modalInitData({ transactionId, financialAccountId }: { transactionId?: string; financialAccountId?: string }) {
    this.formModel = {
      ...this.formModel,
      transactionId: transactionId?.toUpperCase(),
      financialAccountId,
      transactionDetailsPage: !!transactionId,
    };
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDisputeDetailsModelChange(data: unknown) {
    const { disputeType, financialAccountId } = data as DisputeCreateModel;
    if (!disputeType || this.selectedType === disputeType) {
      return;
    }

    this.selectedType = disputeType;

    this.formModel = {
      ...this.formModel,
      disputeQuestions: [{ question: undefined, answer: undefined }],
      questions: undefined,
    };

    this.disputeOptionalQuestionForm.reset();

    this.cardAccountService
      .getCardDisputeQuestions({ financialAccountId, disputeType })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (response) => {
          this.allQuestions = response;
          const newFields = response
            .filter((item) => item.isRequired && !item.parentQuestionCode)
            .map((item) => {
              if (item.choices) {
                return formRow(
                  [
                    formRadioGroup({
                      key: item.questionCode,
                      label: item.questionDescription,
                      props: {
                        required: item.isRequired,
                        options: item.choices.map((choice) => ({ label: choice, value: choice })),
                      },
                    }),
                  ],
                  { className: 'row-with-separator' },
                );
              } else {
                return formRow(
                  [
                    formInput({
                      key: item.questionCode,
                      label: item.questionDescription,
                      props: { required: item.isRequired },
                    }),
                  ],
                  { className: 'row-with-separator' },
                );
              }
            });
          const newFieldsGroup = [{ key: 'questions', defaultValue: {}, fieldGroup: [...newFields] }];
          this.disputeQuestionFields = compact([...newFieldsGroup]);

          this.disputeOptionalQuestions$.next(
            response
              .filter((item) => !item.isRequired && !item.parentQuestionCode && item.disputeQuestionDatatype !== 'NOT_APPLICABLE')
              .map((q) => ({ value: q.questionCode, label: q.questionDescription })),
          );
        },
      });
  }
  onDisputeRequiredQuestionsModelChange() {
    if (this.allQuestions && this.formModel.questions) {
      const requiredQuestions = Object.keys(this.formModel.questions!);
      this.disputeOptionalQuestions$.next(
        compact(
          this.allQuestions
            .filter((item) => !item.isRequired && item.disputeQuestionDatatype !== 'NOT_APPLICABLE')
            .map((questionItem) => {
              if (questionItem.parentQuestionCode && !requiredQuestions.includes(questionItem.parentQuestionCode)) {
                return;
              }
              return { value: questionItem.questionCode, label: questionItem.questionDescription };
            }),
        ),
      );
    }
  }

  onDisputeOptionalQuestionsModelChange() {
    if (this.allQuestions) {
      this.disputeOptionalQuestions$.next(
        this.allQuestions
          .filter((item) => !item.isRequired && !item.parentQuestionCode && item.disputeQuestionDatatype !== 'NOT_APPLICABLE')
          .map((q) => ({ value: q.questionCode, label: q.questionDescription })),
      );
    }
  }

  onSubmit(): void {
    const { disputeType, disputeDescription, transactionId, disputeAmount, disputeReason, financialAccountId } = this.formModel;

    if (!disputeType || !transactionId || !disputeAmount || !disputeReason) {
      return;
    }

    const createDisputeData: CreateDisputeData = {
      disputeType,
      disputeDescription,
      disputedTransactions: [
        {
          transactionId,
          disputeAmount,
          disputeReason,
        },
      ],
      disputeQuestions: this.reviewQuestions.map((item) => {
        return {
          answer: item.answer!,
          questionCode: item.questionCode!,
          questionDescription: item.questionDescription!,
          disputeQuestionDatatype: item.disputeQuestionDatatype!,
          isRequired: item.isRequired!,
          choices: item.choices,
          parentQuestionCode: item.parentQuestionCode,
        };
      }),
    };

    this.loading = true;

    this.cardAccountService
      .createDispute(financialAccountId!, createDisputeData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.notificationService.displaySuccess('Dispute successfully created.');
        },
        error: (error) => {
          this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('cardAccountService.createDispute error', error);
          this.selectedStep = 'disputeDetail';
        },
      });
  }

  onDisputeDetailNextClick() {
    this.selectedStep = 'disputeQuestions';
    this.stepCompleted['disputeDetail'] = true;
  }

  onDisputeQuestionNextClick() {
    this.selectedStep = 'disputeReview';
    this.stepCompleted['disputeQuestions'] = true;
  }

  onDisputeQuestionBackClick() {
    this.selectedStep = 'disputeDetail';
    this.stepCompleted['disputeQuestions'] = false;
  }

  onDisputeReviewBackClick() {
    this.selectedStep = 'disputeDetail';
    this.stepCompleted['disputeQuestions'] = false;
    this.stepCompleted['disputeReview'] = false;
  }

  getDisputeTypeLabel(key: string): string {
    return DISPUTE_TYPES[key] ?? key;
  }

  getDisputeReasonLabel(key: string): string {
    return DISPUTE_REASONS[key] ?? key;
  }
}
