export interface DocumentType {
  label: string;
  value: string;
  icon: string;
}

export const individualCustomerDocumentTypes: DocumentType[] = [
  { label: 'Passport', value: 'passport', icon: 'passport' },
  { label: 'Driver’s License', value: 'driving-license', icon: 'driver-license' },
  { label: 'Other', value: 'other', icon: 'document' },
];

export const businessCustomerDocumentTypes: DocumentType[] = [
  { label: 'Formation', value: 'formation', icon: 'company' },
  { label: 'Tax', value: 'tax', icon: 'accounting' },
  { label: 'Ownership', value: 'ownership', icon: 'businessman' },
  { label: 'Other', value: 'other', icon: 'document' },
];
