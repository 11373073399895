import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { TransactionFormActions, selectEnabledSolutionsByType, transactionFormFeature } from '@shared/store';
import { MultiLegEnabledSolutionPermissions, MultiLegSolutionConfigSolutionName } from '@shared/models';

/* @Deprecated */
@Component({
  selector: 'app-method-selection',
  templateUrl: './method-selection.component.html',
})
export class MethodSelectionComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() type: 'from' | 'to' = 'from';

  fromMethod$ = this.store.select(transactionFormFeature.selectFromAccountMethod);

  toMethod$ = this.store.select(transactionFormFeature.selectToAccountMethod);

  isDebitLegsDisabled$ = this.store.select(transactionFormFeature.selectIsDebitLegsDisabled);

  solutions?: MultiLegEnabledSolutionPermissions;

  methods: { label: string; value: MultiLegSolutionConfigSolutionName; icon: string; isDisabled?: boolean }[] = [
    { label: 'ACH', value: 'ach', icon: 'ach' },
    { label: 'Push To Card', value: 'push-to-card', icon: 'card' },
    { label: 'Transfer', value: 'transfer', icon: 'transfer' },
    { label: 'Wire', value: 'wire', icon: 'bank' },
  ];

  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.store
      .select(selectEnabledSolutionsByType(this.type))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (solutions) => {
          this.solutions = solutions;

          /* Preselect Solution if only one option enabled */
          const enabledSolutions = Object.keys(solutions).filter((solution) => solutions[solution as MultiLegSolutionConfigSolutionName]);
          if (enabledSolutions.length === 1) {
            this.onMethodClick(enabledSolutions[0] as MultiLegSolutionConfigSolutionName);
          }
        },
      });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onMethodClick(method: MultiLegSolutionConfigSolutionName) {
    if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFromMethod({ method }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveToMethod({ method }));
    }
  }
}
