import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, catchError, finalize, of, takeUntil, tap } from 'rxjs';
import { CardDispute, CardDisputeQuestion, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { CardAccountService, NavigationService, NotificationService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Component({
  templateUrl: './dispute-details.component.html',
})
export class DisputeDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'dispute-details-';

  disputeDetailsContext?: TemplateContext<CardDispute>;

  disputeDetails$ = this.store.select(fromFinancialAccount.selectCardAccountDisputeDetails).pipe(
    tap({
      next: (cardDispute) => {
        if (!cardDispute) {
          return;
        }
        this.cardAccountService
          .getCardDisputeQuestions({ financialAccountId: cardDispute.financialAccountId!, disputeType: cardDispute.disputeType })
          .pipe(
            catchError((error?: string) => {
              ErrorUtils.catchError('cardAccountService.getCardDisputeQuestions error', error);
              return of([] as CardDisputeQuestion[]);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
              this.questionsLoading = false;
            }),
          )
          .subscribe({
            next: (response) => {
              this.allQuestions =
                response.reduce((obj: Record<string, string>, { questionCode, questionDescription }) => {
                  return {
                    ...obj,
                    [questionCode]: questionDescription,
                  };
                }, {}) ?? {};
            },
          });
      },
    }),
  );

  allQuestions?: Record<string, string>;

  questionsLoading = true;

  private destroy$ = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private store: Store,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadDisputeDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails());
  }

  ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
