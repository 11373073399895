import { Component, OnDestroy } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAccountService, NotificationService } from '@shared/services';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';
import { CardStatement } from '@shared/models';

@Component({
  selector: 'app-statement-email-modal',
  templateUrl: './statement-email-modal.component.html',
})
export class StatementEmailModalComponent implements OnDestroy {
  statement!: CardStatement;

  loading = false;

  submitError = '';

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private notificationService: NotificationService,
    private cardAccountService: CardAccountService,
  ) {}

  modalInitData(data: CardStatement) {
    this.statement = data;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.loading = true;
    this.cardAccountService
      .sendCardStatementByEmail(this.statement)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.notificationService.displaySuccess('Email Statement successfully sent');
          this.activeModal.close();
        },
        error: (error) => {
          this.submitError = MESSAGE.UNKNOWN_ERROR;
          ErrorUtils.catchError('cardAccountService.sendCardStatementByEmail', error);
        },
      });
  }
}
