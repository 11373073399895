import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formRow, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';
import { FormModel, MoveWireDetails } from '@shared/models';
import { AbstractMoveMoneyBase } from '@shared/components';

@Component({
  selector: 'app-move-wire-selection',
  templateUrl: './move-wire-selection.component.html',
})
export class MoveWireSelectionComponent implements AfterViewChecked {
  @Output() changed = new EventEmitter<MoveWireDetails>();

  @Output() blurred = new EventEmitter<boolean>();

  wireDetailsForm = new FormGroup({});

  wireDetailsField: FormlyFieldConfig[] = [
    formRow([
      formTextarea({
        key: 'narrative',
        label: 'Narrative',
        className: 'wire-details-field',
        props: {
          required: true,
          minLength: 1,
          maxLength: 1024,
          withCounter: true,
        },
      }),
    ]),
    formRow([
      formTextarea({
        key: 'memo',
        label: 'Memo',
        className: 'wire-details-field',
        props: {
          maxLength: 1024,
          withCounter: true,
          blur: () => this.onWireDetailsBlur(),
        },
      }),
    ]),
  ];

  wireDetailsModel: FormModel<{
    narrative?: string;
    memo?: string;
  }> = {
    narrative: undefined,
    memo: undefined,
  };

  constructor(public ref: ChangeDetectorRef, private moveMoneyService: AbstractMoveMoneyBase) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  setInitialValues() {
    const { narrative, memo } = this.moveMoneyService.wireDetails || {};

    this.wireDetailsModel = {
      ...this.wireDetailsModel,
      narrative,
      memo,
    };
  }

  onWireDetailsChange(): void {
    this.changed.emit({
      narrative: this.wireDetailsModel.narrative,
      memo: this.wireDetailsModel.memo,
    });
  }

  onWireDetailsBlur(): void {
    this.blurred.emit(true);
  }

  resetValue(): void {
    this.wireDetailsForm.reset();
    this.onWireDetailsChange();
  }
}
