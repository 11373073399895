import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import { TransactionService } from '@shared/services';
import { TransactionSolutionActions } from './transaction-solution.actions';

@Injectable()
export class TransactionSolutionEffects {
  constructor(private actions$: Actions, private transactionService: TransactionService) {}

  getEnabledSolutions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionSolutionActions.loadEnabledSolutions),
      exhaustMap(() =>
        this.transactionService.getEnabledSolutions().pipe(
          map((enabledSolutions) => {
            return TransactionSolutionActions.loadEnabledSolutionsSuccess({ enabledSolutions });
          }),
          catchError((error) => {
            return of(TransactionSolutionActions.loadEnabledSolutionsFailure({ error: error.message }));
          }),
        ),
      ),
    );
  });

  getSolutionsConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionSolutionActions.loadEnabledSolutionsSuccess),
      exhaustMap(({ enabledSolutions }) => {
        return this.transactionService.getSolutionConfigs(Object.keys(enabledSolutions.create || {})).pipe(
          map((enabledSolutionConfigs) => {
            return TransactionSolutionActions.loadEnabledSolutionConfigsSuccess({ enabledSolutionConfigs });
          }),
          catchError((error) => {
            return of(TransactionSolutionActions.loadEnabledSolutionConfigsFailure({ error: error.message }));
          }),
        );
      }),
    );
  });
}
