import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MoveHowDirectionItem, TransactionDirection } from '@shared/models';

@Component({
  selector: 'app-move-how-direction',
  templateUrl: './move-how-direction.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveHowDirectionComponent {
  @Input() items: MoveHowDirectionItem[] = [];

  @Input() selectedItem?: TransactionDirection;

  @Input() disabledTypes: TransactionDirection[] = [];

  @Input() className?: string;

  @Output() changed = new EventEmitter<TransactionDirection>();

  isDisabledType(type: TransactionDirection): boolean {
    return !!this.disabledTypes?.includes(type);
  }

  onItemClick(type: TransactionDirection): void {
    if (this.selectedItem === type) {
      return;
    }

    this.changed.emit(type);
  }
}
