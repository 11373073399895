import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, finalize, takeUntil } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { formDatepicker, formInput, formRadioGroup, formRow, formSelect, formTimepicker } from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAccountService, NotificationService } from '@shared/services';
import { RESTRICTION_MODES, RESTRICTION_TYPES, RESTRICTION_OPERATORS } from '@shared/enums';
import { CardRestriction, CreateRestrictionData, FormModel } from '@shared/models';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils, formatDateTimeToUTC, systemTimeZone } from '@shared/utils';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

interface RestrictionCreateModel {
  id?: string;
  financialAccountId: string;
  type: string;
  operator: string;
  value: string;
  mode: string;
  active: boolean;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

@Component({
  selector: 'app-restriction-create-modal',
  templateUrl: './restriction-create-modal.component.html',
})
export class RestrictionCreateModalComponent implements AfterViewChecked, OnDestroy {
  loading = false;

  isEdit = false;

  submitError = '';

  formModel: FormModel<RestrictionCreateModel> = { active: true };

  restrictionForm = new FormGroup({});

  restrictionFields = [
    formRow([
      formSelect({
        key: 'type',
        label: 'Type',
        props: { options: Object.keys(RESTRICTION_TYPES).map((key) => ({ label: RESTRICTION_TYPES[key], value: key })), required: true },
      }),
    ]),
    formRow([
      formSelect({
        key: 'operator',
        label: 'Operator',
        props: {
          options: Object.keys(RESTRICTION_OPERATORS).map((key) => ({ label: RESTRICTION_OPERATORS[key], value: key })),
          required: true,
        },
      }),
    ]),
    formRow([formInput({ key: 'value', label: 'Value', props: { required: true } })]),
    formRow([
      formSelect({
        key: 'mode',
        label: 'Mode',
        props: { options: Object.keys(RESTRICTION_MODES).map((key) => ({ label: RESTRICTION_MODES[key], value: key })), required: true },
      }),
    ]),
    formRow(
      [
        formRadioGroup({
          key: 'active',
          label: 'Enable',
          className: 'single-row-radio-group',
          props: {
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
            required: true,
          },
        }),
      ],
      { className: 'row-with-separator' },
    ),
    formRow([
      formDatepicker({
        key: 'startDate',
        label: 'Start Date',
        props: { placeholder: 'DD/MM/YYYY', maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }) },
        expressions: {
          'props.required': '!!model.startTime || !!model.startDate',
        },
      }),
      formTimepicker({
        key: 'startTime',
        label: 'Start Time',
        expressions: {
          'props.required': '!!model.startTime || !!model.startDate',
        },
      }),
    ]),
    formRow([
      formDatepicker({
        key: 'endDate',
        label: 'End Date',
        props: { placeholder: 'DD/MM/YYYY', maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }) },
        expressions: {
          'props.required': '!!model.endTime || !!model.endDate',
        },
      }),
      formTimepicker({
        key: 'endTime',
        label: 'End Time',
        expressions: {
          'props.required': '!!model.endTime || !!model.endDate',
        },
      }),
    ]),
  ];

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
  ) {}

  modalInitData(restriction: Partial<CardRestriction>) {
    const { id, financialAccountId, active, type, operator, value, mode, startDateTime, endDateTime } = restriction;
    if (id) {
      this.isEdit = true;
      const startDateParsed = startDateTime ? utcToZonedTime(new Date(startDateTime), systemTimeZone) : undefined;
      const startDate = startDateParsed ? format(startDateParsed, 'yyyy-MM-dd') : undefined;
      const startTime = startDateParsed ? format(startDateParsed, 'HH:mm:ss') : undefined;

      const endDateParsed = endDateTime ? utcToZonedTime(new Date(endDateTime), systemTimeZone) : undefined;
      const endDate = endDateParsed ? format(endDateParsed, 'yyyy-MM-dd') : undefined;
      const endTime = endDateParsed ? format(endDateParsed, 'HH:mm:ss') : undefined;
      this.formModel = {
        ...this.formModel,
        id,
        financialAccountId,
        active,
        type,
        operator,
        value,
        mode,
        startDate,
        startTime,
        endDate,
        endTime,
      };
    } else {
      this.formModel = { ...this.formModel, financialAccountId };
    }
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.restrictionForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    const { id, financialAccountId, type, operator, value, mode, active, startDate, startTime, endDate, endTime } = this.formModel;

    const createRestrictionData: CreateRestrictionData = {
      financialAccountId: financialAccountId!,
      type: type!,
      operator: operator!,
      value: value!,
      mode: mode!,
      active: active!,
      startDateTime: formatDateTimeToUTC({ date: startDate, time: startTime }),
      endDateTime: formatDateTimeToUTC({ date: endDate, time: endTime }),
    };

    this.loading = true;

    if (this.isEdit) {
      this.cardAccountService
        .editRestriction(id!, createRestrictionData)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.notificationService.displaySuccess('Restriction Details has been successfully updated');
          },
          error: (error) => {
            this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
            ErrorUtils.catchError('cardAccountService.editRestriction error', error);
          },
        });
    } else {
      this.cardAccountService
        .createRestriction(createRestrictionData.financialAccountId, createRestrictionData)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe({
          next: () => {
            this.activeModal.close();
            this.notificationService.displaySuccess('Restriction successfully added');
          },
          error: (error) => {
            this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
            ErrorUtils.catchError('cardAccountService.createRestriction error', error);
          },
        });
    }
  }
}
