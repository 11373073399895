import { Injectable } from '@angular/core';
import { FilterValues, RequestPageParams } from '@shared/models';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { environment } from '@env';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BackendService } from '@shared/services/backend.service';
import {
  EarnedWageCustomer,
  EarnedWageCustomerListItem,
  EarnedWageCustomerListRaw,
  EarnedWageListItem,
  EarnedWageListRaw,
  EarnedWageProgram,
  UpdateEwaCustomerParams,
} from '@shared/models/earned-wage';
import {
  earnedWageCustomerRequestParams,
  earnedWageRequestParams,
  mapEarnedWageCustomer,
  mapEarnedWageCustomerDetails,
  mapEarnedWageProgram,
  mapEarnedWageProgramDetails,
} from '@shared/services/earned-wage/earned-wage-mapping.utils';
import { constants } from '@shared/constants';
import { FinancialAccountService, StorageService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class EarnedWageService {
  ewaCustomerFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
    private financialAccountService: FinancialAccountService,
  ) {}

  public getEarnedWagePrograms(requestParams: RequestPageParams): Observable<EarnedWageListItem> {
    const params = earnedWageRequestParams(requestParams);

    return this.backendService.get<EarnedWageListRaw>(`${environment.earnedWageEndpoint}/earned-wage/program`, { params }).pipe(
      map((response) => mapEarnedWageProgram(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getEarnedWageProgram(programId: EarnedWageProgram['id']): Observable<EarnedWageProgram> {
    return this.backendService.get<EarnedWageProgram>(`${environment.earnedWageEndpoint}/earned-wage/program/${programId}`).pipe(
      map((programResponse) => mapEarnedWageProgramDetails(programResponse)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getEarnedWageProgramCustomers(
    programId: EarnedWageProgram['id'],
    requestParams: RequestPageParams,
  ): Observable<EarnedWageCustomerListItem> {
    let params = earnedWageRequestParams(requestParams);

    params = params.append('programId', programId);

    return this.backendService
      .get<EarnedWageCustomerListRaw>(`${environment.earnedWageEndpoint}/earned-wage/customer-profile`, { params })
      .pipe(
        map((customerResponse) => mapEarnedWageCustomer(customerResponse)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getEarnedWageProgramCustomer(requestParams: RequestPageParams, customerId: string): Observable<EarnedWageCustomer> {
    const params = earnedWageCustomerRequestParams(requestParams);

    return this.backendService
      .get<EarnedWageCustomer>(`${environment.earnedWageEndpoint}/earned-wage/customer-profile/${customerId}`, { params })
      .pipe(
        switchMap((customerDetails: EarnedWageCustomer) => {
          const customerFinancialAccountDetails$ = this.financialAccountService
            .getFinancialAccountById(customerDetails.financialAccountId)
            .pipe(
              catchError((error) => {
                ErrorUtils.catchError('financialAccountService.getFinancialAccountById', error);
                return of(undefined);
              }),
            );
          return forkJoin([customerFinancialAccountDetails$]).pipe(
            map(([financialAccountDetails]) => {
              return { ...customerDetails, financialAccountDetails };
            }),
          );
        }),
        map((customerResponse) => mapEarnedWageCustomerDetails(customerResponse)),
      );
  }

  public updateEarnedWageProgramCustomer(customerId: string, customerData: UpdateEwaCustomerParams): Observable<EarnedWageCustomer> {
    return this.backendService
      .post<EarnedWageCustomer>(`${environment.earnedWageEndpoint}/earned-wage/customer-profile/${customerId}/eligibility`, customerData)
      .pipe(
        map((customerResponse) => customerResponse),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public offBoardEarnedWageCustomer(id: EarnedWageCustomer['id']): Observable<EarnedWageCustomer> {
    return this.backendService
      .post<EarnedWageCustomer>(`${environment.earnedWageEndpoint}/earned-wage/customer-profile/${id}/offboard`)
      .pipe(
        map((customerResponse) => customerResponse),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public setFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_EWA_CUSTOMER_FILTER, filters);
    this.updateFilterParams(filters);
  }

  public getFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_EWA_CUSTOMER_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateFilterParams(filters);
    }
  }

  private updateFilterParams(filters: FilterValues): void {
    this.ewaCustomerFilter.next(filters);
  }
}
