import { Component, OnDestroy } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { WINDOW_RESIZE_DEBOUNCE_TIME } from '@shared/constants';
import { fromAuth } from '@shared/store';
import { ReportService } from '@shared/services';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { NaturalLanguageSearchModalComponent } from '../natural-language-search-modal/natural-language-search-modal.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  activeUser$ = this.store.select(fromAuth.selectUser);

  businessAccountId$ = this.store.select(fromAuth.selectBusinessAccountId);

  isSidenavOpened = false;

  searchString?: string;

  private destroy$ = new Subject<void>();

  constructor(private store: Store, private router: Router, private reportService: ReportService, private modalService: ModalService) {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (event: NavigationEvent) => {
          if (event instanceof NavigationEnd && this.isSidenavOpened) {
            this.isSidenavOpened = false;
          }
        },
      });

    fromEvent(window, 'resize')
      .pipe(debounceTime(WINDOW_RESIZE_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (this.isSidenavOpened) {
            this.isSidenavOpened = false;
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openSearchModal() {
    this.modalService.open(NaturalLanguageSearchModalComponent, {
      className: 'natural-language-search-modal',
    });
  }

  toggleMenu(): void {
    this.isSidenavOpened = !this.isSidenavOpened;
  }
}
