import {
  CardAlert,
  CardAlertRaw,
  CardDispute,
  CardDisputeRaw,
  CardRestriction,
  CardRestrictionRaw,
  FinancialAccountDetails,
  FinancialAccountDetailsWithBalancesRaw,
} from '@shared/models';
import {
  ALERT_TYPES,
  DISPUTE_REASONS,
  DISPUTE_STATUSES,
  DISPUTE_TYPES,
  I2C_CARD_REISSUE_REASONS,
  I2C_CARD_REISSUE_TYPES,
  I2C_CARD_SHIPPING_METHODS,
  I2C_CARD_STATUSES,
  RESTRICTION_MODES,
  RESTRICTION_OPERATORS,
  RESTRICTION_TYPES,
  THRESHOLD_TYPES,
} from '@shared/enums';
import { mapFinancialAccountDetails } from '../financial-account';

export const mapCardAlertItems = (items: CardAlertRaw[]): CardAlert[] => {
  return items.map((item) => mapCardAlertItem(item));
};

export const mapCardAlertItem = (item: CardAlertRaw): CardAlert => {
  return {
    ...item,
    enabledIcon: item.active ? 'done' : 'times',
    thresholdType: item.thresholdDto?.type,
    thresholdAmount: item.thresholdDto?.amount,
    typeLabel: ALERT_TYPES[item.type] ?? item.type,
    thresholdTypeLabel: item.thresholdDto?.type ? THRESHOLD_TYPES[item.thresholdDto?.type] ?? item.thresholdDto?.type : undefined,
  };
};

export const mapCardRestrictionItems = (items: CardRestrictionRaw[]): CardRestriction[] => {
  return items.map((item) => mapCardRestrictionItem(item));
};

export const mapCardRestrictionItem = (item: CardRestrictionRaw): CardRestriction => {
  return {
    ...item,
    enabledIcon: item.active ? 'done' : 'times',
    typeLabel: RESTRICTION_TYPES[item.type] ?? item.type,
    modeLabel: RESTRICTION_MODES[item.mode] ?? item.mode,
    operatorLabel: RESTRICTION_OPERATORS[item.operator] ?? item.operator,
  };
};

export const mapCardDisputeItems = (items: CardDisputeRaw[]): CardDispute[] => {
  return items.map((item) => mapCardDisputeItem(item));
};

export const mapCardDisputeItem = (item: CardDisputeRaw): CardDispute => {
  const reason = item.disputedTransactions![0]?.disputeReason;
  return {
    ...item,
    disputedTransactionId: item.disputedTransactions![0]?.transactionId,
    disputedTransactionAmount: item.disputedTransactions![0]?.disputeAmount,
    disputeReasonText: item.disputedTransactions![0]?.disputeReason,
    disputeTypeLabel: item.disputeType ? DISPUTE_TYPES[item.disputeType] ?? item.disputeType : undefined,
    disputeStatusLabel: item.disputeStatus ? DISPUTE_STATUSES[item.disputeStatus] ?? item.disputeStatus : undefined,
    disputeReasonLabel: reason ? DISPUTE_REASONS[reason] ?? reason : undefined,
  };
};

export const mapCardAccountDetails = (response: FinancialAccountDetailsWithBalancesRaw): FinancialAccountDetails => {
  const details = mapFinancialAccountDetails(response);
  const { cardStatus, shippingMethod, reissueReason, reissueType } = details.cardProviderDetails ?? {};
  return {
    ...details,
    cardProviderDetails: {
      ...details.cardProviderDetails!,
      cardStatusLabel: cardStatus ? I2C_CARD_STATUSES[cardStatus] ?? cardStatus : undefined,
      shippingMethodLabel: shippingMethod ? I2C_CARD_SHIPPING_METHODS[shippingMethod] ?? shippingMethod : undefined,
      reissueReasonLabel: reissueReason ? I2C_CARD_REISSUE_REASONS[reissueReason] ?? shippingMethod : undefined,
      reissueTypeLabel: reissueType ? I2C_CARD_REISSUE_TYPES[reissueType] ?? shippingMethod : undefined,
    },
  };
};
