import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as fromAdaRegistrations from './ada-registration.reducer';
import * as fromAdaRegistrationDetails from './ada-registration-details.reducer';

export const adaRegistrationFeatureKey = 'ada-registration';

export interface AdaRegistrationState {
  [fromAdaRegistrations.adaRegistrationListFeatureKey]: fromAdaRegistrations.AdaRegistrationListState;
  [fromAdaRegistrationDetails.adaRegistrationPageFeatureKey]: fromAdaRegistrationDetails.AdaRegistrationDetailsState;
}

export interface State extends fromRoot.RootState {
  [adaRegistrationFeatureKey]: AdaRegistrationState;
}

export const reducers = (state: AdaRegistrationState | undefined, action: Action) => {
  return combineReducers({
    [fromAdaRegistrations.adaRegistrationListFeatureKey]: fromAdaRegistrations.reducer,
    [fromAdaRegistrationDetails.adaRegistrationPageFeatureKey]: fromAdaRegistrationDetails.reducer,
  })(state, action);
};

export const selectAdaRegistrationState = createFeatureSelector<AdaRegistrationState>(adaRegistrationFeatureKey);

// ADA Registration list selectors
export const selectAdaRegistrationListState = createSelector(selectAdaRegistrationState, (state) => state.list);

export const selectAdaRegistrationList = createSelector(selectAdaRegistrationListState, fromAdaRegistrations.getAdaRegistrations);

export const selectAdaRegistrationsTotalElements = createSelector(selectAdaRegistrationListState, fromAdaRegistrations.getTotalElements);

export const selectAdaRegistrationsLoading = createSelector(selectAdaRegistrationListState, fromAdaRegistrations.getLoading);

// ADA Registration Details selectors
export const selectAdaRegistrationDetailsState = createSelector(selectAdaRegistrationState, (state) => state.detailsPage);

export const selectAdaRegistrationDetails = createSelector(
  selectAdaRegistrationDetailsState,
  fromAdaRegistrationDetails.getAdaRegistration,
);

export const selectAdaRegistrationDetailsLoading = createSelector(selectAdaRegistrationDetailsState, fromAdaRegistrationDetails.getLoading);

export const selectAdaRegistrationDetailsError = createSelector(selectAdaRegistrationDetailsState, fromAdaRegistrationDetails.getError);
