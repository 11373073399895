<div class="modal-header-block title">
  <h2>Create a Dispute</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="creation-steps">
  <div class="creation-step" *ngFor="let step of disputeSteps; index as i">
    <div class="step-number" [class.active]="selectedStep === step.value" *ngIf="!stepCompleted[step.value]">{{ i + 1 }}</div>
    <rkt-icon class="step-completed" name="check" *ngIf="stepCompleted[step.value]"></rkt-icon>
    {{ step.label | titlecase }}
  </div>
</div>

<div class="details-page-edit-form" [hidden]="selectedStep !== 'disputeDetail'">
  <form [formGroup]="disputeForm">
    <rkt-form
      [form]="disputeForm"
      [fields]="disputeFields"
      [model]="formModel"
      (modelChange)="onDisputeDetailsModelChange($event)"
    ></rkt-form>
  </form>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>

    <button rktButton type="submit" (click)="onDisputeDetailNextClick()" [disabled]="loading || !disputeForm.valid" title="Next">
      Next
    </button>
  </div>
</div>

<div class="details-page-edit-form" [hidden]="selectedStep !== 'disputeQuestions'">
  <form [formGroup]="disputeQuestionForm" class="dispute-questions-form">
    <rkt-form
      [form]="disputeQuestionForm"
      [fields]="disputeQuestionFields"
      [model]="formModel"
      (modelChange)="onDisputeRequiredQuestionsModelChange()"
    ></rkt-form>
  </form>

  <div class="separator-line"></div>

  <ng-container *ngIf="allQuestions?.length">
    <form [formGroup]="disputeOptionalQuestionForm" class="dispute-questions-form dispute-optional-questions-form">
      <rkt-form
        [form]="disputeOptionalQuestionForm"
        [fields]="disputeOptionalQuestionFields"
        [model]="formModel"
        (modelChange)="onDisputeOptionalQuestionsModelChange()"
      ></rkt-form>
    </form>
  </ng-container>

  <ng-container *ngIf="!allQuestions?.length">No questions required.</ng-container>

  <div class="separator-line"></div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="onDisputeQuestionBackClick()">Back</button>

    <button
      rktButton
      type="submit"
      (click)="onDisputeQuestionNextClick()"
      [disabled]="
        loading ||
        (allQuestions?.length && disputeQuestionFields[0] && disputeQuestionFields[0].fieldGroup?.length
          ? !disputeQuestionForm.valid
          : false)
      "
      title="Next"
    >
      Next
    </button>
  </div>
</div>

<div class="details-page-edit-form review" [hidden]="selectedStep !== 'disputeReview'">
  <div class="review-section">
    <div class="review-section-title">Dispute Detail</div>

    <div class="review-item half-width">
      <div class="review-item-label">Dispute Transaction ID</div>
      <div class="review-item-value">{{ formModel.transactionId }}</div>
    </div>

    <div class="review-item half-width">
      <div class="review-item-label">Dispute Type</div>
      <div class="review-item-value">{{ getDisputeTypeLabel(formModel.disputeType!) }}</div>
    </div>

    <div class="review-item half-width">
      <div class="review-item-label">Dispute Amount</div>
      <div class="review-item-value">{{ formModel.disputeAmount }}</div>
    </div>

    <div class="review-item half-width">
      <div class="review-item-label">Dispute Reason</div>
      <div class="review-item-value">{{ getDisputeReasonLabel(formModel.disputeReason!) }}</div>
    </div>

    <div class="review-item">
      <div class="review-item-label">Dispute Description</div>
      <div class="review-item-value" [class.not-available]="!formModel.disputeDescription">
        {{ formModel.disputeDescription | notAvailable }}
      </div>
    </div>
  </div>

  <div class="review-section" *ngIf="selectedStep === 'disputeReview'">
    <div class="review-section-title">Dispute Questions</div>

    <div *ngIf="!reviewQuestions.length" class="review-item-value not-available">No questions required.</div>

    <div class="review-item" *ngFor="let question of reviewQuestions">
      <div class="review-item-label">{{ question.questionDescription }}</div>
      <div class="review-item-value" [class.not-available]="!question.answer">
        {{ question.answer | notAvailable }}
      </div>
    </div>
  </div>

  <div class="review-buttons">
    <button rktButton btnType="secondary" title="Back to Edit" (click)="onDisputeReviewBackClick()">Back to Edit</button>

    <div class="buttons">
      <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Discard</button>
      <button rktButton type="submit" (click)="onSubmit()" title="Confirm">Create</button>
    </div>
  </div>
</div>
