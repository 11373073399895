import { AbstractControl } from '@angular/forms';

export const emptySpacesValidator = (control: AbstractControl) => {
  if (!control.value) {
    return null;
  }

  const isWhitespace = (control.value || '').trim().length === 0;

  return !isWhitespace ? null : { isEmptySpaces: { message: 'Please enter valid data' } };
};
