<div class="table-header table-header-offset-sm table-header-with-controls">
  <div class="table-header-search">
    <rkt-table-search (changed)="onSearch($event)" placeholder="Transaction ID"></rkt-table-search>
  </div>

  <div class="table-header-filters">
    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
    </div>
  </div>

  <div class="table-header-controls"></div>
</div>

<rkt-table
  class="financial-account-activity-table"
  [rows]="accountActivities"
  [columns]="columns"
  [limit]="10"
  [count]="totalElements"
  (fetchData)="getFinancialAccountActivities($event)"
  [loadingIndicator]="isLoading"
  [offset]="activePage"
  [rowHeight]="64"
  [isItemsAlignCenter]="true"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
  dateTimeFormat="MM/dd/yy HH:mm"
  (rowActivated)="onTransactionIdClick($event)"
></rkt-table>

<app-filter-menu
  #filterMenu
  [initialValues]="activeFilters"
  [filters]="filters"
  (opened)="filterBtn.classList.add('opened')"
  (closed)="filterBtn.classList.remove('opened')"
  (saved)="applyFilters($event)"
></app-filter-menu>
