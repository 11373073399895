import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { FinancialAccountReviewData } from '@shared/models';

@Component({
  selector: 'app-add-financial-account-review',
  templateUrl: './add-financial-account-review.component.html',
  styleUrls: ['./add-financial-account-review.component.scss'],
})
export class AddFinancialAccountReviewComponent {
  pageClassPrefix = 'financial-account-review-';

  reviewData!: FinancialAccountReviewData | null;

  constructor(public activeModal: ActiveModal) {}

  modalInitData(reviewData: FinancialAccountReviewData) {
    this.reviewData = reviewData;
  }
}
