import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { DatatableColumn } from '@rocketfinancialcorp/rocket-ui/table';
import { CardStatement } from '@shared/models';
import { StatementEmailModalComponent } from '@shared/components';
import { NavigationService } from '@shared/services';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';

@Component({
  selector: 'app-statement-list',
  templateUrl: './statement-list.component.html',
})
export class StatementListComponent implements OnDestroy {
  statements$ = this.store.select(fromFinancialAccount.selectCardAccountStatementList);

  totalElements$ = this.store.select(fromFinancialAccount.selectStatementListTotalElements);

  loading$ = this.store.select(fromFinancialAccount.selectStatementListLoading);

  columns: DatatableColumn[] = [
    { name: 'Statement Year', prop: 'year', fixedSize: 200, isSortable: true },
    { name: 'Statement Month', prop: 'month', flexSize: 200 },
    {
      name: 'Action',
      prop: 'emailStatement',
      fixedSize: 250,
      colType: 'button',
      iconName: 'mail',
      colAlign: 'center',
      colTitle: 'Email Statement',
    },
  ];

  constructor(
    public ref: ChangeDetectorRef,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private store: Store,
  ) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadStatements());
  }

  ngOnDestroy() {
    this.navigationService.ngOnDestroy();
  }

  onColButtonClick({ colButton, row }: { colButton: string; row: CardStatement }) {
    if (colButton !== 'emailStatementBtn') {
      return;
    }

    const statementEmailModalRef = this.modalService.open(StatementEmailModalComponent, {
      className: 'entity-form-modal statement-email-modal',
    });

    const { year, month, customerEmail, financialAccountId } = row;

    statementEmailModalRef.componentInstance.modalInitData({
      year,
      month,
      customerEmail,
      financialAccountId: financialAccountId,
    });
  }
}
