import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthActions, FeatureManagementActions, initialFeatureManagementState } from '@shared/store';
import { TransactionService, APP_ENV_CONFIG } from '@shared/services';

@Injectable()
export class FeatureManagementEffects {
  envConfig = inject(APP_ENV_CONFIG);

  constructor(private actions$: Actions, private transactionService: TransactionService) {}

  resetFeatureFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.businessAccountDetails, FeatureManagementActions.resetFeatureFlags),
      exhaustMap(() => {
        return this.transactionService.getEnabledSolutions().pipe(catchError(() => of(undefined)));
      }),
      map((enabledSolutions) => {
        const isEarnedWageAccessSolutionEnabled = enabledSolutions?.['program']?.['earned-wage-access'] === true;
        const isIntegratedCardSolutionEnabled = enabledSolutions?.['integrated-cards']?.['mastercard-debit-issuing'] === true;
        const envName = this.envConfig.name;

        const updatedFeatureFlags = initialFeatureManagementState.featureFlags.map((featureFlag) => {
          let value = featureFlag.envsEnabledByDefault.includes(envName);

          if (featureFlag.key === 'cards-feature') {
            value = isIntegratedCardSolutionEnabled || featureFlag.envsEnabledByDefault.includes(envName);
          } else if (featureFlag.key === 'earned-wage-feature') {
            value = isEarnedWageAccessSolutionEnabled || featureFlag.envsEnabledByDefault.includes(envName);
          }

          return { ...featureFlag, value };
        });

        return FeatureManagementActions.resetFeatureFlagsComplete({ featureFlags: updatedFeatureFlags });
      }),
    );
  });
}
