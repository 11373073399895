import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { uniqueId } from 'lodash-es';
import { FilterField } from '@shared/models';
import { emptySpacesValidator } from '@shared/validators';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
})
export class FilterInputComponent implements OnChanges, AfterViewInit {
  @HostBinding('class.rkt-filter-item-container') commonClass = true;

  @Input() filterName?: string;

  @Input() selectedValue?: string;

  @Input() defaultValue?: string;

  @Input() props?: FilterField['props'];

  @Input() isCollapsable: boolean = true;

  @Output() changed = new EventEmitter<string>();

  @Output() errored = new EventEmitter<boolean>();

  @Output() filterToggled = new EventEmitter<void>();

  isCollapsed: boolean = true;

  filterInput = new FormControl('', [emptySpacesValidator]);

  id = uniqueId();

  ngAfterViewInit(): void {
    this.isCollapsed = this.isCollapsable && !this.selectedValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue } = changes.selectedValue || {};
    if (currentValue !== previousValue) {
      this.filterInput.setValue(currentValue);
    }
  }

  onInputChange(value?: string) {
    this.errored.emit(this.filterInput.invalid);

    if (this.filterInput.invalid) {
      return;
    }

    this.changed.emit(value || undefined);
  }

  toggleFilter() {
    if (!this.isCollapsable) {
      return;
    }

    this.isCollapsed = !this.isCollapsed;
    this.filterToggled.emit();
  }
}
