import { createSelector, createFeatureSelector, Action, combineReducers } from '@ngrx/store';
import * as fromRoot from '@shared/store';
import * as fromCardAccount from './card-account.reducer';
import * as fromFinancialAccounts from './financial-accounts.reducer';

export const financialAccountFeatureKey = 'financial-account';

export interface FinancialAccountState {
  [fromCardAccount.cardAccountFeatureKey]: fromCardAccount.CardAccountState;
  [fromFinancialAccounts.financialAccountsFeatureKey]: fromFinancialAccounts.FinancialAccountIdsState;
}

export interface State extends fromRoot.RootState {
  [financialAccountFeatureKey]: FinancialAccountState;
}

export const reducers = (state: FinancialAccountState | undefined, action: Action) => {
  return combineReducers({
    [fromCardAccount.cardAccountFeatureKey]: fromCardAccount.reducer,
    [fromFinancialAccounts.financialAccountsFeatureKey]: fromFinancialAccounts.reducer,
  })(state, action);
};

export const selectFinancialAccountState = createFeatureSelector<FinancialAccountState>(financialAccountFeatureKey);

// Card Account selectors
export const selectCardAccountState = createSelector(selectFinancialAccountState, (state) => state.cardAccount);

// Restriction selectors
export const selectCardAccountRestrictionList = createSelector(selectCardAccountState, (state) => state.restrictionList);
export const selectRestrictionListTotalElements = createSelector(selectCardAccountState, (state) => state.restrictionList?.length ?? 0);
export const selectRestrictionListLoading = createSelector(selectCardAccountState, (state) => state.restrictionList === null);
export const selectCardAccountRestrictionDetails = createSelector(selectCardAccountState, (state) => state.restrictionDetails);

// Alert selectors
export const selectCardAccountAlertList = createSelector(selectCardAccountState, (state) => state.alertList);
export const selectAlertListTotalElements = createSelector(selectCardAccountState, (state) => state.alertList?.length ?? 0);
export const selectAlertListLoading = createSelector(selectCardAccountState, (state) => state.alertList === null);
export const selectCardAccountAlertDetails = createSelector(selectCardAccountState, (state) => state.alertDetails);

// Dispute selectors
export const selectCardAccountDisputeList = createSelector(selectCardAccountState, (state) => state.disputeList);
export const selectDisputeListTotalElements = createSelector(selectCardAccountState, (state) => state.disputeList?.length ?? 0);
export const selectDisputeListLoading = createSelector(selectCardAccountState, (state) => state.disputeList === null);
export const selectCardAccountDisputeDetails = createSelector(selectCardAccountState, (state) => state.disputeDetails);

// Statement selectors
export const selectCardAccountStatementList = createSelector(selectCardAccountState, (state) => state.statementList);
export const selectStatementListTotalElements = createSelector(selectCardAccountState, (state) => state.statementList?.length ?? 0);
export const selectStatementListLoading = createSelector(selectCardAccountState, (state) => state.statementList === null);

// Merchant selectors
export const selectCardAccountMerchantDetails = createSelector(selectCardAccountState, (state) => state.merchantDetails);

// Card Programs selectors
export const selectCardProgramList = createSelector(selectCardAccountState, (state) => state.cardProgramList ?? []);
export const selectCardProgramTotalElements = createSelector(selectCardAccountState, (state) => state.cardProgramList?.length ?? 0);
export const selectCardProgramLoading = createSelector(selectCardAccountState, (state) => state.cardProgramList === null);

// MCC selectors
export const selectMccItems = createSelector(selectCardAccountState, (state) => state.mccItems ?? []);

export const selectCardIssueSupportedLanguages = createSelector(selectCardAccountState, (state) => state.cardIssueSupportedLanguages ?? []);

// Card Details
export const selectCardAccountDetails = createSelector(
  selectCardAccountState,
  selectCardIssueSupportedLanguages,
  selectCardProgramList,
  (state, supportedLanguages, programs) => {
    if (!state.cardAccountDetails) {
      return null;
    }

    return {
      ...state.cardAccountDetails,
      cardProviderDetails: state.cardAccountDetails?.cardProviderDetails
        ? {
            ...state.cardAccountDetails.cardProviderDetails,
            issuedLanguageName: supportedLanguages.find(
              (language) => language.languageTag === state.cardAccountDetails!.cardProviderDetails!.issuedLanguageCode,
            )?.label,
            programName: programs.find((program) => program.id === state.cardAccountDetails!.cardProviderDetails!.programId)?.name,
          }
        : undefined,
    };
  },
);
export const selectCardAccountDetailsId = createSelector(selectCardAccountDetails, (financialAccount) => financialAccount?.id);

// Financial Accounts selectors
export const selectCustomerFinancialAccounts = createSelector(selectFinancialAccountState, (state) => state.financialAccounts);

export const selectCustomerFinancialAccountIds = createSelector(selectCustomerFinancialAccounts, (state) => state.financialaccountsIds);
