<div class="modal-title">Cancel Transaction</div>
<div class="modal-subtitle">Please specify an account to move the collected funds to</div>

<div class="cancel-transaction-form" *ngIf="cancelReviewData$ | async as cancelReviewData">
  <div class="cancel-transaction-form-header">
    <div class="amount">
      <div class="label">Amount</div>
      <div class="value">
        {{ cancelReviewData.amount! | amount: cancelReviewData.currency! }}
      </div>
    </div>
  </div>

  <div class="cancel-transaction-form-body">
    <div class="to-account-title">To Account</div>

    <div class="to-account-options" *ngIf="defaultBusinessAccountFinancialAccount$ | async">
      <div class="to-account-option-item" *ngFor="let toAccountTypeOption of toAccountTypeOptions">
        <label [for]="toAccountTypeOption.value" class="to-account-option-radio-input-label">
          <span class="to-account-option-radio-input" [class.checked]="toAccountTypeField.value === toAccountTypeOption.value"></span>

          <input
            hidden
            [id]="toAccountTypeOption.value"
            [value]="toAccountTypeOption.value"
            [formControl]="toAccountTypeField"
            name="to-account-option"
            type="radio"
            class="form-check-input"
          />

          <span class="to-account-option-item-value" [title]="toAccountTypeOption.label">
            {{ toAccountTypeOption.label }}
          </span>
        </label>
      </div>
    </div>

    <div class="default-account" *ngIf="toAccountTypeField.value === 'DEFAULT'">
      <ng-container *ngIf="defaultBusinessAccountFinancialAccount$ | async as defaultFinancialAccount">
        <div class="default-account-row">
          <div class="default-account-item">
            {{ defaultFinancialAccount.accountHolderInfo | rktSplit: '::':1 }}:
            <span class="default-account-item-accent">{{ defaultFinancialAccount.accountHolderInfo | rktSplit: '::':0 }}</span>

            <rkt-icon name="bank"></rkt-icon>
            <span class="default-account-item-accent">
              {{ defaultFinancialAccount.accountInfo | rktSplit: '::':0 }} |
              {{ defaultFinancialAccount.accountInfo | rktSplit: '::':1 }}
            </span>
          </div>
        </div>

        <div class="default-account-row">
          <div class="default-account-item">
            <span class="default-account-item-accent">
              {{ defaultFinancialAccount.email }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="financial-account-selection-wrapper" *ngIf="toAccountTypeField.value === 'SELECT'">
      <div class="list-page-controls">
        <rkt-table-search #tableSearchRef (changed)="onSearch($event)" placeholder="Search by Account Name"></rkt-table-search>
      </div>

      <div class="table-container" [class.empty]>
        <rkt-table
          #financialAccountsTableRef
          [rows]="(financialAccounts$ | async) ?? []"
          [columns]="columns"
          [class.empty-list-loading]="(totalElements$ | async) === 0 && (isLoading$ | async) === true"
          [class.no-pagination]="((totalElements$ | async) ?? 0) < 5"
          [limit]="5"
          (fetchData)="fetchFinancialAccounts($event, cancelReviewData.currency)"
          [count]="(totalElements$ | async) ?? 0"
          [loadingIndicator]="(isLoading$ | async) ?? false"
          [stickyHeader]="false"
          [rowHeight]="64"
          [placeholderItemsCount]="5"
          [isItemsAlignCenter]="true"
          [offset]="activePage"
          [messages]="{ emptyMessage: 'No Financial Accounts found.' }"
          emptyValuePlaceholder="--"
          [selected]="selectedFinancialAccounts"
          [selectionType]="selectionType"
          (rowSelected)="onFinancialAccountSelect($event)"
          [footerHeight]="56"
        ></rkt-table>
      </div>
    </div>

    <div class="cancel-transaction-form-footer">
      <app-create-transaction-note class="create-transaction-note"></app-create-transaction-note>

      <div class="buttons">
        <button
          rktButton
          btnType="tertiary"
          class="cancel-transaction-btn cancel-transaction-btn-clear"
          title="Cancel"
          (click)="activeModal.close()"
        >
          Cancel
        </button>

        <button
          *ngIf="toAccountTypeField.value === 'SELECT'"
          rktButton
          title="Continue"
          class="cancel-transaction-btn cancel-transaction-btn-continue"
          [disabled]="!selectedFinancialAccounts[0]"
          (click)="onContinueBtnClick(selectedFinancialAccounts[0])"
        >
          Continue
        </button>

        <button
          *ngIf="toAccountTypeField.value === 'DEFAULT' && (defaultBusinessAccountFinancialAccount$ | async) as defaultFinancialAccount"
          rktButton
          title="Continue"
          class="cancel-transaction-btn cancel-transaction-btn-continue"
          (click)="onContinueBtnClick(defaultFinancialAccount)"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
