import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formRadioGroup } from '@rocketfinancialcorp/rocket-ui/form';
import { FormModel, LinxTemplateCustomerType, LinxTemplateFinancialAccount } from '@shared/models';

@Component({
  selector: 'app-linx-template-financial-account',
  templateUrl: './linx-template-financial-account.component.html',
})
export class LinxTemplateFinancialAccountComponent implements OnInit {
  @Input() selectedFinancialAccountDetails?: LinxTemplateFinancialAccount;

  @Input() customerType?: LinxTemplateCustomerType;

  @Output() continue = new EventEmitter<LinxTemplateFinancialAccount>();

  financialAccountForm = new UntypedFormGroup({});

  formModel: FormModel<LinxTemplateFinancialAccount> = {};

  financialAccountFields: FormlyFieldConfig[] = [
    formRadioGroup({
      key: 'financialAccountType',
      label: '',
      props: {
        options: [
          { label: 'Use Existing Financial Account', value: 'EXISTING' },
          { label: 'Create New Financial Account', value: 'NEW' },
        ],
        required: true,
      },
      expressions: {
        hide: () => this.customerType === 'NEW',
      },
    }),
    formRadioGroup({
      key: 'financialAccountSubType',
      label: 'Account Type',
      props: {
        options: [
          { label: 'Checking Account', value: 'CHECKING' },
          { label: 'Saving Account', value: 'SAVING' },
          { label: 'Card Account', value: 'CARD' },
        ],
        required: true,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => this.customerType !== 'NEW' && !field.model.financialAccountType,
      },
    }),
  ];

  ngOnInit(): void {
    if (this.selectedFinancialAccountDetails) {
      this.formModel = {
        financialAccountType: this.selectedFinancialAccountDetails.financialAccountType,
        financialAccountSubType: this.selectedFinancialAccountDetails.financialAccountSubType,
      };
    }
  }

  onClickContinue(): void {
    this.continue.emit(this.formModel);
  }

  onClearBtnClick(): void {
    this.financialAccountForm.reset();
  }
}
