<h1 class="title">Edit Transaction Limits</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="rkt-form">
  <ng-container *ngFor="let item of transactionLimits">
    <div class="limits-edit-item-title">
      <div class="item-title item-title-current">Current {{ item.label }} Limit</div>
      <div class="item-title item-title-new">New {{ item.label }} Limit</div>
    </div>
    <div class="limits-edit-item">
      <div class="limits-edit-item-amount">
        {{ (item.amount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$ ' }}
      </div>

      <div class="limits-edit-item-value-field">
        <app-limit-edit-item-field
          [maxAmountValue]="maxLimits[item.type]"
          [limitType]="item.type"
          [initialValue]="values[item.type]"
          (changed)="setLimitAmountValue($event, item.type)"
          (errored)="onLimitAmountValueError($event, item.type)"
        ></app-limit-edit-item-field>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

<div class="buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button rktButton type="button" (click)="onSave()" [disabled]="isLimitsHasError || !isLimitsModified || isLoading">Save</button>
</div>
