import { createReducer, on } from '@ngrx/store';
import { Beneficiary, ErrorMessage } from '@shared/models';
import { BusinessAccountBeneficiaryActions } from './business-account-beneficiary.actions';

export const businessAccountBeneficiaryPageFeatureKey = 'businessAccountBeneficiaryPage';

export interface BusinessAccountBeneficiaryState {
  businessAccountBeneficiary: Beneficiary | null;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialBusinessAccountBeneficiaryState: BusinessAccountBeneficiaryState = {
  businessAccountBeneficiary: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<BusinessAccountBeneficiaryState>(
  initialBusinessAccountBeneficiaryState,
  on(BusinessAccountBeneficiaryActions.initialize, (state): BusinessAccountBeneficiaryState => {
    return {
      ...state,
      isLoading: true,
      businessAccountBeneficiary: null,
      error: null,
    };
  }),
  on(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiary, (state): BusinessAccountBeneficiaryState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiarySuccess, (state, action): BusinessAccountBeneficiaryState => {
    return {
      ...state,
      businessAccountBeneficiary: action.beneficiary,
      isLoading: false,
    };
  }),
  on(BusinessAccountBeneficiaryActions.loadBusinessAccountBeneficiaryError, (state, action): BusinessAccountBeneficiaryState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getBusinessAccountBeneficiary = (state: BusinessAccountBeneficiaryState) => state.businessAccountBeneficiary;
export const getLoading = (state: BusinessAccountBeneficiaryState) => state.isLoading;
export const getError = (state: BusinessAccountBeneficiaryState) => state.error;
