import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface TabItem {
  label: string;
  href: string;
  isExact?: boolean;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() noHorizontalOffset = false;

  @Input() tabItems!: TabItem[];
}
