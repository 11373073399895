<div class="filter-title">
  <div class="filter-name-wrapper" (click)="toggleFilter()">
    <rkt-icon *ngIf="isCollapsable" class="filter-toggler" [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"></rkt-icon>
    <div class="filter-name">{{ filterName }}</div>
  </div>
  <button *ngIf="selectedValue" rktButton btnType="danger" class="filter-reset-btn" (click)="changed.emit()">Reset</button>
</div>

<ng-container *ngIf="!isCollapsed">
  <div class="rkt-form">
    <div class="rkt-form-field" [class.has-error]="filterInput.invalid">
      <div class="rkt-form-label">
        <label *ngIf="props?.label" [attr.for]="id">{{ props?.label }}<span class="asterisk" *ngIf="props?.required">*</span> </label>
      </div>
      <div class="rkt-form-input-field">
        <input
          type="text"
          class="rkt-form-input"
          [pattern]="props?.validationPattern ?? ''"
          [maxlength]="props?.maxLength ?? null"
          [class.is-invalid]="filterInput.invalid"
          [formControl]="filterInput"
          (ngModelChange)="onInputChange($event)"
          [id]="'filter-item-input_' + id"
        />
      </div>

      <div *ngIf="filterInput.invalid" class="rkt-form-field-invalid-feedback">
        {{ props?.validationPatternMessage }}
      </div>
    </div>
  </div>
</ng-container>
