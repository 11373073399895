import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { selectCancelReviewData } from '@shared/store';

@Component({
  selector: 'app-cancel-recovery-financial-account-review-modal',
  templateUrl: './cancel-recovery-financial-account-review-modal.component.html',
})
export class CancelRecoveryFinancialAccountReviewModalComponent {
  private readonly store = inject(Store);

  public readonly activeModal = inject(ActiveModal);

  cancelReviewData$ = this.store.select(selectCancelReviewData);
}
