<div class="filter-title">
  <div class="filter-name-wrapper" (click)="toggleFilter()">
    <rkt-icon *ngIf="isCollapsable" class="filter-toggler" [name]="isCollapsed ? 'chevron-right' : 'chevron-down'"></rkt-icon>
    <div class="filter-name">{{ filterName }}</div>
  </div>
  <button
    *ngIf="selectedValue || minAmountValue || maxAmountValue"
    rktButton
    btnType="danger"
    class="filter-reset-btn"
    (click)="onResetBtnClick()"
  >
    Reset
  </button>
</div>
<ng-container *ngIf="!isCollapsed">
  <div class="rkt-form">
    <div class="rkt-form-row" *ngIf="props?.amountType === 'DEBIT_CREDIT'">
      <div class="rkt-form-field" [class.has-error]="(!maxAllowedAmountError && minAmountError) || maxAllowedAmountError">
        <div class="rkt-form-label">
          <label>Amount type</label>
        </div>
        <div *ngFor="let option of amountTypeOptions; let i = index" class="rkt-form-radio">
          <label class="rkt-form-radio-label" [for]="id + '_' + i">
            <input
              hidden
              type="radio"
              [id]="id + '_' + i"
              class="rkt-form-radio-input"
              [attr.value]="option.value"
              [value]="option.value"
              [formControl]="amountType"
              (change)="onInputChange()"
            />
            <div class="rkt-form-radio-icon"></div>

            <div class="rkt-form-radio-value">
              {{ option.label }}
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="rkt-form-field" [class.has-error]="(!maxAllowedAmountError && minAmountError) || maxAllowedAmountError">
      <div class="rkt-form-label">
        <label [id]="'filter-item-input-min_' + id">Min</label>
      </div>
      <div class="rkt-form-input-field">
        <input
          [class.is-invalid]="(!maxAllowedAmountError && minAmountError) || maxAllowedAmountError"
          type="text"
          class="rkt-form-input"
          [formControl]="minAmount"
          [id]="'filter-item-input-min_' + id"
          (input)="onInputChange()"
          mask="separator.2"
          separatorLimit="999999"
          decimalMarker="."
          [prefix]="minAmount.value ? '$ ' : ''"
          thousandSeparator=","
          placeholder="$ 0.00"
          (blur)="onMinAmountFieldBlur()"
          [allowNegativeNumbers]="false"
        />
      </div>

      <div *ngIf="minAllowedAmountError" class="rkt-form-field-invalid-feedback">Amount must be more or equal to $-999,999.00</div>
      <div *ngIf="!maxAllowedAmountError && minAmountError" class="rkt-form-field-invalid-feedback">Enter minimum amount</div>
    </div>

    <div
      class="rkt-form-field"
      [class.has-error]="(!maxAllowedAmountError && minAmountError) || maxAllowedAmountError || maxAmountEmptyError"
    >
      <div class="rkt-form-label">
        <label [attr.for]="'filter-item-input-max_' + id">Max</label>
      </div>
      <div class="rkt-form-input-field">
        <input
          [class.is-invalid]="(!maxAllowedAmountError && minAmountError) || maxAllowedAmountError || maxAmountEmptyError"
          type="text"
          class="rkt-form-input"
          [formControl]="maxAmount"
          [id]="'filter-item-input-max_' + id"
          (input)="onInputChange()"
          mask="separator.2"
          decimalMarker="."
          separatorLimit="999999"
          [prefix]="maxAmount.value ? '$ ' : ''"
          thousandSeparator=","
          placeholder="$ 0.00"
          DEBIT_CREDIT
          (blur)="onMaxAmountFieldBlur()"
          [allowNegativeNumbers]="false"
        />
      </div>

      <div *ngIf="maxAllowedAmountError" class="rkt-form-field-invalid-feedback">Amount must be less or equal to $999,999.00</div>
      <div *ngIf="!maxAllowedAmountError && maxAmountEmptyError" class="rkt-form-field-invalid-feedback">Enter maximum amount</div>
      <div *ngIf="!maxAllowedAmountError && maxAmountError" class="rkt-form-field-invalid-feedback">
        Maximum amount must be greater or equal than {{ minAmount.value | mask: 'separator':',' }}
      </div>
    </div>
  </div>
</ng-container>
