export const breadCrumbName = {
  DASHBOARD: 'Dashboard',
  ADMIN: 'Admin',
  BUSINESS_ACCOUNT: 'Business Account',
  TRANSACTION: 'Transaction',
  TEAM_MANAGEMENT: 'Team Management',
  RECIPIENT: 'Recipients',
  SETTINGS: 'My Business',
  CUSTOMERS: 'Customers',
  LINX: 'Linx',
  CARDS: 'Cards',
  EARNED_WAGE: 'earned-wage',
  INSIGHTS: 'Insights',
};
