<rkt-table
  [rows]="accountStatements"
  [columns]="columns"
  [limit]="10"
  [count]="totalElements"
  (fetchData)="getFinancialAccountStatements($event)"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [sortParam]="sortParams"
  dateFormat="MM/dd/yy"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
  (rowActivated)="onRowClick($event)"
></rkt-table>
