<div class="details-page-header-wrapper" [class.dark-bg]="isDarkBg">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>
      <h1 class="details-page-title"><div class="loading-placeholder dark-bg"></div></h1>

      <div class="status-tag status-tag-placeholder" *ngIf="withStatus"><div class="loading-placeholder dark-bg"></div></div>
    </div>

    <div class="page-breadcrumbs">
      <div class="breadcrumb-item">
        <div class="loading-placeholder dark-bg"></div>
      </div>
      <div class="breadcrumb-separator">
        <rkt-icon name="chevron-right"></rkt-icon>
      </div>
      <div class="breadcrumb-item">
        <div class="loading-placeholder dark-bg"></div>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
