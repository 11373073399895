<div class="documents-list-title">Documents</div>

<rkt-table
  [rows]="documents"
  [columns]="columns"
  [count]="totalElements"
  [loadingIndicator]="loading"
  (fetchData)="getDocuments()"
  [messages]="{ emptyMessage: emptyListMessage }"
  emptyValuePlaceholder="--"
  [placeholderItemsCount]="3"
  dateTimeFormat="MM/dd/yy HH:mm"
  (rowActivated)="onRowClick($event)"
></rkt-table>
