import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { LegalService } from '@shared/services';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsConditionsComponent {
  constructor(public activeModal: ActiveModal, private legalService: LegalService) {}

  downloadTermsConditions() {
    this.legalService.downloadTermsConditions();
  }
}
