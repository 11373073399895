<h1 class="title">{{ actionType | titlecase }} Team Member</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<form [formGroup]="editForm" class="details-page-edit-form">
  <rkt-form [form]="editForm" [fields]="formFields" [model]="profileModel"></rkt-form>

  <div class="note-container" *ngIf="actionType === 'add'">
    <app-note-form-item [errors]="note.errors" [value]="note.value">
      <textarea
        class="note-field"
        #noteField
        (blur)="onNoteFieldBlur()"
        name="new-note"
        id="new-note"
        [formControl]="note"
        maxlength="1000"
      ></textarea>
    </app-note-form-item>
  </div>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="submit" (click)="onSave()" [disabled]="isLoading || editForm.invalid">Save</button>
  </div>
</form>
