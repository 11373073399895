import { Component, Input } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { Beneficiary, TemplateContext } from '@shared/models';
import { BeneficiaryEditFormComponent } from '@shared/components';
import { toTitleCase } from '@shared/utils';
import { NotificationService } from '@shared/services';

@Component({
  selector: 'app-beneficiary-details',
  moduleId: module.id,
  templateUrl: 'beneficiary-details.component.html',
  styleUrls: ['beneficiary-details.component.scss'],
})
export class BeneficiaryDetailsComponent {
  @Input() beneficiary!: Beneficiary | null;
  @Input() beneficiaryType!: 'customer' | 'business';
  @Input() onBeneficiaryUpdateDone!: () => void;

  pageClassPrefix = 'beneficiary-details-';

  beneficiaryDetailsContext?: TemplateContext<Beneficiary>;

  get beneficiaryGender() {
    return this.beneficiary?.sex === 'NONBINARY' ? 'Non-Binary' : toTitleCase(this.beneficiary?.sex);
  }

  get addresses() {
    if (!this.beneficiary) return [];
    return this.beneficiary.addresses;
  }

  constructor(private modalService: ModalService, private notificationService: NotificationService) {}

  onEditBeneficiaryBtnClick(beneficiary: Beneficiary): void {
    const addBeneficiaryModalRef = this.modalService.open(BeneficiaryEditFormComponent, {
      className: 'entity-form-modal',
    });

    addBeneficiaryModalRef.componentInstance.modalInitData({
      actionType: 'edit',
      beneficiaryType: this.beneficiaryType,
      customerId: beneficiary?.parentId,
      beneficiaryData: beneficiary,
    });

    addBeneficiaryModalRef.result.then(
      (result) => {
        if (result) {
          this.notificationService.displaySuccess('Beneficial Owner updated successfully.', 'Success');
          this.onBeneficiaryUpdateDone();
        }
      },
      () => false,
    );
  }
}
