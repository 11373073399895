import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRemoveFocus]',
})
export class FocusRemoveDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click') onClick() {
    this.elementRef.nativeElement.blur();
  }
}
