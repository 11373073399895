import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @ViewChild('fileSelector') public fileSelector!: ElementRef;

  @Input() fileTypes = ['text/csv'];

  @Input() uploadedFileLabel?: string;

  @Input() title = 'Drag & drop file here';

  isDragging = false;

  fileName?: string;

  @Output() public selectedFile = new EventEmitter<File | null>();

  constructor(private modalService: ModalService) {}

  get fileFormats(): string {
    const formats = this.fileTypes.map((item: string) => {
      const extension = item.split('.').pop()?.toUpperCase();

      if (item.includes('jpeg')) {
        return 'JPEG';
      } else if (item.includes('png')) {
        return 'PNG';
      } else if (item.includes('pdf')) {
        return 'PDF';
      } else if (item.includes('jpg')) {
        return 'JPG';
      } else {
        return extension;
      }
    });

    return formats.join(', ');
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
    const file = event.dataTransfer?.files[0];
    if (file && this.fileTypes.includes(file.type)) {
      if (this.isFileExceededSize(file)) {
        this.showErrorSizeModal();
        return;
      }

      this.setFileName(file);
      this.fileEmit(file);
    } else {
      this.showErrorFormatModal();
    }
  }

  public onFileSelected(event: Event): void {
    const file: File = (event.target as HTMLInputElement).files![0] || {};
    if (file && this.fileTypes.includes(file.type)) {
      if (this.isFileExceededSize(file)) {
        this.showErrorSizeModal();
        return;
      }

      this.setFileName(file);
      this.fileSelector.nativeElement.value = '';
      this.fileEmit(file);
    } else {
      this.showErrorFormatModal();
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  public stopDrag(event: DragEvent): void {
    this.isDragging = false;
    event.preventDefault();
    event.stopPropagation();
  }

  setFileName(file: File | null): void {
    this.fileName = file?.name ?? undefined;
  }

  public fileEmit(file: File | null): void {
    this.selectedFile.emit(file);
  }

  onDeleteFileBtnClick(): void {
    this.setFileName(null);
    this.fileEmit(null);
  }

  onReplaceBtnClick(): void {
    this.setFileName(null);
    this.fileEmit(null);
    setTimeout(() => this.fileSelector?.nativeElement.click(), 0);
  }

  isFileExceededSize(file: File | null): boolean {
    const fileLimit = 1024 * 1024 * 5;
    return !!(file && (file.size === 0 || file.size > fileLimit));
  }

  showErrorFormatModal() {
    this.showErrorModal(`Incorrect format. Please select file of one of the types ${this.fileFormats} and re-upload.`);
  }

  showErrorSizeModal() {
    this.showErrorModal('The uploaded file cannot exceed 5MB in size.');
  }

  showErrorModal(errorText: string) {
    const errorModalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });
    errorModalRef.componentInstance.title = 'Upload Failed';
    errorModalRef.componentInstance.customText = errorText;
    errorModalRef.componentInstance.confirmBtnText = 'Try Again';

    errorModalRef.result.then(
      (result) => {
        if (result) {
          this.fileSelector.nativeElement.click();
        }
      },
      () => false,
    );
  }
}
