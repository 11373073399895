import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, delay, finalize, takeUntil } from 'rxjs';
import { find, isEmpty } from 'lodash-es';
import { format, parse } from 'date-fns';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { FormModel, DocumentCreateModel, DocumentDetails, DocumentUpdateModel } from '@shared/models';
import { DocumentService, NotificationService } from '@shared/services';
import { businessCustomerDocumentTypes, individualCustomerDocumentTypes, DocumentType } from '../document-type-selection/document-types';
import { documentFields } from './document-form-fields';

@Component({
  selector: 'app-document-create-form',
  templateUrl: 'document-create-form.component.html',
  styleUrls: ['document-create-form.component.scss'],
})
export class DocumentCreateFormComponent implements AfterViewChecked, OnDestroy {
  formModel: FormModel<DocumentCreateModel> = {};

  documentForm = new FormGroup({});

  documentFields = documentFields;

  documentUploadData?: File | null;

  file?: string | ArrayBuffer | null;

  documentType!: string;

  accountHolderType?: DocumentCreateModel['accountHolderType'];

  isEdit = false;

  editData?: DocumentDetails;

  isLoading = false;

  get documentTypeData(): DocumentType {
    if (this.isEdit) {
      return (
        find([...individualCustomerDocumentTypes, ...businessCustomerDocumentTypes], (type) => type.value === this.documentType) ?? {
          icon: '',
          label: '',
          value: this.documentType,
        }
      );
    }

    switch (this.accountHolderType) {
      case 'Individual':
      case 'BusinessBeneficiary':
        return find(individualCustomerDocumentTypes, (type) => type.value === this.documentType)!;

      case 'Business':
        return find(businessCustomerDocumentTypes, (type) => type.value === this.documentType)!;

      default:
        return { icon: '', label: '', value: this.documentType };
    }
  }

  private destroy$ = new Subject<void>();

  constructor(
    public ref: ChangeDetectorRef,
    public activeModal: ActiveModal,
    private documentService: DocumentService,
    private notificationService: NotificationService,
  ) {}

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.documentForm.markAsUntouched();
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData({ documentType, accountHolderType, editData }: DocumentCreateModel) {
    if (editData) {
      this.documentType = editData.documentType;
      this.isEdit = true;

      this.editData = editData;

      this.formModel = {
        ...this.formModel,
        documentType: editData.documentType,
        description: editData.description,
        expiryDate: editData.expiryDate,
        issuingDate: editData.issuingDate,
        issuingCountryCode: editData.issuingCountryCode,
        issuingStateCode: editData.issuingStateCode,
        name: editData.name,
        number: editData.number,
        isFileUploaded: !isEmpty(editData.url?.value),
        accountHolderType,
      };
    } else {
      this.documentType = documentType;
      this.accountHolderType = accountHolderType;
      this.formModel.documentType = documentType;
      this.formModel.accountHolderType = accountHolderType;
    }
  }

  onFileUploaded(file: File | null): void {
    if (!file && this.formModel.isFileUploaded) {
      this.formModel = {
        ...this.formModel,
        isFileUploaded: false,
      };
    }
    if (!file) {
      this.documentUploadData = null;
    } else {
      this.documentUploadData = file;
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent) => {
        const fr = e.currentTarget as FileReader;
        this.file = fr.result;
      };
      reader.readAsArrayBuffer(file);
    }
  }

  onSubmit() {
    if (this.documentForm.invalid || (!this.isEdit && !this.documentUploadData)) {
      return;
    }

    const { description, name, number, issuingDate, expiryDate, issuingCountryCode, issuingStateCode } = this.formModel;

    const extension = this.documentUploadData?.name.match(/^.*\.(?<extension>[a-z]+)$/i);

    const documentData: Partial<DocumentUpdateModel> = {
      name,
      number,
      issuingCountryCode,
      issuingStateCode,
      description,
      extension: extension?.groups?.extension.toUpperCase(),
      contentLength: this.documentUploadData?.size,
      issuingDate: issuingDate ? format(parse(issuingDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd') : undefined,
      expiryDate: expiryDate ? format(parse(expiryDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd') : undefined,
      documentType: this.documentType,
      file: this.file,
      action: 'SAVE',
    };

    if (this.isEdit) {
      this.isLoading = true;
      this.documentService
        .updateDocument(this.editData!.id, documentData)
        .pipe(
          delay(this.file ? 3000 : 0),
          finalize(() => (this.isLoading = false)),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: () => {
            this.notificationService.displaySuccess(`Document updated successfully.`, 'Success');
            this.activeModal.close(true);
          },
        });
    } else {
      this.activeModal.close(documentData);
    }
  }
}
