import { find, startCase, compact, orderBy } from 'lodash-es';
import {
  Beneficiaries,
  BeneficiariesRaw,
  Beneficiary,
  BeneficiaryRaw,
  BusinessAccount,
  BusinessAccountRaw,
  LinkedBusinessAccount,
  LinkedBusinessAccountsRaw,
  VerificationStatus,
} from '@shared/models';
import { mapVerifactionStatus, toTitleCase } from '@shared/utils';

export const mapBusinessAccountDetails = (businessAccount: BusinessAccountRaw): BusinessAccount => {
  const primaryAddress = find(businessAccount.addresses, ['primary', true]);
  const logoInitials = startCase(businessAccount.operatingName.charAt(0));

  return {
    ...businessAccount,
    primaryAddress,
    logoInitials,
    parent: !businessAccount.parentId,
  };
};

export const mapLinkedBusinessAccountList = (response: LinkedBusinessAccountsRaw): LinkedBusinessAccount[] => {
  const { content = [] } = response;

  const items: LinkedBusinessAccount[] = content.map((item) => ({
    ...item,
    operatingName: item.name,
    logoInitials: startCase(item.name.charAt(0)),
  }));

  return items;
};

export const mapBusinessAccountBeneficiaryList = (beneficiaries: BeneficiariesRaw[]): Beneficiaries[] => {
  return beneficiaries.map((item) => {
    const { id, firstName, lastName, middleName, primaryPhoneNumber, primaryEmail, controller, verificationStatus } = item;

    return {
      id,
      initials: `${firstName.charAt(0)}${lastName.charAt(0)}`,
      fullName: `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`,
      phoneNumber: primaryPhoneNumber?.number,
      email: primaryEmail?.value,
      controller,
      verification: verificationStatus?.status ?? 'UNVERIFIED',
    };
  });
};

export const mapBusinessAccountBeneficiary = (businessAccountBeneficiary: BeneficiaryRaw): Beneficiary => {
  const { firstName, lastName, middleName, primaryPhoneNumber, primaryEmail, placeOfBirth, socialId, addresses, createdAt } =
    businessAccountBeneficiary;
  const addressItems = addresses?.map((address) => ({
    ...address,
    label: compact([toTitleCase(address.type), 'Address', address.primary ? '(Primary)' : undefined]).join(' '),
  }));

  const verificationStatus: VerificationStatus = mapVerifactionStatus({
    verificationStatus: businessAccountBeneficiary.verificationStatus,
    updatedAt: createdAt,
  });

  return {
    ...businessAccountBeneficiary,
    fullName: `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`,
    email: primaryEmail?.value,
    phoneNumber: primaryPhoneNumber?.number,
    placeOfBirthDisplayValue: compact([placeOfBirth?.city, placeOfBirth?.countryCode]).join(', '),
    socialId: socialId ? `*${socialId}` : undefined,
    addresses: orderBy(addressItems, 'primary', 'desc'),
    verificationStatus,
    verificationStatusHistory: businessAccountBeneficiary.verificationStatusHistory ?? [verificationStatus],
  };
};
