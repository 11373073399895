import { HttpParams } from '@angular/common/http';
import { compact, orderBy } from 'lodash-es';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  Beneficiary,
  BeneficiaryRaw,
  Beneficiaries,
  BeneficiariesRaw,
  RequestPageParams,
  IndustryListRaw,
  IndustryList,
  VerificationStatus,
} from '@shared/models';
import { filterCreatedAtDateRange, mapVerifactionStatus, systemTimeZone, toTitleCase } from '@shared/utils';

/**
 * Returns the HttpParams object for making customers API request with provided request parameters.
 * @param {RequestPageParams} requestParams - The request parameters object containing pagination, sorting, filtering and search options.
 * @returns {HttpParams} - The HttpParams object containing the query parameters to be sent in the request.
 */
export const customersRequestParams = (requestParams: RequestPageParams): HttpParams => {
  const { page, size, searchString, sortParams, activeFilters } = requestParams;
  let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

  params = params.append('size', size ?? constants.TABLE_ROWS);

  if (page) {
    params = params.append('page', page);
  }

  if (searchString) {
    params = params.append('search', searchString);
  }

  if (sortParams) {
    params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
  }

  if (activeFilters) {
    Object.keys(activeFilters).forEach((key) => {
      if (key === 'createdAt') {
        const { from, to } = filterCreatedAtDateRange(activeFilters[key]) ?? {};

        if (!from || !to) {
          return;
        }

        params = params.append('createdAtFrom', from);
        params = params.append('createdAtTo', to);
        params = params.append('timeZone', systemTimeZone);
      } else {
        params = params.append(key, activeFilters[key]!);
      }
    });
  }

  return params;
};

export const mapCustomerBeneficiaryList = (customerBeneficiaries: BeneficiariesRaw[]): Beneficiaries[] => {
  return customerBeneficiaries.map((item) => {
    const { id, firstName, lastName, middleName, primaryPhoneNumber, primaryEmail, controller, verificationStatus } = item;

    return {
      id,
      initials: `${firstName.charAt(0)}${lastName.charAt(0)}`,
      fullName: `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`,
      phoneNumber: primaryPhoneNumber?.number,
      email: primaryEmail?.value,
      controller,
      verification: verificationStatus?.status ?? 'UNVERIFIED',
    };
  });
};

export const mapCustomerBeneficiary = (customerBeneficiary: BeneficiaryRaw): Beneficiary => {
  const { firstName, lastName, middleName, primaryPhoneNumber, primaryEmail, placeOfBirth, socialId, addresses } = customerBeneficiary;
  const addressItems = addresses?.map((address) => ({
    ...address,
    label: compact([toTitleCase(address.type), 'Address', address.primary ? '(Primary)' : undefined]).join(' '),
  }));

  const verificationStatus: VerificationStatus = mapVerifactionStatus({
    verificationStatus: customerBeneficiary.verificationStatus,
    updatedAt: customerBeneficiary.createdAt,
  });

  return {
    ...customerBeneficiary,
    fullName: `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`,
    email: primaryEmail?.value,
    phoneNumber: primaryPhoneNumber?.number,
    placeOfBirthDisplayValue: compact([placeOfBirth?.city, placeOfBirth?.countryCode]).join(', '),
    socialId: socialId ? `*${socialId}` : undefined,
    addresses: orderBy(addressItems, 'primary', 'desc'),
    verificationStatus,
    verificationStatusHistory: customerBeneficiary.verificationStatusHistory ?? [verificationStatus],
  };
};

export const mapIndustries = (industries: IndustryListRaw): IndustryList => {
  const { content = [], totalElements = 0 } = industries || {};
  return {
    items: content,
    totalElements,
  };
};
