<div class="page-breadcrumbs">
  <ng-container *ngFor="let item of itemList; let i = index">
    <div *ngIf="i !== 0">
      <div class="breadcrumb-separator">
        <rkt-icon name="chevron-right"></rkt-icon>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="item.url ? breadcrumbLink : breadcrumb; context: { $implicit: item }"></ng-container>
  </ng-container>
</div>

<ng-template #breadcrumb let-item>
  <div class="breadcrumb-item">
    {{ item.label }}
  </div>
</ng-template>

<ng-template #breadcrumbLink let-item>
  <a [routerLink]="item.url" class="breadcrumb-item">
    {{ item.label }}
  </a>
</ng-template>
