import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { add, differenceInMinutes, format, getWeekOfMonth, parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { uniqBy, compact } from 'lodash-es';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { RktFormFieldConfig, formDatepicker, formInput, formRow, formSelect, formTimepicker } from '@rocketfinancialcorp/rocket-ui/form';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { formatDate, formatDateTimeToUTC, formatRecurrenceRule, systemTimeZone } from '@shared/utils';
import { FormModel, MultiLegTransactionScheduler } from '@shared/models';
import { MoveMoneySchedulerRecurrenceModalComponent } from '@shared/components';
import { TransactionFormActions, selectDisabledSameDayScheduleDayTime } from '@shared/store';

interface MoveMoneySchedulerModel {
  name: string;
  scheduleDate: string;
  scheduleTime: string;
  scheduleRecurring: string;
  alreadySentCount: number;
  scheduleRecurringDisplayValue: string;
  disabledDates?: { year: number; day: number; month: number }[];
  disabledWeekdays?: number[];
  minTime?: string;
  maxTime?: string;
}

const defaultRecurringOptions = [
  { value: '', label: 'Does not repeat' },
  { value: 'FREQ=DAILY;INTERVAL=1', label: 'Daily' },
  { value: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR', label: 'Every weekday (Monday to Friday)' },
  { value: 'CUSTOM', label: 'Custom' },
];

@Component({
  selector: 'app-edit-transaction-scheduler',
  templateUrl: './edit-transaction-scheduler.component.html',
})
export class EditTransactionSchedulerComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() editData?: MultiLegTransactionScheduler;

  schedulerFormModel: FormModel<MoveMoneySchedulerModel> = {
    name: undefined,
    scheduleDate: format(new Date(), 'yyyy-MM-dd'),
    scheduleTime: '00:00:00',
    scheduleRecurring: '',
    alreadySentCount: undefined,
    scheduleRecurringDisplayValue: undefined,
  };

  schedulerForm = new FormGroup({});

  schedulerNameForm = new FormGroup({});

  scheduleRecurringOptions$ = new BehaviorSubject<{ label: string; value: string }[]>([...defaultRecurringOptions]);

  schedulerNameFormFields: RktFormFieldConfig[] = [
    formRow([
      formInput({
        key: 'name',
        label: 'Nam\u0435',
        expressions: {
          'props.required': '!!model?.scheduleRecurring',
        },
      }),
    ]),
  ];

  schedulerFormFields: RktFormFieldConfig[] = [
    formRow([
      formDatepicker({
        key: 'scheduleDate',
        label: 'Date',
        props: {
          placeholder: 'MM/DD/YYYY',
          required: true,
          minDate: formatDate(utcToZonedTime(new Date(), systemTimeZone)),
          maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
        },
        expressions: {
          'props.disabledDates': 'model?.disabledDates',
          'props.disabledWeekdays': 'model?.disabledWeekdays',
        },
      }),
    ]),
    formRow([
      formTimepicker({
        key: 'scheduleTime',
        label: 'Time',
        props: {
          required: true,
        },
        expressions: {
          'props.minTime': 'model?.minTime',
          'props.maxTime': 'model?.maxTime',
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'scheduleRecurring',
        label: '',
        props: {
          options: this.scheduleRecurringOptions$.asObservable(),
        },
        expressions: {
          'props.disabled': '!model?.scheduleDate',
        },
      }),
    ]),
  ];

  startTimeError = '';

  customRecurringOption?: { value: string; label: string };

  private destroy$ = new Subject<void>();

  constructor(private modalService: ModalService, private ref: ChangeDetectorRef, private store: Store) {
    this.store
      .select(selectDisabledSameDayScheduleDayTime)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (disabledScheduleDayTime) => {
          this.schedulerFormModel = {
            ...this.schedulerFormModel,
            disabledDates: disabledScheduleDayTime?.disabledDates,
            disabledWeekdays: disabledScheduleDayTime?.disabledWeekdays,
            minTime: disabledScheduleDayTime?.minTime,
            maxTime: disabledScheduleDayTime?.maxTime,
          };
        },
      });
  }

  ngOnInit() {
    if (this.editData) {
      const editData = { ...this.editData };
      setTimeout(() => {
        const { scheduleDateTime, scheduleRecurringDisplayValue, recurrenceRule, name, alreadySentCount } = editData;
        const dateParsed = parse(scheduleDateTime!, 'MM/dd/yy HH:mm', new Date());
        const date = format(dateParsed, 'MM/dd/yyyy');
        const time = format(dateParsed, 'HH:mm:ss');

        this.schedulerFormModel = {
          ...this.schedulerFormModel,
          scheduleDate: date,
          scheduleTime: time,
          name,
          scheduleRecurring: recurrenceRule,
          alreadySentCount,
          scheduleRecurringDisplayValue,
        };

        if (recurrenceRule) {
          const updatedOptions = this.getRecurringOptions(date);
          if (!updatedOptions.find(({ value }) => value === recurrenceRule)) {
            this.customRecurringOption = {
              value: recurrenceRule,
              label: recurrenceRule,
            };
          }
        }

        this.onModelChange();
      }, 0);
    }
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onModelChange() {
    this.schedulerFormModel = { ...this.schedulerFormModel };
    const { scheduleTime, scheduleDate } = this.schedulerFormModel;
    if (scheduleTime && scheduleDate) {
      const scheduleDateParsed = format(parse(scheduleDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd');

      this.startTimeError =
        differenceInMinutes(utcToZonedTime(new Date(), systemTimeZone), new Date(`${scheduleDateParsed}T${scheduleTime}`)) > 0
          ? 'Start time cannot be before current time.'
          : '';
    }
    this.updateRecurringOptions();
    this.emitChanges();
  }

  emitChanges() {
    const { name, scheduleRecurring, scheduleDate, scheduleTime } = this.schedulerFormModel || {};

    this.store.dispatch(
      TransactionFormActions.setScheduler({
        schedulerDetails: {
          scheduleDateTime: formatDateTimeToUTC({ date: scheduleDate, time: scheduleTime }),
          scheduleDateTimeDisplayValue: scheduleDate
            ? `${format(parse(scheduleDate!, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')} ${scheduleTime}`
            : undefined,
          scheduleRecurringDisplayValue: formatRecurrenceRule(scheduleRecurring),
          recurrenceRule: scheduleRecurring,
          name,
          timeZone: 'UTC',
          isError: (!!scheduleRecurring && !name) || !scheduleDate || !scheduleTime || !!this.startTimeError,
        },
      }),
    );
  }

  updateRecurringOptions() {
    const { scheduleDate, scheduleRecurring } = this.schedulerFormModel;

    if (scheduleRecurring === 'CUSTOM') {
      this.openCustomRecurrenceModal();
      this.setRecurringFieldValue();
      return;
    }

    const updatedOptions = this.getRecurringOptions(scheduleDate);

    if (!updatedOptions.find(({ value }) => value === scheduleRecurring)) {
      this.setRecurringFieldValue();
    }

    this.scheduleRecurringOptions$.next(updatedOptions);
  }

  openCustomRecurrenceModal() {
    const recurrenceModalRef = this.modalService.open(MoveMoneySchedulerRecurrenceModalComponent, {
      className: 'entity-form-modal schedule-recurrence-modal',
    });

    recurrenceModalRef.componentInstance.modalInitData({
      scheduleDate: this.schedulerFormModel.scheduleDate,
    });

    recurrenceModalRef.result.then(
      (result?: { value: string; label: string }) => {
        if (result) {
          this.customRecurringOption = result;
          this.setRecurringFieldValue(this.customRecurringOption.value);
          this.updateRecurringOptions();
        }
      },
      () => false,
    );
  }

  setRecurringFieldValue(value?: string) {
    this.schedulerFormModel = {
      ...this.schedulerFormModel,
      scheduleRecurring: value ?? '',
    };
    this.emitChanges();
  }

  getRecurringOptions(scheduleDate?: string) {
    const scheduleDateParsed = scheduleDate
      ? zonedTimeToUtc(`${format(parse(scheduleDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')} 00:00`, systemTimeZone)
      : undefined;

    if (!scheduleDateParsed) {
      return [...defaultRecurringOptions];
    }

    const dayOfWeek = format(scheduleDateParsed, 'EEEE');
    const dayOfWeekValue = format(scheduleDateParsed, 'EEEEEE').toUpperCase();
    const week = getWeekOfMonth(scheduleDateParsed);
    const isLastWeek = format(scheduleDateParsed, 'MMMM') !== format(add(scheduleDateParsed, { weeks: 1 }), 'MMMM');
    const weekLabels = ['first', 'second', 'third', 'fourth'];
    const weekLabel = isLastWeek ? 'last' : weekLabels[week - 1];
    const dayOfMonth = format(scheduleDateParsed, 'MMMM d');
    const dayValue = format(scheduleDateParsed, 'd');
    const monthValue = format(scheduleDateParsed, 'M');
    const weekValue = isLastWeek ? '-1' : week;

    return uniqBy(
      compact([
        { value: '', label: 'Does not repeat' },
        { value: 'FREQ=DAILY;INTERVAL=1', label: 'Daily' },
        { value: `FREQ=WEEKLY;INTERVAL=1;BYDAY=${dayOfWeekValue}`, label: `Weekly on ${dayOfWeek}` },
        {
          value: `FREQ=MONTHLY;INTERVAL=1;BYSETPOS=${weekValue};BYDAY=${dayOfWeekValue}`,
          label: `Monthly on the ${weekLabel} ${dayOfWeek}`,
        },
        { value: `FREQ=YEARLY;INTERVAL=1;BYMONTH=${monthValue};BYMONTHDAY=${dayValue}`, label: `Annually on ${dayOfMonth}` },
        { value: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR', label: 'Every weekday (Monday to Friday)' },
        this.customRecurringOption
          ? { value: this.customRecurringOption.value, label: formatRecurrenceRule(this.customRecurringOption?.value) }
          : undefined,
        { value: 'CUSTOM', label: 'Custom' },
      ]),
      'value',
    );
  }
}
