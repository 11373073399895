import { createAction, props } from '@ngrx/store';
import { Beneficiary, ErrorMessage } from '@shared/models';

export namespace CustomerBeneficiaryActions {
  export const loadCustomerBeneficiary = createAction('[Customer Beneficiaries API] Load Customer Beneficiary');

  export const loadCustomerBeneficiarySuccess = createAction(
    '[Customer Beneficiaries API] Load Customer Beneficiary Success',
    props<{ beneficiary: Beneficiary }>(),
  );

  export const loadCustomerBeneficiaryError = createAction(
    '[Customer Beneficiaries API] Load Customer Beneficiary Error',
    props<{ error: ErrorMessage }>(),
  );

  export const initialize = createAction('[Customer Beneficiary Page] Initialize');
}
