import { AbstractControl, ValidationErrors } from '@angular/forms';
import { formCheckboxGroup, formRadioGroup, formRow, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { compact } from 'lodash-es';

export const linxRequestProfileFields: RktFormFieldConfig[] = [
  {
    fieldGroup: [
      formRow([
        formCheckboxGroup({
          key: 'personalInfo',
          label: 'Personal Information',
          props: {
            options: [
              { value: 'salutation', label: 'Salutation' },
              { value: 'sex', label: 'Sex' },
              { value: 'socialId', label: 'SSN' },
              { value: 'socialIdCountryCode', label: 'SSN Country' },
              { value: 'dateOfBirth', label: 'Date of Birth' },
              { value: 'cityOfBirth', label: 'City of Birth' },
              { value: 'countryOfBirthCode', label: 'Country of Birth' },
              { value: 'citizenshipCountryCode', label: 'Citizenship Country' },
            ],
          },
        }),
        formRadioGroup({
          key: 'phoneNumber',
          label: 'Phone Number Options',
          props: {
            options: [
              { value: 'WORK', label: 'Work' },
              { value: 'HOME', label: 'Home' },
            ],
          },
        }),
        formCheckboxGroup({
          key: 'addresses',
          label: 'Address Options',
          props: {
            options: [
              { value: 'PHYSICAL', label: 'Physical' },
              { value: 'MAILING', label: 'Mailing' },
              { value: 'SHIPPING', label: 'Shipping' },
            ],
          },
        }),
      ]),
    ],
    validators: {
      required: (control: AbstractControl): ValidationErrors | null => {
        const controlArray = compact(Object.keys(control.value || {}).map((key) => control.value[key]));
        const nonEmpty: string[] = [];
        controlArray.forEach((item) => {
          const selectedOptions = Object.keys(item).filter((el) => item[el]);
          nonEmpty.push(...selectedOptions);
        });
        return nonEmpty.length ? { required: { message: 'error' } } : null;
      },
    },
  },
];
