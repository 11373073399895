import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

type TransformType = ReturnType<CurrencyPipe['transform']>;
type CurrencyCodeType = Parameters<CurrencyPipe['transform']>[1];

@Pipe({ name: 'amount' })
export class AmountPipe extends CurrencyPipe implements PipeTransform {
  override transform(value?: number | string, currencyCode?: CurrencyCodeType): TransformType;

  override transform(value?: null | undefined, currencyCode?: CurrencyCodeType): null;

  override transform(value: number | string | null | undefined, currencyCode?: CurrencyCodeType): TransformType {
    const currencyFormat = super.transform(value, currencyCode, 'symbol-narrow');
    if (!currencyFormat) {
      return currencyFormat;
    }

    return `${currencyFormat.charAt(0)} ${currencyFormat.slice(1)}`;
  }
}
