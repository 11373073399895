import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionSolution, MoveHowSolutionItem } from '@shared/models';

@Component({
  selector: 'app-move-how-solution',
  templateUrl: './move-how-solution.component.html',
  styleUrls: ['./move-how-solution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveHowSolutionComponent {
  @Input() items: MoveHowSolutionItem[] = [];

  @Input() selectedItem?: TransactionSolution;

  @Input() disabledTypes: TransactionSolution[] = [];

  @Output() changed = new EventEmitter<TransactionSolution>();

  isDisabledType(type: TransactionSolution): boolean {
    return !!this.disabledTypes?.includes(type);
  }

  solutionTrackBy(_i: number, solution: MoveHowSolutionItem) {
    return solution.type;
  }

  onItemClick(type: TransactionSolution): void {
    if (this.selectedItem === type) {
      return;
    }

    this.changed.emit(type);
  }
}
