<div class="limits-progress-item">
  <div class="limits-progress-item-label">{{ limitItem.label }}</div>

  <div class="limits-progress-item-single-amount" *ngIf="isSingle">
    {{ (limitItem.amount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$' }}
  </div>

  <ng-container *ngIf="!isSingle">
    <div class="limits-progress-item-amount">
      <div class="limits-progress-item-progress">
        <div class="progress-bar">
          <div class="progress-bar-inner" [ngStyle]="{ width: limitItem.progress > 100 ? 100 : limitItem.progress + '%' }"></div>
        </div>
      </div>

      <div class="limits-progress-item-values">
        <div class="limit-amounts">
          <span class="limit-amounts-spent">
            {{ (limitItem.usedAmount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$' }} spent
          </span>
          of {{ (limitItem.amount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '' }}
        </div>
        <div class="limits-progress-item-used-amount">
          <span *ngIf="limitItem.progress > 0 && limitItem.progress < 1; else limitPercentage"><1</span
          ><ng-template #limitPercentage>{{ limitItem.progress | number: '1.0-1' }}</ng-template
          >%
        </div>
      </div>
    </div>
  </ng-container>
</div>
