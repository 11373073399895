<div class="account-verification-header">
  <div class="title">
    <app-text-with-placeholder [darkBg]="true" [showPlaceholder]="!verificationStatus"
      >Account Verification Status</app-text-with-placeholder
    >

    <div *ngIf="verificationStatus" [class]="'inline-status inline-status-' + verificationStatus.status | lowercase">
      {{ verificationStatus.status | titlecase }}
    </div>
  </div>

  <button *ngIf="verificationStatusHistory" class="view-history-btn" appRemoveFocus (click)="showAccountVerificationHistoryModal()">
    <rkt-icon name="clock"></rkt-icon>View History
  </button>
</div>

<div class="account-verification-body">
  <div class="account-verification-fields">
    <app-details-item
      [showPlaceholder]="!verificationStatus"
      [isDarkBg]="true"
      label="Verification Status Last updated Date"
      [value]="verificationStatus?.updatedAt | dateFormat"
    ></app-details-item>

    <app-details-item
      [showPlaceholder]="!verificationStatus"
      [isDarkBg]="true"
      label="Verification Status Last updated By"
      [value]="verificationStatus?.updatedBy?.id | teamMemberName | async"
    ></app-details-item>
  </div>

  <div class="account-verification-reason">
    <app-details-item
      [showPlaceholder]="!verificationStatus"
      [isDarkBg]="true"
      label="Verification Status Reason"
      [value]="verificationStatus?.reason"
    ></app-details-item>
  </div>
</div>
