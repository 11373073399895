import { createReducer, on } from '@ngrx/store';
import { AdaRegistrationList, ErrorMessage } from '@shared/models';
import { AdaRegistrationListActions } from './ada-registration.actions';

export const adaRegistrationListFeatureKey = 'list';

export interface AdaRegistrationListState {
  adaRegistrations: AdaRegistrationList['items'] | null;
  totalElements: number;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialAdaRegistrationsState: AdaRegistrationListState = {
  adaRegistrations: null,
  totalElements: 0,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<AdaRegistrationListState>(
  initialAdaRegistrationsState,
  on(AdaRegistrationListActions.loadAdaRegistrations, (state): AdaRegistrationListState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(AdaRegistrationListActions.loadAdaRegistrationsSuccess, (state, action): AdaRegistrationListState => {
    return {
      ...state,
      adaRegistrations: action.adaRegistrations,
      totalElements: action.totalElements,
      isLoading: false,
    };
  }),
  on(AdaRegistrationListActions.loadAdaRegistrationsError, (state, action): AdaRegistrationListState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getAdaRegistrations = (state: AdaRegistrationListState) => state.adaRegistrations;
export const getTotalElements = (state: AdaRegistrationListState) => state.totalElements;
export const getLoading = (state: AdaRegistrationListState) => state.isLoading;
