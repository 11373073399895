<div class="rkt-form-field-wrapper">
  <div class="rkt-form-field" [class.has-error]="isMaximumValue || isMinimumValue">
    <input
      type="text"
      class="rkt-form-input"
      [class.is-invalid]="isMaximumValue || isMinimumValue"
      [formControl]="valueField"
      id="limitValue"
      prefix="$ "
      mask="separator.2"
      thousandSeparator=","
      placeholder="$ 0.00"
      (ngModelChange)="onChange($event)"
      (blur)="onBlur()"
    />

    <div *ngIf="isMaximumValue" class="rkt-form-field-invalid-feedback">
      Transaction amount limit must be less or equal to {{ maxLimitsType[limitType] }}.
    </div>
    <div *ngIf="isMinimumValue" class="rkt-form-field-invalid-feedback">Transaction amount limit must be greater than $0.00</div>
  </div>
</div>
