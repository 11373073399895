<div class="solutions" *ngIf="solutions">
  <button
    class="solution-item"
    *ngFor="let method of methods"
    [disabled]="(type === 'from' && (isDebitLegsDisabled$ | async)) || solutions[method.value] !== true"
    [class.active]="(type === 'from' && (fromMethod$ | async) === method.value) || (type === 'to' && (toMethod$ | async) === method.value)"
    (click)="onMethodClick(method.value)"
  >
    <rkt-icon [name]="method.icon"></rkt-icon>
    {{ method.label }}
  </button>
</div>
