<div class="filter-date-selector" [hidden]="selectedValue !== 'CUSTOM_DATE' || !showCalendar">
  <div class="selected-range" [hidden]="showCalendar">
    <div class="from-to">
      <label class="date-label" for="from-date">Select Date</label>

      <div class="date-field">
        <input
          #dpCustomDate
          id="from-date"
          class="date-input"
          placeholder="mm/dd/yyyy"
          mask="M0/d0/0000"
          (focus)="datepicker.navigateTo(customDate || undefined)"
          (input)="customDateInput(customDate, dpCustomDate.value)"
          (blur)="customDateBlur(customDate)"
          [(ngModel)]="inputValue"
          [value]="formatter.format(customDate)"
        />
        <rkt-icon class="calendar-icon" name="calendar"></rkt-icon>
      </div>
    </div>
  </div>

  <ngb-datepicker
    #datepicker
    *ngIf="selectedValue === 'CUSTOM_DATE'"
    [(ngModel)]="customDateModel"
    [minDate]="minStartDate!"
    [maxDate]="maxDate!"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
  >
  </ngb-datepicker>

  <ng-template
    #t
    let-date
    let-focused="focused"
    let-today="today"
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
  >
    <span
      class="custom-day"
      [class.selected]="selected"
      [class.disabled]="disabled"
      [class.focused]="focused"
      [class.today]="today"
      [title]="date.day"
      [class.text-muted]="date.month !== currentMonth"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>

  <div class="calendar-buttons">
    <button type="button" class="table-header-filter-btn" (click)="onClose()" #filterBtn>Cancel</button>

    <button rktButton (click)="onSave()" title="Save" [disabled]="!customDate" class="add-entity-btn">Save</button>
  </div>
</div>
