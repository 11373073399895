import { createReducer, on } from '@ngrx/store';
import { Beneficiary, ErrorMessage } from '@shared/models';
import { CustomerBeneficiaryActions } from './customer-beneficiary.actions';

export const customerBeneficiaryPageFeatureKey = 'customerBeneficiaryPage';

export interface CustomerBeneficiaryState {
  customerBeneficiary: Beneficiary | null;
  isLoading: boolean;
  error: ErrorMessage;
}

export const initialCustomerBeneficiaryState: CustomerBeneficiaryState = {
  customerBeneficiary: null,
  isLoading: false,
  error: null,
};

export const reducer = createReducer<CustomerBeneficiaryState>(
  initialCustomerBeneficiaryState,
  on(CustomerBeneficiaryActions.initialize, (state): CustomerBeneficiaryState => {
    return {
      ...state,
      isLoading: true,
      customerBeneficiary: null,
      error: null,
    };
  }),
  on(CustomerBeneficiaryActions.loadCustomerBeneficiary, (state): CustomerBeneficiaryState => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),
  on(CustomerBeneficiaryActions.loadCustomerBeneficiarySuccess, (state, action): CustomerBeneficiaryState => {
    return {
      ...state,
      customerBeneficiary: action.beneficiary,
      isLoading: false,
    };
  }),
  on(CustomerBeneficiaryActions.loadCustomerBeneficiaryError, (state, action): CustomerBeneficiaryState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const getCustomerBeneficiary = (state: CustomerBeneficiaryState) => state.customerBeneficiary;
export const getLoading = (state: CustomerBeneficiaryState) => state.isLoading;
export const getError = (state: CustomerBeneficiaryState) => state.error;
