import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import {
  TransactionFormActions,
  selectProcessingPriorityByType,
  selectUnavailableProcessingPriorities,
  transactionFormFeature,
} from '@shared/store';
import { MultiLegTransactionProcessingPriority } from '@shared/models';
import { concatLatestFrom } from '@ngrx/effects';

/* @Deprecated */
@Component({
  selector: 'app-create-transaction-processing-priority',
  templateUrl: './create-transaction-processing-priority.component.html',
})
export class CreateTransactionProcessingPriorityComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() type: 'from' | 'to' = 'from';

  isSameDayDisabled = false;

  isNextDayDisabled = false;

  processingPriority = new FormControl<MultiLegTransactionProcessingPriority | null>(null);

  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.getProcessingPriorities();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getProcessingPriorities() {
    this.store
      .select(selectUnavailableProcessingPriorities(this.type))
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFormFeature.selectIsDebitLegsDisabled),
          this.store.select(selectProcessingPriorityByType(this.type)),
          this.store.select(transactionFormFeature.selectFormAction),
        ]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([{ isSameDayDisabled, isNextDayDisabled }, isDebitLegsDisabled, processingPriority, formAction]) => {
          if (this.type === 'from' && isDebitLegsDisabled) {
            this.isSameDayDisabled = true;
            this.isNextDayDisabled = true;

            if (processingPriority) {
              this.processingPriority.setValue(processingPriority);
            }
            return;
          }

          if (formAction === 'EDIT_SLT') {
            this.processingPriority.setValue(processingPriority);
            this.isSameDayDisabled = true;
            this.isNextDayDisabled = true;
          } else {
            this.isSameDayDisabled = isSameDayDisabled;
            this.isNextDayDisabled = isNextDayDisabled;

            if (
              processingPriority &&
              ((processingPriority === 'NEXT_DAY' && !isNextDayDisabled) || (processingPriority === 'SAME_DAY' && !isSameDayDisabled))
            ) {
              this.processingPriority.setValue(processingPriority);
            } else if (isSameDayDisabled && isNextDayDisabled) {
              this.processingPriority.setValue(null);
            } else if (isSameDayDisabled && !isNextDayDisabled) {
              this.processingPriority.setValue('NEXT_DAY');
            } else if (!isSameDayDisabled && isNextDayDisabled) {
              this.processingPriority.setValue('SAME_DAY');
            }
          }

          this.onProcessingPrioritySelect();
        },
      });
  }

  onProcessingPrioritySelect() {
    if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFromProcessingPriority({ processingPriority: this.processingPriority.value }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveToProcessingPriority({ processingPriority: this.processingPriority.value }));
    }
  }
}
