import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-financial-account-activity-table',
  templateUrl: 'external-financial-account-activity-table.component.html',
})
export class ExternalFinancialAccountActivityTableComponent {
  public financialAccountId?: string | null;

  get participatingFinancialAccountId() {
    return this.financialAccountId ? [this.financialAccountId] : [];
  }

  constructor(private activatedRoute: ActivatedRoute) {
    this.financialAccountId = this.activatedRoute.snapshot?.parent?.paramMap.get('id');
  }
}
