import { LinxV1RequestFormInstanceRaw, LinxV1RequestList, LinxV1RequestListItem, LinxV1RequestListRaw } from '@shared/models';

export const mapLinxRequest = (response: LinxV1RequestFormInstanceRaw): LinxV1RequestListItem => {
  const { instance, result } = response || {};

  return {
    ...result?.entries,
    name: result?.entries?.name || '',
    createdAt: instance?.createdAt,
    createdBy: instance?.createdBy,
    updatedAt: instance?.updatedAt,
    updatedBy: instance?.updatedBy,
    expiry: instance?.expiry,
    id: instance?.instanceId,
    shortId: instance?.instanceId?.split('-')[0]?.toUpperCase(),
    status: instance?.instanceState,
  };
};

export const mapLinxRequestList = (response: LinxV1RequestListRaw): LinxV1RequestList => {
  const { instances = [], totalElements } = response;

  const items = instances.map((item) => mapLinxRequest(item));

  return { items, totalElements };
};
