<div class="summary-info">
  <div class="summary-info-header">
    <div class="name">
      <app-text-with-placeholder [showPlaceholder]="isLoading">{{ user?.name | titlecase }}</app-text-with-placeholder>
    </div>

    <div class="header-row user-name" *ngIf="customerType === 'business'">
      <app-text-with-placeholder [showPlaceholder]="isLoading">{{ user?.businessContactName }}</app-text-with-placeholder>
    </div>
  </div>

  <div class="summary-info-body">
    <div class="title">{{ isBusinessCustomer ? 'Business' : 'Primary' }} Information</div>

    <div class="body-row">
      <div class="row-icon"><rkt-icon name="phone"></rkt-icon></div>
      <div class="row-value" [class.no-value]="!user?.phoneNumber">
        <app-text-with-placeholder [showPlaceholder]="isLoading">{{ user?.phoneNumber | phone | notAvailable }}</app-text-with-placeholder>
      </div>
    </div>

    <div class="body-row" *ngIf="!isBusinessCustomer">
      <div class="row-icon"><rkt-icon name="mail"></rkt-icon></div>
      <div class="row-value" [class.no-value]="!user?.email">
        <app-text-with-placeholder [showPlaceholder]="isLoading">{{ user?.email | notAvailable }}</app-text-with-placeholder>
      </div>
    </div>

    <div class="body-row" *ngIf="!isRecipient">
      <div class="row-icon"><rkt-icon name="location"></rkt-icon></div>
      <div class="row-value" [class.no-value]="!user?.address">
        <app-text-with-placeholder [showPlaceholder]="isLoading">{{ user?.address | notAvailable }}</app-text-with-placeholder>
      </div>
    </div>
  </div>

  <div class="summary-info-block" *ngIf="!isRecipient && user?.sex">
    <div class="details-row">
      <app-details-item [showPlaceholder]="isLoading" [class.no-value]="!user?.sex" label="Sex" [value]="userGender"></app-details-item>
    </div>
  </div>

  <div class="summary-info-block footer-section" *ngIf="!isRecipient">
    <a class="details-link" routerLink="../detailed-profile">View more information</a>
  </div>
</div>
