<div class="financial-account-selection-title">{{ type | titlecase }} Account</div>

<div class="financial-account-container" *ngIf="formAction$ | async as formAction">
  <ng-container *ngIf="type === 'from'">
    <button *ngIf="(fromAccount$ | async) === null" class="empty-state-button" (click)="onSelectFinancialAccountClick()" appRemoveFocus>
      <div class="empty-state-icon"><rkt-icon name="plus"></rkt-icon></div>
    </button>

    <ng-container *ngIf="fromAccount$ | async as fromAccount">
      <div class="selected-financial-account">
        <div class="rows">
          <div class="row">
            <div class="label">Account Holder:</div>
            <div class="value">
              <div [class.empty-value]="!(fromAccount.accountHolderInfo || '' | rktSplit: '::':0)">
                {{ fromAccount.accountHolderInfo || '' | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(fromAccount.accountHolderInfo || '' | rktSplit: '::':1)">
                {{ fromAccount.accountHolderInfo || '' | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Info:</div>
            <div class="value">
              <div [class.empty-value]="!(fromAccount.accountInfo | rktSplit: '::':0)">
                {{ fromAccount.accountInfo | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(fromAccount.accountInfo | rktSplit: '::':1)">
                {{ fromAccount.accountInfo | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Category:</div>
            <div class="value">{{ fromAccount.accountCategory }}</div>
          </div>

          <div class="row" *ngIf="fromAccount.category !== 'EXTERNAL'">
            <div class="label">Available Balance:</div>
            <div class="value">
              <div *ngIf="fromAccount.availableBalance !== undefined; else noValue">
                {{ fromAccount.currency | currencySign }}
                {{ (fromAccount.availableBalance | number: '1.2-2') || '' | mask: 'separator':',' }}
              </div>
            </div>
          </div>
        </div>

        <div class="edit-btn-wrapper" *ngIf="(isDebitLegsDisabled$ | async) === false">
          <button
            class="edit-btn"
            (click)="onSelectFinancialAccountClick({ selectedFinancialAccount: fromAccount, formAction, fromAccount })"
          >
            <rkt-icon name="edit"></rkt-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type === 'to'">
    <button
      *ngIf="(toAccount$ | async) === null && (fromAccount$ | async) as fromAccount"
      class="empty-state-button"
      (click)="onSelectFinancialAccountClick({ fromAccount, formAction })"
      appRemoveFocus
    >
      <div class="empty-state-icon"><rkt-icon name="plus"></rkt-icon></div>
    </button>

    <button
      *ngIf="(toAccount$ | async) === null && (fromAccount$ | async) === null"
      class="empty-state-button"
      (click)="onSelectFinancialAccountClick()"
      [attr.disabled]="true"
      appRemoveFocus
    >
      <div class="empty-state-icon"><rkt-icon name="plus"></rkt-icon></div>
    </button>

    <ng-container *ngIf="toAccount$ | async as toAccount">
      <div class="selected-financial-account">
        <div class="rows">
          <div class="row">
            <div class="label">Account Holder:</div>
            <div class="value">
              <div [class.empty-value]="!(toAccount.accountHolderInfo || '' | rktSplit: '::':0)">
                {{ toAccount.accountHolderInfo || '' | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(toAccount.accountHolderInfo || '' | rktSplit: '::':1)">
                {{ toAccount.accountHolderInfo || '' | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Info:</div>
            <div class="value">
              <div [class.empty-value]="!(toAccount.accountInfo | rktSplit: '::':0)">
                {{ toAccount.accountInfo | rktSplit: '::':0 | notAvailable: '--' }}
              </div>
              <div [class.empty-value]="!(toAccount.accountInfo | rktSplit: '::':1)">
                {{ toAccount.accountInfo | rktSplit: '::':1 | notAvailable: '--' }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="label">Account Category:</div>
            <div class="value">{{ toAccount.accountCategory }}</div>
          </div>

          <div class="row" *ngIf="toAccount.category !== 'EXTERNAL'">
            <div class="label">Available Balance:</div>
            <div class="value">
              <div *ngIf="toAccount.availableBalance !== undefined; else noValue">
                {{ toAccount.currency | currencySign }}
                {{ (toAccount.availableBalance | number: '1.2-2') || '' | mask: 'separator':',' }}
              </div>
            </div>
          </div>
        </div>

        <div class="edit-btn-wrapper" *ngIf="fromAccount$ | async as fromAccount">
          <button
            class="edit-btn"
            (click)="onSelectFinancialAccountClick({ selectedFinancialAccount: toAccount, formAction, fromAccount })"
          >
            <rkt-icon name="edit"></rkt-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noValue>
  <div class="empty-value">--</div>
</ng-template>
