import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-page-header-placeholder',
  templateUrl: './details-page-header-placeholder.component.html',
  styleUrls: ['./details-page-header-placeholder.component.scss'],
})
export class DetailsPageHeaderPlaceholderComponent {
  @Input() pageReturnURL!: string;

  @Input() withStatus = true;

  @Input() isDarkBg = false;
}
