import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { compact, startCase } from 'lodash-es';
import { HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env';
import { constants } from '@shared/constants';
import { Store } from '@ngrx/store';
import {
  BusinessAccount,
  TeamMemberList,
  TeamMember,
  RequestPageParams,
  TeamMemberPasswordDetails,
  TeamMemberActivate,
  TeamMemberListRaw,
  TeamMemberRaw,
  AddTeamMemberParams,
  UpdateTeamMemberStateParams,
  SubjectQuickInfo,
  CreatedUpdatedBy,
  TeamMembersAuditInfo,
} from '@shared/models';
import { BusinessAccountService } from '@shared/services';
import { getRealmName, toPhone } from '@shared/utils';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { BackendService } from '@shared/services/backend.service';
import { CUSTOM_ERROR_MESSAGE, SKIP_ERROR_NOTIFICATION } from '@shared/interceptors';
import { AuditActions } from '@shared/store';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService {
  teamMemberInfo: Record<string, TeamMember['name'] | undefined> = {};

  constructor(private backendService: BackendService, private businessAccountService: BusinessAccountService, private store: Store) {}

  public getBusinessAccountTeamMembers({ page, size, searchString, sortParams }: RequestPageParams): Observable<TeamMemberList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<TeamMemberListRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/employees`, { params })
          .pipe(
            map((response) => {
              const { content = [], totalElements = 0 } = response || {};

              return {
                items: content?.map((item) => TeamMemberService.mapTeamMemberData(item)),
                totalElements,
              };
            }),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public addTeamMember(teamMemberData: AddTeamMemberParams): Observable<TeamMemberRaw> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<TeamMemberRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/employees`, teamMemberData)
          .pipe(
            map((response) => response),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateEmployee(teamMemberId: TeamMember['id'], teamMemberData: Partial<AddTeamMemberParams>): Observable<TeamMember> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<TeamMemberRaw>(
            `${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${teamMemberId}`,
            teamMemberData,
          )
          .pipe(
            map((response) => TeamMemberService.mapTeamMemberData(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getBusinessAccountEmployeeById(teamMemberId: TeamMember['id'], isSuppressError = false): Observable<TeamMember> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<TeamMemberRaw>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${teamMemberId}`, {
            context: isSuppressError
              ? new HttpContext().set(SKIP_ERROR_NOTIFICATION, true)
              : new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to get Team Member details.'),
          })
          .pipe(
            tap({
              next: (response) => {
                const { createdBy, updatedBy } = response;
                this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
              },
            }),
            map((response) => TeamMemberService.mapTeamMemberData(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public activateEmployeeAccount(passwordDetails: TeamMemberPasswordDetails): Observable<TeamMemberActivate> {
    const realm = getRealmName();
    const url = `${environment.accountFlowEndpoint}/api/auth/realms/${realm}/validate`;
    return this.backendService.post<TeamMemberActivate>(url, passwordDetails).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public resendEmployeeWelcomeEmail(teamMemberId: TeamMember['id']): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post(
            `${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${teamMemberId}/resend-welcome-email`,
            {},
          )
          .pipe(
            map(() => true),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public resetEmployeePassword(passwordDetails: TeamMemberPasswordDetails): Observable<TeamMemberActivate> {
    const realm = getRealmName();
    const url = `${environment.accountFlowEndpoint}/api/auth/realms/${realm}/validate-reset-password`;
    return this.backendService.post<TeamMemberActivate>(url, passwordDetails).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public updateState(teamMemberId: TeamMember['id'], { state }: UpdateTeamMemberStateParams): Observable<TeamMember> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<TeamMemberRaw>(
            `${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${teamMemberId}/${state}`,
            {},
            {
              context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to update Team Member state.'),
            },
          )
          .pipe(
            map((response) => response as TeamMember),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public deleteTeamMember(teamMemberId: TeamMember['id']): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .delete(`${environment.accountFlowService}/business-accounts/${businessAccountId}/employees/${teamMemberId}`, {
            context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to remove Team Member.'),
          })
          .pipe(
            map(() => true),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getSubjectQuickInfo(id: string, isSuppressError = false): Observable<SubjectQuickInfo> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<SubjectQuickInfo>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/subjects/${id}`, {
            context: isSuppressError
              ? new HttpContext().set(SKIP_ERROR_NOTIFICATION, true)
              : new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to get Team Member details.'),
          })
          .pipe(catchError((errorRes) => throwError(() => errorRes))),
      ),
    );
  }

  private static mapTeamMemberData(teamMember: TeamMemberRaw): TeamMember {
    const phones = teamMember.phoneNumbers || [];
    const primaryPhone = phones.filter((phone) => phone.type !== 'WORK')[0];
    const workPhone = phones.filter((phone) => phone.type === 'WORK')[0];
    const teamMemberRoles: string[] = [];
    teamMember.roles?.forEach((role) => teamMemberRoles.push(role.name));
    const role = teamMemberRoles.join(',');

    return {
      ...teamMember,
      role,
      displayName: compact([teamMember.firstName, teamMember.middleName, teamMember.lastName]).join(' '),
      phoneNumbers: phones,
      status: teamMember.userStatus?.toLowerCase(),
      primaryPhoneNumber: toPhone(teamMember.primaryPhoneNumber),
      shortId: teamMember.id.split('-')[0],
      name: compact([teamMember.firstName, teamMember.middleName, teamMember.lastName]).join(' '),
      avatarInitials: startCase(teamMember.firstName.charAt(0)),
      primaryPhone,
      workPhone,
      workPhoneNumber: toPhone(workPhone?.number),
      workPhoneNumberExtension: workPhone?.extension,
    };
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  getEmployeeDetails(entity?: { id: string; type?: string }): Observable<string | undefined> {
    if (!entity?.type) {
      return of(undefined);
    }

    /*
     TODO https://rocketbnk.atlassian.net/browse/KOR-10218
      kor_employee type should be properly handled after https://rocketbnk.atlassian.net/browse/KOR-6840 is done
     */
    if (entity.type === 'internal' || entity.type === 'BA' || entity.type === 'kor_employee') {
      return of('Internal');
    }

    if (this.teamMemberInfo[entity.id]) {
      return of(this.teamMemberInfo[entity.id]);
    }

    if (entity.type === 'service-account' || entity.type === 'employee') {
      return this.getSubjectQuickInfo(entity.id, true).pipe(
        map((teamMember) => {
          this.teamMemberInfo[entity.id] = teamMember.name;
          return teamMember.name;
        }),
        catchError((errorRes) => {
          throwError(() => errorRes);
          return of(undefined);
        }),
      );
    }

    return this.getBusinessAccountEmployeeById(entity.id, true).pipe(
      map((teamMember) => {
        this.teamMemberInfo[entity.id] = teamMember.name;
        return teamMember.name;
      }),
      catchError((errorRes) => {
        throwError(() => errorRes);
        return of(undefined);
      }),
    );
  }

  getEmployeesDetails(entitys: CreatedUpdatedBy[], teamMemberInfo: TeamMembersAuditInfo): Observable<SubjectQuickInfo[]> {
    const subjects = entitys
      .filter((entity) => !teamMemberInfo[entity.id] && (entity.type === 'service-account' || entity.type === 'employee'))
      .map((entity) => entity.id);

    const subjectsQuickInfo: SubjectQuickInfo[] = [];
    if (subjects.length !== entitys.length) {
      entitys
        .filter((entity) => !teamMemberInfo[entity.id])
        .forEach((entity) => {
          if (['internal', 'BA', 'kor_employee'].includes(entity.type)) {
            subjectsQuickInfo.push({ id: entity.id, name: 'Internal' } as SubjectQuickInfo);
          }
        });
    }

    if (subjects.length) {
      return this.getSubjectsQuickInfo(subjects).pipe(map((subjectsObs) => [...subjectsObs, ...subjectsQuickInfo]));
    } else {
      return of(subjectsQuickInfo);
    }
  }

  getSubjectsQuickInfo(subjectIds: string[]): Observable<SubjectQuickInfo[]> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<SubjectQuickInfo[]>(`${environment.accountFlowService}/business-accounts/${businessAccountId}/subjects/retrieve`, {
            subjectIds,
          })
          .pipe(catchError(() => of([]))),
      ),
    );
  }
}
