<form [formGroup]="debitAuthorizationForm">
  <div class="debit-auth-forms">
    <rkt-form [form]="debitAuthorizationForm" [fields]="debitAuthorizationFields" [model]="formModel"></rkt-form>

    <rkt-form [form]="noteForm" [fields]="notesField" [model]="formModel"></rkt-form>
  </div>

  <div class="manual-authorization" *ngIf="isManualAuthorization">
    <div class="fixed-amount">
      <div class="fixed-amount-section-title">Fixed Amount</div>
      <div class="fixed-amount-section">
        <div class="rkt-form">
          <div class="rkt-form-control">
            <div class="rkt-form-field">
              <div class="rkt-form-input-field">
                <input
                  type="text"
                  class="rkt-form-input"
                  [formControl]="fixedAmount"
                  id="fixedAmount"
                  [prefix]="fixedAmount.value ? '$ ' : ''"
                  mask="separator.2"
                  maxlength="20"
                  thousandSeparator=","
                  [placeholder]="'$ 0.00'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-linx-request-day-or-month-input-control [label]="'Fixed Term'" [suffixLabel]="'months'" (changed)="onFixedtermChange($event)">
    </app-linx-request-day-or-month-input-control>
  </div>

  <div class="linx-request-buttons">
    <button rktButton btnType="tertiary" class="linx-request-btn btn-clear" title="Clear" (click)="onClearBtnClick()">Clear</button>

    <button rktButton title="Continue" [disabled]="debitAuthorizationForm.invalid" (click)="onClickContinue()">Continue</button>
  </div>
</form>
