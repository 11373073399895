import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardMerchant, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { NavigationService } from '@shared/services';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
})
export class MerchantDetailsComponent implements OnDestroy {
  pageClassPrefix = 'merchant-details-';

  merchantDetailsContext?: TemplateContext<CardMerchant>;

  merchantDetails$ = this.store.select(fromFinancialAccount.selectCardAccountMerchantDetails);

  constructor(private navigationService: NavigationService, private store: Store) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadMerchants());
  }

  ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
  }
}
