<div class="details-item-label" [class.primary]="isPrimary">
  <div *ngIf="!showLabel" class="loading-placeholder" [class.dark-bg]="isDarkBg"></div>
  <ng-container *ngIf="showLabel">{{ label }}</ng-container>
</div>

<div class="details-item-value" [class.multiline]="multiline">
  <div *ngIf="!multiline" class="loading-placeholder" [class.dark-bg]="isDarkBg"></div>

  <div class="loading-wrapper" *ngIf="multiline">
    <div class="loading-placeholder" *ngFor="let row of [1, 2, 3]" [class.dark-bg]="isDarkBg"></div>
  </div>
</div>
