<h1 class="modal-title">Select Business Account</h1>

<div class="table-header table-header-offset">
  <div class="table-header-controls no-title account-list-header">
    <div class="table-header-search">
      <rkt-table-search placeholder="Search..." (changed)="onSearch($event)"></rkt-table-search>
    </div>

    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
    </div>
  </div>
</div>

<div class="table-container">
  <rkt-table
    [rows]="businessAccountList"
    [columns]="columns"
    [limit]="5"
    (fetchData)="getBusinessAccountList($event)"
    [count]="totalElements"
    [loadingIndicator]="loading"
    [offset]="activePage"
    [selectionType]="selectionType"
    [selected]="selectedBusinessAccount"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    [class.no-pagination]="totalElements < 5"
  ></rkt-table>

  <app-filter-menu
    #filterMenu
    [initialValues]="activeFilters"
    [filters]="filters"
    (opened)="filterBtn.classList.add('opened')"
    (closed)="filterBtn.classList.remove('opened')"
    (saved)="applyFilters($event)"
  ></app-filter-menu>
</div>

<div class="modal-buttons">
  <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
  <button
    rktButton
    type="submit"
    [disabled]="!selectedBusinessAccount.length"
    [title]="'Select Business Account'"
    (click)="onSelectBtnClick()"
  >
    Select
  </button>
</div>
