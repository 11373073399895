import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TeamMember, User } from '@shared/models';

export type TeamMemberDetailsAction = 'EDIT' | 'SUSPEND' | 'UNSUSPEND' | 'REMOVE' | 'EDIT_SAVE' | 'EDIT_CANCEL';

@Component({
  selector: 'app-team-member-details-header',
  templateUrl: './team-member-details-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMemberDetailsHeaderComponent {
  @Input() currentUserId!: User['id'] | null;

  @Input() teamMember!: TeamMember | null;

  @Input() isControlsDisabled = true;

  @Output() btnClicked = new EventEmitter<TeamMemberDetailsAction>();

  get isSuspensionControlsDisabled(): boolean {
    return this.teamMember?.id === this.currentUserId;
  }
}
