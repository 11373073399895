import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

type AttentionTypes = 'sameDayACHLimit' | 'sameDayWindowClosed';

@Component({
  selector: 'app-move-money-attention-modal',
  templateUrl: './move-money-attention-modal.component.html',
})
export class MoveMoneyAttentionModalComponent {
  attentionType!: AttentionTypes;

  constructor(public activeModal: ActiveModal) {}
}
