import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BackendService } from '@shared/services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  constructor(private backendService: BackendService) {}

  downloadPrivacyPolicy(): void {
    const url = 'https://rktkor-privacypolicy.s3.amazonaws.com/RocketKOR+Privacy+Policy+v1.pdf';
    this.backendService.get<ArrayBuffer>(url, { responseType: 'arraybuffer' }).subscribe({
      next: (response) => {
        this.downloadFile(response, 'RocketKOR_Privacy_Policy_v1.pdf');
      },
    });
  }

  downloadTermsConditions(): void {
    const url = 'https://rktkor-privacypolicy.s3.amazonaws.com/RocketKOR+T%26Cs+1-13-21.pdf';
    this.backendService.get<ArrayBuffer>(url, { responseType: 'arraybuffer' }).subscribe({
      next: (response) => {
        this.downloadFile(response, 'RocketKOR_T&Cs_1-13-21.pdf');
      },
    });
  }

  downloadFile(response: ArrayBuffer, fileName: string): void {
    const blob = new Blob([response], { type: 'application/pdf' });
    const file = new File([blob], fileName);
    saveAs(file);
  }
}
