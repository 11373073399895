import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PortalAppModule } from './app/app.module';
import packageInfo from '../package.json';
import { initializeSentry, getEnvironment } from '@shared/services';

initializeSentry('rocket-portal@' + packageInfo.version);

if (getEnvironment().production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(PortalAppModule)
  .catch((err) => console.error(err));
