<div class="custom-input-section-title">{{ label }}</div>

<div class="custom-input-section">
  <div class="rkt-form">
    <div class="rkt-form-control input-feilds">
      <div class="rkt-form-input-field">
        <input
          class="rkt-form-input"
          type="number"
          pattern="^[0-9]*$"
          [class.is-invalid]="dayOrMonthFormControl.value && showMaxValueErrorMessage"
          [formControl]="dayOrMonthFormControl"
          id="form-number-control"
          (ngModelChange)="onChange($event)"
        />
        <span *ngIf="suffixLabel === 'months'">{{ dayOrMonthFormControl.value === 1 ? 'month' : 'months' }}</span>
        <span *ngIf="suffixLabel === 'days'">{{ dayOrMonthFormControl.value === 1 ? 'day' : 'days' }}</span>
      </div>

      <div class="rkt-form-input-field" *ngIf="showHours">
        <input
          class="rkt-form-input"
          type="number"
          pattern="^[0-9]*$"
          [class.is-invalid]="hoursFormControl.value && showMaxValueErrorMessage"
          [formControl]="hoursFormControl"
          id="form-number-control"
          (ngModelChange)="onHoursChange($event)"
        />
        <span>{{ hoursFormControl.value === 1 ? 'hour' : 'hours' }}</span>
      </div>
    </div>
    <div *ngIf="showMaxValueErrorMessage" class="rkt-form-field-invalid-feedback">
      {{ label }} must be less or equal to {{ maxValue }} {{ suffixLabel }}
    </div>
    <div *ngIf="dayOrMonthFormControl.invalid" class="rkt-form-field-invalid-feedback">Enter a valid number for {{ suffixLabel }}.</div>
    <div *ngIf="hoursFormControl.invalid" class="rkt-form-field-invalid-feedback">Enter a valid number for hours.</div>
  </div>
</div>
