import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { NoteService } from '@shared/services';
import { AttachmentCreateFormComponent, ConfirmModalComponent } from '@shared/components';
import { emptySpacesValidator } from '@shared/validators';
import { MESSAGE } from '@shared/constants';
import { AttachmentCreateModel, Note } from '@shared/models';
import { ErrorUtils } from '@shared/utils';
import { fromAuth } from '@shared/store';
import { isObject } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-note-item',
  templateUrl: './note-item.component.html',
  styleUrls: ['./note-item.component.scss'],
})
export class NoteItemComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('noteField') noteField!: ElementRef;

  @Input() singleNote = false;

  @Input() note!: Note;

  @Input() notesLoading!: boolean;

  @Input() showPlaceholder?: boolean;

  @Output() changed: EventEmitter<void> = new EventEmitter();

  error?: string;

  displayError = false;

  editNoteValue = new UntypedFormControl('', [emptySpacesValidator]);

  loading = false;

  isEdit = false;

  activeUserId$ = this.store.select(fromAuth.selectActiveUserId);

  attachmentsData: Partial<AttachmentCreateModel>[] = [];

  get isEditSaveDisabled(): boolean {
    return !this.editNoteValue.value || this.editNoteValue.value === this.note.contentText || this.editNoteValue.errors !== null;
  }

  get isShowSpinner() {
    return this.notesLoading || this.loading;
  }

  constructor(public ref: ChangeDetectorRef, private noteService: NoteService, private store: Store, private modalService: ModalService) {}

  ngOnInit(): void {
    this.editNoteValue.valueChanges.subscribe({
      next: () => {
        if (this.displayError) {
          this.displayError = false;
        }
      },
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const notesLoading = changes.notesLoading?.currentValue;
    this.notesLoading = notesLoading;

    if (!notesLoading) {
      this.setLoading(false);
      this.isEdit = false;
      this.error = '';
    }
  }

  onNoteEdit(): void {
    this.isEdit = true;
    this.editNoteValue.setValue(this.note.contentText);
    setTimeout(() => this.noteField.nativeElement.focus(), 100);
  }

  onNoteEditCancel(): void {
    this.isEdit = false;
    this.error = '';
    this.attachmentsData = [];
  }

  onNoteDelete(): void {
    const suspendConfirmModalRef = this.modalService.open(ConfirmModalComponent, {
      size: 'lg',
      className: `confirm-modal state-update-confirm-modal ${this.singleNote ? '' : 'note-delete-confirm'}`,
    });
    suspendConfirmModalRef.componentInstance.customText = 'Are you sure you want to delete this note? This cannot be undone.';
    suspendConfirmModalRef.componentInstance.title = 'Delete note';

    suspendConfirmModalRef.result.then(
      (result) => {
        if (result) {
          this.onNoteDeleteConfirm();
        }
      },
      () => false,
    );
  }

  onNoteEditSave(): void {
    this.setLoading(true);

    this.noteService
      .updateNote(this.note.id, {
        contentText: this.editNoteValue.value,
        attachments: this.attachmentsData.length ? this.attachmentsData : undefined,
      })
      .subscribe({
        next: () => {
          this.changed.emit();
        },
        error: (error) => {
          this.displayError = true;
          this.handleError(error, 'updateNote');
        },
      });
  }

  onNoteDeleteConfirm(): void {
    this.setLoading(true);

    this.noteService.deleteNote(this.note.id).subscribe({
      next: () => {
        this.changed.emit();
      },
      error: (error) => {
        this.handleError(error, 'deleteNote');
      },
    });
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  onNoteFieldBlur(): void {
    this.editNoteValue.setValue(this.editNoteValue.value.trim());
  }

  handleError(error: number | string | HttpErrorResponse, serviceMethod: string): void {
    this.setLoading(false);
    this.error = (isObject(error) && error.status === 403) || error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
    ErrorUtils.catchError(`noteService.${serviceMethod}`, this.error);
  }

  onAddAttachment(attachment: Partial<AttachmentCreateModel>): void {
    this.attachmentsData.push(attachment);
  }

  onDeleteFileBtnClick(i: number): void {
    this.attachmentsData.splice(i, 1);
  }

  onEditFileBtnClick(i: number): void {
    const attachmentEditFormModalRef = this.modalService.open(AttachmentCreateFormComponent, {
      className: 'entity-form-modal',
    });
    attachmentEditFormModalRef.componentInstance.isEditMode = true;
    attachmentEditFormModalRef.componentInstance.formModel = {
      name: this.attachmentsData[i].name,
      description: this.attachmentsData[i].description,
    };

    attachmentEditFormModalRef.result.then(
      (result) => {
        if (result) {
          this.attachmentsData[i].description = result.description;
          this.attachmentsData[i].name = result.name;
        }
      },
      () => false,
    );
  }

  onAttachmentsDropped(attachments: Partial<AttachmentCreateModel>[]): void {
    this.attachmentsData.push(...attachments);
  }
}
