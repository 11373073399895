<div class="table-header table-header-offset-sm table-header-with-controls" *ngIf="!isEmpty">
  <div class="table-header-controls">
    <button
      rktButton
      appRemoveFocus
      btnType="primary"
      title="Add a Beneficial Owner"
      (click)="onAddBeneficiaryBtnClick(holderId)"
      class="add-entity-btn"
      [disabled]="loading"
    >
      <rkt-icon name="plus"></rkt-icon>
      Add a Beneficial Owner
    </button>
  </div>
</div>
