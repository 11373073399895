import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
})
export class SuccessModalComponent {
  actionName!: string;

  type!: 'Recipient' | 'TeamMember' | 'FinancialAccount' | 'Transaction' | 'Customer';

  customTitle = '';

  get typeText() {
    switch (this.type) {
      case 'TeamMember':
        return 'Team Member';
      case 'Recipient':
        return 'Recipient Account';
      case 'FinancialAccount':
        return 'Financial Account';
      case 'Transaction':
        return 'Transaction';
      case 'Customer':
        return 'Customer Account';
      default:
        return this.type;
    }
  }

  constructor(public activeModal: ActiveModal) {}
}
