<div class="transaction-amount-field-label">Amount</div>

<div class="transaction-amount-field-container" [class.disabled]="isDebitLegsDisabled$ | async">
  <div class="amount-preffix" *ngIf="(amountOption$ | async) === 'EXACT_AMOUNT'">&nbsp;$&nbsp;</div>
  <div
    class="transaction-amount-field-wrapper"
    [class.transaction-amount-field-wrapper--percentage]="(amountOption$ | async) === 'PERCENTAGE'"
    #textInputWrapperRef
  >
    <ng-container *ngIf="(amountOption$ | async) === 'EXACT_AMOUNT'">
      <input
        #textInputRef
        type="text"
        class="transaction-amount-field-input"
        [formControl]="moveAmount"
        autocomplete="off"
        id="moveAmount"
        mask="separator.2"
        [attr.disabled]="(isDebitLegsDisabled$ | async) ? true : null"
        maxlength="20"
        [size]="4"
        thousandSeparator=","
        placeholder="00.00"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
      />
    </ng-container>

    <ng-container *ngIf="(amountOption$ | async) === 'PERCENTAGE'">
      <input
        #textInputRef
        appPercentageField
        type="text"
        class="transaction-amount-field-input"
        [formControl]="moveAmount"
        autocomplete="off"
        [maxlength]="10"
        [attr.disabled]="(isDebitLegsDisabled$ | async) ? true : null"
        id="moveAmount"
        [size]="moveAmount.value ? 1 : 4"
        placeholder="00.00"
        (ngModelChange)="onChange($event)"
        (blur)="onBlur()"
      />
    </ng-container>
  </div>
  <div class="amount-suffix" *ngIf="(amountOption$ | async) === 'PERCENTAGE'">%</div>
</div>
