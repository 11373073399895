<div class="delivery-speed-header">
  <div class="delivery-speed-title">Choose delivery speed</div>

  <div class="multi-leg-title-container" *ngIf="(transactionType$ | async) === 'MLT'">
    <div class="multi-leg-title">
      <div class="multi-leg-title-icon"></div>
      This is a Multi-Leg Transaction
    </div>
  </div>
</div>

<div class="delivery-speed-form">
  <div class="delivery-speed-selection">
    <div class="delivery-speed-selection-inner">
      <div *ngFor="let option of deliverySpeedOptions$ | async; let i = index" class="rkt-form-radio delivery-speed-radio">
        <label
          class="rkt-form-radio-label"
          [for]="id + '_' + i"
          [class.disabled]="
            ((formAction$ | async) === 'EDIT_MLT' && (isMltDisbursementStage$ | async)) || (formAction$ | async) === 'EDIT_SLT'
          "
        >
          <input
            hidden
            type="radio"
            [id]="id + '_' + i"
            class="rkt-form-radio-input"
            [attr.value]="option.value"
            [value]="option.value"
            [formControl]="deliverySpeed"
            [attr.disabled]="
              ((formAction$ | async) === 'EDIT_MLT' && (isMltDisbursementStage$ | async)) || (formAction$ | async) === 'EDIT_SLT'
                ? true
                : null
            "
            (change)="onDeliverySpeedChange(option)"
          />
          <div class="rkt-form-radio-icon"></div>

          <div class="rkt-form-radio-value">
            <div>
              {{ option.label }}
            </div>

            <div class="est-label">
              In the <strong>{{ option.estLabelTime }}</strong> of
              <strong>{{ option.estLabelDate }} ({{ option.estLabelDay }})</strong>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>

  <div class="leg-fields" *ngIf="selectedDeliveryOption$ | async">
    <div class="rows">
      <ng-container *ngIf="(transactionType$ | async) === 'SLT'">
        <div class="row">
          <div class="label">Payment reason<span class="asterisk">*</span></div>
          <div class="value">
            <app-payment-reason-selection type="from"></app-payment-reason-selection>
          </div>
        </div>
        <ng-container *ngIf="fromAccountMethod$ | async as fromAccountMethod">
          <app-create-transaction-ach-details *ngIf="fromAccountMethod === 'ach'" type="from"></app-create-transaction-ach-details>

          <app-create-transaction-wire-details *ngIf="fromAccountMethod === 'wire'" type="from"></app-create-transaction-wire-details>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="(transactionType$ | async) === 'MLT'">
        <div class="row">
          <div class="label">Payment reason<span class="asterisk">*</span></div>
          <div class="value">
            <app-payment-reason-selection type="to"></app-payment-reason-selection>
          </div>
        </div>

        <ng-container *ngIf="toAccountMethod$ | async as toAccountMethod">
          <app-create-transaction-ach-details *ngIf="toAccountMethod === 'ach'" type="to"></app-create-transaction-ach-details>

          <app-create-transaction-wire-details *ngIf="toAccountMethod === 'wire'" type="to"></app-create-transaction-wire-details>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="selectedDeliveryOption$ | async as selectedDeliveryOption">
      <div class="separator-line"></div>

      <div class="funds-delivery-estimate">
        <div class="rows">
          <div class="row">
            <div class="label">Funds Delivery Estimate</div>
            <div class="value">
              <div class="funds-delivery-estimate-date">
                {{ selectedDeliveryOption.estLabelDay }} - {{ selectedDeliveryOption.estLabelDate }}
              </div>
              <div class="funds-delivery-estimate-details">
                We estimate the funds will settle at the receiving institution in the
                <strong>{{ selectedDeliveryOption.estLabelTime }}</strong> of
                <strong>{{ selectedDeliveryOption.estLabelDate }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
