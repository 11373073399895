<div class="auth-layout">
  <div class="auth-layout-content">
    <div class="auth-background"></div>
    <div class="left-col">
      <div class="auth-content-about-us">
        <a routerLink="/"><div class="auth-content-logo"></div></a>

        <div class="auth-content-message">
          <ng-container *ngTemplateOutlet="authMessages; context: { $implicit: templateNumber }"></ng-container>
        </div>
      </div>
    </div>

    <div class="right-col">
      <div class="auth-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <app-auth-layout-footer></app-auth-layout-footer>
</div>

<ng-template #authMessages let-templateNumber>
  <ng-container *ngIf="templateNumber >= quotes.length; else quoteTemplate">
    <div class="title">KOR</div>
    <i class="sub-title">noun</i>
    the central, innermost, or&nbsp;most essential part of&nbsp;anything.
    <i class="sub-title">adjective</i>
    of central importance; basic; fundamental
  </ng-container>

  <ng-template #quoteTemplate>
    <ng-container *ngIf="quotes[templateNumber].author; else noAuthorTemplate">
      &ldquo;{{ quotes[templateNumber].text }}&rdquo;
      <i class="quote-author">{{ quotes[templateNumber].author }}</i>
    </ng-container>

    <ng-template #noAuthorTemplate>
      {{ quotes[templateNumber].text }}
    </ng-template>
  </ng-template>
</ng-template>
