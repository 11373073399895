import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '@shared/models';

export namespace FinancialAccountsActions {
  export const loadFinancialAccountsIds = createAction('[FA API] Load Financial Accounts Ids');

  export const loadFinancialAccountsIdsSuccess = createAction(
    '[FA API] Load Financial Accounts Ids Success',
    props<{ financialAccountsIds: string[] }>(),
  );

  export const loadFinancialAccountsIdsError = createAction('[FA API] Load Financial Accounts Ids Error', props<{ error: ErrorMessage }>());

  export const initialize = createAction('[FA API] Initialize');
}
