<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<h1 class="title">Privacy Policy</h1>

<div class="content">
  <h3>Welcome to RocketKOR</h3>
  <h4 class="fst-italic">Effective date: December 2020</h4>
  <div>
    <p>The words “we”, “us”, and “our” mean RocketKOR.</p>
    <p>
      The words “you”, “your”, and “yours” mean you, your representatives, your employees, people related to your business. We use these
      words to refer to your business.
    </p>
    <p>
      If you inquire about, apply for, or have a financial product or service with us, please review our Privacy Policy, which applies to
      U.S. customers and consumers as described in this Privacy Policy (the “Privacy Policy”).
    </p>
    <p>
      We will collect, store, use and protect any personal information that you share with us. We will not share your information with
      anyone, except as described in this Privacy Policy.
    </p>
    <p>
      This Privacy Policy explains what personal information we collect and how we use and share any such personal information. It also
      explains what you can do to limit the use and sharing of your personal information.
    </p>
    <p>
      Personal information generally means information that identifies you, your business or your devices, and other information we
      associate with it.
    </p>
    <p>
      We reserve the right to change this Privacy Policy at any time, and if we do so, we will post changes and updates to this page. Please
      refer to this Privacy Policy periodically to inform yourself if changes have been made.
    </p>
    <p>Please read this Privacy Policy carefully and understand what we do.</p>
  </div>

  <h3 class="my-4">Principles we follow when collect and share Your Personal Data</h3>

  <ul>
    <li>We safeguard the personal information shared with us in accordance with strict standards of security and confidentiality.</li>
    <li>
      We limit the collection and use of Personal Information to only what is necessary to deliver superior service to our customers. Such
      Personal Information may be used only for the purposes described in Privacy Policy.
    </li>
    <li>
      We retain your personal information for only as long as needed to fulfill the intended purpose for which it was collected, or as may
      be required by law.
    </li>
    <li>We make access mechanisms available that will allow each client to change their personal information at any time.</li>
    <li>We do not reveal personal information to a third party unless this case is not described in Privacy Policy.</li>
    <li>We continually maintain proper safeguarding of the personal information in our care, to protect the privacy of each client.</li>
    <li>
      We require authorized third-party organizations with whom we share personal information to have at least the same strict level of
      security and confidentiality as we do.
    </li>
    <li>We continually assess ourselves to ensure that each client’s privacy rights are being respected.</li>
  </ul>

  <h3 class="my-4">Personal Information we may collect and Sources we may use</h3>

  <p>Personal information we collect may include but is not limited to the examples listed below:</p>

  <ul>
    <li>
      General Information
      <span>
        We may collect the following information: entity name and DBAs, entity physical address, employer tax ID number, licenses, form of
        business, contact phone or email, type of your activity, number of years in business, information on individuals somehow related to
        you, such as name, social security number, date of birth, home address, email address, phone number. We may collect this information
        from you during the enrollment process or during your interactions with our products or services.
      </span>
    </li>

    <li>
      Digital Information
      <span>
        We may collect the following information: information regarding your computer and mobile phone, such as your device’s manufacturer,
        model number, and mobile phone plan carrier, information regarding your IP address, including your geolocation data, your activity
        and behavior on our website, how you were referred to our website or which link you followed to the Apple App or Google Play store,
        a list of your contacts from your phone’s operating system, and/or your communications. We may collect this information through your
        use of our website (“Site”) or mobile app (“App”), from your internet service provider or through web beacons, customized links,
        cookies & other session trackers, if you choose to share your contacts with us, or during your interaction with us via the in-App
        chat, email, phone, or Site.
      </span>
    </li>

    <li>
      Credentials
      <span>
        We may collect the following information: Passwords, password hints, and similar security information used for authentication and
        account access. We may collect this information from you during the enrollment process or after you access our Site or App to update
        your personal information.
      </span>
    </li>

    <li>
      Financial Information
      <span>
        We may collect the following information: bank account numbers, balances, transaction history, credit score and other credit history
        data. We may collect this information from you or during your interactions with our App and Site, our bank partners, from a credit
        bureau, through a payment processor.
      </span>
    </li>

    <li>
      Employment Information
      <span>
        We may collect the following information: number of employees, level of salaries, business structure, leadership, how often you pay
        to employees. We may collect this information from you directly, during the enrollment process or during your interactions with our
        App and Site, or from a third-party service provider.
      </span>
    </li>

    <li>
      Commercial Information
      <span>
        Commercial information is information about your interest in a particular product or service, your purchasing or consuming
        tendencies, receipts or records for products or services, and/or your feedback and opinion about us. We may collect this information
        from you or sort it according to your preferences on our website or from user research after your participating in a questionnaire
        or survey.
      </span>
    </li>

    <li>
      Biometric information
      <span>
        We may collect the following information: voice recordings, facial recognition information. We may use the following sources to
        collect the information: App, Site, customer service and communications platform vendors.
      </span>
    </li>
  </ul>

  <h3 class="my-4">Cookies</h3>

  <p>
    “Cookies” are small data files transferred by a website to your computer's hard drive. We or our service providerssend cookies when you
    surf our Site, or sites where our ads appear, request or personalize information, or register yourself for certain Services. Accepting
    the cookies used on our Site, sites that are “powered by” another company on our behalf, or sites where our ads appear may give us
    access to information about your browsing preferences, which we may use to personalize and enhance your experience. Cookies are
    typically classified as either “session” cookies or “persistent” cookies. Session cookies do not stay on your computer after you close
    your browser. Persistent cookies remain on your computer until you delete them, or they expire. Generally speaking, web browsers
    automatically accept cookies by default. That being said, you can typically prevent cookies or selectively accept cookies by adjusting
    the preferences in your browser. If cookies are not accepted, there may be some features of our Site that will not be available, and
    some websites/pages/content may not display properly. We do not store passwords or any other information about a visitor in a cookie
    that would identify them, locate them, or determine their preferences or their financial activity. Aggregated customer information may
    help us to assess the performance of our Site and develop strategies to maximize utility. This information may be provided to other
    companies, including but not limited to third-party advertisers. This information does NOT include any personal details that can be used
    to identify individuals.
  </p>

  <h3 class="my-4">How can we use and share Your Personal Information?</h3>

  <p>
    All financial companies need to share customers’ Personal Information to run their everyday business. We use the Personal Information we
    collect to provide our products and services; to communicate with you; to create and market products and services; to prevent, detect
    and investigate fraud, hacking, infringement, or other suspected or actual misconduct or crime; for other business purposes and as
    otherwise allowed by law. We also use Personal Information to the extent necessary to comply with our procedures, legal requirements and
    to prevent imminent harm to persons or property. Your Personal Information allows us to verify your identity, communicate with you and
    enforce our agreements with you, as well as secure the best possible experience for all our customers by ensuring compliance with
    applicable US state and federal laws and our own policies. We may also use Personal Information to improve and enhance our offerings to
    you. We use your Personal Information to process and resolve disputes you initiate, to resolve errors and unauthorized transfers, for
    customer service training and quality assurance.
  </p>

  <h4>Reasons we can share your Personal Information</h4>

  <ul>
    <li>
      For our everyday business purposes— such as to process your transactions, maintain your account(s), respond to court orders and legal
      investigations, or report to credit bureaus
    </li>
    <li>For our marketing purposes— to offer our products and services to you</li>
    <li>For joint marketing with other financial companies</li>
    <li>For our affiliates’ everyday business purposes— information about your transactions and experiences</li>
    <li>For our affiliates’ everyday business purposes— information about your creditworthiness</li>
    <li>For our affiliates to market to you</li>
    <li>For non-affiliates to market to you</li>
  </ul>

  <h4>We may disclose your Personal Information to the following entities:</h4>

  <ul>
    <li>Our affiliated and subsidiary companies;</li>
    <li>Non-affiliated third parties that perform services, such as marketing or market research, on our behalf;</li>
    <li>Non-affiliated third parties under contract that service, maintain, or process your transactions and accounts;</li>
    <li>
      Non-affiliated third parties as permitted or required by law to defend our rights or enforce a contract, or to cooperate with
      government agencies or regulators, (including for tax purposes), securities exchanges, self-regulatory bodies and law enforcement
      officials (for example, for reporting suspicious activity or transactions, complying with a subpoena);
    </li>
    <li>
      Non-affiliated third parties to protect against actual or potential fraud or unauthorized transactions, and to protect the security of
      our records and systems, unless prohibited by law;
    </li>
    <li>
      Non-affiliated third parties at your direction or for which you provide your express consent (for example, banking or financial
      institutions and professional service providers).
    </li>
  </ul>

  <p>
    Our service providers are obligated to keep the Personal Information we share with them confidential and use it only to provide services
    specified by RocketKOR. We require our service providers to have administrative, technical, and physical safeguards and apply them
    during our cooperation.
  </p>

  <h3 class="my-4">How long do we keep Your Personal Information?</h3>

  <p>
    We will maintain the information that is necessary to enable us to provide the requested service only for as long as it takes us to to
    provide any such requested service. We may still need to keep your Personal Information to ensure that our systems reflect your
    preferences. We may keep records of any transactions you enter into on the Site or the App for a minimum of six years from the end of
    our relationship with you. We may keep other information about you to the extent necessary to comply with applicable laws and to further
    legitimate business needs.
  </p>

  <h3 class="my-4">Protecting Your Personal Data</h3>

  <p>
    To protect your Personal Information from unauthorized access and use, we use security measures that comply with federal law. These
    measures include computer safeguards and secured files and buildings. We maintain physical, electronic and procedural safeguards
    designed to protect your Personal Information from unauthorized access or intrusion. We regularly adapt these controls to respond to
    changing requirements and advances in technology. We limit access to the Personal Information to those who require it to develop or
    support our products and services, who need such access in connection with providing products or services to you, or for other
    legitimate business purposes. All Personal Information is securely stored on our servers in the United States. We employ the highest in
    industry standards to protect your Personal Information. Although RocketKOR utilizes the highest reasonable levels of data security in
    the industry, we can not guarantee the security of Personal Information or other information provided to us. By using our services, you
    acknowledge and agree that we make no such guarantee, and that you use our systems at your own risk.
  </p>

  <h3 class="my-4">Compromise of Personal Information</h3>

  <p>
    We regularly review our operational and business practices for compliance with corporate policies and procedures governing the security,
    confidentiality, and quality of our information. However, no method of electronic transmission or storage is 100% secure. Therefore, we
    cannot guarantee absolute security of your Personal Information. We shall not be liable for the transfer of any Personal Information
    resulting from loss or distribution of data, the delineation or corruption ofstorage media, power failures, natural phenomena, riots,
    acts of vandalism, sabotage, terrorism or any other event beyond our control. In the event that Personal Information is compromised as a
    result of a breach of security, we will promptly notify those, whose Personal Information has been compromised, in accordance with the
    notification procedures set forth in this Privacy Policy by email, or as otherwise required by applicable law. Remember! You also should
    protect your Personal Information. Please safeguard your username and password for your Account and do not share them with others.
  </p>

  <h3 class="my-4">Notification Procedures</h3>

  <p>
    We will provide notifications, whether such notifications are required by law or are for marketing or other business related purposes,
    to you via email notice, written or hard copy notice, or through conspicuous posting of such notice on our App or Site, as determined by
    RocketKOR in its sole discretion. We reserve the right to determine the form and means of providing notifications to you.
  </p>

  <h3 class="my-4">Your choice regarding Your Personal Data</h3>

  <p>
    Many of our products require some personal data to operate and provide you with a service. If you decide to restrict the use or
    disclosure of your personal information, this may result in the limitation or discontinuance of certain services to you. Likewise, where
    we need to collect personal data by law or to enter into or carry out a contract with you, and you do not provide the data, we will not
    be able to enter into the contract; or if this relates to an existing product you’re using, we may have to suspend or cancel it.
  </p>

  <h3 class="my-4">Minors</h3>

  <p>
    This Site is not directed at individuals under the age of eighteen (18), or individuals under the age of nineteen (19) in the states of
    Alabama or Nebraska, and we do not knowingly collect Personal Information from any individual under the age of eighteen (18), or under
    the age of nineteen (19) in the states of Alabama or Nebraska, at this Site or the App.
  </p>

  <h3 class="my-4">Links to or from Other Websites</h3>

  <p>
    RocketKOR is not responsible for the practices employed by websites linked to or from our App or Site, nor the information or content
    contained therein. Please remember that when you use a link to go from our App or Site to another mobile app or website, our Privacy
    Policy is no longer in effect. Your browsing and interaction on any other mobile app or website, including those that have a link on our
    App or Site, is subject to that mobile app’s or website’s own rules and policies. Please read over those rules and policies before
    proceeding.
  </p>

  <h3 class="my-4">How can I limit the sharing my Personal Data?</h3>

  <p>Federal law gives you the right to limit only:</p>
  <ul>
    <li>
      sharing for affiliates’ everyday business purposes—information about your creditworthiness;sharing for affiliates’ everyday business
      purposes—information about your creditworthiness;
    </li>
    <li>affiliates from using your information to market to you;</li>
    <li>sharing for non-affiliates to market to you.</li>
  </ul>

  <p>
    State laws and individual companies may give you additional rights to limit sharing. See the Policy for more on your rights under state
    law.
  </p>
  <p>
    <strong>Please note:</strong>
    When you are no longer our customer, we continue to share your Personal Information as described in this Privacy Policy. However, you
    can contact us at any time to limit our sharing.
  </p>

  <p>
    <strong>Please note:</strong>
    We may contact our existing customers by mail, telephone, or email to offer additional financial products or services, including
    products and services offered by non-affiliates that we believe may be of interest to you. You may direct us not to send you such
    offers.
  </p>

  <p>
    To limit or “opt-out” of sharing your Personal Information with our affiliated companies for marketing purposes, information about your
    creditworthiness and for non-affiliates to market to you please contact us by calling
    <strong>+1 (XXX) XXX-XXXX (NOTE (as of 02/12/20): phone based Support will not be available until service goes live)</strong> or by
    emailing RocketKOR at <a class="underline" href="mailto:support@RocketKOR.net">support@RocketKOR.net</a>.
  </p>

  <h3 class="my-4">Other Important Information</h3>

  <h4 class="mt-4 mb-0 fst-italic">Notice to California Residents</h4>

  <p>
    Effective January 1, 2020, the California Consumer Privacy Act (“CCPA”) allows California residents, upon a verifiable consumer request
    and subject to applicable exemptions, to request that we give you access, and (if technically feasible) in readily usable form, to the
    specific pieces and categories of Personal Information that we have collected or shared about you. California residents also have the
    right to submit a request for deletion of Personal Information under certain circumstances that RocketKOR is not otherwise entitled to
    maintain.
  </p>

  <p>A California consumer may, for no fee, make the following verifiable requests to a business collecting their Personal Information:</p>

  <ul>
    <li>request to know categories of Personal Information collected and disclosed;</li>
    <li>request to know specific pieces of Personal Information collected; and</li>
    <li>request to delete their Personal Information.</li>
  </ul>

  <p>
    To submit a request under the CCPA, please contact us by calling
    <strong>+1 (XXX) XXXXXXX (NOTE (as of 02/12/20): phone based Support will not be available until service goes live)</strong> or by
    emailing RocketKOR at <a class="underline" href="mailto:support@RocketKOR.net">support@RocketKOR.net</a>. For your protection, we will
    need to verify your identity using the Personal Information associated with your Account before fulfilling your request. Government
    identification may be required. We will try to comply with your request as soon as reasonably practicable and consistent with applicable
    law, which may take up to 45 days following verification of your identity. Please note that the Gramm-Leach-Bliley Act and the CCPA
    allows us to keep certain forms of data for recordkeeping purposes and/or to complete any transactions that you began prior to
    requesting a change or deletion. In addition, there may be certain data that we may not allow you to review for legal, security, or
    other reasons.
  </p>

  <h4 class="mt-4 mb-0 fst-italic">Notice to Vermont Residents</h4>
  <p>
    We will not share information we collect about you with non-affiliated companies except as permitted by law, including, for example with
    your consent or to service your Account. We will not share information about your creditworthiness with affiliated and non-affiliated
    companies except with your authorization or consent, but we may share information about our transactions or experiences with you with
    our affiliates.
  </p>

  <h4 class="mt-4 mb-0 fst-italic">Notice to Nevada Residents</h4>
  <p>
    We may contact our existing customers by telephone to offer additional financial products that we believe may be of interest to you. You
    have the right to opt out of these calls. To opt out of these calls, or for more information about your opt out rights, please reach us
    by calling
    <strong>+1 (XXX) XXX-XXXX (NOTE (as of 02/12/20): phone based Support will not be available until service goes live)</strong> or by
    emailing RocketKOR at <a class="underline" href="mailto:support@RocketKOR.net">support@RocketKOR.net</a>. You are being provided this
    notice under Nevada state law. In addition to contacting RocketKOR, Nevada residents can contact the Nevada Attorney General for more
    information about your opt out rights by calling 702.486.3132, emailing aginfo@ag.nv.gov, or by writing to: Office of the Attorney
    General, Nevada Department of Justice, Bureau of Consumer Protection 100 North Carson Street, Carson City, NV 89701-4717.
  </p>

  <p>
    RocketKOR will not discriminate against customers exercising their rights as mentioned above. Specifically, we will not deny you
    services, charge you different prices for services or provide you with a different level or quality of services.
  </p>

  <p class="my-5 fst-italic">
    Please note! When you visit our App or Site, download and use our services, apply for a financial product or service, or open or
    maintain an account with us, you signify acceptance to the terms of this Privacy Policy and consent to the collection, use, and
    disclosure of your Personal Information as described above. If you do not agree with or you are not comfortable with any aspect of this
    Privacy Policy, you should immediately discontinue access or use of our Services.
  </p>
</div>

<div class="buttons">
  <button rktButton btnType="tertiary" (click)="downloadPrivacyPolicy()">Download PDF</button>
  <button rktButton (click)="activeModal.close()">Close</button>
</div>
