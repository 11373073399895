import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { filter } from 'lodash-es';
import { NotificationService, captureSentryException } from '@shared/services';
import { AuthActions } from '@shared/store';
import { CARD_ACCOUNT_ERROR_CODES, IDV_ERROR_CODES, MESSAGE, TRANSACTION_ERROR_CODES } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';

export const IS_EXTENDED_ERRORS = new HttpContextToken<boolean>(() => false);
export const SKIP_ERROR_NOTIFICATION = new HttpContextToken<boolean>(() => false);
export const CUSTOM_ERROR_MESSAGE = new HttpContextToken<string>(() => '');

const excluded401Urls = ['/validate'].map((urlPattern) => new RegExp(urlPattern, 'i'));

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let errorOutput = (err && err.status !== 0 && err.status) || err.statusText;

        if (
          err &&
          (err.status === HttpStatusCode.BadRequest ||
            err.status === HttpStatusCode.UnprocessableEntity ||
            err.status === HttpStatusCode.Conflict) &&
          err.error &&
          err.error.message
        ) {
          errorOutput = err.error.message;
        }

        // catch by error code
        if (err.error && TRANSACTION_ERROR_CODES.includes(err.error.errorCode)) {
          const { errorCode, message, _embedded = {} } = err.error;

          errorOutput = errorCode + this.captureLimitErrors(errorCode, message, _embedded);
        }

        if (err.error && CARD_ACCOUNT_ERROR_CODES.includes(err.error.errorCode)) {
          errorOutput = err.error?.errorCode || err.error?.message;
        }

        if (err.error && IDV_ERROR_CODES.includes(err.error.errorCode)) errorOutput = JSON.stringify(err.error);

        // return the entire error object if IS_EXTENDED_ERRORS is set to true in the request context
        // and the error status is not [401, 403, 404, 500]
        if (
          request.context.get(IS_EXTENDED_ERRORS) === true &&
          ![HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden, HttpStatusCode.InternalServerError, HttpStatusCode.NotFound].includes(
            err.status,
          )
        ) {
          errorOutput = JSON.stringify(err.error);
        }

        const customErrorMessage = request.context.get(CUSTOM_ERROR_MESSAGE);

        this.captureErrorByHttpCode(err.status, errorOutput, request, request.context.get(SKIP_ERROR_NOTIFICATION) || !!customErrorMessage);

        if (customErrorMessage) {
          errorOutput = customErrorMessage;
          this.notificationService.displayError(customErrorMessage, '');
        }

        return throwError(() => errorOutput);
      }),
    );
  }

  captureErrorByHttpCode(status: HttpStatusCode, errorMessage: string, request: HttpRequest<unknown>, skipNotification: boolean) {
    switch (status) {
      case HttpStatusCode.Unauthorized:
        const passThrough = !!excluded401Urls.find((regex) => regex.test(request.url));

        if (passThrough) {
          return;
        }

        this.store.dispatch(AuthActions.logout({ params: { isReturnUrl: true } }));

        if (!skipNotification)
          this.notificationService.displayError(MESSAGE.SESSION_EXPIRED_NOTIFICATION, MESSAGE.SESSION_EXPIRED_NOTIFICATION_TITLE);

        break;

      case HttpStatusCode.Forbidden:

      case HttpStatusCode.InternalServerError:
        if (
          (request.url.includes('token') && !request.url.includes('exchange-token')) ||
          request.url.includes('linked-business-accounts')
        ) {
          this.store.dispatch(AuthActions.logout({ params: { isReturnUrl: true } }));

          if (!skipNotification)
            this.notificationService.displayError(MESSAGE.SESSION_EXPIRED_NOTIFICATION, MESSAGE.SESSION_EXPIRED_NOTIFICATION_TITLE);
        } else if (status === HttpStatusCode.Forbidden && !request.url.includes('forgot-password') && !skipNotification) {
          this.notificationService.displayError(MESSAGE.PERMISSION_DENIED_NOTIFICATION, MESSAGE.PERMISSION_DENIED_NOTIFICATION_TITLE);
        } else if (status === HttpStatusCode.InternalServerError) {
          if (!skipNotification) this.notificationService.displayError(MESSAGE.SESSION_UNAVAILABLE_NOTIFICATION, '');

          ErrorUtils.catchError(request.url, errorMessage);
          this.captureErrorForSentry(request.url, errorMessage, HttpStatusCode.InternalServerError);
        }
        break;

      case HttpStatusCode.NotFound:
        if (!skipNotification) this.notificationService.displayError(MESSAGE.PAGE_NOT_FOUND_NOTIFICATION, '');
        this.captureErrorForSentry(request.url, errorMessage, HttpStatusCode.NotFound);
        break;

      default:
        break;
    }
  }

  captureErrorForSentry(requestURL: string, errorMessage: string, errorStatusCode: HttpStatusCode) {
    captureSentryException({
      requestURL,
      errorMessage,
      pageURL: location.href,
      errorStatusCode,
      timestamp: new Date().toUTCString(),
    });
  }

  captureLimitErrors(errorCode: string, message: string, _embedded: { [key: string]: { scopeType: string; limitType: string }[] }): string {
    const messageLimits = message.match(/[^([]*(?:provider|settlement|solution|sponsor)[^)\]]*/gi);

    let limits = '';

    if (errorCode === 'insufficient-limit' && _embedded['limit-utilizations']?.length) {
      limits = filter(_embedded['limit-utilizations'], ({ scopeType }) => ['CHILD_BA', 'FA_KFA'].includes(scopeType))
        .sort((a, b) => (a.scopeType > b.scopeType ? 1 : -1))
        .map(({ limitType, scopeType }) => `${scopeType}:${limitType}`)
        .join('::');
    }

    if (messageLimits?.length) {
      limits = (limits.length ? `${limits}::` : '') + messageLimits.join('::');
    }

    return limits ? `__${limits.toLowerCase()}` : '';
  }
}
