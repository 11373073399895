<div class="row-wrapper">
  <div class="row-title-wrapper" (click)="toggleRow()">
    <rkt-icon *ngIf="!!titleIconName" [name]="titleIconName" class="row-title-icon" [class.is-expanded]="!isCollapsed"></rkt-icon>
    <div *ngIf="!!title" class="row-title">{{ title }}</div>
    <rkt-icon class="row-toggler" [class.is-expanded]="!isCollapsed" [name]="isCollapsed ? 'chevron-down' : 'chevron-up'"></rkt-icon>
  </div>
  <div *ngIf="!isCollapsed" class="row-content">
    <ng-content></ng-content>
  </div>
</div>
