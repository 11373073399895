import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MoveHowWireDetails, TransactionSettlementPriority } from '@shared/models';
import { AbstractMoveMoneyBase } from '../move-money-wrapper/move-money-wrapper.service';

@Component({
  selector: 'app-move-how-wire-details',
  templateUrl: './move-how-wire-details.component.html',
  styleUrls: ['./move-how-wire-details.component.scss'],
})
export class MoveHowWireDetailsComponent {
  @Input() isSameDayWindowDisabled?: boolean;

  @Input() isNextDayWireWindowDisabled?: boolean;

  @Output() selected = new EventEmitter<MoveHowWireDetails>();

  wireType = new FormControl<TransactionSettlementPriority | null>(null);

  constructor(private moveMoneyService: AbstractMoveMoneyBase) {}

  setInitialValues(): void {
    const { moveWireType } = this.moveMoneyService.moveHowWireDetails || {};

    if (moveWireType) {
      this.wireType.setValue(moveWireType);
    }
  }

  onWireTypeSelect(wireType?: TransactionSettlementPriority) {
    if (wireType) {
      this.wireType.setValue(wireType);
    }
    this.onMoveHowWireDetailsSelect();
  }

  onMoveHowWireDetailsSelect() {
    this.selected.emit({
      moveWireType: this.wireType?.value ?? undefined,
    });
  }

  resetValue(): void {
    this.wireType.reset();
    this.onMoveHowWireDetailsSelect();
  }
}
