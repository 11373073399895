<form [formGroup]="achDetailsForm" class="ach-sec-code-wrapper">
  <rkt-form [form]="achDetailsForm" (modelChange)="onSecCodeChange()" [fields]="secCodeField" [model]="achDetailsModel"></rkt-form>
</form>

<form [formGroup]="achDetailsForm" class="ach-details-wrapper">
  <rkt-form [form]="achDetailsForm" [fields]="achDetailsField" (modelChange)="onAchDetailsChange()" [model]="achDetailsModel"></rkt-form>
</form>

<div class="ach-type-wrapper">
  <div class="ach-type-item" [class.disabled]="isSameDayWindowDisabled">
    <label for="ach-same-day" class="ach-type-radio-input-label" [class.disabled]="isSameDayWindowDisabled">
      <span class="ach-type-radio-input" [class.checked]="achType.value === 'SAME_DAY'"></span>

      <input
        hidden
        id="ach-same-day"
        value="SAME_DAY"
        [formControl]="achType"
        (change)="onAchTypeSelect()"
        [attr.disabled]="isSameDayWindowDisabled ? true : null"
        name="ach-type"
        type="radio"
        class="form-check-input"
      />
      Same Day
    </label>

    <div class="ach-type-description">Funds will be available to the recipient today</div>
  </div>

  <div class="ach-type-item">
    <label for="ach-next-day" class="ach-type-radio-input-label">
      <span class="ach-type-radio-input" [class.checked]="achType.value === 'NEXT_DAY'"></span>

      <input
        hidden
        id="ach-next-day"
        value="NEXT_DAY"
        [formControl]="achType"
        (change)="onAchTypeSelect()"
        name="ach-type"
        type="radio"
        class="form-check-input"
      />
      Next Day
    </label>

    <div class="ach-type-description">Funds will be available to the recipient on the next banking day</div>
  </div>
</div>
