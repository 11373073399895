<div class="table-container">
  <rkt-table
    [rows]="businessFinancialAccounts"
    [columns]="columns"
    [limit]="10"
    (fetchData)="getBusinessFinancialAccounts($event)"
    [count]="totalFinancialAccountsElements"
    [loadingIndicator]="financialAccountsLoading"
    [stickyHeader]="stickyHeader"
    [offset]="activeFinancialAccountsPage"
    [messages]="{ emptyMessage: emptyListMessage }"
    emptyValuePlaceholder="--"
    [selectionType]="SelectionType.single"
    (rowSelected)="onBusinessFinancialAccountsSelect($event)"
    [footerHeight]="56"
  ></rkt-table>
</div>
